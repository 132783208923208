import {ColorModel} from "@/models/model_colors"
import model_chart_text, {ChartTextModel} from "@/models/content/charts/model_chart_text"


export interface ChartFunctionModel {
  functionExpression: string
  functionExpression2?: string
  label?: ChartTextModel
  color: ColorModel
  strokeWidth: number
  x_min?: number
  x_max?: number
  isFilled?: boolean
  tu?: Array<TunableParameter>
}

export interface TunableParameter {
  la?: string
  vr: string
  sv: boolean
  vd: number
  ta?: number
  de?: number
  sm: boolean
  mi: number
  ma: number
}

export default {
  template(): ChartFunctionModel {
    return {
      functionExpression: 'x',
      color: ColorModel.cyan,
      strokeWidth: 2,
      isFilled: false,
    }
  },
  template_small(): ChartFunctionModel {

    let label: ChartTextModel = model_chart_text.template()
    label.text.text = 'f'
    label.text.size = 14
    label.text.isFormula = true

    return {
      functionExpression: 'x',
      color: ColorModel.cyan,
      strokeWidth: 1.5,
      isFilled: false,
      label: label,
    }
  },
  template_tunableParameter(): TunableParameter {
    return {
      la: '$$a$$',
      vr: 'a',
      sv: true,
      vd: 1,
      sm: true,
      mi: 0,
      ma: 5,
    }
  },
  template_tunableParameter_action(): TunableParameter {
    return {
      la: '$$a$$',
      vr: 'a',
      sv: false,
      vd: 1,
      ta: 2.5,
      sm: false,
      mi: 0,
      ma: 5,
    }
  },
}