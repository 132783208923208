



























import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {RecordingModel} from "@/models/recording/model_recording"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import Checkbox from "@/components/fundamental/inputs/Checkbox.vue"
import {eventbus} from "@/main"
import {AlertDialogType, EventType} from "@/components/app/eventModel"
import util from "@/util/util"
import IconButton from "@/components/fundamental/buttons/IconButton.vue"
import VideoEditorDialog from "@/components/video/editor/VideoEditorDialog.vue"
import WaitForRecorderDialog from "@/components/recorder/recorderWindow/WaitForRecorderDialog.vue"
import {RecorderRequestModel} from "@/models/recording/model_recorder"
import {PredefinedActionState} from "@/simulator/screen/sim_screen_communication"
import store from "@/store/store"
import {ScreenModel} from "@/models/screen/model_screen"
import appState from "@/app/state/app_state"
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue"


@Component({
  components: {
    AdvancedEditText,
    WaitForRecorderDialog, VideoEditorDialog, IconButton, Checkbox, SpacerBox, TextField, TextButton, Row, Column}
})
export default class NewRecordingButton extends Vue {
  @Prop({required: true}) videoId!: string|undefined
  @Prop({type: String, required: true}) unitId!: string
  @Prop({type: Object, required: true}) screen!: ScreenModel
  @Prop({type: Array, required: true}) recordings!: Array<RecordingModel>
  @Prop({type: String, default: null, required: false}) predefinedActionState!: PredefinedActionState
  @Prop({type: Boolean, default: false, required: false}) landscapeMode!: boolean

  isWaitingForRecorder = false
  newRecordingId = ''

  get recording() {
    return this.videoId ? this.recordings.find(r => r.id === this.videoId) : undefined
  }


  /////////////////////////////////
  // Watch
  /////////////////////////////////
  recorderWindow = store.recorderWindow // is required for @Watch('recorderWindow.response')

  @Watch('recorderWindow.response')
  responseChanged() {
    if (store.recorderWindow.response) {
      this.handleRecorderWindowResponse()
    }
  }


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  async requestNewRecording() {
    if (appState.unsavedUnitChanges) {
      eventbus.$emit(EventType.alertDialog, {
        alertType: AlertDialogType.warning,
        title: 'Unsaved changes',
        text: 'Save current changes before recording new video.',
        positiveButton: 'Save',
        callback: (response: boolean) => {
          if (response) {
            appState.unitSavingRequest = true
          }
        }
      })

      return
    }

    this.newRecordingId = this.videoId ? this.videoId : util.getId('re')

    let request: RecorderRequestModel = {
      unitId: this.unitId,
      screenId: this.screen.id,
      recordingId: this.newRecordingId,
      landscapeMode: this.landscapeMode,
      predefinedActionState: this.predefinedActionState
    }

    this.showLoading(true)
    await store.recorderWindow.submitRequest(request)
    this.isWaitingForRecorder = true
    this.showLoading(false)
  }

  async cancelRecording() {
    await store.recorderWindow.cancelRequest()
    this.isWaitingForRecorder = false
  }

  async handleRecorderWindowResponse() {
    let response = store.recorderWindow.response
    console.log('handleRecorderWindowResponse', response, this.newRecordingId)

    // If we get a new response from the recorder window, we add the recording to local recording.
    if (response && response.recordingId && response.recordingId === this.newRecordingId) {

      // try 3 times to load recording
      let recording: RecordingModel|null = null
      for (let i = 0; i < 3; i++) {
        recording = await store.recordings.getRemoteRecording(this.newRecordingId)
        if (recording) {
          break
        }
        await new Promise(resolve => setTimeout(resolve, 2000))
      }


      if (recording) {
        // If recording already exists, it will be replaced. Otherwise it will be added to the recordings array.
        let index = this.recordings.findIndex(r => r.id === recording!.id)
        if (index >= 0) {
          this.$set(this.recordings, index, recording)
        } else {
          this.recordings.push(recording)
        }

        this.$emit('recordingCompleted', this.newRecordingId)
        this.newRecordingId = ''
      } else {
        console.error('ERROR - NewRecordingButton: failed to load recording: ' + this.newRecordingId)
      }
    } else if (response) {
      console.error('ERROR - NewRecordingButton: recordingIds do not match - response recordingId: ' + response.recordingId + ' newRecordingId: ' + this.newRecordingId)
    } else {
      console.error('ERROR - NewRecordingButton: no response: ' + response)
    }

    this.isWaitingForRecorder = false
  }

  requestDeleting() {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.delete_high,
      title: `Delete recording`,
      text: 'This will permanently delete the recording.',
      callback: (response: boolean) => {
        if (response) {
          this.deleteRecording()
        }
      }
    })
  }

  deleteRecording() {
    let recordingIndex = this.recordings.findIndex(recording => recording.id === this.videoId)
    util.removeFromArray(this.recordings, recordingIndex)

    this.$emit('recordingCompleted', undefined)
  }

  showLoading(show: boolean) {
    eventbus.$emit(EventType.loadingDialog, show)
  }

  addRecordingManually(recordingId: string) {
    this.$emit('recordingCompleted', recordingId)
  }

}
