import {ContentModel} from "@/models/content/model_content"

/////////////////////////////////
// Send
/////////////////////////////////
export enum SendType {
  contentData = 'contentData',
}

export interface PostSimulatorMessage {
  type: SendType
}

export interface PostSimulatorMessage_contentData extends PostSimulatorMessage {
  type: SendType.contentData
  contentData: ContentModel
}


/////////////////////////////////
// Receive
/////////////////////////////////
export enum ReceiveType {
  initCompleted = 'initCompleted',
}

export interface ReceivedSimulatorMessage {
  type: ReceiveType
  parentId: String
}

export interface ReceivedSimulatorMessage_initCompleted extends ReceivedSimulatorMessage {
  type: ReceiveType.initCompleted
  version: String
}