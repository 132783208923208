



















































import {Component, Prop, Vue} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import OfficialSections from "./OfficialSections.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import {OfficialChapterModel, CurriculumModel} from "@/models/curriculum/model_curriculum"

@Component({
  components: {Column, OfficialSections, RoundIconButton, InputField, Row, Container}
})
export default class OfficialChapterElement extends Vue {
  @Prop({type: Object, required: true}) curriculum!: CurriculumModel
  @Prop({type: Object, required: true}) chapter!: OfficialChapterModel
  @Prop({type: String, required: true}) topicPrefix!: string
  @Prop({type: Number, required: true}) chapterIndex!: number

  show = true
}
