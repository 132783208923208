







































import {Component, Prop, Vue} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Checkbox from "@/components/fundamental/inputs/Checkbox.vue"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import BasicTextEditor from "@/components/screen_editor/content/text/BasicTextEditor.vue"

@Component({
  components: {BasicTextEditor, TextButton, Column, TransitionEffect, Checkbox, TextField, Row}
})
export default class BasicTextEditorDialog extends Vue {
  @Prop({type: String, default: '', required: false}) title!: string
  @Prop({required: true}) value!: string | undefined
  @Prop({type: Boolean, default: false, required: false}) isNullable!: boolean
  @Prop({type: Boolean, default: false, required: false}) isFormula!: boolean
  @Prop({type: Number, default: 0, required: false}) width!: number
  @Prop({type: Number, default: 0, required: false}) titleWidth!: number
  @Prop({type: Boolean, default: false, required: false}) titleBold!: boolean

  isDialogVisible = false
  newValue: string = this.value ? this.value : ''

  get rowStyle() {
    let s = ''
    if (this.width > 0 ) {
      s += 'width:' + this.width + 'px;'
    }
    return s
  }

  get titleStyle() {
    let s = ''
    if (this.titleWidth > 0 ) {
      s += 'width:' + this.titleWidth + 'px;'
    }
    return s
  }

  open() {
    if (!this.value) {
      this.newValue = ''
    } else {
      this.newValue = this.value
    }

    this.isDialogVisible = true
  }

  save() {
    this.isDialogVisible = false
    this.$emit('input', this.newValue)
  }

  cancel() {
    this.isDialogVisible = false
  }

  resetValue() {
    this.newValue = ''
    this.isDialogVisible = false
    this.$emit('input', undefined)
  }
}
