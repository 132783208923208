import {ActionModel, ActionType} from "@/models/action/model_action"
import model_response from "@/models/action/model_response"

export interface SlideUnlockActionModel extends ActionModel {

}

export default {
  template_action(): SlideUnlockActionModel {
    return {
      type: ActionType.slideUnlock,
      responses: model_response.template_responses(),
    }
  },
}