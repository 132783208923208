import axios from 'axios'

export interface TenorHelperResponse {
  item?: TenorHelperResponseItem
  msg: string
}

interface TenorHelperResponseItem {
  url: string,
  size: number,
  width: number,
  height: number,
}

export default {
  async fetchGif(tenorId: string): Promise<TenorHelperResponse> {

    if (tenorId.startsWith('https://tenor.com/')) {
      let a = tenorId.split('-')
      tenorId = a[a.length - 1]
    }

    if (!tenorId || (tenorId.length < 6 || tenorId.length > 10) || !tenorId.match(/^[0-9]+$/)) {
      return ({item: undefined, msg: 'Please enter a valid Tenor ID.'})
    }


    try {
      let response = await axios.get('https://api.tenor.com/v1/gifs?ids=' + tenorId + '&key=21ZN5DFPQOUQ&media_filter=minimal') // account michael@niedermayr.tech

      if (!response.data || !response.data.results || response.data.results.length === 0) {
        return ({item: undefined, msg: 'Could not find gif. Please check the Tenor ID.'})
      }

      let result = response.data.results[0]

      if (!result.media || result.media.length === 0 || !result.media[0].tinygif) {
        return ({item: undefined, msg: 'No Tinygif available for this ID. Please enter a different ID.'})
      }

      let tinyGif = result.media[0].tinygif
      let item = {
        url: tinyGif.url,
        size: tinyGif.size,
        width: tinyGif.dims[0],
        height: tinyGif.dims[1],
      }
      return ({item: item, msg: ''})


    } catch (e) {
      throw e
    }
  }
}