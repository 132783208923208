import {RecordingModel} from "@/models/recording/model_recording"
import {MockVideoPlayer, PlayerStatus, VideoPlayer, VideoPlayerValue, VimeoVideoPlayer} from "@/components/video/controller/VideoPlayer"
import {PathModel} from "@/models/recording/model_path"
import PathHandler from "@/components/video/controller/PathHandler"


export class VideoControllerValue {
  recording: RecordingModel
  width = 0

  isInitialized = false
  isBuffering = false
  isPlaying = false
  isFinished = false

  time = 0
  speed = 1
  volume = 1

  currentPaths: Array<PathModel> = []

  status = PlayerStatus.ok

  constructor(recording: RecordingModel, width: number) {
    this.recording = recording
    this.width = width
  }
}


export class VideoController {

  /////////////////////////////////
  // Members
  /////////////////////////////////
  value: VideoControllerValue
  _valueCallbacks: Array<((v: VideoControllerValue) => void) | null> = []
  _videoPlayer: VideoPlayer
  _pathHandler: PathHandler

  /////////////////////////////////
  // Constructor
  /////////////////////////////////
  constructor(recording: RecordingModel, width = 0, containerId: string) {
    this.value = new VideoControllerValue(recording, width)
    this._pathHandler = new PathHandler(recording)

    if (recording.url) {
      this._videoPlayer = new VimeoVideoPlayer(
        recording,
        (v: VideoPlayerValue) => {
          this._playerCallback(v)
        },
        width,
        containerId
      )
    } else {
      this._videoPlayer = new MockVideoPlayer(
        recording,
        (v: VideoPlayerValue) => {
          this._playerCallback(v)
        },
        width
      )
    }
  }

  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  destroy() {
    this.removeAllValueCallbacks()
    this._videoPlayer.destroy()
  }


  /////////////////////////////////
  // Controller Callbacks
  /////////////////////////////////
  addValueCallback(callback: (v: VideoControllerValue) => void): number {
    this._valueCallbacks.push(callback)
    callback(this.value)
    return this._valueCallbacks.length - 1
  }

  removeValueCallback(id: number) {
    this._valueCallbacks[id] = null
  }

  removeAllValueCallbacks() {
    for (let i = 0; i < this._valueCallbacks.length; i++) {
      this._valueCallbacks[i] = null
    }
  }

  _valueChanged() {
    for (let i = 0; i < this._valueCallbacks.length; i++) {
      let callback = this._valueCallbacks[i]
      if (callback) {
        callback(this.value)
      }
    }
  }


  /////////////////////////////////
  // Player Methods
  /////////////////////////////////
  togglePlay() {
    this._videoPlayer.togglePlay()
  }

  start() {
    this._videoPlayer.start()
  }

  pause() {
    this._videoPlayer.pause()
  }

  setTime(time: number) {
    this._videoPlayer.setTime(time)
  }

  setSpeed(speed: number) {
    this._videoPlayer.setSpeed(speed)
  }

  setVolume(volume: number) {
    this._videoPlayer.setVolume(volume)
  }


  /////////////////////////////////
  // Player Callback
  /////////////////////////////////
  _playerCallback(v: VideoPlayerValue) {
    this.value.isInitialized = v.isInitialized
    this.value.isBuffering = v.isBuffering
    this.value.isPlaying = v.isPlaying
    this.value.isFinished = v.isFinished

    this.value.time = v.time
    this.value.speed = v.speed
    this.value.volume = v.volume

    this.value.currentPaths = this._pathHandler.updateTime(v.time)

    this.value.status = v.status

    this._valueChanged()
  }
}