














































import {Component, Prop, Vue} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import {UnitModel} from "@/models/unit/model_unit"
import {RecordingModel} from "@/models/recording/model_recording"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import util from "@/util/util"

@Component({
  components: {SpacerBox, TextField, Row, Container}
})
export default class UnitInfo extends Vue {
  @Prop({type: Object, required: false}) unit!: UnitModel
  @Prop({type: Array, default: undefined, required: false}) recordings!: Array<RecordingModel>
  @Prop({type: Boolean, default: false, required: false}) small!: boolean

  gap = this.small ? 0 : 8

  get numScreens() {
    return this.unit.screens.length
  }
}
