






























































































import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {EventType, NavbarViewModel, SidePanelViewModel} from "@/components/app/eventModel"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import SectionLayout from "@/components/fundamental/layout/SectionLayout.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import 'firebase/firestore'
import Container from "@/components/fundamental/layout/Container.vue"
import {Route} from "vue-router"
import {eventbus} from "@/main"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import AdvancedCheckbox from "@/components/fundamental/advanced_inputs/AdvancedCheckbox.vue"
import appState from "@/app/state/app_state"
import AdvancedSelect from "@/components/fundamental/advanced_inputs/AdvancedSelect.vue"

@Component({
  components: {
    AdvancedSelect,
    AdvancedCheckbox,
    SpacerBox,
    Container, SelectField, InputField, TextField, SectionLayout, TextButton, Row, Column}
})
export default class SearchPage extends Vue {

  editorState = appState.regexpEditor

  searchTemplateValues = [
    '( |^)(\\w\\d?)( |\\.|$|[\\n\\r])',
    '( |^)(\\w\\w?\\(\\w\\))( |\\.|$|[\\n\\r])'
  ]
  searchTemplateLabels = [
    'Find x or x1',
    'Find f(x)',
  ]

  replaceTemplateValues = [
    '$1$$$$$2$$$$$3'
  ]
  replaceTemplateLabels = [
    'Replace with $$x$$'
  ]


  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  created() {
    window.scrollTo(0, 0)

    // update navbar
    eventbus.$emit(EventType.navbar, new NavbarViewModel(true))

    // update sidePanel
    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(false))
  }

  beforeRouteLeave(to: Route, from: Route, next: () => void) {

    next()
  }


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  updateSearch(s: string) {
    this.editorState.search = s
    this.editorState.useRegEx = true
  }

  updateReplace(s: string) {
    this.editorState.replace = s
  }


  run() {
    // @ts-ignore
    this.editorState.text = this.editorState.text.replaceAll(
      this.editorState.useRegEx ? new RegExp(this.editorState.search, 'ig') : this.editorState.search,
      this.editorState.replace
    )
  }

}
