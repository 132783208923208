import model_text, {TextModel} from "@/models/content/model_text"


export interface ChartTextModel {
  position: Array<number>
  text: TextModel
}

export default {
  template(): ChartTextModel {
    return {
      position: [0.5, 0.5],
      text: model_text.template(),
    }
  },
}