







































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import util from '@/util/util'
import Draggable from "@/components/fundamental/layout/Draggable.vue"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import model_action_drag_drop, {DragDropActionModel} from "@/models/action/model_action_drag_drop"
import DragDropItem from "@/components/screen_editor/action/drag_drop/DragDropItem.vue"
import ActionSectionHeader from "@/components/basic/ActionSectionHeader.vue"
import ContentEditor from "@/components/screen_editor/content/ContentEditor.vue"
import AdvancedEditNumber from "@/components/fundamental/advanced_inputs/AdvancedEditNumber.vue"
import AdvancedCheckbox from "@/components/fundamental/advanced_inputs/AdvancedCheckbox.vue"
import {ContentModel} from "@/models/content/model_content"


@Component({
  components: {
    AdvancedCheckbox,
    AdvancedEditNumber, ContentEditor, ActionSectionHeader, DragDropItem, Container,
    SpacerBox, AddButton, Draggable, Row, Column
  }
})
export default class DragDropActionEditor extends Vue {
  @Prop({type: Object, required: true}) action!: DragDropActionModel

  addDragItem() {
    this.action.dragItems.push(model_action_drag_drop.template_dragItem())
  }

  updateContent(newContent: ContentModel) {
    this.action.backgroundContent = newContent
  }

  deleteDragItem(index: number) {
    util.removeFromArray(this.action.dragItems, index)
  }
}
