import model_firestore, {FirestoreModel} from "@/models/model_firestore"
import util from "@/util/util"

export interface CurriculumModel extends FirestoreModel {
  co: Country
  sc: string
  to: Array<OfficialTopicModel>
}

export enum Country {
  austria = 'austria',
  germany = 'germany',
  switzerland = 'switzerland',
}

export interface OfficialTopicModel {
  id: string
  ti: string
  pr: string
  ch: Array<OfficialChapterModel>
}

export interface OfficialChapterModel {
  id: string
  ti: string
  se: Array<OfficialSectionModel>
}

export interface OfficialSectionModel {
  id: string
  ti: string
  de: string
  su: Array<OfficialSubsectionModel>
}

export interface OfficialSubsectionModel {
  id: string
  ti: string
  un: Array<string>
}

export default {
  template_curriculum(): CurriculumModel {
    return {
      ...model_firestore.template('cu'),
      co: Country.austria,
      sc: '',
      to: [],
    }
  },
  template_officialTopic(): OfficialTopicModel {
    return {
      id: util.getId('to'),
      ti: '',
      pr: '',
      ch: []
    }
  },
  template_officialChapter(): OfficialChapterModel {
    return {
      id: util.getId('ch'),
      ti: '',
      se: []
    }
  },
  template_officialSection(): OfficialSectionModel {
    return {
      id: util.getId('se'),
      ti: '',
      de: '',
      su: [],
    }
  },
  template_officialSubsection(): OfficialSubsectionModel {
    return {
      id: util.getId('su'),
      ti: '',
      un: [],
    }
  },
}