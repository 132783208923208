
















































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {
  PathModel,
  PenPathModel,
  SpacerPathModel,
  PathColor,
  PathType,
  PathWidth,
  SliderPathModel
} from "@/models/recording/model_path"
import util from '@/util/util'
import Toolbox from "@/components/recorder/drawingboard/Toolbox.vue"
import ShortcutsDialog from "@/components/recorder/drawingboard/ShortcutsDialog.vue"
import {Devices} from "@/app/devices"
import Container from "@/components/fundamental/layout/Container.vue"
import {StartPathEvent} from "@/components/recorder/drawingboard/drawingboardEvents"
import ScreenWidget from "@/simulator/screen/ScreenWidget.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import {PredefinedActionState, ReceivedSimulatorMessage_addSpacer, ReceivedSimulatorMessage_sliderChanged} from "@/simulator/screen/sim_screen_communication"
import mMath from "@/util/mMath"
import Whiteboard from "@/components/simulator/screen/whiteboard/Whiteboard.vue"
import Eventboard from "@/components/recorder/drawingboard/Eventboard.vue"
import {ScreenModel} from "@/models/screen/model_screen"
import {PointModel} from "@/models/math/model_point"
import SideToolbox from "@/components/recorder/drawingboard/SideToolbox.vue";

@Component({
  components: {
    SideToolbox,
    Eventboard, Whiteboard, SpacerBox, ScreenWidget: ScreenWidget, Container, ShortcutsDialog, Toolbox, Row, Column}
})
export default class Drawingboard extends Vue {
  @Prop({default: null, required: true}) screen!: ScreenModel | null
  @Prop({type: Boolean, default: true, required: false}) active!: boolean
  @Prop({type: String, default: null, required: false}) predefinedActionState!: PredefinedActionState
  @Prop({type: Boolean, default: false, required: false}) linkedExercise!: boolean
  @Prop({type: Boolean, default: false, required: false}) landscapeMode!: boolean

  pathWidths = PathWidth

  drawingboardContainerHeight = 0
  screenHeight = 0
  isLargeDisplay = true
  showBorders = false
  drawingModeActive = true

  isFlashlight = false
  pathColor_pen = PathColor.black
  pathWidth_pen = PathWidth.small
  pathWidth_flashlight = PathWidth.medium

  paths: Array<PathModel> = []
  spacerPaths: Array<PathModel> = []

  startTime = 0

  reducedWidth = 0.95


  /////////////////////////////////
  // Computed
  /////////////////////////////////
  get displayWidth() {
    return this.isLargeDisplay ? Devices.getDefault().width*2 : Devices.getDefault().width
  }

  get displayHeight() {
    return (this.isLargeDisplay ? Devices.getDefault().height*2 : Devices.getDefault().height) * 3
  }

  get extraWidth() {
    return this.landscapeMode ? this.displayWidth * (640-400)/400 : 0
  }


  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  created() {
    this.updateWindowHeight()
  }

  mounted() {
    window.addEventListener('resize', this.updateWindowHeight)
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowHeight)
  }

  @Watch('paths', {deep: true})
  pathsChanged() {
    let paths: Array<PathModel> = []
    for (let path of this.paths) {
      if (path.type == PathType.spacer) {
        paths.push(path)
      }
    }

    if (paths.length != this.spacerPaths.length) {
      this.spacerPaths = paths
    }
  }


  /////////////////////////////////
  // Settings
  /////////////////////////////////
  updateWindowHeight() {
    this.drawingboardContainerHeight = Math.max(window.innerHeight - 350, 300)
  }

  toggleDisplaySize() {
    this.isLargeDisplay = !this.isLargeDisplay
  }

  togglePathType() {
    this.isFlashlight = !this.isFlashlight
  }

  toggleBorders() {
    this.showBorders = !this.showBorders
  }

  toggleDrawingMode() {
    this.drawingModeActive = !this.drawingModeActive
    if (this.drawingModeActive && this.showBorders) {
      this.showBorders = false
    }
  }

  setPathColor(color: PathColor) {
    this.pathColor_pen = color
  }

  setPathWidth(width: PathWidth) {
    if (this.isFlashlight) {
      this.pathWidth_flashlight = width
    } else {
      this.pathWidth_pen = width
    }
  }


  /////////////////////////////////
  // Pen and flashlight
  /////////////////////////////////
  startPath(pathCoordinates: PointModel) {
    if (this.paths.length === 0) {
      this.startTime = Date.now() / 1000
    }

    let path = {
      type: this.isFlashlight ? PathType.flashlight : PathType.pen,
      width: this.isFlashlight ? this.pathWidth_flashlight : this.pathWidth_pen,
      color: this.isFlashlight ? PathColor.black : this.pathColor_pen,
      x: [mMath.round(pathCoordinates.x, 4)],
      y: [mMath.round(pathCoordinates.y, 4)],
      time: [mMath.round(Date.now() / 1000 - this.startTime, 4)],
    }
    this.paths.push(path)

    let startPathEvent: StartPathEvent = {
      type: this.isFlashlight ? PathType.flashlight : PathType.pen,
      width: this.isFlashlight ? this.pathWidth_flashlight : this.pathWidth_pen,
      color: this.isFlashlight ? PathColor.black : this.pathColor_pen,
      x: pathCoordinates.x*this.reducedWidth + (1-this.reducedWidth)/2,
      y: pathCoordinates.y*this.reducedWidth
    }
    this.$emit('startPath', startPathEvent)
  }

  updatePath(pathCoordinates: PointModel) {
    let lastPath = this.paths[this.paths.length - 1]

    if (lastPath.type === PathType.pen || lastPath.type === PathType.flashlight) {
      let p = lastPath as PenPathModel
      p.x.push(mMath.round(pathCoordinates.x, 4))
      p.y.push(mMath.round(pathCoordinates.y, 4))
      p.time.push(mMath.round(Date.now() / 1000 - this.startTime, 4))

      let emitPoint = {
        x: pathCoordinates.x*this.reducedWidth + (1-this.reducedWidth)/2,
        y: pathCoordinates.y*this.reducedWidth
      }
      this.$emit('updatePath', emitPoint)
    }
  }

  stopPath() {
    this.emitNewPaths()
  }


  /////////////////////////////////
  // Spacer
  /////////////////////////////////
  addSpacer(event: ReceivedSimulatorMessage_addSpacer) {
    if (!this.active) return

    let p: SpacerPathModel = {
      type: PathType.spacer,
      ad: {
        lo: event.location,
        ii: event.index,
      },
      h: event.positive ? 50 : -50,
      t: 0,
    }

    this.paths.push(p)
    this.$emit('addSpacer', p)
    this.emitNewPaths()

    // @ts-ignore
    this.$refs.drawingboard.getDiv().focus()
  }


  /////////////////////////////////
  // Slider Changed
  /////////////////////////////////
  sliderChanged(event: ReceivedSimulatorMessage_sliderChanged) {
    if (!this.active) return

    let addedDataToCurrentPath = false
    let l = this.paths.length
    if (l > 0 && this.paths[l-1].type == PathType.slider) {
      let lastPath = this.paths[l-1] as SliderPathModel
      if (lastPath.ad.ii === event.itemIndex && lastPath.ad.si === event.sliderIndex) {
        lastPath.t.push(0)
        lastPath.v.push(event.value)
        addedDataToCurrentPath = true
      }
    }

    if (!addedDataToCurrentPath) {
      let p: SliderPathModel = {
        type: PathType.slider,
        ad: {
          lo: event.location,
          ii: event.itemIndex,
          si: event.sliderIndex,
        },
        t: [0],
        v: [event.value],
      }
      this.paths.push(p)
    }

    this.$emit('sliderChanged', event)
    this.emitNewPaths()

    // @ts-ignore
    this.$refs.drawingboard.getDiv().focus()
  }


  /////////////////////////////////
  // Delete
  /////////////////////////////////
  deleteAll() {
    this.paths = []
    this.$emit('deleteAllPaths')
    this.emitNewPaths()
  }

  deleteLast() {
    if (!this.active) return

    for (let i = this.paths.length - 1; i >= 0; i--) {
      if (this.paths[i].type === PathType.pen || this.paths[i].type === PathType.flashlight) {
        util.removeFromArray(this.paths, this.paths.length - 1)
        this.$emit('deleteLastPath')
        this.emitNewPaths()
        break
      }
    }
  }


  /////////////////////////////////
  // Scrolling
  /////////////////////////////////
  scrollUp() {
    // @ts-ignore
    let container = this.$refs.drawingboardContainer.getDiv()
    container.scrollTop = Math.max(container.scrollTop - 50, 0)
  }

  scrollDown() {
    // @ts-ignore
    let container = this.$refs.drawingboardContainer.getDiv()
    container.scrollTop = Math.min(container.scrollTop + 50, container.scrollHeight - container.clientHeight)
  }


  /////////////////////////////////
  // Helper
  /////////////////////////////////
  emitNewPaths() {
    this.$emit('newPathsAvailable', util.copy(this.paths))
  }

  handleShortcuts(event: any) {
    switch (event.srcKey) {
      case 'colorBlack':
        this.setPathColor(PathColor.black)
        break
      case 'colorBlue':
        this.setPathColor(PathColor.blue)
        break
      case 'colorGreen':
        this.setPathColor(PathColor.green)
        break
      case 'colorYellow':
        this.setPathColor(PathColor.yellow)
        break
      case 'colorRed':
        this.setPathColor(PathColor.red)
        break

      case 'pathSmall':
        this.setPathWidth(PathWidth.small)
        break
      case 'pathMedium':
        this.setPathWidth(PathWidth.medium)
        break
      case 'pathLarge':
        this.setPathWidth(PathWidth.large)
        break

      case 'deleteAll':
        this.deleteAll()
        break
      case 'deleteLast':
        this.deleteLast()
        break

      case 'togglePath':
        this.togglePathType()
        break
      case 'toggleDrawingMode':
        this.toggleDrawingMode()
        break
    }
  }

  handleIframeKeyboardShortcuts(key: String) {
    switch (key) {
      case 'a':
        this.setPathColor(PathColor.black)
        break
      case 'b':
        this.setPathColor(PathColor.blue)
        break
      case 'c':
        this.setPathColor(PathColor.green)
        break
      case 'd':
        this.setPathColor(PathColor.yellow)
        break
      case 'e':
        this.setPathColor(PathColor.red)
        break

      case 'g':
        this.setPathWidth(PathWidth.small)
        break
      case 'h':
        this.setPathWidth(PathWidth.medium)
        break
      case 'i':
        this.setPathWidth(PathWidth.large)
        break

      case 'j':
        this.deleteAll()
        break
      case 'k':
        this.deleteLast()
        break

      case 'l':
        this.togglePathType()
        break
      case 'm':
        this.toggleDrawingMode()
        break
    }
  }
}
