import {PathModel, FlashlightPathModel, PenPathModel, PathColor, PathType, PathWidth} from "@/models/recording/model_path"

export default class PathDrawer {

  /////////////////////////////////
  // CONST
  /////////////////////////////////
  static ALPHA_LPF = 0.15
  static USE_QUADRATIC = true

  static FLASHLIGHT_CUT_OFF_TIME = 1.5 // in s


  /////////////////////////////////
  // Members
  /////////////////////////////////
  _canvas: HTMLCanvasElement
  _canvasCtx: CanvasRenderingContext2D | null
  _extraWidth: number


  /////////////////////////////////
  // CONSTRUCTOR
  /////////////////////////////////
  constructor(canvas: HTMLCanvasElement, extraWidth: number) {
    this._canvas = canvas
    this._canvasCtx = canvas.getContext('2d')
    this._extraWidth = extraWidth
  }


  /////////////////////////////////
  // PUBLIC
  /////////////////////////////////
  draw(paths: Array<PathModel>) {
    this.clear()

    for (let i = 0; i < paths.length; i++) {
      let path = paths[i]

      // spacer
      if (path.type === PathType.spacer) continue

      // pen
      if (path.type === PathType.pen) {
        this._drawPath(<PenPathModel>path)
      }
    }

    // flashlight
    for (let i = paths.length-1; i >= 0; i--) {
      if (paths[i].type !== PathType.flashlight) break

      let path = <FlashlightPathModel>paths[i]

      if (i < paths.length-1) {
        let startTimeNextPath = (<FlashlightPathModel>paths[i+1]).time[0]
        let endTimeThisPath = path.time[path.time.length-1]
        if (startTimeNextPath - endTimeThisPath > PathDrawer.FLASHLIGHT_CUT_OFF_TIME) {
          break
        }
      }

      this._drawPath(path)
    }
  }

  clear() {
    if (!this._canvasCtx) return

    this._canvasCtx.clearRect(0, 0, this._canvas.width, this._canvas.height)
  }


  /////////////////////////////////
  // PRIVATE
  /////////////////////////////////
  _drawPath(path: PenPathModel | FlashlightPathModel) {
    if (!this._canvasCtx) return

    let x = path.x
    let y = path.y

    if (x.length === 0) return

    this._canvasCtx.beginPath()
    this._canvasCtx.strokeStyle = this.mapPathColor(path.color, path.type)
    this._canvasCtx.lineWidth = this.mapPathWidth(path.width, path.type)
    this._canvasCtx.lineCap = 'round'
    this._canvasCtx.lineJoin = 'round'

    let x_lpf = this.mapValue(x[0])
    let y_lpf = this.mapValue(y[0])
    this._canvasCtx.moveTo(x_lpf, y_lpf)

    if (x.length === 1) {
      this._canvasCtx.lineTo(x_lpf, y_lpf)

    } else if (PathDrawer.USE_QUADRATIC) {
      let i
      for (i = 1; i < path.x.length; i++) {
        let lastX = x_lpf
        let lastY = y_lpf
        x_lpf = this.lowPassFilter(this.mapValue(x[i]), lastX)
        y_lpf = this.lowPassFilter(this.mapValue(y[i]), lastY)
        let xm = (lastX + x_lpf) / 2
        let ym = (lastY + y_lpf) / 2
        this._canvasCtx.quadraticCurveTo(lastX, lastY, xm, ym)
      }
      this._canvasCtx.lineTo(this.mapValue(x[i]), this.mapValue(y[i]))

    } else {
      for (let i = 1; i < x.length; i++) {
        this._canvasCtx.lineTo(this.mapValue(x[i]), this.mapValue(y[i]))
      }
    }

    this._canvasCtx.stroke()
    this._canvasCtx.closePath()
  }

  mapValue(v: number): number {
    return v * (this._canvas.width - this._extraWidth)
  }

  mapPathColor(color: PathColor, pathType: PathType): string {
    if (pathType === PathType.flashlight) {
      return 'rgba(245,255,0,0.32)'
    } else {
      return color // we could define here a different set of colors
    }
  }

  mapPathWidth(width: PathWidth, pathType: PathType): number {

    if (pathType === PathType.flashlight) {
      switch (width) {
        case PathWidth.small:
          return this.mapValue(10 / 400)
        case PathWidth.medium:
          return this.mapValue(20 / 400)
        case PathWidth.large:
          return this.mapValue(30 / 400)
      }

    } else {
      switch (width) {
        case PathWidth.small:
          return 2 //return this.mapValue(2/400);
        case PathWidth.medium:
          return this.mapValue(3 / 400)
        case PathWidth.large:
          return this.mapValue(6 / 400)
      }
    }
  }

  lowPassFilter(newValue: number, oldValue: number): number {
    return newValue * (1 - PathDrawer.ALPHA_LPF) + oldValue * PathDrawer.ALPHA_LPF
  }
}