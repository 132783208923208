








































































































































































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import algoliasearch from "algoliasearch"
import params from "@/app/params"
import dbHelper from "@/util/basic/dbHelper"
import {eventbus} from "@/main"
import {EventType} from "@/components/app/eventModel"
import {UnitModel, UnitStatus, UnitType} from "@/models/unit/model_unit"
import Checkbox from "@/components/fundamental/inputs/Checkbox.vue"
import ScreenWidget from "@/simulator/screen/ScreenWidget.vue"
import model_screen, {ScreenModel} from "@/models/screen/model_screen"
import store from "@/store/store"
import util from "@/util/util"

@Component({
  components: {
    ScreenWidget,
    Checkbox, InputField, TextButton, Container, SpacerBox, TextField, TransitionEffect, Row, Column}
})
export default class FindUnitDialog extends Vue {
  @Prop({type: String, required: false, default: ''}) curriculumId!: string

  unitStatus = UnitStatus
  unitType = UnitType

  util = util

  isDialogVisible = false
  searchStr = ''
  lastSearchStr = ''
  units: Array<UnitModel> = []
  selectedUnitIndex = 0

  get selectedUnitScreen(): ScreenModel {
    if (this.units.length > 0 && this.units[this.selectedUnitIndex].screens.length > 0) {
      return this.units[this.selectedUnitIndex].screens[0]
    } else {
      return model_screen.template_screen()
    }
  }

  open(searchStr: string) {
    this.searchStr = searchStr ? searchStr.trim() : ''
    this.units = []
    this.isDialogVisible = true

    if (this.searchStr) {
      this.search()
    }
  }

  submit() {
    this.isDialogVisible = false

    if (this.units.length > 0) {
      this.$emit('submit', this.units[this.selectedUnitIndex])
    }
  }

  cancel() {
    this.isDialogVisible = false
  }

  async search() {
    let client = algoliasearch(params.algoliaAppId, params.algoliaSearchApiKey)
    let index = client.initIndex('units')

    this.showLoading(true)
    let response = await index.search(this.searchStr, {hitsPerPage: 50})
    this.showLoading(false)

    this.lastSearchStr = this.searchStr
    this.selectedUnitIndex = 0
    this.units = []
    for (let hit of response.hits) {
      let unit: UnitModel = dbHelper.algoliaJsonToJson(hit)
      if (!this.curriculumId || unit.curriculumId === this.curriculumId) {
        this.units.push(unit)
      }
    }
  }


  /////////////////////////////////
  // Helper
  /////////////////////////////////
  showLoading(show: boolean) {
    eventbus.$emit(EventType.loadingDialog, show)
  }

  getSchoolName(unit: UnitModel): string {
    let curriculum = store.curriculum.getRemoteCurriculum(unit.curriculumId)
    if (!curriculum) return '--'

    return curriculum.sc + ' (' + util.capitalizeFirstLetter(curriculum.co) + ')'
  }
}
