






















import {Component, Prop, Vue} from "vue-property-decorator"
import TextField from "@/components/fundamental/text/TextField.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import util from "@/util/util"
import {ColorModel} from "@/models/model_colors"
import ColorPreview from "@/components/screen_editor/content/color/ColorPreview.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"

@Component({
  components: {RoundIconButton, ColorPreview, SelectField, Row, TextField}
})
export default class InlineColorSelector extends Vue {
  @Prop({required: true}) value?: ColorModel
  @Prop({type: Number, default: 0, required: false}) width!: number
  @Prop({type: Number, default: 0, required: false}) titleWidth!: number
  @Prop({type: Boolean, default: false, required: false}) titleBold!: boolean
  @Prop({type: Boolean, default: false, required: false}) isNullable!: boolean

  colors = util.enumToArray(ColorModel)

  get rowStyle() {
    let s = ''
    if (this.width > 0 ) {
      s += 'width:' + this.width + 'px;'
    }
    return s
  }

  get titleStyle() {
    let s = ''
    if (this.titleWidth > 0 ) {
      s += 'width:' + this.titleWidth + 'px;'
    }
    return s
  }

  removeColor() {
    this.$emit('input', undefined)
  }
}
