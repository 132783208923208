



















import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import {
  PostSimulatorMessage,
  PostSimulatorMessage_borders,
  PostSimulatorMessage_drawingboard,
  PostSimulatorMessage_pathsData, PostSimulatorMessage_predefinedActionState,
  PostSimulatorMessage_screenData,
  PostSimulatorMessage_scrollable, PredefinedActionState,
  ReceivedSimulatorMessage, ReceivedSimulatorMessage_initCompleted, ReceivedSimulatorMessage_keyboardShortcut,
  ReceivedSimulatorMessage_screenHeight,
  ReceiveType,
  SendType,
  PostSimulatorMessage_linkedExercise
} from "@/simulator/screen/sim_screen_communication"
import {PathModel} from "@/models/recording/model_path"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import {VideoController, VideoControllerValue} from "@/components/video/controller/VideoController"
import {ScreenModel} from "@/models/screen/model_screen"

@Component({
  components: {RoundIconButton, Column, SpacerBox, Container, Row}
})
export default class ScreenWidget extends Vue {
  @Prop({type: Number, required: true}) displayWidth!: number
  @Prop({type: Number, required: true}) displayHeight!: number
  @Prop({type: Object, default: null, required: false}) screen!: ScreenModel
  @Prop({type: Array, default: null, required: false}) paths!: Array<PathModel>
  @Prop({default: null, required: false}) videoController!: VideoController | null
  @Prop({type: Boolean, default: false, required: false}) scrollable!: boolean
  @Prop({type: Boolean, default: false, required: false}) drawingboard!: boolean
  @Prop({type: Boolean, default: false, required: false}) showControlBar!: boolean
  @Prop({type: Boolean, default: false, required: false}) showBorders!: boolean
  @Prop({type: Boolean, default: false, required: false}) linkedExercise!: boolean
  @Prop({type: String, default: null, required: false}) predefinedActionState!: PredefinedActionState

  simUrl = process.env.NODE_ENV === 'development' ? 'https://sim-dev.mc2.miranda.works/?parentId=' : 'https://sim.mc2.miranda.works/?parentId='
  parentId = 'parent-' + (this.screen ? this.screen.id : '') + '-' + window.performance.now()

  iframe?: any = undefined

  videoControllerCallbackId = -1

  actualScreenHeight = 0
  showBorders_ = false


  get screenHeight() {
    return this.actualScreenHeight > 0 ? this.actualScreenHeight : this.displayHeight
  }

  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  mounted() {
    window.addEventListener('message', this.receiveMessage)

    // @ts-ignore
    this.iframe = this.$refs.iframe
    this.showBorders_ = this.showBorders
    this.showBorders_ = this.showBorders

    this.videoControllerChanged()
  }

  // noinspection JSUnusedGlobalSymbols
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)

    if (this.videoController && this.videoControllerCallbackId >= 0) {
      this.videoController.removeValueCallback(this.videoControllerCallbackId)
    }
  }

  @Watch('displayHeight')
  displayHeightChanged() {
    this.actualScreenHeight = 0
  }

  @Watch('screen', {deep: true})
  screenChanged() {
    this.sendScreenData()
  }

  @Watch('paths', {deep: true})
  pathsChanged() {
    this.sendPathsData(this.paths)
  }

  @Watch('videoController')
  videoControllerChanged() {
    if (this.videoController && this.videoControllerCallbackId < 0) {
      this.videoControllerCallbackId = this.videoController.addValueCallback((v: VideoControllerValue) => {
        this.sendPathsData(v.currentPaths)
      })
    }
  }

  @Watch('predefinedActionState')
  predefinedActionStateChanged() {
    this.sendPredefinedActionState()
  }

  @Watch('showBorders')
  showBordersChanged() {
    this.showBorders_ = this.showBorders
    this.sendBorders(this.showBorders)
  }

  @Watch('linkedExercise')
  linkedExerciseChanged() {
    this.sendLinkedExercise(this.linkedExercise)
  }


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  toggleBorders() {
    this.showBorders_ = !this.showBorders_
    this.sendBorders(this.showBorders_)
  }


  /////////////////////////////////
  // Send Message
  /////////////////////////////////
  sendScreenData() {
    if (!this.screen) return

    let message: PostSimulatorMessage_screenData = {
      type: SendType.screenData,
      screenData: this.screen
    }
    this.sendMessage(message)
  }

  sendPathsData(paths: Array<PathModel>) {
    if (!paths) return

    let message: PostSimulatorMessage_pathsData = {
      type: SendType.pathsData,
      pathsData: paths
    }
    this.sendMessage(message)
  }

  sendPredefinedActionState() {
    if (!this.predefinedActionState) return

    let message: PostSimulatorMessage_predefinedActionState = {
      type: SendType.predefinedActionState,
      predefinedActionState: this.predefinedActionState
    }
    this.sendMessage(message)
  }

  sendDrawingboard(drawingboard: boolean) {
    let message: PostSimulatorMessage_drawingboard = {
      type: SendType.drawingboard,
      drawingboard: drawingboard
    }
    this.sendMessage(message)
  }

  sendScrollable(scrollable: boolean) {
    let message: PostSimulatorMessage_scrollable = {
      type: SendType.scrollable,
      scrollable: scrollable
    }
    this.sendMessage(message)
  }

  sendBorders(showBorders: boolean) {
    let message: PostSimulatorMessage_borders = {
      type: SendType.borders,
      borders: showBorders
    }
    this.sendMessage(message)
  }

  sendLinkedExercise(linked: boolean) {
    let message: PostSimulatorMessage_linkedExercise = {
      type: SendType.linkedExercise,
      linked: linked,
    }
    this.sendMessage(message)
  }

  sendMessage(message: PostSimulatorMessage) {
    this.iframe?.contentWindow?.postMessage(message, '*')
  }


  /////////////////////////////////
  // Receive Message
  /////////////////////////////////
  receiveMessage(event: MessageEvent) {
    let data = event.data
    // console.log('received message', data);

    if (!data || data.parentId !== this.parentId || !data.type) {
      return
    }

    let message = data as ReceivedSimulatorMessage

    switch (message.type) {
      case ReceiveType.initCompleted:
        this.$emit('version', (message as ReceivedSimulatorMessage_initCompleted).version)
        this.sendScreenData()
        this.sendPathsData(this.paths)
        this.sendPredefinedActionState()
        this.sendScrollable(this.scrollable)
        this.sendLinkedExercise(this.linkedExercise)
        this.sendDrawingboard(this.drawingboard)
        this.sendBorders(this.showBorders)
        break

      case ReceiveType.screenHeight:
        if (!this.scrollable) {
          this.actualScreenHeight = (message as ReceivedSimulatorMessage_screenHeight).height
          this.$emit('screenHeight', this.actualScreenHeight)
        }
        break

      case ReceiveType.addSpacer:
        this.$emit('addSpacer', message)
        break

      case ReceiveType.sliderChanged:
        this.$emit('sliderChanged', message)
        break

      case ReceiveType.keyboardShortcut:
        this.$emit('keyboardShortcut', (message as ReceivedSimulatorMessage_keyboardShortcut).key)
        break
    }
  }
}
