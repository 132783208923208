
















































import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {ActionType, ActionTypeLabels} from "@/models/action/model_action"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import util from '@/util/util'
import {ScreenModel} from '@/models/screen/model_screen'
import appState from '@/app/state/app_state'


@Component({
  components: {TextField, RoundIconButton, SpacerBox, TextButton, SelectField, Row, Column}
})
export default class ActionEditorHeader extends Vue {
  @Prop({type: Object, required: true}) screen!: ScreenModel
  @Prop({type: Number, required: true}) index!: number

  actionTypes = util.enumToArray(ActionType)
  actionTypeLabels = util.enumToArray(ActionTypeLabels)

  actionType = ActionType.mc
  unitError = appState.unitError ? appState.unitError : null

  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  appState = appState

  @Watch('appState.unitError')
  unitErrorChanged() {
    this.unitError = appState.unitError ? appState.unitError : null
  }


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  getActionTypeLabel() {
    return this.screen.action ? ActionTypeLabels[this.screen.action.type] : ''
  }

  get copiedActionAvailable() {
    return appState.copiedAction != null
  }

  copyAction() {
    appState.copiedAction = JSON.stringify(this.screen.action)
  }

  pasteAction() {
    if (appState.copiedAction) {
      this.$emit('pasteAction', JSON.parse(appState.copiedAction))
    }
  }
}
