import { render, staticRenderFns } from "./DragConnectActionEditor.vue?vue&type=template&id=235d2105&"
import script from "./DragConnectActionEditor.vue?vue&type=script&lang=ts&"
export * from "./DragConnectActionEditor.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports