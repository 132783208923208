



































































import {Component, Vue} from 'vue-property-decorator'
import Column from "@/components/fundamental/layout/Column.vue"
import SectionLayout from "@/components/fundamental/layout/SectionLayout.vue"
import {eventbus} from "@/main"
import {EventType, NavbarViewModel, SidePanelViewModel} from "@/components/app/eventModel"
import {Route} from "vue-router"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import Link from "@/components/fundamental/text/Link.vue"
import router, {RouteName} from "@/router/router"
import appState from "@/app/state/app_state"

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate', 'beforeRouteLeave'])

@Component({
  components: {Link, SpacerBox, TextField, Row, SectionLayout, Column},
})
export default class TestAreaPage extends Vue {
  routeNames = RouteName

  beforeRouteEnter(to: Route, from: Route, next: (...args: any[]) => void) {
    eventbus.$emit(EventType.navbar, new NavbarViewModel(true))
    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(true))

    if (appState.testingPathName && !appState.skipTestPath) {
      appState.skipTestPath = true

      router.push({name: appState.testingPathName}) // otherwise we get an error
      // next({name: appState.testingPathName})
    } else {
      appState.skipTestPath = false
      next()
    }
  }

  beforeRouteUpdate(to: Route, from: Route, next: () => void) {
    if (to.name) {
      appState.testingPathName = to.name
    }
    next()
  }

  created() {
    window.scrollTo(0, 0)
  }
}
