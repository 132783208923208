






































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import {CurriculumModel, OfficialSubsectionModel} from "@/models/curriculum/model_curriculum"
import Row from "@/components/fundamental/layout/Row.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import Draggable from "@/components/fundamental/layout/Draggable.vue"
import UnitChip from "@/pages/curriculum_viewer/UnitChip.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import util from "@/util/util"
import store from "@/store/store"
import {eventbus} from "@/main"
import {EventType} from "@/components/app/eventModel"
import appState from "@/app/state/app_state"

@Component({
  components: {
    TextButton, SpacerBox, Container, UnitChip, Draggable, RoundIconButton, Row, TextField, Column}
})
export default class OfficialSubsection extends Vue {
  @Prop({type: Object, required: true}) curriculum!: CurriculumModel
  @Prop({type: Object, required: true}) subsection!: OfficialSubsectionModel

  curriculumState = appState.curriculum
  contentVisible: boolean = !!this.curriculumState.officialSubsectionsVisible.get(this.subsection.id)

  toggleContentVisible() {
    this.contentVisible = !this.contentVisible
    this.curriculumState.officialSubsectionsVisible.set(this.subsection.id, this.contentVisible)
  }

  async addUnit() {
    eventbus.$emit(EventType.loadingDialog, true)
    await store.units.addUnit(this.curriculum, this.subsection.id)
    eventbus.$emit(EventType.loadingDialog, false)
  }

  removeUnit(unitIndex: number) {
    util.removeFromArray(this.subsection.un, unitIndex)
  }
}
