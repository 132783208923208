import {PointModel} from "@/models/math/model_point"

export interface VectorModel {
  start: PointModel
  end: PointModel
}

export default {
  template(): VectorModel {
    return {
      start: {x: 0, y: 0,},
      end: {x: 1, y: 1,},
    }
  },
}
