import {ActionType} from "@/models/action/model_action"
import {ImageType} from "@/models/content/model_image"
import {ContentType} from "@/models/content/model_content"
import {ScreenModel} from "@/models/screen/model_screen"

export default <ScreenModel>{
  id: "scre",
  title: "Test Action Drag & Drop",
  statics: [],
  action: {
    type: ActionType.dragDrop,
    responses: [],
    areaHeight: 300,
    fieldWidth: 48,
    fieldHeight: 48,
    backgroundContent: {
      type: ContentType.image,
      image: {
        imageType: ImageType.image,
        url: "https://miranda-40b79.s3.amazonaws.com/images/imag-_ycuqU4WgZX1oyeGHZ9idrByTQMA.jpg",
        size: 0,
        fileType: "",
        width: 807,
        height: 532,
        scaling: 1,
      },
    },
    dragItems: [
      {
        position: [0.50, 0.50],
        hideStart: false,
        hideEnd: false,
        content: {
          type: ContentType.text,
          text: {
            text: "$$\\mathbb{Z}$$",
            size: 16,
          }
        }
      },
      {
        position: [0.25, 0.50],
        hideStart: false,
        hideEnd: false,
        content: {
          type: ContentType.text,
          text: {
            text: "$$\\mathbb{Q}$$",
            size: 16,
          }
        }
      },
      {
        position: [0.80, 0.50],
        hideStart: false,
        hideEnd: false,
        content: {
          type: ContentType.text,
          text: {
            text: "$$\\mathbb{N}$$",
            size: 16,
          }
        }
      },
      {
        position: [0.015, 0.50],
        hideStart: false,
        hideEnd: false,
        content: {
          type: ContentType.text,
          text: {
            text: "$$\\mathbb{R}$$",
            size: 16,
          }
        }
      },
    ],
  },
}