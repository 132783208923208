





















































































import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {eventbus} from "@/main"
import {EventType, NavbarViewModel, SidePanelViewModel} from "@/components/app/eventModel"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import SectionLayout from "@/components/fundamental/layout/SectionLayout.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import firebase from 'firebase/app'
import 'firebase/firestore'
import params from "@/app/params"
import Container from "@/components/fundamental/layout/Container.vue"
import {LogEventBundle, ScreenBugReportModel} from "@/models/log/model_log_events"
import LogEventBundleDetails from "@/pages/logging/LogEventBundleDetails.vue"
import firestoreDocSize from "@/util/basic/firestoreDocSize"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import EditNumber from "@/components/fundamental/inputs/EditNumber.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import AdvancedSelect from "@/components/fundamental/advanced_inputs/AdvancedSelect.vue"
import BugReportDetails from "@/pages/logging/BugReportDetails.vue"
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue"
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

@Component({
  components: {
    AdvancedEditText,
    BugReportDetails,
    AdvancedSelect,
    SpacerBox,
    EditNumber,
    InputField,
    LogEventBundleDetails,
    DatePicker,
    Container, TextField, SectionLayout, TextButton, Row, Column}
})
export default class LoggingPage extends Vue {

  loggingTypes = ['Log Event', 'Bug Report']
  loggingType = 'Log Event'
  userMail: string|null = null
  dateRange: Array<string|null> = [null, null]

  orderTypes = ['asc', 'desc']
  orderType: any = 'desc'

  limit = 1
  newestLogEntries: Array<LogEventBundle> = []
  newestBugReports: Array<ScreenBugReportModel> = []


  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  created() {
    // update navbar
    eventbus.$emit(EventType.navbar, new NavbarViewModel(true))

    // update sidePanel
    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(false))
  }


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  async fetchData() {
    if (this.loggingType === this.loggingTypes[0]) {
      this.fetchLogEvents()
    } else {
      this.fetchBugReports()
    }
  }

  async fetchLogEvents() {
    this.newestLogEntries = []
    this.newestBugReports = []

    console.log(this.dateRange)

    let query = await firebase.firestore()
      .collectionGroup(params.firestore.logs)
      .limit(Math.floor(this.limit))

    if (this.userMail) {
      query = query.where('email', '==', this.userMail)
    }

    if (this.dateRange[0] && this.dateRange[1]) {
      query = query
        .where('time', '>=', new Date(Date.parse(this.dateRange![0] + ' 00:00:00 GMT')))
        .where('time', '<=', new Date(Date.parse(this.dateRange![1] + ' 23:59:59.99 GMT')))
    }

    // fetch data
    this.showLoading(true)
    let querySnapshot = await query.orderBy('time', this.orderType).get()
    this.showLoading(false)

    querySnapshot.docs.map(doc => {
      // @ts-ignore
      let eventBundle: LogEventBundle = doc.data()
      eventBundle.id = doc.id
      eventBundle.size = firestoreDocSize.calcDocSize(doc.data())
      eventBundle.events = eventBundle.events.reverse()
      this.newestLogEntries.push(eventBundle)
    })
  }

  async fetchBugReports() {
    this.newestLogEntries = []
    this.newestBugReports = []

    // fetch data
    this.showLoading(true)
    let querySnapshot = await firebase.firestore()
      .collectionGroup(params.firestore.reports)
      .limit(Math.floor(this.limit))
      .orderBy('time', 'desc')
      .get()
    this.showLoading(false)

    querySnapshot.docs.map(doc => {
      // @ts-ignore
      let bugReport: ScreenBugReportModel = doc.data()
      bugReport.id = doc.id
      bugReport.size = firestoreDocSize.calcDocSize(doc.data())
      this.newestBugReports.push(bugReport)
    })
  }

  showLoading(show: boolean) {
    eventbus.$emit(EventType.loadingDialog, show)
  }
}
