

























import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import {CatalogTopicType, SoundModel} from "@/models/sound/model_sound"
import SoundItem from "@/pages/sounds/SoundItem.vue"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"

@Component({
  components: {AddButton, SoundItem, Container, TextField, TextButton, Row, Column}
})
export default class CatalogTopic extends Vue {
  @Prop({type: Array, required: true}) sounds!: Array<SoundModel>
  @Prop({type: String, required: true}) type!: CatalogTopicType

  get title(): string {
    switch (this.type) {
      case CatalogTopicType.tip: return 'Tip'
      case CatalogTopicType.correct: return 'Correct'
      case CatalogTopicType.wrong: return 'Wrong'
      case CatalogTopicType.achievementShort: return 'Achievement - short'
      case CatalogTopicType.achievementLong: return 'Achievement - long'
    }
  }
}
