import firebase from 'firebase/app'
import 'firebase/firestore'
import DocumentData = firebase.firestore.DocumentData
import QuerySnapshot = firebase.firestore.QuerySnapshot
import {RecorderRequestModel, RecorderResponseModel} from '@/models/recording/model_recorder'
import store from "./store"
import router, {RouteName} from "@/router/router"
import params from "@/app/params"


export default class RecorderWindowStore {
  _communicationUnsubscribe?: any = undefined
  _recorderWindowRef: Window | null = null
  _request: RecorderRequestModel | null = null
  _response: RecorderResponseModel | null = null

  init() {
  }

  /////////////////////////////////
  // Getters
  /////////////////////////////////
  get request(): RecorderRequestModel | null {
    return this._request
  }

  get response(): RecorderResponseModel | null {
    return this._response
  }

  get _recorderRef() {
    return firebase.firestore().collection(params.firestore.users).doc(store.auth.userId).collection(params.firestore.recorder)
  }

  /////////////////////////////////
  // Action
  /////////////////////////////////
  subscribeToRecorderCommunication() {
    return new Promise<void>((resolve, reject) => {
      if (this._communicationUnsubscribe) {
        resolve()
        return
      }

      this._communicationUnsubscribe = this._recorderRef
        .onSnapshot(
          (querySnapshot: QuerySnapshot<DocumentData>) => {
            this._request = null
            this._response = null

            for (let doc of querySnapshot.docs) {
              if (doc.id === 'request' && doc.data()) {
                this._request = <RecorderRequestModel>doc.data()
              } else if (doc.id === 'response') {
                this._response = <RecorderResponseModel>doc.data()
              }
            }
            resolve()
          },

          (error: Error) => reject(error)
        )
    })
  }

  async submitRequest(request: RecorderRequestModel) {
    // start listening for responses
    this.subscribeToRecorderCommunication()

    // open recorder window
    if (this._recorderWindowRef === null || this._recorderWindowRef.closed) {
      let routeData = router.resolve({name: RouteName.recorderWindow})
      this._recorderWindowRef = window.open(routeData.href, 'recorder', "height=900,width=1200")

    } else {
      this._recorderWindowRef.focus()
    }

    // delete current response and set new request
    await this._recorderRef.doc('response').delete()
    await this._recorderRef.doc('request').set(request)
  }

  async submitResponse(response: RecorderResponseModel) {
    await this._recorderRef.doc('request').delete()
    await this._recorderRef.doc('response').set(response)
  }

  async cancelRequest() {
    await this._recorderRef.doc('request').delete()
    await this._recorderRef.doc('response').delete()
  }
}