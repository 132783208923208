








import {Component, Prop, Vue} from "vue-property-decorator"

@Component
export default class SpacerBox extends Vue {
  @Prop({type: Number, default: 1, required: false}) width!: number
  @Prop({type: Number, default: 1, required: false}) height!: number

  get styleString() {
    let s = ''
    s += this.width >= 0 ? 'width: ' + this.width + 'px; ' : ''
    s += this.height >= 0 ? 'height: ' + this.height + 'px; ' : ''
    return s
  }
}
