<template>
  <VueDraggable
    :value="value"
    @input="$emit('input', $event)"
    :handle="handle"
    :group="{name: group, pull: pullFunction}"
    @start="start"
    @end="$emit('end', $event)"
    ghost-class="ghost"
  >
    <transition-group :class="{'draggable-row': row}">

      <slot></slot>

    </transition-group>
  </VueDraggable>
</template>

<style lang="scss" scoped>
  .ghost {
    opacity: 0.4;
  }

  .draggable-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
</style>

<script>
  import VueDraggable from 'vuedraggable'

  export default {
    name: 'Draggable',
    components: {
      VueDraggable
    },
    props: {
      value: {
        type: Array,
        required: true,
      },
      handle: {
        type: String,
        default: '',
        required: false,
      },
      group: {
        type: String,
        default: '',
        required: false,
      },
      ctrlClone: {
        type: Boolean,
        default: false,
        required: false,
      },
      endCallback: {
        default: () => {
        },
        required: false,
      },
      row: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    data() {
      return {
        ctrlPressedOnStart: true
      };
    },
    methods: {
      pullFunction() {
        if (this.ctrlClone) {
          return this.ctrlPressedOnStart ? "clone" : true;
        } else {
          return true
        }
      },
      start({originalEvent}) {
        this.ctrlPressedOnStart = originalEvent.ctrlKey;
      }
    }
  }
</script>
