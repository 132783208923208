






























import {Component, Prop, Vue} from "vue-property-decorator"

@Component({
  components: {}
})
export default class Checkbox extends Vue {
  @Prop({type: Boolean, default: false, required: false}) value!: boolean
  @Prop({type: Boolean, default: false, required: false}) small!: boolean

  updateState() {
    this.$emit('input', !this.value)
  }
}
