












import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import dummy_recording0 from "@/test_area/dummy_data/recordings/dummy_recording0"
import {eventbus} from "@/main"
import {EventType, SidePanelViewModel} from "@/components/app/eventModel"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import VideoPlayerWidget from "@/components/video/player/VideoPlayerWidget.vue"
import PreviewPlayerWidget from "@/components/video/player/PreviewPlayerWidget.vue"
import dummy_screen_simple from "@/test_area/dummy_data/screens/dummy_screen_simple"
import {ScreenModel} from "@/models/screen/model_screen"
import {RecordingModel} from "@/models/recording/model_recording"

@Component({
  components: {PreviewPlayerWidget, VideoPlayerWidget, TextField, TextButton, Row, Column}
})
export default class TestRecording_videoPlayer extends Vue {

  screen: ScreenModel = dummy_screen_simple
  recording: RecordingModel = dummy_recording0

  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  mounted() {
    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(false))
  }
}
