
















































import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import FindUnitDialog from "@/components/unit/FindUnitDialog.vue"
import {UnitModel} from "@/models/unit/model_unit"

@Component({
  components: {FindUnitDialog, InputField, TextButton, Container, SpacerBox, TextField, TransitionEffect, Row, Column}
})
export default class AddUrlDialog extends Vue {

  isDialogVisible = false
  additionalSpaceAtStart = false
  additionalSpaceAtEnd = false
  text = ''
  url = ''

  open(text: string) {
    this.additionalSpaceAtStart = text.startsWith(' ')
    this.additionalSpaceAtEnd = text.endsWith(' ')
    this.text = text.trim()
    this.url = ''
    this.isDialogVisible = true
  }

  save() {
    this.isDialogVisible = false
    this.$emit('save', (this.additionalSpaceAtStart ? " ": "") + '[a href="' + this.url + '"]' +
      this.text + '[/a]' + (this.additionalSpaceAtEnd ? " ": ""))
  }

  saveWithUnit(unit: UnitModel) {
    this.isDialogVisible = false
    this.$emit('save', (this.additionalSpaceAtStart ? " ": "") + '[a href="unit/' + unit.id + '"]' +
      (this.text ? this.text : unit.title) + '[/a]' + (this.additionalSpaceAtEnd ? " ": ""))
  }

  cancel() {
    this.isDialogVisible = false
  }
}
