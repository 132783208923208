














import {Component, Prop, Vue} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import IconButton from "../../fundamental/buttons/IconButton.vue"
import Link from "../../fundamental/text/Link.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import ImageField from "@/components/fundamental/ImageField.vue"

@Component({
  components: {ImageField, RoundIconButton, Link, IconButton, Container}
})
export default class ImagePreview extends Vue {
  @Prop({type: String, required: true}) url!: string
}
