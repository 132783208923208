






















































































































































import {Component, Prop, Vue} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import ContentWidget from "@/simulator/content/ContentWidget.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Checkbox from "@/components/fundamental/inputs/Checkbox.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import util from "@/util/util"
import EditNumber from "@/components/fundamental/inputs/EditNumber.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import AdvancedCheckbox from "@/components/fundamental/advanced_inputs/AdvancedCheckbox.vue"
import AdvancedEditNumber from "@/components/fundamental/advanced_inputs/AdvancedEditNumber.vue"
import AdvancedScaling from "@/components/fundamental/advanced_inputs/AdvancedScaling.vue"
import AdvancedSelect from "@/components/fundamental/advanced_inputs/AdvancedSelect.vue"
import {Alignment, TableModel} from "@/models/content/model_table"
import TextEditor from "@/components/screen_editor/content/text/TextEditor.vue"
import InlineTextEditor from "@/components/screen_editor/content/text/InlineTextEditor.vue"
import BasicTextEditorDialog from "@/components/screen_editor/content/text/BasicTextEditorDialog.vue"
import SourceEditorDialog from "@/components/screen_editor/content/SourceEditorDialog.vue"

@Component({
  components: {
    SourceEditorDialog,
    BasicTextEditorDialog,
    InlineTextEditor,
    TextEditor,
    AdvancedSelect,
    AdvancedScaling,
    AdvancedEditNumber,
    AdvancedCheckbox,
    InputField,
    EditNumber, RoundIconButton, SpacerBox, Checkbox, TextField, Column, ContentWidget, Row, Container
  }
})
export default class ContentTableEditor extends Vue {
  @Prop({type: Object, required: true}) content!: TableModel
  @Prop({type: Boolean, default: false, required: false}) showPreview!: boolean

  alignments = util.enumToArray(Alignment)

  get numRows() {
    if (!this.content) return 0
    return this.content?.rows.length
  }

  get numColumns() {
    if (!this.content) return 0
    return this.numRows == 0 ? 0 : this.content?.rows[0].cells.length
  }

  /////////////////////////////////
  // Life Cycle
  /////////////////////////////////
  created() {
    if (!this.content) return

    if (this.content.showHeaderRow === undefined) {
      this.$set(this.content, 'showHeaderRow', true)
      this.$set(this.content, 'showHeaderColumn', false)
    }

    if (this.content.fontSize === undefined) {
      this.$set(this.content, 'fontSize', 20)
    }

    if (this.content.scaling === undefined) {
      this.$set(this.content, 'scaling', 1)
    }

    if (this.content.columnWidths === undefined) {
      let a = []
      for (let i = 0; i < this.numColumns; i++) {
        a.push(0)
      }
      this.$set(this.content, 'columnWidths', a)
    }
  }


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  updateRows(v: number) {
    if (!this.content) return

    if (v === 1) {
      this.content.rows.push({cells: util.arrayOf(this.numColumns, '')})

    } else if (v === -1 && this.numRows > 1) {
      util.removeFromArray(this.content.rows, this.content.rows.length - 1)
    }
  }

  updateColumns(v: number) {
    if (!this.content) return

    if (v === 1) {
      for (let row of this.content.rows) {
        row.cells.push('')
      }
      this.content.columnWidths.push(0)

    } else if (v === -1 && this.numColumns > 1) {
      for (let row of this.content.rows) {
        util.removeFromArray(row.cells, row.cells.length - 1)
      }
      util.removeFromArray(this.content.columnWidths, this.content.columnWidths.length - 1)
    }
  }

  updateField(value: string, rowIndex: number, columnIndex: number) {
    if (!this.content) return

    this.$set(this.content.rows[rowIndex].cells, columnIndex, value)
  }
}
