

























































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"

@Component({
  components: {Column}
})
export default class MobileButton extends Vue {
  @Prop({type: String, required: true}) title!: string
  @Prop({type: Boolean, default: false, required: false}) disabled!: boolean
}
