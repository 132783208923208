




































import {Component, Prop, Vue} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import {ContentModel, ContentType} from "@/models/content/model_content"
import ContentDialog from "@/components/screen_editor/content/ContentDialog.vue"
import ContentLineChartPreview from "@/components/screen_editor/content/chart/line_chart/ContentLineChartPreview.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import ImagePreview from "@/components/screen_editor/content/image/ImagePreview.vue"
import ContentTextPreview from "@/components/screen_editor/content/text/ContentTextPreview.vue"
import util from "@/util/util"
import ContentSpacerPreview from "@/components/screen_editor/content/spacer/ContentSpacerPreview.vue"
import ContentTablePreview from "@/components/screen_editor/content/table/ContentTablePreview.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import ContentBoxPlotPreview from "@/components/screen_editor/content/chart/box_plot/ContentBoxPlotPreview.vue"
import {SizeModel} from "@/models/math/model_size"
import ContentPieChartPreview from "@/components/screen_editor/content/chart/pie_chart/ContentPieChartPreview.vue"

@Component({
  components: {
    ContentPieChartPreview,
    ContentBoxPlotPreview,
    Column,
    ContentTablePreview, ContentSpacerPreview, ContentTextPreview, ImagePreview, TextField,
    ContentLineChartPreview, ContentDialog, SpacerBox, Row,
  }
})
export default class InlineContentEditor extends Vue {
  @Prop({type: Object, required: true}) content!: ContentModel
  @Prop({type: Array, default: () => {return []}, required: false}) invalidTypes!: Array<string>
  @Prop({type: Boolean, default: false, required: false}) inlineText!: boolean
  @Prop({type: Object, default: null, required: false}) fieldSize!: SizeModel|null
  @Prop({type: Number, default: 18, required: false}) recommendedFontSize!: number

  contentTypes = ContentType

  get typeStr() {
    return util.capitalizeFirstLetter(util.getEnumKey(this.content.type, ContentType))
  }
}
