import model_xy_chart, {XyChartModel} from "@/models/content/charts/model_xy_chart"
import {ActionModel, ActionType} from "@/models/action/model_action"
import model_response from "@/models/action/model_response"

export interface DragBoxActionModel extends ActionModel {
  xyChart: XyChartModel
  targetPercentiles: Array<number>
  snapGrid: boolean
  tolerance: number
}

export default {
  template_action(): DragBoxActionModel {
    return {
      type: ActionType.dragBox,
      responses: model_response.template_responses(),
      xyChart: model_xy_chart.template_dragBoxAction(),
      targetPercentiles: [-2, -1, 0, 1, 2],
      snapGrid: true,
      tolerance: 0.1,
    }
  },
}