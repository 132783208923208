import AppStore from "@/store/store_app"
import CurriculumStore from './store_curriculum'
import AuthStore from "@/store/store_auth"
import RecorderWindowStore from '@/store/store_recorderWindow'
import RecordingsStore from '@/store/store_recordings'
import UnitsStore from "@/store/store_units"
import DevSettingsStore from "@/store/store_devSettings"
import CoursesStore from "@/store/store_courses"
import SoundsStore from "@/store/store_sounds"

class Store {
  app = new AppStore()
  auth = new AuthStore()
  curriculum = new CurriculumStore()
  courses = new CoursesStore()
  recorderWindow = new RecorderWindowStore()
  recordings = new RecordingsStore()
  sounds = new SoundsStore()
  units = new UnitsStore()
  devSettings = new DevSettingsStore()

  constructor() {
  }

  init() {
    this.app.init()
    this.auth.init()
    this.curriculum.init()
    this.courses.init()
    this.recorderWindow.init()
    this.recordings.init()
    this.sounds.init()
    this.units.init()
    this.devSettings.init()
  }
}

const store = new Store()
export default store