import Vue from "vue"

Vue.component('IconClose', require('vue-material-design-icons/Close').default)
Vue.component('IconCheck', require('vue-material-design-icons/Check').default)
Vue.component('IconAlertCircleOutline', require('vue-material-design-icons/AlertCircleOutline').default)
Vue.component('IconPlus', require('vue-material-design-icons/Plus').default)
Vue.component('IconMinus', require('vue-material-design-icons/Minus').default)
Vue.component('IconPlusCircleOutline', require('vue-material-design-icons/PlusCircleOutline').default)
Vue.component('IconPencil', require('vue-material-design-icons/Pencil').default)

Vue.component('IconMarker', require('vue-material-design-icons/Marker').default)
Vue.component('IconFountainPenTip', require('vue-material-design-icons/FountainPenTip').default)
Vue.component('IconGreasePencil', require('vue-material-design-icons/GreasePencil').default)

Vue.component('IconMenuDown', require('vue-material-design-icons/MenuDown').default)
Vue.component('IconChevronUp', require('vue-material-design-icons/ChevronUp').default)
Vue.component('IconChevronDown', require('vue-material-design-icons/ChevronDown').default)
Vue.component('IconChevronLeft', require('vue-material-design-icons/ChevronLeft').default)
Vue.component('IconChevronRight', require('vue-material-design-icons/ChevronRight').default)

Vue.component('IconMagnify', require('vue-material-design-icons/Magnify').default)
Vue.component('IconMagnifyMinusOutline', require('vue-material-design-icons/MagnifyMinusOutline').default)
Vue.component('IconMagnifyPlusOutline', require('vue-material-design-icons/MagnifyPlusOutline').default)

Vue.component('IconHelpCircleOutline', require('vue-material-design-icons/HelpCircleOutline').default)

Vue.component('IconAccountCircle', require('vue-material-design-icons/AccountCircle').default)
Vue.component('IconLogout', require('vue-material-design-icons/Logout').default)
Vue.component('IconLogin', require('vue-material-design-icons/Login').default)

Vue.component('IconCellphoneAndroid', require('vue-material-design-icons/CellphoneAndroid').default)
Vue.component('IconCellphoneScreenshot', require('vue-material-design-icons/CellphoneScreenshot').default)

Vue.component('IconPlayCircle', require('vue-material-design-icons/PlayCircle').default)
Vue.component('IconRestart', require('vue-material-design-icons/Restart').default)

Vue.component('IconPlay', require('vue-material-design-icons/Play').default)
Vue.component('IconReplay', require('vue-material-design-icons/Replay').default)
Vue.component('IconPause', require('vue-material-design-icons/Pause').default)
Vue.component('IconStop', require('vue-material-design-icons/Stop').default)
Vue.component('IconRecord', require('vue-material-design-icons/Record').default)
Vue.component('IconFastForward', require('vue-material-design-icons/FastForward').default)
Vue.component('IconRewind', require('vue-material-design-icons/Rewind').default)

Vue.component('IconSkipForward', require('vue-material-design-icons/SkipForward').default)
Vue.component('IconSkipBackward', require('vue-material-design-icons/SkipBackward').default)
Vue.component('IconVolumeHigh', require('vue-material-design-icons/VolumeHigh').default)
Vue.component('IconVolumeLow', require('vue-material-design-icons/VolumeLow').default)

Vue.component('IconNote', require('vue-material-design-icons/Note').default)
Vue.component('IconLibraryVideo', require('vue-material-design-icons/FileVideo').default)
Vue.component('IconSubtitles', require('vue-material-design-icons/Subtitles').default)
Vue.component('IconLayers', require('vue-material-design-icons/Layers').default)

Vue.component('IconFullscreen', require('vue-material-design-icons/Fullscreen').default)
Vue.component('IconFullscreenExit', require('vue-material-design-icons/FullscreenExit').default)
Vue.component('IconArrowExpand', require('vue-material-design-icons/ArrowExpand').default)
Vue.component('IconArrowCollapse', require('vue-material-design-icons/ArrowCollapse').default)

Vue.component('IconFormatListBulleted', require('vue-material-design-icons/FormatListBulleted').default)
Vue.component('IconFormatListChecks', require('vue-material-design-icons/FormatListChecks').default)
Vue.component('IconFileFind', require('vue-material-design-icons/FileFind').default)
Vue.component('IconCodeBraces', require('vue-material-design-icons/CodeBraces').default)
Vue.component('IconCodeJson', require('vue-material-design-icons/CodeJson').default)
Vue.component('IconXml', require('vue-material-design-icons/Xml').default)
Vue.component('IconInvertColors', require('vue-material-design-icons/InvertColors').default)
Vue.component('IconCloudUploadOutline', require('vue-material-design-icons/CloudUploadOutline').default)
Vue.component('IconVideoVintage', require('vue-material-design-icons/VideoVintage').default)
Vue.component('IconOpenInNew', require('vue-material-design-icons/OpenInNew').default)
Vue.component('IconTeach', require('vue-material-design-icons/Teach').default)
Vue.component('IconTrashCan', require('vue-material-design-icons/TrashCan').default)
Vue.component('IconAutorenew', require('vue-material-design-icons/Autorenew').default)
Vue.component('IconBorderAll', require('vue-material-design-icons/BorderAll').default)
Vue.component('IconBorderNone', require('vue-material-design-icons/BorderNone').default)


Vue.component('IconDragHorizontal', require('vue-material-design-icons/DragHorizontal').default)
Vue.component('IconDragVertical', require('vue-material-design-icons/DragVertical').default)


Vue.component('IconCamera', require('vue-material-design-icons/Camera').default)
Vue.component('IconCameraOff', require('vue-material-design-icons/CameraOff').default)

Vue.component('IconArrowLeft', require('vue-material-design-icons/ArrowLeft').default)
Vue.component('IconArrowRight', require('vue-material-design-icons/ArrowRight').default)
Vue.component('IconArrowUp', require('vue-material-design-icons/ArrowUp').default)
Vue.component('IconArrowDown', require('vue-material-design-icons/ArrowDown').default)
Vue.component('IconContentSave', require('vue-material-design-icons/ContentSave').default)
Vue.component('IconContentSaveMove', require('vue-material-design-icons/ContentSaveMove').default)
Vue.component('IconRestoreAlert', require('vue-material-design-icons/RestoreAlert').default)
Vue.component('IconCloudDownloadOutline', require('vue-material-design-icons/CloudDownloadOutline').default)

Vue.component('IconChartBellCurveCumulative', require('vue-material-design-icons/ChartBellCurveCumulative').default)
Vue.component('IconFormatText', require('vue-material-design-icons/FormatText').default)
Vue.component('IconImage', require('vue-material-design-icons/Image').default)
Vue.component('IconGif', require('vue-material-design-icons/Gif').default)
Vue.component('IconTable', require('vue-material-design-icons/Table').default)
Vue.component('IconDraw', require('vue-material-design-icons/Draw').default)

Vue.component('IconTools', require('vue-material-design-icons/Tools').default)
Vue.component('IconCalculator', require('vue-material-design-icons/Calculator').default)
Vue.component('IconBookOpen', require('vue-material-design-icons/BookOpen').default)
Vue.component('IconMonitor', require('vue-material-design-icons/Monitor').default)
Vue.component('IconVideoOutline', require('vue-material-design-icons/VideoOutline').default)
Vue.component('IconKeyboardSpace', require('vue-material-design-icons/KeyboardSpace').default)
Vue.component('IconCheckboxMultipleMarkedCircleOutline', require('vue-material-design-icons/CheckboxMultipleMarkedCircleOutline').default)
Vue.component('IconContentCopy', require('vue-material-design-icons/ContentCopy').default)
Vue.component('IconContentPaste', require('vue-material-design-icons/ContentPaste').default)
Vue.component('IconGestureTapButton', require('vue-material-design-icons/GestureTapButton').default)
Vue.component('IconNumeric2BoxMultiple', require('vue-material-design-icons/Numeric2BoxMultiple').default)
Vue.component('IconRoutes', require('vue-material-design-icons/Routes').default)
Vue.component('IconEmoticonCool', require('vue-material-design-icons/EmoticonCool').default)
Vue.component('IconDatabase', require('vue-material-design-icons/Database').default)
Vue.component('IconMathIntegral', require('vue-material-design-icons/MathIntegral').default)

Vue.component('IconChartPie', require('vue-material-design-icons/ChartPie').default)

Vue.component('IconServer', require('vue-material-design-icons/Server').default)
Vue.component('IconClockOutline', require('vue-material-design-icons/ClockOutline').default)
Vue.component('IconMovieRoll', require('vue-material-design-icons/MovieRoll').default)
Vue.component('IconMessageReplyText', require('vue-material-design-icons/MessageReplyText').default)

Vue.component('IconArrowExpandHorizontal', require('vue-material-design-icons/ArrowExpandHorizontal').default)
Vue.component('IconArrowExpandVertical', require('vue-material-design-icons/ArrowExpandVertical').default)
Vue.component('IconTimelapse', require('vue-material-design-icons/Timelapse').default)
Vue.component('IconLinkVariant', require('vue-material-design-icons/LinkVariant').default)
Vue.component('IconWeb', require('vue-material-design-icons/Web').default)
Vue.component('IconFilmstrip', require('vue-material-design-icons/Filmstrip').default)
Vue.component('IconFilmstripOff', require('vue-material-design-icons/FilmstripOff').default)

Vue.component('IconAlert', require('vue-material-design-icons/Alert').default)
Vue.component('IconAlertCircle', require('vue-material-design-icons/AlertCircle').default)
Vue.component('IconInformationOutline', require('vue-material-design-icons/InformationOutline').default)
Vue.component('IconHelpCircle', require('vue-material-design-icons/HelpCircle').default)

Vue.component('IconDotsVertical', require('vue-material-design-icons/DotsVertical').default)
Vue.component('IconDotsHorizontal', require('vue-material-design-icons/DotsHorizontal').default)

Vue.component('IconNewBox', require('vue-material-design-icons/NewBox').default)
Vue.component('IconCancel', require('vue-material-design-icons/Cancel').default)

Vue.component('IconSettings', require('vue-material-design-icons/CellphoneSettings').default)
Vue.component('IconRefresh', require('vue-material-design-icons/Refresh').default)

Vue.component('IconFlashlight', require('vue-material-design-icons/Flashlight').default)


Vue.component('IconSquareRoot', require('vue-material-design-icons/SquareRoot').default)
Vue.component('IconMathIntegral', require('vue-material-design-icons/MathIntegral').default)
Vue.component('IconMathSin', require('vue-material-design-icons/MathSin').default)
Vue.component('IconMathCos', require('vue-material-design-icons/MathCos').default)
Vue.component('IconAbTesting', require('vue-material-design-icons/AbTesting').default)


export default {
  componentList() {
    return this.list
  },
  list: [
    // Info
    [
      'IconAlert',
      'IconAlertCircle',
      'IconInformationOutline',
      'IconHelpCircle',
    ],

    // Arrows
    [
      'IconArrowLeft',
      'IconArrowRight',
      'IconArrowUp',
      'IconArrowDown',
      'IconChevronUp',
      'IconChevronDown',
      'IconChevronLeft',
      'IconChevronRight',
      'IconMenuDown',
      'IconArrowExpandVertical',
      'IconArrowExpandHorizontal',
      'IconRoutes',
    ],


    // Control
    [
      'IconClose',
      'IconCheck',
      'IconCancel',
      'IconCheckboxMultipleMarkedCircleOutline',
      'IconPlus',
      'IconMinus',
      'IconPlusCircleOutline',
      'IconAlertCircleOutline',
      'IconHelpCircleOutline',
      'IconInvertColors',
      'IconTrashCan',
      'IconContentSave',
      'IconContentSaveMove',
      'IconContentCopy',
      'IconContentPaste',
      'IconDragHorizontal',
      'IconDragVertical',
    ],


    // Magnify
    [
      'IconMagnify',
      'IconMagnifyMinusOutline',
      'IconMagnifyPlusOutline',
      'IconFileFind',
    ],


    // Video
    [
      'IconCamera',
      'IconCameraOff',
      'IconImage',
      'IconGif',
      'IconVideoOutline',
      'IconVideoVintage',
      'IconFilmstrip',
      'IconFilmstripOff',
      'IconMovieRoll',
      'IconLibraryVideo',
      'IconPlayCircle',
    ],

    [

      'IconPlay',

      'IconPause',
      'IconStop',
      'IconRecord',
      'IconRewind',
      'IconFastForward',

      'IconSkipBackward',
      'IconSkipForward',
      'IconVolumeLow',
      'IconVolumeHigh',
      'IconSubtitles',
    ],
    [
      'IconRestart',
      'IconReplay',
      'IconAutorenew',
      'IconRestoreAlert',
      'IconRefresh',
    ],


    // Time
    [
      'IconClockOutline',
      'IconTimelapse',
    ],


    // Edit
    [
      'IconPencil',
      'IconMarker',
      'IconFountainPenTip',
      'IconGreasePencil',
      'IconDraw',
      'IconFlashlight',
    ],

    [
      'IconFormatText',
      'IconKeyboardSpace',
    ],


    // Web
    [
      'IconLinkVariant',
      'IconWeb',
      'IconOpenInNew',
    ],


    // Server
    [
      'IconCloudUploadOutline',
      'IconCloudDownloadOutline',
      'IconServer',
      'IconDatabase',
    ],


    // Code
    [
      'IconCodeBraces',
      'IconCodeJson',
      'IconXml',
    ],


    // Mobile
    [
      'IconCellphoneAndroid',
      'IconCellphoneScreenshot',
      'IconGestureTapButton',
      'IconMonitor',
      'IconFullscreen',
      'IconFullscreenExit',
      'IconArrowExpand',
      'IconArrowCollapse',
    ],


    // Account
    [
      'IconAccountCircle',
      'IconLogout',
      'IconLogin',
    ],


    // School
    [
      'IconMathIntegral',
      'IconChartPie',
      'IconTeach',
      'IconCalculator',
      'IconChartBellCurveCumulative',
    ],

    // Math
    [
      'IconSquareRoot',
      'IconMathIntegral',
      'IconMathSin',
      'IconMathCos',
      'IconAbTesting',
    ],


    [
      'IconNote',
      'IconLayers',
      'IconFormatListBulleted',
      'IconFormatListChecks',
      'IconTable',
      'IconBorderAll',
      'IconBorderNone',

      'IconBookOpen',
      'IconNumeric2BoxMultiple',
      'IconMessageReplyText',

      'IconTools',
      'IconSettings',
      'IconEmoticonCool',
      'IconDotsVertical',
      'IconDotsHorizontal',
      'IconNewBox',
    ],
  ]
}
