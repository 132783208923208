import { render, staticRenderFns } from "./CurriculumInfo.vue?vue&type=template&id=131d6705&scoped=true&"
import script from "./CurriculumInfo.vue?vue&type=script&lang=ts&"
export * from "./CurriculumInfo.vue?vue&type=script&lang=ts&"
import style0 from "./CurriculumInfo.vue?vue&type=style&index=0&id=131d6705&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "131d6705",
  null
  
)

export default component.exports