









import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {eventbus} from "@/main"
import {EventType, SidePanelViewModel} from "@/components/app/eventModel"
import {PathModel} from "@/models/recording/model_path"
import Drawingboard from "@/components/recorder/drawingboard/Drawingboard.vue"
import dummy_screen_action_mc2 from "@/test_area/dummy_data/screens/dummy_screen_action_mc2"

@Component({
  components: {Drawingboard, Row, Column}
})
export default class TestRecording_drawingboard extends Vue {
  // screen = dummy_screen_action_drag_connect2
  screen = dummy_screen_action_mc2

  mounted() {
    this.updateSidePanel([])
  }

  updateSidePanel(paths: Array<PathModel>) {
    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(true, {paths: paths}))
  }
}
