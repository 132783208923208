



























import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import model_curriculum from "@/models/curriculum/model_curriculum"
import TextField from "@/components/fundamental/text/TextField.vue"
import {eventbus} from "@/main"
import {EventType, SidePanelViewModel} from "@/components/app/eventModel"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import store from "@/store/store"


@Component({
  components: {InputField, TextField, TextButton, Row, Column}
})
export default class TestFirestore_entries extends Vue {
  curriculumId = ''
  sidePanelViewModel = new SidePanelViewModel(true)

  /////////////////////////////////
  // Curriculum
  /////////////////////////////////
  createCurriculum() {
    let curriculum = model_curriculum.template_curriculum()
    this.curriculumId = curriculum.id
    store.curriculum.updateCurriculum(curriculum)

    this.sidePanelViewModel.json = curriculum
    eventbus.$emit(EventType.sidePanel, this.sidePanelViewModel)
  }

  async deleteCurriculum() {
    let curriculum = await store.curriculum.deleteCurriculum(this.curriculumId)
    this.sidePanelViewModel.json = undefined
  }

  /////////////////////////////////
  // Recorder Window
  /////////////////////////////////
  async submitRecorderWindowRequest() {
    await store.recorderWindow.submitRequest({
      unitId: 'test-unitid',
      screenId: 'test-screenid',
      recordingId: 'test-recordingid',
      landscapeMode: false,
      predefinedActionState: null,
    })
  }

  async submitRecorderWindowResponse() {
    await store.recorderWindow.submitResponse({recordingId: 'test-id'})
  }

  async cancelRecorderWindowRequest() {
    await store.recorderWindow.cancelRequest()
  }
}
