





























import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import model_content, {ContentModel} from "@/models/content/model_content"
import ContentDialog from "@/components/screen_editor/content/ContentDialog.vue"
import ContentEditor from "@/components/screen_editor/content/ContentEditor.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import {eventbus} from "@/main"
import {EventType, SidePanelViewModel} from "@/components/app/eventModel"
import Container from "@/components/fundamental/layout/Container.vue"
import InlineContentEditor from "@/components/screen_editor/content/InlineContentEditor.vue"

@Component({
  components: {InlineContentEditor, Container, SpacerBox, ContentEditor, ContentDialog, TextButton, Column}
})
export default class TestHtml_contentEditor extends Vue {
  content: ContentModel = model_content.template_none()
  invalidTypes = []

  mounted() {
    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(true, this.content))
  }

  updateContent(newContent: ContentModel) {
    this.content = newContent
  }
}
