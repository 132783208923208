export enum SelectableContentType {
  none = 'None',
  text = 'Text',
  textDivider = 'Text Divider',
  image = 'Image',
  gif = 'Gif',
  lineChart = 'LineChart',
  lineChart_small = 'LineChart small',
  pieChart = 'PieChart',
  boxPlot = 'BoxPlot',
  table = 'Table',
  spacer = 'Spacer',
}

export enum ContentType {
  none,
  text,
  textDivider,
  image,
  gif,
  xyChart,
  boxPlot,
  pieChart,
  table,
  spacer,
}

export interface ContentModel {
  type: ContentType
  vp?: number
  videoId?: string
  source?: string
}

export default {
  template_none(): ContentModel {
    return {
      type: ContentType.none,
    }
  },
  template_textDivider(): ContentModel {
    return {
      type: ContentType.textDivider,
    }
  },
}