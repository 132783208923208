












































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import ContentEditorTitle from "@/pages/unit_editor/ContentEditorTitle.vue"
import StaticEditorHeader from "@/components/screen_editor/static/StaticEditorHeader.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Draggable from "@/components/fundamental/layout/Draggable.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import {ScreenModel} from "@/models/screen/model_screen"
import StaticEditorItem from "@/components/screen_editor/static/StaticEditorItem.vue"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import model_content, {ContentModel} from "@/models/content/model_content"
import {RecordingModel} from "@/models/recording/model_recording";
import {UnitModel} from "@/models/unit/model_unit";

@Component({
  components: {
    AddButton, StaticEditorItem, Container, Draggable,
    TextField, SpacerBox, StaticEditorHeader, ContentEditorTitle, Row, Column
  }
})
export default class StaticEditor extends Vue {
  @Prop({type: Object, required: false}) unit?: UnitModel|undefined
  @Prop({type: Object, required: true}) screen!: ScreenModel
  @Prop({type: Array, required: false}) recordings?: Array<RecordingModel>|undefined


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  addStatic() {
    this.screen.statics.push(model_content.template_none())
  }

  updateStatic(index: number, newItem: ContentModel) {
    this.$set(this.screen.statics, index, newItem)
  }

  deleteStatic(index: number) {
    this.$delete(this.screen.statics, index)
  }
}
