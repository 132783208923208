






































































































import {Component, Prop, Vue} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import {UnitLevel, UnitStatus} from "@/models/unit/model_unit"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import {UnitPreviewModel} from "@/models/unit/model_unit_preview";
import store from "@/store/store";
import util from "@/util/util"

@Component({
  components: {SpacerBox, TextField, Row, RoundIconButton, Container}
})
export default class UnitChip extends Vue {
  @Prop({required: true}) unitId!: string
  @Prop({type: Boolean, required: false, default: false}) hideRemoveButton!: boolean

  unitStatus = UnitStatus
  unitLevelValues = util.enumToArray(UnitLevel)

  get unit(): UnitPreviewModel|null {
    return store.curriculum.getUnitPreview(this.unitId)
  }

  openUnitEditor() {
    if (this.unit) {
      this.$router.push({path: '/unit-editor/' + this.unitId, query: {back: this.$route.fullPath}})
    }
  }

  getTitle(unit: UnitPreviewModel): string {
    let title = unit.ti
    if (unit.re) {
      title += ' - ' + unit.re
    }
    return  title
  }
}
