import model_xy_chart, {XyChartModel} from "@/models/content/charts/model_xy_chart"
import {LineModel} from "@/models/math/model_line"
import {ActionModel, ActionType} from "@/models/action/model_action"
import model_response from "@/models/action/model_response"

export interface DrawLineActionModel extends ActionModel {
  xyChart: XyChartModel
  targetLines: Array<LineModel>
  snapGrid: boolean
  tolerance: number
}


export default {
  template_action(): DrawLineActionModel {
    return {
      type: ActionType.drawLine,
      responses: model_response.template_responses(),
      xyChart: model_xy_chart.template_lineChart(),
      targetLines: [],
      snapGrid: true,
      tolerance: 0,
    }
  },
}