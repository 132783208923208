


















import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {eventbus} from "@/main"
import {EventType, SidePanelViewModel} from "@/components/app/eventModel"
import dummy_screen_simple from "@/test_area/dummy_data/screens/dummy_screen_simple"
import dummy_recording0 from "@/test_area/dummy_data/recordings/dummy_recording0"
import dummy_recording_without_video from "@/test_area/dummy_data/recordings/dummy_recording_without_video"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import VideoEditor from "@/components/video/editor/VideoEditor.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"

@Component({
  components: {SpacerBox, VideoEditor, SelectField, Row, Column}
})
export default class TestRecording_videoEditor extends Vue {

  videoValues = ['withVideo', 'withoutVideo']
  videoLabels = ['With Video', 'Without Video']
  videoValue = 'withVideo'

  screen = dummy_screen_simple
  recordingWithVideo = dummy_recording0
  recordingWithoutVideo = dummy_recording_without_video

  mounted() {
    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(false))
  }
}
