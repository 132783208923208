












































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import EditText from "@/components/fundamental/inputs/EditText.vue"
import EditNumber from "@/components/fundamental/inputs/EditNumber.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import ActionSectionHeader from "@/components/basic/ActionSectionHeader.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import util from "@/util/util"
import LineChartEditor from "@/components/screen_editor/content/chart/line_chart/LineChartEditor.vue"
import AdvancedCheckbox from "@/components/fundamental/advanced_inputs/AdvancedCheckbox.vue"
import AdvancedEditNumber from "@/components/fundamental/advanced_inputs/AdvancedEditNumber.vue"
import model_action_drag_rect, {DragDirection, DragRectActionModel} from '@/models/action/model_action_drag_rect'
import AdvancedSelect from "@/components/fundamental/advanced_inputs/AdvancedSelect.vue"


@Component({
  components: {
    AdvancedSelect,
    AdvancedEditNumber,
    AdvancedCheckbox,
    LineChartEditor,
    RoundIconButton,
    ActionSectionHeader, SpacerBox, TextField, EditNumber, EditText, AddButton, Row, Column
  }
})
export default class DragRectActionEditor extends Vue {
  @Prop({type: Object, required: true}) action!: DragRectActionModel

  directionValues = util.enumToArray(DragDirection)

  addRect() {
    this.action.targetRects.push(model_action_drag_rect.template_rect())
  }

  deleteRect(index: number) {
    util.removeFromArray(this.action.targetRects, index)
  }
}
