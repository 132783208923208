import { render, staticRenderFns } from "./DragConnectField.vue?vue&type=template&id=67f1337c&"
import script from "./DragConnectField.vue?vue&type=script&lang=ts&"
export * from "./DragConnectField.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports