



































import {Component, Prop, Vue} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import mMath from "../../../util/mMath"
import Row from "../layout/Row.vue"
import TextField from "../text/TextField.vue"
import InputField from "./InputField.vue"

@Component({
  components: {InputField, TextField, Row, Container}
})
export default class EditNumber extends Vue {
  @Prop({type: Number, required: true}) value!: number
  @Prop({type: Number, default: 0, required: false}) min!: number
  @Prop({type: Number, default: 0, required: false}) max!: number
  @Prop({type: Number, default: 16, required: false}) size!: number
  @Prop({type: Boolean, default: false, required: false}) light!: number
  @Prop({type: Number, default: 2, required: false}) fractionDigits!: number

  editNumber = false

  get valueStr() {
    return this.value.toFixed(this.fractionDigits)
  }


  editNumberCompleted(value: number) {
    if (this.min < this.max) {
      value = mMath.constrain(value, this.min, this.max)
    }
    this.editNumber = false
    this.$emit('completed', mMath.round(value, this.fractionDigits))
  }
}
