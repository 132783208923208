
















































































import {Component, Vue, Watch} from 'vue-property-decorator'
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import util from "@/util/util"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import {eventbus} from "@/main"
import {AlertDialogType, EventType, NavbarViewModel, SidePanelViewModel} from "@/components/app/eventModel"
import {CurriculumModel} from "@/models/curriculum/model_curriculum"
import SectionLayout from "@/components/fundamental/layout/SectionLayout.vue"
import {RouteName} from "@/router/router"
import {Route} from "vue-router"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import UnitDialog from "@/pages/curriculum_viewer/UnitDialog.vue"
import WhatsNewDialog from "@/components/app/WhatsNewDialog.vue"
import Checkbox from "@/components/fundamental/inputs/Checkbox.vue"
import store from "@/store/store"
import appState from "@/app/state/app_state"
import OfficialCurriculum from "@/pages/curriculum_viewer/official/OfficialCurriculum.vue"
import MirandaCourses from "@/pages/curriculum_viewer/courses/MirandaCourses.vue"

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate', 'beforeRouteLeave'])

@Component({
  components: {
    MirandaCourses, OfficialCurriculum, Checkbox, WhatsNewDialog, UnitDialog, SpacerBox,
    SectionLayout, SelectField, TextButton, TextField, Row, Column
  }
})
export default class CurriculumPage extends Vue {
  localCurriculum: CurriculumModel | null = null

  curriculaValues: Array<string> = []
  curriculaLabels: Array<string> = []

  curriculumState = appState.curriculum


  /////////////////////////////////
  // Life Cycle
  /////////////////////////////////
  beforeRouteLeave(to: Route, from: Route, next: () => void) {
    if (appState.curriculum.unsavedCourseChanges) {
      this.showUnsavedChangesDialog()
      return
    }

    store.curriculum.updateCurriculum(this.localCurriculum)
    appState.curriculum.scrollPosition = window.scrollY
    next()
  }

  created() {
    eventbus.$emit(EventType.navbar, new NavbarViewModel(true))
    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(false))

    store.curriculum.addListener(this.handleCurriculumChanges, this.constructor.name)

    this.showLoading(true)

    store.curriculum.subscribeToCurricula().then(() => {
      this.showLoading(false)
      this.localCurriculum = util.copy(store.curriculum.getRemoteCurriculum(appState.curriculum.curriculumId))
      this.$nextTick(() => {
        window.scrollTo(0, appState.curriculum.scrollPosition)
      })
    })
  }

  destroy() {
    store.curriculum.removeListener(this.constructor.name)
  }


  /////////////////////////////////
  // Watch
  /////////////////////////////////
  @Watch('localCurriculum', {deep: true})
  localCurriculumChanged() {
    if (!this.localCurriculum) return

    store.curriculum.updateCurriculum(this.localCurriculum)
  }


  /////////////////////////////////
  // Curriculum
  /////////////////////////////////
  handleCurriculumChanges() {
    if (this.localCurriculum) {
      this.localCurriculum = util.copy(store.curriculum.getRemoteCurriculum(this.localCurriculum.id))
    }

    this.curriculaValues = []
    store.curriculum.remoteCurricula.forEach(c => this.curriculaValues.push(c.id))

    this.curriculaLabels = []
    store.curriculum.remoteCurricula.forEach(c => this.curriculaLabels.push(util.capitalizeFirstLetter(c.co) + ' - ' + c.sc))
  }

  async openCurriculum(curriculumId: string) {
    if (curriculumId === appState.curriculum.curriculumId) return

    if (appState.curriculum.unsavedCourseChanges) {
      this.showUnsavedChangesDialog()
      return
    }

    this.showLoading(true)
    this.localCurriculum = util.copy(store.curriculum.getRemoteCurriculum(curriculumId))
    appState.curriculum.curriculumChanged(this.localCurriculum)
    await store.courses.subscribeToCourses(curriculumId)
    await store.curriculum.subscribeToUnitPreviewList(curriculumId)
    this.showLoading(false)
  }

  requestDeletingCurriculum() {
    if (!this.localCurriculum) return

    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.delete_high,
      title: `Delete ${this.localCurriculum.sc}`,
      text: 'This will permanently delete the curriculum.',
      confirmAnswer: this.localCurriculum.sc,
      callback: (response: boolean) => {
        if (response) {
          this.deleteCurriculum()
        }
      }
    })
  }

  async deleteCurriculum() {
    if (!this.localCurriculum) return

    this.showLoading(true)
    let id = this.localCurriculum.id
    this.localCurriculum = null
    await store.curriculum.deleteCurriculum(id)
    appState.curriculum.reset()
    this.showLoading(false)
  }

  showUnsavedChangesDialog() {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.info,
      title: `Save or reset`,
      text: `Save or reset current course changes first.`,
      positiveButton: 'Got it',
      negativeButtonVisible: false,
    })
  }


  /////////////////////////////////
  // Editor
  /////////////////////////////////
  newCurriculum() {
    appState.curriculum.newCurriculum = true
    this.$router.push({name: RouteName.curriculumEditor})
  }

  openCurriculumEditor() {
    if (!appState.curriculum.curriculumId) return

    this.$router.push({name: RouteName.curriculumEditor})
  }


  /////////////////////////////////
  // Unit
  /////////////////////////////////
  async openUnitDialog(unitId: string) {
    // @ts-ignore
    this.$refs.unitDialog.open(unitId)
  }


  ////////////////////////////////
  // Courses
  ////////////////////////////////
  toggleMirandaCourses() {
    if (this.curriculumState.coursesVisible && this.curriculumState.unsavedCourseChanges) {
      eventbus.$emit(EventType.alertDialog, {
        alertType: AlertDialogType.info,
        title: `Save or reset`,
        text: `Save or reset current course changes first.`,
        positiveButton: 'Got it',
        negativeButtonVisible: false,
      })
      return
    }

    this.curriculumState.coursesVisible = !this.curriculumState.coursesVisible
  }

  /////////////////////////////////
  // Helper
  /////////////////////////////////
  showLoading(show: boolean) {
    eventbus.$emit(EventType.loadingDialog, show)
  }
}
