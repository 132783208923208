






























































import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import IconButton from "../../components/fundamental/buttons/IconButton.vue"
import Link from "../../components/fundamental/text/Link.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import {SoundModel} from "@/models/sound/model_sound"
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue"
import FirestoreInfo from "@/components/basic/FirestoreInfo.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"

@Component({
  components: {
    TextButton,
    FirestoreInfo, AdvancedEditText, Column, TextField, Row, RoundIconButton, Link, IconButton, Container}
})
export default class SoundItem extends Vue {
  @Prop({type: Number, required: true}) index!: number
  @Prop({type: Object, required: true}) sound!: SoundModel

  @Watch('sound', {deep: true})
  soundChanged() {
    let audio = <HTMLAudioElement>document.getElementById(this.sound.id)
    audio.load()
  }
}
