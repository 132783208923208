
















import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import dummy_screen_simple from "@/test_area/dummy_data/screens/dummy_screen_simple"
import {eventbus} from "@/main"
import {EventType, SidePanelViewModel} from "@/components/app/eventModel"
import ScreenWidget from "@/simulator/screen/ScreenWidget.vue"

@Component({
  components: {ScreenWidget, Container, Row, Column}
})
export default class TestSimulator_screen extends Vue {
  screen = dummy_screen_simple

  mounted() {
    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(true, this.screen))
  }
}
