import {ActionModel, ActionType} from "@/models/action/model_action"
import model_response from "@/models/action/model_response"

export interface DragPieActionModel extends ActionModel {
  targetShares: Array<number>
  labels: Array<string>
  tolerance: number
}

export default {
  template_action(): DragPieActionModel {
    return {
      type: ActionType.dragPie,
      responses: model_response.template_responses(),
      targetShares: [0.5, 0.5],
      labels: ['1', '2'],
      tolerance: 0.1,
    }
  },
}