
































































































































































import {Component, Prop, Vue} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import TextButton from "../../fundamental/buttons/TextButton.vue"
import TextField from "../../fundamental/text/TextField.vue"
import Row from "../../fundamental/layout/Row.vue"
import ProgressBar from "@/components/fundamental/ProgressBar.vue"
import util from "@/util/util"
import Column from "@/components/fundamental/layout/Column.vue"
import s3Uploader from "@/util/images/s3Uploader"
import ImageField from "@/components/fundamental/ImageField.vue"
import model_image from "@/models/content/model_image"

enum ImageUploaderState {
  noFile,
  fileProvided,
  uploading,
  uploadCompleted,
  uploadFailed,
}

@Component({
  components: {ImageField, Column, ProgressBar, Row, TextField, TextButton, Container}
})
export default class ImageUploader extends Vue {
  @Prop({default: undefined, required: false}) name!: any

  imageUploaderState = ImageUploaderState
  state: ImageUploaderState = ImageUploaderState.noFile
  dragHovering = false

  file?: File = undefined
  imageWidth = 0
  imageHeight = 0
  previewSrc: any = ''

  progress = 0

  errorMsg = ''

  /////////////////////////////////
  // Methods
  /////////////////////////////////
  dragEnter() {
    if (this.state === ImageUploaderState.noFile || this.state === ImageUploaderState.fileProvided) {
      this.dragHovering = true
    }
  }

  dragLeave() {
    this.dragHovering = false
  }

  dropFile(e: any) {
    this.dragHovering = false

    if (e.dataTransfer) {
      this.handleUpload(e.dataTransfer.files[0])
    }
  }

  handleUpload(file: File) {
    if (this.state !== ImageUploaderState.noFile && this.state !== ImageUploaderState.fileProvided) return

    // unsupported type
    if (file.type != 'image/png' && file.type != 'image/jpeg') {
      this.errorMsg = 'Unsupported file type\n(supported types: png, jpg)';
      this.state = ImageUploaderState.uploadFailed;
      return;
    }

    this.state = ImageUploaderState.fileProvided
    this.file = file

    let reader = new FileReader()

    // image preview and dims
    reader.onload = (event: any) => {
      this.previewSrc = event.target.result
      let img = new Image()
      img.src = event.target.result
      img.onload = () => {
        this.imageWidth = img.width
        this.imageHeight = img.height
      }
    }

    reader.readAsDataURL(file)
  }

  reset() {
    this.file = undefined
    this.state = ImageUploaderState.noFile
  }

  async submitFile() {
    this.state = ImageUploaderState.uploading

    if (!this.file) {
      this.state = ImageUploaderState.uploadFailed
      return
    }

    let name = this.name
    if (!name) {
      name = util.getId('im')
      let s = this.file.name.split('.')

      if (s.length >= 2) {
        name += '.' + s[s.length-1];
      } else if (this.file.type == 'image/png') {
        name += '.png';
      } else if (this.file.type == 'image/jpg') {
        name += '.jpg';
      }
    }

    try {
      let fileUrl = await s3Uploader.startUpload(name, 'images', this.file, this.progressCallback)

      let image = model_image.template_image()
      image.url = fileUrl
      image.size = this.file ? this.file.size : 0
      image.fileType = this.file ? this.file.type : ''
      image.width = this.imageWidth
      image.height = this.imageHeight

      this.$emit('uploaded', image)

      this.state = ImageUploaderState.uploadCompleted
    } catch (e) {
      this.state = ImageUploaderState.uploadFailed
    }
  }

  progressCallback(progress: number) {
    this.progress = Math.round(progress * 100)
  }
}
