








































import {Component, Prop, Vue} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import TextField from "../../../fundamental/text/TextField.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import AdvancedScaling from "@/components/fundamental/advanced_inputs/AdvancedScaling.vue"
import InlineTextEditor from "@/components/screen_editor/content/text/InlineTextEditor.vue"
import {ChartTextModel} from "@/models/content/charts/model_chart_text"

@Component({
  components: {
    InlineTextEditor, AdvancedScaling,
    TextButton, AdvancedEditText, Column,
    TextField, RoundIconButton, InputField, Row, Container
  }
})
export default class TextElement extends Vue {
  @Prop({required: true}) value!: ChartTextModel | undefined
  @Prop({type: Boolean, required: false, default: false}) hideDeleteButton!: boolean
  @Prop({type: Boolean, required: false, default: false}) smallContent!: boolean
}
