var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"button flat-button text-button",class:{
              'primary': _vm.primary,
              'secondary': _vm.secondary,
              'green': _vm.green,
              'red': _vm.red,
              'blue': _vm.blue,
              'yellow': _vm.yellow,
              'gray': _vm.gray,
              'disabled': _vm.disabled,
              'selected': _vm.selected,
              'small': _vm.small,
              'smaller': _vm.smaller,
              'smallest': _vm.smallest
          },attrs:{"title":_vm.title,"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('clicked')}}},[_c('span',[_vm._v(_vm._s(_vm.title))])])}
var staticRenderFns = []

export { render, staticRenderFns }