

























































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {VideoController, VideoControllerValue} from "@/components/video/controller/VideoController"
import Container from "@/components/fundamental/layout/Container.vue"
import Loading from "@/components/fundamental/Loading.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import {PlayerStatus} from "@/components/video/controller/VideoPlayer"
import {RecordingModel} from "@/models/recording/model_recording"

@Component({
  components: {TextField, Loading, Container, Row, Column}
})
export default class VideoPlayerWidget extends Vue {
  @Prop({type: Object, required: true}) recording!: RecordingModel
  @Prop({type: Number, required: true}) width!: number

  videoController?: VideoController = undefined
  containerId = 'video-' + this.recording.id + window.performance.now()

  timeout = false
  isLoading = false
  isPlaying = false
  isFinished = false

  callbackId = -1

  get height() {
    if (this.recording.height && this.recording.width) {
      return this.recording.height * this.width / this.recording.width
    } else {
      return this.width * 1.4
    }
  }


  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  mounted() {
    this.videoController = new VideoController(this.recording, this.width, this.containerId)
    this.callbackId = this.videoController.addValueCallback(this.controllerCallback)
    this.$emit('videoController', this.videoController)
  }

  beforeDestroy() {
    if (this.videoController) {
      this.videoController.removeValueCallback(this.callbackId)
      this.videoController.destroy()
    }
  }

  /////////////////////////////////
  // Methods
  /////////////////////////////////
  togglePlay() {
    if (this.videoController) {
      this.videoController.togglePlay()
    }
  }


  /////////////////////////////////
  // Callbacks
  /////////////////////////////////
  controllerCallback(v: VideoControllerValue) {
    this.isPlaying = v.isPlaying
    this.isFinished = v.isFinished
    this.isLoading = v.isBuffering

    if (v.status === PlayerStatus.timeout) {
      this.timeout = true

    } else if (v.status === PlayerStatus.ok) {
      this.timeout = false
      this.isLoading = false

    } else if (v.status === PlayerStatus.error) {
      this.timeout = false
      this.isLoading = true
    }
  }
}
