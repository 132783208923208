
































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Container from "@/components/fundamental/layout/Container.vue"

@Component({
  components: {Container, Row, Column}
})
export default class UnitProgressIndicator extends Vue {
  @Prop({type: Number, required: true}) numScreens!: number
  @Prop({type: Number, required: true}) currentScreen!: number

  get indicatorArray() {
    let a = []
    for (let i = 0; i < this.numScreens; i++) {
      a.push(i <= this.currentScreen)
    }
    return a
  }
}
