
















import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {eventbus} from "@/main"
import {EventType, SidePanelViewModel} from "@/components/app/eventModel"
import ScreenWidget from "@/simulator/screen/ScreenWidget.vue"
import dummy_screen_action_drag_drop from "@/test_area/dummy_data/screens/dummy_screen_action_drag_drop"
import DragDropActionEditor from "@/components/screen_editor/action/drag_drop/DragDropActionEditor.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import {ScreenModel} from "@/models/screen/model_screen"

@Component({
  components: {Container, DragDropActionEditor, ScreenWidget, Row, Column}
})
export default class TestUnitEditor_actionDragDrop extends Vue {
  screen: ScreenModel = dummy_screen_action_drag_drop

  mounted() {
    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(true, this.screen))
  }
}
