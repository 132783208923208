












































import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import util from "@/util/util"
import TextField from "@/components/fundamental/text/TextField.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import store from "@/store/store"

@Component({
  components: {Container, RoundIconButton, TextField, Row, Column}
})
export default class CurriculumInfo extends Vue {
  @Prop({type: String, required: true}) unitId!: string
  @Prop({type: String, required: true}) curriculumId!: string

  country = ''
  school = ''
  topic = ''
  chapter = ''
  section = ''

  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  created() {
    this.update()
  }

  @Watch('unitId')
  unitIdChanged() {
    this.update()
  }

  @Watch('curriculumId')
  curriculumIdChanged() {
    this.update()
  }


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  async update() {
    await store.curriculum.subscribeToCurricula()

    this.country = ''
    this.school = ''
    this.topic = ''
    this.chapter = ''
    this.section = ''
    let curriculum = store.curriculum.getRemoteCurriculum(this.curriculumId)

    if (curriculum) {
      this.country = util.capitalizeFirstLetter(curriculum.co)
      this.school = curriculum.sc

      for (let topic of curriculum.to) {
        if (!topic.ch) continue

        for (let chapter of topic.ch) {
          if (!chapter.se) continue

          for (let section of chapter.se) {

            for (let sub of section.su) {

              for (let unitId of sub.un) {
                if (unitId === this.unitId) {
                  this.topic = topic.ti
                  this.chapter = chapter.ti
                  this.section = section.ti
                  return
                }
              }
            }
          }
        }
      }
    }
  }

  copy(text: string) {
    navigator.clipboard.writeText(text).catch(error => {
      console.log('Clipboard error', error)
    })
  }
}
