



















import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import ContentWidget from "@/simulator/content/ContentWidget.vue"
import LineChartEditor from "@/components/screen_editor/content/chart/line_chart/LineChartEditor.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import {SizeModel} from "@/models/math/model_size"
import {XyChartModel} from "@/models/content/charts/model_xy_chart"

@Component({
  components: {
    Container,
    LineChartEditor, ContentWidget, Row, Column
  }
})
export default class ContentLineChartEditor extends Vue {
  @Prop({type: Object, required: true}) content!: XyChartModel
  @Prop({type: Boolean, default: false, required: false}) showPreview!: boolean
  @Prop({type: Object, default: null, required: false}) fieldSize!: SizeModel|null


  get previewWidth(): number {
    let width: number|null = this.fieldSize != null && this.fieldSize.width != null ? this.fieldSize.width : null
    if (width == null) {
      width = this.fieldSize != null && this.fieldSize.maxWidth != null ? this.fieldSize.maxWidth : null
    }
    return width ? width : 300
  }

  get previewHeight(): number {
    let height: number|null = this.fieldSize != null && this.fieldSize.height != null ? this.fieldSize.height : null
    if (height == null) {
      height = this.fieldSize != null && this.fieldSize.maxHeight != null ? this.fieldSize.maxHeight : null
    }
    return height ? height : 300
  }
}
