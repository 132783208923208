


















































import {Component, Prop, Vue} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import IconButton from "@/components/fundamental/buttons/IconButton.vue"
import FirestoreInfo from "@/components/basic/FirestoreInfo.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import {Country, CurriculumModel} from "@/models/curriculum/model_curriculum"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import EditText from "@/components/fundamental/inputs/EditText.vue"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import util from "@/util/util"

@Component({
  components: {SelectField, EditText, SpacerBox, Column, TextField, Row, FirestoreInfo, IconButton, Container}
})
export default class CurriculumHeader extends Vue {
  @Prop({type: Object, required: true}) curriculum!: CurriculumModel

  countries = util.enumToArray(Country)
  countriesLabels = util.capitalizeFirstLetters(util.enumToArray(Country) as Array<string>)

  get curriculumTitle() {
    return util.capitalizeFirstLetter(this.curriculum.co) + ' - ' + this.curriculum.sc
  }

  get numOfficialTopics() {
    return this.curriculum.to.length
  }

  get numOfficialChapters() {
    let n = 0
    for (let topic of this.curriculum.to) {
      n += topic.ch ? topic.ch.length : 0
    }
    return n
  }

  get numOfficialSections() {
    let n = 0
    for (let topic of this.curriculum.to) {
      if (!topic.ch) continue
      for (let chapter of topic.ch) {
        n += chapter.se ? chapter.se.length : 0
      }
    }
    return n
  }
}
