import {ResponseModel} from "@/models/action/model_response"

export interface ActionModel {
  type: ActionType
  responses: Array<ResponseModel>
}

export enum ActionType {
  dragBox = 'dragBox',
  dragConnect = 'dragConnect',
  dragDrop = 'dragDrop',
  dragPie = 'dragPie',
  dragRect = 'dragRect',
  dragText = 'dragText',
  drawAxis = 'drawAxis',
  drawLine = 'drawLine',
  drawPoint = 'drawPoint',
  drawPoly = 'drawPoly',
  drawVector = 'drawVector',
  input = 'input',
  mc = 'mc',
  slideUnlock = 'slideUnlock',
  table = 'table',
  tuneChart = 'tuneChart',
}

export enum ActionTypeLabels {
  dragBox = 'Drag Box',
  dragConnect = 'Drag Connect',
  dragDrop = 'Drag Drop',
  dragPie = 'Drag Pie',
  dragRect = 'Drag Rect',
  dragText = 'Drag Text',
  drawAxis = 'Draw Axis',
  drawLine = 'Draw Line',
  drawPoint = 'Draw Point',
  drawPoly = 'Draw Poly',
  drawVector = 'Draw Vector',
  input = 'Input',
  mc = 'Multiple Choice',
  slideUnlock = 'Slide Unlock',
  table = 'Table',
  tuneChart = 'Tune Chart',
}