
















































































































































import {Component, Vue} from "vue-property-decorator"
import MenuButton from "@/components/fundamental/buttons/MenuButton.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Link from "@/components/fundamental/text/Link.vue"
import {eventbus} from "@/main"
import {EventType, NavbarViewModel} from "@/components/app/eventModel"
import {RouteName} from "@/router/router"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import store from "@/store/store"

@Component({
  components: {Column, Container, SpacerBox, Link, TextField, Row, MenuButton}
})
export default class Navbar extends Vue {

  routeNames = RouteName
  navbarViewModel = new NavbarViewModel(false)

  appStore = store.app
  authStore = store.auth

  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  created() {
    eventbus.$on(EventType.navbar,
      (viewModel: NavbarViewModel) => {
        this.navbarViewModel = viewModel

        if (!viewModel.backPath) {
          viewModel.backPath = (typeof (this.$route.query.back) === 'string') ? this.$route.query.back : '/'
        }
      }
    )
  }
}
