



















































import {Component, Prop, Vue} from "vue-property-decorator"
import {Route} from "vue-router"

@Component
export default class Link extends Vue {
  @Prop({type: String, default: '', required: false}) url!: string
  @Prop({type: Boolean, default: false, required: false}) underline!: boolean
  @Prop({type: Boolean, default: false, required: false}) primary!: boolean

  @Prop({type: Boolean, default: false, required: false}) internal!: boolean
  @Prop({type: String, default: undefined, required: false}) routeName!: Route
  @Prop({type: Object, default: undefined, required: false}) route!: Route

  @Prop({type: String, default: '_blank', required: false}) target!: string

  get localUrl() {
    if (this.internal && this.route) {
      return this.route

    } else if (this.internal && this.routeName) {
      return {name: this.routeName}

    } else {
      return this.url
    }
  }
}
