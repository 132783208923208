

































































































































import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {eventbus} from "@/main"
import VideoConverterWebSocketHandler, {ConverterLogs} from '@/components/converter/VideoConverterWebSocketHandler'
import {AlertDialogType, EventType, NavbarViewModel, SidePanelViewModel} from "@/components/app/eventModel"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import ConverterList from "@/components/converter/ConverterList.vue"
import SectionLayout from "@/components/fundamental/layout/SectionLayout.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import store from "@/store/store"
import AdvancedEditNumber from "@/components/fundamental/advanced_inputs/AdvancedEditNumber.vue"

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate', 'beforeRouteLeave'])

@Component({
  components: {AdvancedEditNumber, TextField, SectionLayout, ConverterList, TextButton, Row, Column}
})
export default class ConverterWindow extends Vue {
  navbarViewModel = new NavbarViewModel(true)
  sidePanelViewModel = new SidePanelViewModel(true)

  cropTop = 400
  cropBottom = 0

  isLoading = false
  isConnected = false
  socketLogs: ConverterLogs | null = null

  webSocketHandler = new VideoConverterWebSocketHandler(
    this.updateLoading,
    this.updateConnected,
    this.updateLogs,
    this.showAlertDialog
  )


  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  created() {
    eventbus.$emit(EventType.navbar, this.navbarViewModel)
    this.navbarViewModel.title = 'Recorder Window'
    this.navbarViewModel.back = true
    this.navbarViewModel.showBackArrow = false

    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(false))
  }

  beforeDestroy() {
    this.closeWebSocket()
  }


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  openWebSocket() {
    this.webSocketHandler.openWebSocket()
  }

  closeWebSocket() {
    this.webSocketHandler.closeWebSocket()
  }

  clearCache() {
    this.webSocketHandler.clearCache()
  }

  fetchOpenRecordings() {
    this.webSocketHandler.fetchOpenRecordings()
  }

  convertOpenRecordings() {
    this.webSocketHandler.convertOpenRecordings(store.auth.userEmail, this.cropTop, this.cropBottom)
  }


  /////////////////////////////////
  // Callbacks
  /////////////////////////////////
  updateLoading(b: boolean) {
    this.isLoading = b
    eventbus.$emit(EventType.loadingDialog, b)
  }

  updateConnected(b: boolean) {
    this.isConnected = b
  }

  updateLogs(logs: ConverterLogs | null) {
    this.socketLogs = logs
  }

  showAlertDialog(alertType: AlertDialogType, title: string, text: string) {
    eventbus.$emit(EventType.alertDialog, {
      alertType: alertType,
      title: title,
      text: text,
      positiveButton: 'Ok',
      negativeButtonVisible: false,
    })
  }

}
