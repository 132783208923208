
























import {Component, Prop, Vue} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import TextField from "../../../fundamental/text/TextField.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import TextElement from "@/components/screen_editor/content/chart/TextElement.vue"
import {ChartTextModel} from "@/models/content/charts/model_chart_text"

@Component({
  components: {
    TextElement, Column, TextField, RoundIconButton, InputField, Row, Container
  }
})
export default class ChartTextEditor extends Vue {
  @Prop({type: Number, required: true}) index!: number
  @Prop({type: Object, required: true}) text!: ChartTextModel
}
