import {ActionModel, ActionType} from "@/models/action/model_action"
import model_response from "@/models/action/model_response"

export interface DragTextActionModel extends ActionModel {
  targetTexts: Array<string> // ??fieldIndex,itemIndex??
  fields: Array<DragTextFieldModel>
  fontSize: number
  fieldFontSize: number
  multipleColumns: boolean
}

export interface DragTextFieldModel {
  itemTexts: Array<string>
}


export default {
  template_action(): DragTextActionModel {
    return {
      type: ActionType.dragText,
      responses: model_response.template_responses(),
      targetTexts: ['A house is', '??0,1??', '.'],
      fields: [this.template_field(), this.template_field()],
      fontSize: 16,
      fieldFontSize: 16,
      multipleColumns: false,
    }
  },
  template_field(): DragTextFieldModel {
    return {
      itemTexts: ['1', '2', '3'],
    }
  },
}