





















import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import {Device, Devices} from "@/app/devices"

@Component({
  components: {RoundIconButton, TextField, SelectField, Row, Column}
})
export default class DeviceSelector extends Vue {
  @Prop({type: Object, required: true}) device!: Device

  devices = Devices

  updateDevice(d: string) {
    this.$emit('device', Devices.getDevice(d))
  }

}
