




































































































































import {Component, Prop, Vue} from "vue-property-decorator"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import Loading from "@/components/fundamental/Loading.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {CameraStatus} from "@/components/recorder/camera/EosHandler"

@Component({
  components: {Row, TextField, Loading, Column, TransitionEffect}
})
export default class CameraDialog extends Vue {
  @Prop({type: Object, required: true}) cameraStatus!: CameraStatus
  @Prop({type: Boolean, required: true}) visible!: boolean
}
