






















































































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import EditText from "@/components/fundamental/inputs/EditText.vue"
import EditNumber from "@/components/fundamental/inputs/EditNumber.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import ActionSectionHeader from "@/components/basic/ActionSectionHeader.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import util from "@/util/util"
import AdvancedCheckbox from "@/components/fundamental/advanced_inputs/AdvancedCheckbox.vue"
import AdvancedEditNumber from "@/components/fundamental/advanced_inputs/AdvancedEditNumber.vue"
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue"
import model_action_drag_text, {DragTextActionModel} from "@/models/action/model_action_drag_text"
import AdvancedSelect from "@/components/fundamental/advanced_inputs/AdvancedSelect.vue"
import InlineTextEditor from "@/components/screen_editor/content/text/InlineTextEditor.vue"
import BasicTextEditorDialog from "@/components/screen_editor/content/text/BasicTextEditorDialog.vue"
import {eventbus} from "@/main"
import {AlertDialogType, EventType} from "@/components/app/eventModel"


@Component({
  components: {
    BasicTextEditorDialog,
    InlineTextEditor,
    AdvancedSelect,
    AdvancedEditText,
    AdvancedEditNumber,
    AdvancedCheckbox,
    RoundIconButton,
    ActionSectionHeader, SpacerBox, TextField, EditNumber, EditText, AddButton, Row, Column
  }
})
export default class DragTextActionEditor extends Vue {
  @Prop({type: Object, required: true}) action!: DragTextActionModel





  /////////////////////////////////
  // Drag fields
  /////////////////////////////////
  addField() {
    this.action.fields.push(model_action_drag_text.template_field())
  }

  deleteField(fieldIndex: number) {
    for (let text of this.action.targetTexts) {
      if (text.startsWith('??' + fieldIndex + ',')) {
        eventbus.$emit(EventType.alertDialog, {
          alertType: AlertDialogType.info,
          title: 'Field used in Target Text',
          text: 'Remove this field from Target Text firs.',
          positiveButton: 'Ok',
        })

        return
      }
    }


    util.removeFromArray(this.action.fields, fieldIndex)
  }

  addItem(fieldIndex: number) {
    this.action.fields[fieldIndex].itemTexts.push('')
  }

  deleteItem(fieldIndex: number, itemIndex: number) {
    util.removeFromArray(this.action.fields[fieldIndex].itemTexts, itemIndex)
  }

  getFieldValues() {
    let a = []
    for (let i = 0; i < this.action.fields.length; i++) {
      a.push(''+(i+1))
    }
    return a
  }

  getItemValues(fieldIndex: number) {
    let a = []
    for (let i = 0; i < this.action.fields[fieldIndex].itemTexts.length; i++) {
      a.push(''+(i+1))
    }
    return a
  }

  getItemLabels(fieldIndex: number) {
    let a = []
    for (let i = 0; i < this.action.fields[fieldIndex].itemTexts.length; i++) {
      a.push(this.action.fields[fieldIndex].itemTexts[i])
    }
    return a
  }

  /////////////////////////////////
  // Target texts
  /////////////////////////////////
  addTargetText() {
    this.action.targetTexts.push('')
  }

  deleteTargetText(index: number) {
    util.removeFromArray(this.action.targetTexts, index)
  }

  updateTargetText(index: number, type: string) {
    if (this.isDropField(this.action.targetTexts[index]) && type !== 'text' || !this.isDropField(this.action.targetTexts[index]) && type === 'text') return

    this.$set(this.action.targetTexts, index, type === 'text' ? '' : '??0,0??')
  }

  isDropField(text: string) {
    return text.match(/\?\?(.*?),(.*?)\?\?/)
  }

  getFieldIndex(text: string) {
    if (!this.isDropField(text)) return -1

    let s = text.split(',')
    return  parseInt(s[0].substring(2))
  }

  setFieldIndex(index: number, fieldIndex: number) {
    let itemIndex = this.getItemIndex(this.action.targetTexts[index])
    this.$set(this.action.targetTexts, index, '??' + fieldIndex.toFixed(0) + ',' + itemIndex.toFixed(0) + '??')
  }

  getItemIndex(text: string) {
    if (!this.isDropField(text)) return -1

    let s = text.split(',')
    return  parseInt(s[1].substring(0, s[1].length-2))
  }

  setItemIndex(index: number, itemIndex: number) {
    let fieldIndex = this.getFieldIndex(this.action.targetTexts[index])
    this.$set(this.action.targetTexts, index, '??' + fieldIndex.toFixed(0) + ',' + itemIndex.toFixed(0) + '??')
  }
}
