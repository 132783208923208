import { render, staticRenderFns } from "./UnitEditorOverview.vue?vue&type=template&id=39f2a175&"
import script from "./UnitEditorOverview.vue?vue&type=script&lang=ts&"
export * from "./UnitEditorOverview.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports