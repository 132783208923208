import model_firestore, {FirestoreModel} from "@/models/model_firestore"
import {PathModel} from "@/models/recording/model_path"
import {CameraDetailsJson} from "@/components/recorder/camera/EosHandler"
import util from "@/util/util"

export interface RecordingRawModel extends FirestoreModel {
  type: number // RecordingType
  screenVersion: string
  unitId: string // old unitId
  uId2: string // unitId
  screenId: string
  as?: number // actionStatus
  startTime: number
  duration: number
  videoEncodingRequired: boolean
  qe?: boolean // quickEncoded
  sequences: Array<SequenceModel>
}

export interface SequenceModel {
  startTime: number
  stopTime: number
  duration: number
  filename: string
  paths: Array<PathModel>
  cameraStartingTime: number
  cameraStoppingTime: number
  cameraDetails?: CameraDetailsJson
}

export default {
  template_recordingRaw(): RecordingRawModel {
    return {
      ...model_firestore.template('re'),
      type: 0, // RecordingType.mobil
      screenVersion: '',
      unitId: '',
      uId2: '',
      screenId: '',
      startTime: Date.now() / 1000,
      duration: 0,
      videoEncodingRequired: false,
      sequences: [this.template_sequence()],
    }
  },

  template_sequence(): SequenceModel {
    return {
      startTime: 0,
      stopTime: 0,
      duration: 0,
      filename: '',
      paths: [],
      cameraStartingTime: 0,
      cameraStoppingTime: 0,
      cameraDetails: undefined
    }
  },

  copyRecordingRaw(recording: RecordingRawModel, newUnitId: string) {
    let newRecording: RecordingRawModel = {
      ...model_firestore.template('re'),
      type: recording.type,
      screenVersion: recording.screenVersion,
      unitId: recording.type == 0 ? newUnitId : newUnitId + '_yt',
      uId2: newUnitId,
      screenId: recording.screenId,
      startTime: recording.startTime,
      duration: recording.duration,
      videoEncodingRequired: recording.videoEncodingRequired,
      sequences: util.copyArray(recording.sequences),
    }

    if (recording.as) {
      newRecording['as'] = recording.as
    }

    if (recording.qe) {
      newRecording['qe'] = recording.qe
    }

    return newRecording
  }
}