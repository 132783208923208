









































































































































































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {PathColor, PathWidth} from "@/models/recording/model_path"
import Container from "@/components/fundamental/layout/Container.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"

@Component({
  components: {SelectField, RoundIconButton, Container, Row, Column}
})
export default class Toolbox extends Vue {
  @Prop({type: String, required: true}) pathWidth!: PathWidth
  @Prop({type: String, required: true}) pathColor!: PathColor
  @Prop({type: Boolean, default: false, required: false}) largeDisplay!: boolean
  @Prop({type: Boolean, default: false, required: false}) borders!: boolean
  @Prop({type: Boolean, default: false, required: false}) flashlight!: boolean
  @Prop({type: Boolean, default: false, required: false}) drawingModeActive!: boolean

  pathColors = PathColor
  pathWidths = PathWidth

  setColor(color: PathColor) {
    this.$emit('color', color)
  }

  setWidth(width: PathWidth) {
    this.$emit('width', width)
  }
}
