













































import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {PenPathModel, PathColor, PathType, PathWidth} from "@/models/recording/model_path"
import Container from "@/components/fundamental/layout/Container.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import Eventboard from "@/components/recorder/drawingboard/Eventboard.vue"
import Whiteboard from "@/components/simulator/screen/whiteboard/Whiteboard.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import mMath from "@/util/mMath"
import ScreenWidget from "@/simulator/screen/ScreenWidget.vue"
import {PointModel} from "@/models/math/model_point"

@Component({
  components: {ScreenWidget, TextField, Whiteboard, Eventboard, TextButton, SpacerBox, Container, Row, Column}
})
export default class Drawingboard2 extends Vue {

  width = 900
  height = 650

  pathColor_pen = PathColor.black
  pathWidth_pen = PathWidth.small

  paths: Array<PenPathModel> = []

  startTime = 0
  deltaAverage = 0

  reset() {
    this.paths.splice(0, this.paths.length)
  }

  /////////////////////////////////
  // Pen and flashlight
  /////////////////////////////////
  startPath(pathCoordinates: PointModel) {
    this.startTime = Date.now() / 1000

    let path: PenPathModel = {
      type: PathType.pen,
      width: this.pathWidth_pen,
      color: this.pathColor_pen,
      x: [pathCoordinates.x],
      y: [pathCoordinates.y],
      time: [0],
    }
    this.paths.push(path)
  }

  updatePath(pathCoordinates: PointModel) {
    let lastPath = this.paths[this.paths.length - 1]
    lastPath.x.push(pathCoordinates.x)
    lastPath.y.push(pathCoordinates.y)
    lastPath.time.push(Date.now() / 1000 - this.startTime)
  }

  stopPath() {
    this.deltaAverage = 0
    let deltaStepsCounter = 0

    for (let path of this.paths) {
      // for (var x in path.x) {
      //     xStr += '${(x * width).toStringAsFixed(1)}\n';
      // }
      //
      // for (var y in path.y) {
      //     yStr += '${(y * width).toStringAsFixed(1)}\n';
      // }
      //
      // for (var t in path.time) {
      //     timeStr += '${(t).toStringAsFixed(3)}\n';
      // }

      // xStr += '\n';
      // yStr += '\n';
      // timeStr += '\n';


      for (let i = 1; i < path.time.length; i++) {
        let d = path.time[i] - path.time[i - 1]
        this.deltaAverage += d
        // if (d > 0.02) {
        //     deltaTimeStr += '* ';
        // } else {
        //     deltaTimeStr += '  ';
        // }
        // deltaTimeStr += '${(d).toStringAsFixed(3)}\n';
      }
      deltaStepsCounter += path.time.length - 1
    }

    this.deltaAverage /= deltaStepsCounter
    this.deltaAverage = mMath.round(this.deltaAverage, 3)
  }
}
