import firebase from 'firebase/app'
import 'firebase/firestore'
import dbHelper from '../util/basic/dbHelper'
import ChangeNotifier from "@/util/basic/change_notifier"
import params from "@/app/params"
import util from "@/util/util"
import {CourseModel} from "@/models/curriculum/model_course";


export default class CoursesStore extends ChangeNotifier {

  _curriculumId = ''
  _courses: Array<CourseModel> = []
  _coursesUnsubscribe: any = undefined

  init() {}

  /////////////////////////////////
  // Getters
  /////////////////////////////////
  get remoteCourses(): Array<CourseModel> {
    return this._courses
  }


  /////////////////////////////////
  // Courses
  /////////////////////////////////
  async subscribeToCourses(curriculumId: string): Promise<void> {
    if (curriculumId === this._curriculumId) return

    this._curriculumId = curriculumId

    return new Promise((resolve, reject) => {

      if (this._coursesUnsubscribe) {
        this._coursesUnsubscribe()
      }

      this._coursesUnsubscribe = firebase.firestore().collection(params.firestore.courses)
        .where('curriculumId', '==', curriculumId)
        .onSnapshot(
          (querySnapshot) => {
            this._courses = querySnapshot.docs.map(doc => dbHelper.docToJson(doc))
            this.notify()
            resolve()
          },

          error => reject(error)
        )
    })
  }

  async updateCourse(course: CourseModel | null) {
    if (!course) return

    // return if course has not changed
    let remoteCourse = this._courses.find(c => c.id === course.id)
    if (util.compare(course, remoteCourse)) return

    await firebase.firestore().collection(params.firestore.courses).doc(course.id)
      .set(dbHelper.jsonToFirestoreJson(course))
  }

  async deleteCourse(id: string) {
    await firebase.firestore().collection(params.firestore.courses).doc(id).delete()
  }
}