






import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {eventbus} from "@/main"
import {EventType, SidePanelViewModel} from "@/components/app/eventModel"
import dummy_screen_mc_cats from "@/test_area/dummy_data/screens/dummy_screen_mc_cats"
import ActionEditor from "@/components/screen_editor/action/ActionEditor.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"

@Component({
  components: {TextButton, ActionEditor, Row, Column}
})
export default class TestUnitEditor_unitEditor extends Vue {
  screen = dummy_screen_mc_cats

  mounted() {
    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(false))
  }

  openEditor() {
    this.$router.push({path: '/unit-editor/unit-MKAxdfYKdmHQUoIwhtmv2fRFF0c', query: {back: this.$route.fullPath}})
  }
}
