








import {Component, Prop, Vue} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import util from "@/util/util"
import {ColorModel, ColorModelValue} from "@/models/model_colors"

@Component({
  components: {Container}
})
export default class ColorPreview extends Vue {
  @Prop({type: String, required: true}) color!: ColorModel
  @Prop({type: Number, required: false, default: 24}) size!: number

  colors = util.enumToArray(ColorModel)
  colorsValues = ColorModelValue
}
