

































import {Component, Vue} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import {eventbus} from "@/main"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import IconButton from "@/components/fundamental/buttons/IconButton.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {EventType, SnackbarEvent} from "@/components/app/eventModel"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"

@Component({
  components: {RoundIconButton, Row, TextField, IconButton, TransitionEffect, Container}
})
export default class Snackbar extends Vue {

  isVisible: boolean = false
  visibilityTimeout: any = undefined
  msg: string = 'test'

  /////////////////////////////////
  // Life Cycle
  /////////////////////////////////
  created() {
    eventbus.$on(EventType.snackbar, (e: SnackbarEvent) => {
      this.msg = e.msg
      this.isVisible = true
      this.clearTimeout()
      this.visibilityTimeout = setTimeout(
        () => {
          this.close()
        },
        e.duration ? e.duration : 2000
      )
    })
  }

  /////////////////////////////////
  // Methods
  /////////////////////////////////
  close() {
    this.isVisible = false
    this.clearTimeout()
  }

  clearTimeout() {
    if (this.visibilityTimeout) {
      clearTimeout(this.visibilityTimeout)
    }
  }
}

