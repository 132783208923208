














import {Component, Vue, Watch} from 'vue-property-decorator'
import model_curriculum, {CurriculumModel} from "@/models/curriculum/model_curriculum"
import util from "@/util/util"
import {eventbus} from "@/main"
import {AlertDialogType, EventType, NavbarViewModel, SidePanelViewModel} from "@/components/app/eventModel"
import Loading from "@/components/fundamental/Loading.vue"
import CurriculumHeader from "@/pages/curriculum_editor/CurriculumHeader.vue"
import OfficialTopics from "@/pages/curriculum_editor/official/OfficialTopics.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import SectionLayout from "@/components/fundamental/layout/SectionLayout.vue"
import {Route} from "vue-router"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import store from "@/store/store"
import appState from "@/app/state/app_state"
import {RouteName} from "@/router/router"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import Row from "@/components/fundamental/layout/Row.vue"

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate', 'beforeRouteLeave'])

@Component({
  components: {
    Row, TextButton, TextField, SpacerBox, SectionLayout, Column, OfficialTopics, CurriculumHeader, Loading}
})
export default class CurriculumEditorPage extends Vue {
  isNew = true
  unsavedChanges = false
  curriculum: CurriculumModel = model_curriculum.template_curriculum()
  navbarViewModel = new NavbarViewModel(true)
  sidePanelViewModel = new SidePanelViewModel(true)

  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  created() {
    window.scrollTo(0, 0)

    // update navbar
    eventbus.$emit(EventType.navbar, this.navbarViewModel)
    this.navbarViewModel.back = true
    this.navbarViewModel.title = 'Curriculum Editor'
    this.navbarViewModel.resetCallback = this.reset
    this.navbarViewModel.saveCallback = this.save
    this.navbarViewModel.saveCloseCallback = () => {this.save(true)}
    this.navbarViewModel.backRoute = {name: RouteName.curriculum}
    this.updateUnsavedChanges()

    // update sidePanel
    eventbus.$emit(EventType.sidePanel, this.sidePanelViewModel)
    this.sidePanelViewModel.json = this.curriculum

    // fetch curriculum
    this.showLoading(true)
    store.curriculum.subscribeToCurricula().then(() => {
      this.showLoading(false)

      if (appState.curriculum.curriculumId && !appState.curriculum.newCurriculum) {
        this.isNew = false
        this.curriculum = util.copy(store.curriculum.getRemoteCurriculum(appState.curriculum.curriculumId))
      }
      appState.curriculum.newCurriculum = false

      this.sidePanelViewModel.json = this.curriculum
    })
  }

  beforeRouteLeave(to: Route, from: Route, next: () => void) {
    if (this.unsavedChanges) {
      eventbus.$emit(EventType.alertDialog, {
        alertType: AlertDialogType.save,
        callback: (response: boolean) => {
          if (response) {
            this.save(true)
          } else {
            next()
          }
        }
      })
    } else {
      next()
    }
  }

  /////////////////////////////////
  // Watch
  /////////////////////////////////
  @Watch('isNew')
  isNewChanged() {
    this.updateUnsavedChanges()
  }

  @Watch('curriculum', {deep: true})
  curriculumChanged() {
    this.updateUnsavedChanges()
  }

  updateUnsavedChanges() {
    this.unsavedChanges = this.isNew || !util.compare(this.curriculum, store.curriculum.getRemoteCurriculum(this.curriculum.id))
    this.navbarViewModel.showReset = !this.isNew && this.unsavedChanges
    this.navbarViewModel.showSave = this.unsavedChanges
    this.navbarViewModel.showSaveClose = this.unsavedChanges
  }

  /////////////////////////////////
  // Methods
  /////////////////////////////////
  async save(close = false) {
    if (!this.curriculum.sc) {
      eventbus.$emit(EventType.alertDialog, {
        alertType: AlertDialogType.warning,
        title: 'School type missing',
        text: 'Please provide <b>School Type</b> first.',
        negativeButtonVisible: false,
      })
      return
    }

    this.showLoading(true)
    await store.curriculum.updateCurriculum(this.curriculum)

    this.isNew = false
    this.curriculum = util.copy(store.curriculum.getRemoteCurriculum(this.curriculum.id))
    appState.curriculum.curriculumChanged(this.curriculum)
    this.updateUnsavedChanges()

    if (close) {
      this.$router.push({name: RouteName.curriculum}).then()
    }

    this.showLoading(false)
  }

  reset() {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.warning,
      title: 'Discard changes',
      text: 'Current changes will be discarded.',
      positiveButton: 'Discard',
      callback: (response: boolean) => {
        if (response) {
          this.curriculum = util.copy(store.curriculum.getRemoteCurriculum(this.curriculum.id))
        }
      }
    })
  }

  showLoading(show: boolean) {
    eventbus.$emit(EventType.loadingDialog, show)
  }
}
