




















































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import Draggable from "@/components/fundamental/layout/Draggable.vue"
import {CourseModel} from "@/models/curriculum/model_course";
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import util from "@/util/util"

@Component({
  components: {
    TextField,
    AdvancedEditText,
    RoundIconButton,
    Draggable, AddButton, TextButton, SpacerBox, Container, Row, Column}
})
export default class MirandaSkills extends Vue {
  @Prop({type: Object, required: true}) course!: CourseModel

  addSkill() {
    this.course.skills.push({id: util.getId('sk'), ti: ''})
  }

  deleteSkill(index: number) {
    for (let topic of this.course.topics) {
      for (let chapter of topic.ch) {
        if (chapter.si === this.course.skills[index].id) {
          delete chapter['si']
        }
      }
    }
    this.$delete(this.course.skills, index)
  }
}
