


























import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {eventbus} from "@/main"
import {EventType, SidePanelViewModel} from "@/components/app/eventModel"
import {RecordingRawModel} from "@/models/recording/model_recording_raw"
import Drawingboard from "@/components/recorder/drawingboard/Drawingboard.vue"
import RecorderControl from "@/components/recorder/RecorderControl.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import dummy_screen_action_mc2 from "@/test_area/dummy_data/screens/dummy_screen_action_mc2"

@Component({
  components: {SpacerBox, RecorderControl, Drawingboard, Row, Column}
})
export default class TestRecording_recorder extends Vue {

  // screen = dummy_screen_simple
  screen = dummy_screen_action_mc2
  recorderRunning = false
  drawingEnabled = true
  recordingRaw?: RecordingRawModel = undefined

  mounted() {
    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(true, this.recordingRaw))
  }

  started() {
    this.recorderRunning = true
  }

  stopped(recordingRaw: RecordingRawModel) {
    this.recorderRunning = false
    this.recordingRaw = recordingRaw
    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(true, this.recordingRaw))
  }
}
