

















































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import {CurriculumModel, OfficialSectionModel} from "@/models/curriculum/model_curriculum"
import Row from "@/components/fundamental/layout/Row.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import Draggable from "@/components/fundamental/layout/Draggable.vue"
import UnitChip from "@/pages/curriculum_viewer/UnitChip.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import appState from "@/app/state/app_state"
import OfficialSubsection from "@/pages/curriculum_viewer/official/OfficialSubsection.vue";

@Component({
  components: {
    OfficialSubsection,
    TextButton, SpacerBox, Container, UnitChip, Draggable, RoundIconButton, Row, TextField, Column}
})
export default class OfficialSection extends Vue {
  @Prop({type: Object, required: true}) curriculum!: CurriculumModel
  @Prop({type: Object, required: true}) section!: OfficialSectionModel

  curriculumState = appState.curriculum
  contentVisible: boolean = !!this.curriculumState.officialSectionsVisible.get(this.section.id)

  toggleContentVisible() {
    this.contentVisible = !this.contentVisible
    this.curriculumState.officialSectionsVisible.set(this.section.id, this.contentVisible)
  }
}
