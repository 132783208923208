import store from "@/store/store"

export default {

  /////////////////////////////////
  // Objects
  /////////////////////////////////
  copy(object: any): any {
    return JSON.parse(JSON.stringify(object))
  },
  compare(json1?: object | null, json2?: object | null) {
    if (json1 === undefined || json2 === undefined || json1 === null || json2 === null) return false
    return JSON.stringify(json1) === JSON.stringify(json2)
  },

  /////////////////////////////////
  // Enum
  /////////////////////////////////
  enumToArray(e: any) {
    return Object.values(e).filter(v => typeof (v) !== 'number')
  },

  getEnumKey(index: number, e: any): any {
    return this.enumToArray(e)[index]
  },


  /////////////////////////////////
  // Arrays
  /////////////////////////////////
  copyArray(array: Array<any>): Array<any> {
    let a = []
    for (let item of array) {
      a.push(this.copy(item))
    }
    return a
  },
  arrayOf(length: number, value: any): Array<any> {
    let a = []
    for (let i = 0; i < length; i++) {
      a.push(value)
    }
    return a
  },
  addToArray(array: Array<any>, element: any, index: number): Array<any> {
    array.splice(index, 0, element)
    return array
  },
  removeFromArray(array: Array<any>, index: number): Array<any> {
    array.splice(index, 1)
    return array
  },
  moveInArray(array: Array<any>, oldIndex: number, newIndex: number): Array<any> {
    let element = array[oldIndex]
    array.splice(oldIndex, 1)
    array.splice(newIndex, 0, element)
    return array
  },

  /////////////////////////////////
  // Hash
  /////////////////////////////////
  getId(prefix = '') {
    let p = prefix ? prefix.substring(0, 2) + '_' : '' // prefix length max is 2
    return p + store.auth.userId.substring(0, 3) + this.toBase62((new Date()).getTime()) // add part of user id to ensure that there is no overlap
  },
  toBase62(n: number) {
    if (n === 0) {
      return '0'
    }

    let digits = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    let result = ''

    while (n > 0) {
      result = digits[n % digits.length] + result
      n = parseInt((n / digits.length).toString(), 10)
    }

    return result
  },
  fromBase62(s: string) {
    let digits = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    let result = 0
    for (let i = 0; i < s.length; i++) {
      let p = digits.indexOf(s[i])
      if (p < 0) {
        return NaN
      }
      result += p * Math.pow(digits.length, s.length - i - 1)
    }
    return result
  },
  replaceAll(str: string, find: string, replace: string) {
    find = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1")
    return str.replace(new RegExp(find, 'g'), replace)
  },


  /////////////////////////////////
  // String
  /////////////////////////////////
  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  },
  capitalizeFirstLetters(array: Array<string>) {
    return array.map(v => v.charAt(0).toUpperCase() + v.slice(1))
  },
  copyString(str: string) {
    return (' ' + str).slice(1)
  },


  /////////////////////////////////
  // Pretty Html
  /////////////////////////////////
  getPrettyHtml(json: any) {
    let prettyHtml = require('json-pretty-html').default
    return prettyHtml(json)
  },


  /////////////////////////////////
  // Time
  /////////////////////////////////
  createTimeString(s: number, showHours = true, showMillis = true) {
    let hundredths = Math.floor(s * 100) % 100
    let seconds = Math.floor(s) % 60
    let minutes = Math.floor(s / 60) % 60
    let hours = Math.floor(s / (60 * 60))

    function addZero(t: number) {
      return t < 10 ? '0' + t : t
    }

    let timeStr = `${addZero(minutes)}:${addZero(seconds)}`
    if (showHours) timeStr = `${addZero(hours)}:` + timeStr
    if (showMillis) timeStr = timeStr + `.${addZero(hundredths)}`
    return timeStr
  },
  timeStrToNumber(timeStr: string) {
    timeStr += 'end'
    let m = timeStr.match(/(?<hours>\d{2})?:?(?<minutes>\d{2}):(?<seconds>\d{2}(\.\d{0,3})?)end/)

    if (!m || !m.groups) return 0

    let time = parseFloat(m.groups.seconds)
    time += parseInt(m.groups.minutes) * 60
    if (m.groups.hours) time += parseInt(m.groups.hours) * 3600
    return time
  },


  /////////////////////////////////
  // Pixels
  /////////////////////////////////
  pxToFloat(pixelStr: string) {
    // @ts-ignore
    return pixelStr ? parseFloat(pixelStr.trim('px')) : 0
  },
  floatToPx(f: number) {
    return f + 'px'
  },

  /////////////////////////////////
  // Clipboard
  /////////////////////////////////
  unitLinkToClipboard(unitId: string) {
    navigator.clipboard.writeText('https://miranda.works/unit/' + unitId).catch(error => {
      console.log('Clipboard error', error)
    })
  }
}