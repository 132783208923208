









import {Component, Vue} from "vue-property-decorator"
import RoundIconButton from "./RoundIconButton.vue"

@Component({
  components: {RoundIconButton}
})
export default class AddButton extends Vue {
}
