import {LimitsModel} from "@/models/content/charts/model_limits"
import {ColorModel} from "@/models/model_colors"

export enum ThumbnailType {
  chart = 'chart',
  formula = 'formula',
  image = 'image',
}

export interface ThumbnailModel {
  ty: ThumbnailType
  ti?: string
}

export interface ChartThumbnailModel extends ThumbnailModel {
  f1: string
  c1: ColorModel
  f2?: string
  c2?: ColorModel
  la?: string
  li: LimitsModel
}

export interface FormulaThumbnailModel extends ThumbnailModel {
  fr: string
  si?: number
}

export interface ImageThumbnailModel extends ThumbnailModel {
  ur: string
  hw: number
}

export default {
  template_chartThumbnail(): ChartThumbnailModel {
    return {
      ty: ThumbnailType.chart,
      f1: 'x^2',
      c1: ColorModel.cyan,
      li: {
        x_min: -5,
        x_max: 5,
        y_min: -5,
        y_max: 5,
      }
    }
  },
  template_formulaThumbnail(): FormulaThumbnailModel {
    return {
      ty: ThumbnailType.formula,
      fr: 'x^2',
    }
  },
  template_imageThumbnail(): ImageThumbnailModel {
    return {
      ty: ThumbnailType.image,
      ur: '',
      hw: 1
    }
  },
}