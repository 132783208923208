































































import {Component, Prop, Vue} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import InlineColorSelector from "@/components/screen_editor/content/color/InlineColorSelector.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue"
import AdvancedEditNumber from "@/components/fundamental/advanced_inputs/AdvancedEditNumber.vue"
import AdvancedCheckbox from "@/components/fundamental/advanced_inputs/AdvancedCheckbox.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import AdvancedScaling from "@/components/fundamental/advanced_inputs/AdvancedScaling.vue"
import TextElement from "@/components/screen_editor/content/chart/TextElement.vue"
import model_chart_text from "@/models/content/charts/model_chart_text"
import TextField from "@/components/fundamental/text/TextField.vue"
import {ChartVectorModel} from "@/models/content/charts/model_chart_vector"

@Component({
  components: {
    TextField,
    TextElement, AdvancedScaling, TextButton,
    AdvancedCheckbox, AdvancedEditNumber, AdvancedEditText, Column,
    InlineColorSelector, RoundIconButton, InputField, Row, Container
  }
})
export default class ChartVectorEditor extends Vue {
  @Prop({type: Number, required: true}) index!: number
  @Prop({type: Object, required: true}) vector!: ChartVectorModel
  @Prop({type: Boolean, required: false, default: false}) smallContent!: boolean

  addLabel() {
    this.$set(this.vector, 'label', model_chart_text.template())

    if (this.smallContent) {
      this.vector.label!.text.size = 14
    }
  }

  deleteLabel() {
    this.$delete(this.vector, 'label')
  }
}
