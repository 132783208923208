










import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import {TableModel} from "@/models/content/model_table"

@Component({
  components: {
    Container, TextField, SpacerBox, Row, Column
  }
})
export default class ContentTablePreview extends Vue {
  @Prop({type: Object, required: true}) content!: TableModel

  get numRows() {
    if (!this.content) return 0
    return this.content?.rows.length
  }

  get numColumns() {
    if (!this.content) return 0
    return this.numRows == 0 ? 0 : this.content?.rows[0].cells.length
  }
}
