















































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import ContentLineChartEditor from "@/components/screen_editor/content/chart/line_chart/ContentLineChartEditor.vue"
import ContentTypeSelector from "@/components/screen_editor/content/ContentTypeSelector.vue"
import ContentSpacerEditor from "@/components/screen_editor/content/spacer/ContentSpacerEditor.vue"
import ContentTableEditor from "@/components/screen_editor/content/table/ContentTableEditor.vue"
import ContentTextEditor from "@/components/screen_editor/content/text/ContentTextEditor.vue"
import ContentImageEditor from "@/components/screen_editor/content/image/ContentImageEditor.vue"
import ContentBoxPlotEditor from "@/components/screen_editor/content/chart/box_plot/ContentBoxPlotEditor.vue"
import appState from "@/app/state/app_state"
import {SizeModel} from "@/models/math/model_size"
import ContentPieChartEditor from "@/components/screen_editor/content/chart/pie_chart/ContentPieChartEditor.vue";
import {ContentModel, ContentType} from "@/models/content/model_content"

@Component({
  components: {
    ContentPieChartEditor,
    ContentBoxPlotEditor,
    ContentImageEditor, ContentTextEditor, ContentTableEditor, ContentSpacerEditor, ContentTypeSelector,
    ContentLineChartEditor, RoundIconButton, Container, TextField, SpacerBox, TransitionEffect, Row, Column
  }
})
export default class ContentDialog extends Vue {
  @Prop({type: Object, required: true}) content!: ContentModel
  @Prop({type: Array, default: [], required: false}) invalidTypes!: Array<string>
  @Prop({type: Boolean, default: false, required: false}) inlineText!: boolean
  @Prop({type: Object, default: null, required: false}) fieldSize!: SizeModel|null
  @Prop({type: Number, default: 18, required: false}) recommendedFontSize!: number

  contentTypes = ContentType
  isVisible = false


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  open() {
    this.isVisible = true
  }

  close() {
    this.isVisible = false
  }

  get copiedContentAvailable() {
    return appState.copiedContent != null && JSON.stringify(this.content) !== appState.copiedContent
  }

  copyContent() {
    appState.copiedContent = JSON.stringify(this.content)
  }

  pasteContent() {
    if (appState.copiedContent) {
      this.$emit('onContentChanged',  JSON.parse(appState.copiedContent))
    }
  }
}
