


















































































































































































































































































import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {eventbus} from "@/main"
import {EventType, NavbarViewModel, SidePanelViewModel} from "@/components/app/eventModel"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import SectionLayout from "@/components/fundamental/layout/SectionLayout.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import firebase from 'firebase/app'
import 'firebase/firestore'
import params from "@/app/params"
import model_search, {
  ActionInputType,
  ResultDocModel,
  SearchCategory,
  SearchCollection,
  SearchOrder
} from './model_search'
import util from "@/util/util"
import Container from "@/components/fundamental/layout/Container.vue"
import dbHelper from "@/util/basic/dbHelper"
import DocOverview from "@/pages/search/DocOverview.vue"
import AdvancedSelect from "@/components/fundamental/advanced_inputs/AdvancedSelect.vue"
import {ActionType, ActionTypeLabels} from "@/models/action/model_action"
import store from "@/store/store"
import appState from "@/app/state/app_state"
import {UnitModel, UnitStatus, UnitStatusLabels, UnitType} from "@/models/unit/model_unit"
import {Route} from "vue-router"
import AdvancedEditNumber from "@/components/fundamental/advanced_inputs/AdvancedEditNumber.vue"
import algoliasearch from "algoliasearch"
import {InputActionModel, InputType} from '@/models/action/model_action_input'
import {ContentType} from "@/models/content/model_content"
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue"
import Timestamp = firebase.firestore.Timestamp

@Component({
  components: {
    AdvancedEditText,
    AdvancedEditNumber,
    AdvancedSelect,
    DocOverview, Container, SelectField, InputField, TextField, SectionLayout, TextButton, Row, Column}
})
export default class SearchPage extends Vue {

  docsPerPage = 50

  sidePanelViewModel = new SidePanelViewModel(true)

  searchCategories = SearchCategory
  searchCategoryValues = util.enumToArray(SearchCategory)
  searchCollectionValues = util.enumToArray(SearchCollection)
  searchOrderValues = util.enumToArray(SearchOrder)

  unitStatusValues = util.enumToArray(UnitStatus)
  unitStatusLabels = util.enumToArray(UnitStatusLabels)
  unitTypesValues = util.enumToArray(UnitType)

  actionTypes = ActionType
  actionTypeValues = util.enumToArray(ActionType)
  actionTypeLabels = util.enumToArray(ActionTypeLabels)

  actionInputTypeValues = util.enumToArray(ActionInputType)

  curriculaValues: Array<string> = []
  curriculaLabels: Array<string> = []

  searchState = appState.search

  get pageDocs() {
    let currentPage = this.searchState.currentPage
    return this.searchState.requestedDocs.slice(currentPage*this.docsPerPage, (currentPage+1)*this.docsPerPage)
  }

  get numPages() {
    return Math.ceil(this.searchState.requestedDocs.length/this.docsPerPage)
  }


  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  created() {
    window.scrollTo(0, 0)

    // update navbar
    eventbus.$emit(EventType.navbar, new NavbarViewModel(true))

    // update sidePanel
    eventbus.$emit(EventType.sidePanel, this.sidePanelViewModel)
    this.sidePanelViewModel.json = {}

    this.$nextTick(() => {window.scrollTo(0, appState.search.scrollPosition)})

    store.curriculum.remoteCurricula.forEach(c => this.curriculaValues.push(c.id))
    store.curriculum.remoteCurricula.forEach(c => this.curriculaLabels.push(util.capitalizeFirstLetter(c.co) + ' - ' + c.sc))
  }

  beforeRouteLeave(to: Route, from: Route, next: () => void) {
    appState.search.scrollPosition = window.scrollY
    next()
  }


  /////////////////////////////////
  // Search
  /////////////////////////////////
  updateSearchCategory(newCategory: SearchCategory) {
    this.searchState.searchCategory = newCategory

    if (newCategory == SearchCategory.unitStatus || newCategory == SearchCategory.unitType || newCategory == SearchCategory.action ||
      newCategory == SearchCategory.error || newCategory == SearchCategory.pending || newCategory == SearchCategory.proposal ||
      newCategory == SearchCategory.withStaticTable
    ) {
      this.fetchCurricula()
    }
  }

  search() {
    // reset
    this.searchState.errorMsg = ''
    this.searchState.requestedDocs.splice(0, this.searchState.requestedDocs.length)
    this.searchState.currentPage = 0

    if (this.searchState.searchOrder == SearchOrder.undefined) {
      this.searchState.searchOrder = SearchOrder.createdAt_desc
    }

    switch (this.searchState.searchCategory) {
      case SearchCategory.id:
        this.fetchDoc(this.searchState.searchStr)
        break

      case SearchCategory.algolia:
        this.fetchUnitsWithAlgolia(this.searchState.searchStr)
        break

      case SearchCategory.collection:
        this.fetchCollection()
        break

      case SearchCategory.openRecordings:
        this.fetchOpenRecordings()
        break

      case SearchCategory.unitStatus:
        this.fetchUnitsWithStatus(this.searchState.curriculumId, this.searchState.unitStatus)
        break

      case SearchCategory.error:
        this.fetchUnitsWithError(this.searchState.curriculumId)
        break

      case SearchCategory.pending:
        this.fetchUnitsPending(this.searchState.curriculumId)
        break

      case SearchCategory.proposal:
        this.fetchUnitsWithProposal(this.searchState.curriculumId)
        break

      case SearchCategory.unitType:
        this.fetchUnitsWithType(this.searchState.curriculumId, this.searchState.unitType, this.searchState.unitStatus)
        break

      case SearchCategory.action:
        this.fetchUnitsWithAction(this.searchState.curriculumId, this.searchState.unitStatus, this.searchState.actionType,
          this.searchState.actionInputType)
        break

      case SearchCategory.withStaticTable:
        this.fetchUnitsWithStaticTable(this.searchState.curriculumId, this.searchState.unitStatus)
        break
    }
  }

  async fetchDoc(docId: string) {
    if (!docId || !this.searchState.searchCollection) return

    let result: ResultDocModel = model_search.template_doc(this.searchState.searchCollection)
    result.collection = this.getCollectionName(this.searchState.searchCollection)!

    // fetch data
    this.showLoading(true)
    let doc = await firebase.firestore().collection(result.collection).doc(docId).get()

    // handle doc
    if (doc.exists) {
      result.doc = dbHelper.docToJson(doc)

      // load recordings if doc is unit
      if (this.searchState.searchCollection === SearchCollection.units) {
        let querySnapshot = await firebase.firestore().collection(params.firestore.recordings)
          .where('uId2', "==", docId).get()

        // handle querySnapshot
        result.unitRecordings = []
        querySnapshot.docs.map(doc => result.unitRecordings!.push(dbHelper.docToJson(doc)))
      }

      this.searchState.requestedDocs.push(result)
    } else {
      this.searchState.errorMsg = `ERROR: '${docId}' not found in collection '${result.collection}'`
    }

    this.showLoading(false)
  }

  async fetchUnitsWithAlgolia(searchStr: string) {
    let client = algoliasearch(params.algoliaAppId, params.algoliaSearchApiKey)
    let index = client.initIndex('units')

    this.showLoading(true)
    let response = await index.search(searchStr, {hitsPerPage: 50})
    this.showLoading(false)

    console.log(response)

    for (let hit of response.hits) {
      let json = dbHelper.algoliaJsonToJson(hit)
      this.searchState.requestedDocs.push({algoliaDoc: json, collection: params.firestore.units, collectionType: SearchCollection.algoliaUnits})
    }

    this.searchState.searchOrder = SearchOrder.undefined
  }

  async fetchCollection() {
    let collectionName = this.getCollectionName(this.searchState.searchCollection)
    if (!collectionName) return

    let startTime = !this.searchState.startTime ? null : new Date(this.searchState.startTime)
    if (startTime && isNaN(startTime.getDate())) {
      startTime = null
    }

    let endTime = !this.searchState.endTime ? null : new Date(this.searchState.endTime)
    if (endTime && isNaN(endTime.getDate())) {
      endTime = null
    }

    // fetch data
    this.showLoading(true)

    let querySnapshot

    if (startTime && endTime) {
      querySnapshot = await firebase.firestore().collection(collectionName)
        .where('createdAt', '>', Timestamp.fromDate(startTime))
        .where('createdAt', '<', Timestamp.fromDate(endTime))
        .limit(this.searchState.numEntries)
        .orderBy(this.searchState.searchOrder.split('_')[0], this.searchState.searchOrder.endsWith('desc') ? 'desc': 'asc')
        .get()

    } else if (startTime) {
      querySnapshot = await firebase.firestore().collection(collectionName)
        .where('createdAt', '>', Timestamp.fromDate(startTime))
        .limit(this.searchState.numEntries)
        .orderBy(this.searchState.searchOrder.split('_')[0], this.searchState.searchOrder.endsWith('desc') ? 'desc': 'asc')
        .get()

    } else if (endTime) {
      querySnapshot = await firebase.firestore().collection(collectionName)
        .where('createdAt', '<', Timestamp.fromDate(endTime))
        .limit(this.searchState.numEntries)
        .orderBy(this.searchState.searchOrder.split('_')[0], this.searchState.searchOrder.endsWith('desc') ? 'desc': 'asc')
        .get()

    } else {
      querySnapshot = await firebase.firestore().collection(collectionName)
        .limit(this.searchState.numEntries)
        .orderBy(this.searchState.searchOrder.split('_')[0], this.searchState.searchOrder.endsWith('desc') ? 'desc': 'asc')
        .get()
    }

    this.showLoading(false)

    // handle querySnapshot
    querySnapshot.docs.map(doc => this.searchState.requestedDocs.push({
      doc: dbHelper.docToJson(doc),
      collection: collectionName!,
      collectionType: this.searchState.searchCollection
    }))
  }

  async fetchOpenRecordings() {

    // fetch data
    this.showLoading(true)
    let querySnapshot = await firebase.firestore().collection(params.firestore.recordings)
      .where('videoEncodingRequired', "==", true)
      .get()
    this.showLoading(false)

    // handle querySnapshot
    querySnapshot.docs.map(doc => this.searchState.requestedDocs.push({
      doc: dbHelper.docToJson(doc),
      collection: params.firestore.recordings,
      collectionType: SearchCollection.recordings
    }))

    this.sortResults(this.searchState.searchOrder)
  }

  async fetchUnitsWithStatus(curriculumId: string, unitStatus: UnitStatus) {
    if (!curriculumId) return

    // fetch data
    this.showLoading(true)
    let querySnapshot = await firebase.firestore().collection(params.firestore.units)
      .where('curriculumId', '==', curriculumId)
      .where('status', '==', unitStatus)
      .get()
    this.showLoading(false)

    // handle querySnapshot
    querySnapshot.docs.map(doc => {
      let json = dbHelper.docToJson(doc)
      if (!json.pending) {
        this.searchState.requestedDocs.push({doc: json, collection: params.firestore.units, collectionType: SearchCollection.units})
      }
    })

    this.sortResults(this.searchState.searchOrder)
  }

  async fetchUnitsWithError(curriculumId: string) {
    if (!curriculumId) return

    // fetch data
    this.showLoading(true)
    let querySnapshot = await firebase.firestore().collection(params.firestore.units)
      .where('curriculumId', '==', curriculumId)
      .where('error', '==', true)
      .get()
    this.showLoading(false)

    // handle querySnapshot
    querySnapshot.docs.map(doc => this.searchState.requestedDocs.push({
      doc: dbHelper.docToJson(doc),
      collection: params.firestore.units,
      collectionType: SearchCollection.units
    }))

    this.sortResults(this.searchState.searchOrder)
  }

  async fetchUnitsPending(curriculumId: string) {
    if (!curriculumId) return

    // fetch data
    this.showLoading(true)
    let querySnapshot = await firebase.firestore().collection(params.firestore.units)
      .where('curriculumId', '==', curriculumId)
      .where('pending', '==', true)
      .get()
    this.showLoading(false)

    // handle querySnapshot
    querySnapshot.docs.map(doc => this.searchState.requestedDocs.push({
      doc: dbHelper.docToJson(doc),
      collection: params.firestore.units,
      collectionType: SearchCollection.units
    }))

    this.sortResults(this.searchState.searchOrder)
  }

  async fetchUnitsWithProposal(curriculumId: string) {
    if (!curriculumId) return

    // fetch data
    this.showLoading(true)
    let querySnapshot = await firebase.firestore().collection(params.firestore.units)
      .where('curriculumId', '==', curriculumId)
      .orderBy('proposal')
      .get()
    this.showLoading(false)

    // handle querySnapshot
    querySnapshot.docs.map(doc => this.searchState.requestedDocs.push({
      doc: dbHelper.docToJson(doc),
      collection: params.firestore.units,
      collectionType: SearchCollection.units
    }))

    this.sortResults(this.searchState.searchOrder)
  }

  async fetchUnitsWithType(curriculumId: string, unitType: UnitType, unitStatus: UnitStatus) {
    if (!curriculumId) return

    // fetch data
    this.showLoading(true)
    let querySnapshot = await firebase.firestore().collection(params.firestore.units)
      .where('curriculumId', '==', curriculumId)
      .where('type', '==', unitType)
      .where('status', '==', unitStatus)
      .get()
    this.showLoading(false)

    // handle querySnapshot
    querySnapshot.docs.map(doc => this.searchState.requestedDocs.push({
      doc: dbHelper.docToJson(doc),
      collection: params.firestore.units,
      collectionType: SearchCollection.units
    }))

    this.sortResults(this.searchState.searchOrder)
  }

  async fetchUnitsWithAction(curriculumId: string, unitStatus: UnitStatus, actionType: ActionType, actionInputType: ActionInputType) {
    if (!curriculumId) return

    // fetch data
    this.showLoading(true)
    let querySnapshot = await firebase.firestore().collection(params.firestore.units)
      .where('curriculumId', '==', curriculumId)
      .where('status', '==', unitStatus)
      .get()
    this.showLoading(false)

    // handle querySnapshot
    let units: Array<UnitModel> = querySnapshot.docs.map(doc => dbHelper.docToJson(doc))
    mainLoop: for (let unit of units) {
      for (let screen of unit.screens) {
        if (screen.action?.type === actionType) {

          if (actionType === ActionType.input) {
            let input: InputActionModel = <InputActionModel>screen.action

            let addEntry = actionInputType === ActionInputType.all
            addEntry = addEntry || actionInputType === ActionInputType.unsortedList && input?.unsortedList === true
            addEntry = addEntry || actionInputType === ActionInputType.continuousText && input?.continuousText === true

            if (!addEntry && actionInputType === ActionInputType.term) {
              for (let item of input?.inputs) {
                if (item.type === InputType.term) {
                  addEntry = true
                  break
                }
              }

            } else if (!addEntry && actionInputType === ActionInputType.equation) {
              for (let item of input?.inputs) {
                if (item.type === InputType.equation) {
                  addEntry = true
                  break
                }
              }

            } else if (!addEntry && actionInputType === ActionInputType.time) {
              for (let item of input?.inputs) {
                if (item.type === InputType.time) {
                  addEntry = true
                  break
                }
              }

            } else if (!addEntry && actionInputType === ActionInputType.interval) {
              for (let item of input?.inputs) {
                if (item.type === InputType.interval) {
                  addEntry = true
                  break
                }
              }

            } else if (!addEntry && actionInputType === ActionInputType.dropDown) {
              for (let item of input?.inputs) {
                if (item.type === InputType.dropDown) {
                  addEntry = true
                  break
                }
              }

            } else if (!addEntry && actionInputType === ActionInputType.vector) {
              for (let item of input?.inputs) {
                if (item.type === InputType.vector) {
                  addEntry = true
                  break
                }
              }

            } else if (!addEntry && actionInputType === ActionInputType.ci) {
              for (let item of input?.inputs) {
                if (item.type === InputType.ci) {
                  addEntry = true
                  break
                }
              }

            } else if (!addEntry && actionInputType === ActionInputType.coordinates) {
              for (let item of input?.inputs) {
                if (item.type === InputType.coordinates) {
                  addEntry = true
                  break
                }
              }

            } else if (!addEntry && actionInputType === ActionInputType.paramEq) {
              for (let item of input?.inputs) {
                if (item.type === InputType.paramEq) {
                  addEntry = true
                  break
                }
              }

            } else if (!addEntry && actionInputType === ActionInputType.slideUnlock) {
              for (let item of input?.inputs) {
                if (item.type === InputType.slideUnlock) {
                  addEntry = true
                  break
                }
              }
            }


            if (!addEntry) {
              continue
            }
          }


          this.searchState.requestedDocs.push({
            doc: unit,
            collection: params.firestore.units,
            collectionType: SearchCollection.units
          })

          continue mainLoop;
        }
      }
    }

    this.sortResults(this.searchState.searchOrder)
  }

  async fetchUnitsWithStaticTable(curriculumId: string, unitStatus: UnitStatus) {
    if (!curriculumId) return

    // fetch data
    this.showLoading(true)
    let querySnapshot = await firebase.firestore().collection(params.firestore.units)
      .where('curriculumId', '==', curriculumId)
      .where('status', '==', unitStatus)
      .get()
    this.showLoading(false)

    // handle querySnapshot
    let units: Array<UnitModel> = querySnapshot.docs.map(doc => dbHelper.docToJson(doc))

    for (let i = 0; i < units.length; i++) {
      screenLoop: for (let screen of units[i].screens) {
        for (let staticItem of screen.statics) {
          if (staticItem.type === ContentType.table) {
            this.searchState.requestedDocs.push({doc: units[i], collection: params.firestore.units, collectionType: SearchCollection.units})
            break screenLoop
          }
        }
      }
    }

    this.sortResults(this.searchState.searchOrder)
  }


  /////////////////////////////////
  // Curriculum
  /////////////////////////////////
  async fetchCurricula() {
    this.showLoading(true)
    await store.curriculum.subscribeToCurricula()
    this.showLoading(false)

    this.curriculaValues = []
    store.curriculum.remoteCurricula.forEach(c => this.curriculaValues.push(c.id))

    this.curriculaLabels = []
    store.curriculum.remoteCurricula.forEach(c => this.curriculaLabels.push(util.capitalizeFirstLetter(c.co) + ' - ' + c.sc))
  }


  /////////////////////////////////
  // Helper
  /////////////////////////////////
  showLoading(show: boolean) {
    eventbus.$emit(EventType.loadingDialog, show)
  }

  nextPage(delta: number) {
    this.searchState.currentPage += delta
    if (this.searchState.currentPage < 0) {
      this.searchState.currentPage = this.numPages-1
    } else if (this.searchState.currentPage >= this.numPages) {
      this.searchState.currentPage = 0
    }
  }

  getCollectionName(collectionType: SearchCollection) {
    switch (collectionType) {
      case SearchCollection.curricula:return params.firestore.curricula
      case SearchCollection.recordings:return params.firestore.recordings
      case SearchCollection.recordingsRaw:return params.firestore.recordingsRaw
      case SearchCollection.units:return params.firestore.units
      case SearchCollection.users:return params.firestore.users
    }
  }

  sortResults(searchOrder: SearchOrder) {
    if (this.searchState.requestedDocs.length < 2) return

    this.searchState.searchOrder = searchOrder
    let sortByCreatedAt = searchOrder === SearchOrder.createdAt_desc || searchOrder === SearchOrder.createdAt_asc
    let sortByLastModifiedAt = searchOrder === SearchOrder.lastModifiedAt_desc || searchOrder === SearchOrder.lastModifiedAt_asc

    let newResults: Array<ResultDocModel> = [
      this.searchState.requestedDocs[0]
    ]

    for (let i = 1; i < this.searchState.requestedDocs.length; i++) {
      let result = this.searchState.requestedDocs[i]

      for (let j = 0; j < newResults.length; j++) {
        if (sortByCreatedAt && result.doc?.createdAt! < newResults[j].doc?.createdAt!) {
          newResults.splice(j, 0, result);
          break

        } else if (sortByLastModifiedAt && result.doc?.lastModifiedAt! < newResults[j].doc?.lastModifiedAt!) {
          newResults.splice(j, 0, result);
          break

        } else if (j === newResults.length-1) {
          // add result at the end and break loop
          newResults.push(result)
          break
        }
      }

    }

    this.searchState.requestedDocs = this.searchState.searchOrder.endsWith('asc') ? newResults : newResults.reverse()
  }
}
