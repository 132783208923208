












import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import ImageField from "@/components/fundamental/ImageField.vue"
import Link from "@/components/fundamental/text/Link.vue"
import {ImageModel} from "@/models/content/model_image"

@Component({
  components: {
    Link, ImageField, Container, TextField, SpacerBox, Row, Column
  }
})
export default class ImagePreview extends Vue {
  @Prop({type: Object, required: true}) image!: ImageModel
  @Prop({type: Boolean, required: false, default: false}) linkDisabled!: boolean

  get url() {
    return this.image.url
  }
}
