























import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"

@Component({
  components: {TextField, Row, Column}
})
export default class Toast extends Vue {
  isVisible = false
  message = ''
  timer = 0
  visiblePeriod_short = 1500
  visiblePeriod_long = 3000

  show(msg: string) {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.isVisible = false
    }, this.visiblePeriod_short)
    this.isVisible = true
    this.message = msg
  }

  showLong(msg: string) {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.isVisible = false
    }, this.visiblePeriod_long)
    this.isVisible = true
    this.message = msg
  }
}
