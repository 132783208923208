





























import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import ConverterList from "@/components/converter/ConverterList.vue"
import SectionLayout from "@/components/fundamental/layout/SectionLayout.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import router, {RouteName} from "@/router/router"
import appState from "@/app/state/app_state"
import {eventbus} from "@/main"
import {EventType, NavbarViewModel} from "@/components/app/eventModel"

@Component({
  components: {TextField, SectionLayout, ConverterList, TextButton, Row, Column}
})
export default class ConverterPage extends Vue {

  created() {
    eventbus.$emit(EventType.navbar, new NavbarViewModel(true))
  }

  openWindow() {
    // open recorder window
    if (appState.converterWindowRef === null || appState.converterWindowRef.closed) {
      let routeData = router.resolve({name: RouteName.converterWindow})
      appState.converterWindowRef = window.open(routeData.href, 'converter', "height=900,width=1200")

    } else {
      appState.converterWindowRef.focus()
    }
  }
}
