import {ColorModel} from "@/models/model_colors"
import {ChartTextModel} from "@/models/content/charts/model_chart_text"


export interface ChartVectorModel {
  vector: Array<number>
  label?: ChartTextModel
  color: ColorModel
  width: number
  hideArrowHead?: boolean
}

export default {
  template(): ChartVectorModel {
    return {
      vector: [0, 0, 1, 1],
      color: ColorModel.cyan,
      width: 2,
    }
  },
}