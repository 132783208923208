
























import {Component, Prop, Vue} from "vue-property-decorator"
import model_curriculum, {OfficialChapterModel, CurriculumModel} from '@/models/curriculum/model_curriculum'
import Row from "@/components/fundamental/layout/Row.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import Draggable from "@/components/fundamental/layout/Draggable.vue"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import OfficialSectionElement from "@/pages/curriculum_editor/official/OfficialSectionElement.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import {eventbus} from "@/main"
import {AlertDialogType, EventType} from "@/components/app/eventModel"
import util from "@/util/util"

@Component({
  components: {Container, OfficialSectionElement, AddButton, Draggable, Column, Row}
})
export default class OfficialSections extends Vue {
  @Prop({type: Object, required: true}) curriculum!: CurriculumModel
  @Prop({type: Object, required: true}) chapter!: OfficialChapterModel
  @Prop({type: String, required: true}) topicPrefix!: string
  @Prop({type: Number, required: true}) chapterIndex!: number

  addSection() {
    this.chapter.se.push(model_curriculum.template_officialSection())
  }

  requestDeletingSection(index: number) {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.delete_low,
      title: `Delete section`,
      text: `Delete the section: <b>${this.chapter.se[index].de}</b>?<br><br> You can restore it by resetting the curriculum. Changes will be permanent if you save the curriculum.`,
      callback: (response: boolean) => {
        if (response) this.deleteSection(index)
      }
    })
  }

  deleteSection(index: number) {
    util.removeFromArray(this.chapter.se, index)
  }
}
