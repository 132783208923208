






































import {Component, Ref, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import dummy_recording1 from "@/test_area/dummy_data/recordings/dummy_recording1"
import dummy_recording0 from "@/test_area/dummy_data/recordings/dummy_recording0"
import {eventbus} from "@/main"
import {EventType, SidePanelViewModel} from "@/components/app/eventModel"
import VideoPlayerWidget from "@/components/video/player/VideoPlayerWidget.vue"
import {VideoController} from "@/components/video/controller/VideoController"

@Component({
  components: {VideoPlayerWidget, TextButton, Row, Column}
})
export default class TestRecording_multipleVideos extends Vue {
  @Ref() readonly player0!: VideoPlayerWidget
  @Ref() readonly player1!: VideoPlayerWidget

  width = 300
  recording0 = dummy_recording0
  recording1 = dummy_recording1

  videoController0?: VideoController = undefined
  videoController1?: VideoController = undefined

  mounted() {
    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(true, {recording0: this.recording0, recording1: this.recording1}))
  }

  togglePlay(index: number) {
    if (index === 0) {
      if (this.videoController0) {
        this.videoController0.togglePlay()
      }
    } else {
      if (this.videoController1) {
        this.videoController1.togglePlay()
      }
    }
  }

  reset(index: number) {
    if (index === 0) {
      if (this.videoController0) {
        this.videoController0.setTime(0)
      }
    } else {
      if (this.videoController1) {
        this.videoController1.setTime(0)
      }
    }
  }
}
