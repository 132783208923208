import {AlertDialogType} from "@/components/app/eventModel"

export interface ConverterLogs {
  recordings: Array<ConverterLog>
  conversion: Array<ConverterLog>
  subtitles: Array<ConverterLog>
  upload: Array<ConverterLog>
  database: Array<ConverterLog>
}

export interface ConverterLog {
  error: string
  status: string
  id: string
}

export enum ConverterLogStatus {
  completed = 'completed',
  running = 'running',
  error = 'error',
  none = '',
}

export default class VideoConverterWebSocketHandler {

  /////////////////////////////////
  // CONST
  /////////////////////////////////
  static WS_URL = "ws://localhost:9091/video-converter"


  /////////////////////////////////
  // Members
  /////////////////////////////////
  _loadingCallback: (b: boolean) => void
  _connectedCallback: (b: boolean) => void
  _logsCallback: (l: ConverterLogs | null) => void
  _alertDialogCallback: (type: AlertDialogType, title: string, text: string) => void
  _webSocket: WebSocket | null


  /////////////////////////////////
  // CONSTRUCTOR
  /////////////////////////////////
  constructor(
    loadingCallback: (b: boolean) => void,
    connectedCallback: (b: boolean) => void,
    logsCallback: (l: ConverterLogs | null) => void,
    alertDialogCallback: (type: AlertDialogType, title: string, text: string) => void
  ) {

    this._loadingCallback = loadingCallback
    this._connectedCallback = connectedCallback
    this._logsCallback = logsCallback
    this._alertDialogCallback = alertDialogCallback

    this._webSocket = null
  }

  /////////////////////////////////
  // PUBLIC
  /////////////////////////////////
  openWebSocket() {
    this._loadingCallback(true)

    this._webSocket = new WebSocket(VideoConverterWebSocketHandler.WS_URL)
    this._webSocket.onopen = () => this._socketOpened()
    this._webSocket.onclose = () => this._socketClosed()
    this._webSocket.onmessage = (result) => this._socketMessage(result)
    this._webSocket.onerror = () => this._socketError()
  }

  closeWebSocket() {
    if (this._webSocket) {
      this._webSocket.close()
    }
  }

  clearCache() {
    if (this._webSocket && this._webSocket.readyState === WebSocket.OPEN) {
      this._loadingCallback(true)
      this._webSocket.send(JSON.stringify({cmd: 'clear_cache'}))
    }
  }

  fetchOpenRecordings() {
    if (this._webSocket && this._webSocket.readyState === WebSocket.OPEN) {
      this._loadingCallback(true)
      this._webSocket.send(JSON.stringify({cmd: 'fetch_open_recordings'}))
    }
  }

  convertOpenRecordings(userId: string, cropTop: number, cropBottom: number) {
    if (this._webSocket && this._webSocket.readyState === WebSocket.OPEN) {
      this._loadingCallback(true)
      this._webSocket.send(JSON.stringify({cmd: 'convert_open_recordings', userId: userId, cropTop: cropTop, cropBottom: cropBottom}))
    }
  }


  /////////////////////////////////
  // PRIVATE
  /////////////////////////////////
  _socketOpened() {
    // this._loadingCallback(false)
    this._connectedCallback(true)
  }

  _socketClosed() {
    this._loadingCallback(false)
    this._connectedCallback(false)
    this._logsCallback(null)
  }

  _socketMessage(result: MessageEvent) {
    let message = JSON.parse(result.data)
    this._logsCallback(message.logs)

    if (message.cmd !== 'convert_open_recordings') {
      this._loadingCallback(false)
    }

    if (message.error) {
      this._alertDialogCallback(AlertDialogType.error, 'Something went wrong', `<b>Server message:</b> <i>${message.error}</i><br><br><b>Next steps:</b> Please check the internet connection and the server logs.`)

    } else if (message.cmd === 'fetch_open_recordings' && !message.logs.recordings) {
      this._alertDialogCallback(AlertDialogType.warning, 'No recordings found', 'There are no open recordings that can be converted.')
    }
  }

  _socketError() {
    this._loadingCallback(false)
    this._connectedCallback(false)
    this._alertDialogCallback(AlertDialogType.error, 'Connection failed', 'Please check if the <b>Miranda Recording Server</b> is running.')
  }
}