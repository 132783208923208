











































































import {Component, Prop, Vue} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Checkbox from "@/components/fundamental/inputs/Checkbox.vue"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import mMath from "@/util/mMath"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import EditNumber from "@/components/fundamental/inputs/EditNumber.vue"

@Component({
  components: {EditNumber, SpacerBox, TextButton, InputField, Column, TransitionEffect, Checkbox, TextField, Row}
})
export default class AdvancedEditNumber extends Vue {
  @Prop({type: String, required: false, default: ''}) title!: string
  @Prop({type: String, required: false, default: ''}) info!: string
  @Prop({required: true}) value!: number | undefined
  @Prop({type: Boolean, default: false, required: false}) isNullable!: boolean
  @Prop({type: Number, default: 0, required: false}) width!: number
  @Prop({type: Number, default: 0, required: false}) titleWidth!: number
  @Prop({type: Boolean, default: false, required: false}) titleBold!: boolean
  @Prop({type: Number, default: 0, required: false}) min!: number
  @Prop({type: Number, default: 0, required: false}) max!: number
  @Prop({type: Number, default: 2, required: false}) fractionDigits!: number
  @Prop({default: null, required: false}) recommended!: number | null
  @Prop({type: Boolean, default: false, required: false}) evenOnly!: boolean
  @Prop({type: Boolean, default: false, required: false}) scientificNotation!: number
  @Prop({type: String, default: '', required: false}) toolTipText!: string

  isDialogVisible = false
  newValue = 0
  internalFractionDigits = this.fractionDigits

  get valueStr() {
    if (this.scientificNotation) {
      return this.value != undefined ? this.value.toExponential() : 'null'
    } else {
      return this.value != undefined ? this.value.toFixed(this.internalFractionDigits) : 'null'
    }
  }

  get rowStyle() {
    let s = ''
    if (this.width > 0 ) {
      s += 'width:' + this.width + 'px;'
    }
    return s
  }

  get titleStyle() {
    let s = ''
    if (this.titleWidth > 0 ) {
      s += 'width:' + this.titleWidth + 'px;'
    }
    return s
  }

  created() {
    if (this.value != undefined) {
      if(Math.floor(this.value) !== this.value) {
        this.internalFractionDigits = Math.max(this.value.toString().split(".")[1].length, this.internalFractionDigits);
      }
    }
  }

  open() {
    this.newValue = this.value ? this.value : 0
    this.isDialogVisible = true
  }

  save() {
    this.isDialogVisible = false

    if (this.min < this.max) {
      this.newValue = mMath.constrain(this.newValue, this.min, this.max)
    }

    // return if evenOnly == true and number is not even
    if (this.evenOnly && this.newValue % 2 != 0) {
      return
    }

    this.newValue = mMath.round(this.newValue, this.internalFractionDigits)
    this.$emit('input', this.newValue)
  }

  cancel() {
    this.isDialogVisible = false
  }

  resetValue() {
    this.isDialogVisible = false
    this.$emit('input', undefined)
  }
}
