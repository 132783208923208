






































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {eventbus} from "@/main"
import {AlertDialogType, EventType} from "@/components/app/eventModel"
import EditText from "@/components/fundamental/inputs/EditText.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import {ScreenModel} from "@/models/screen/model_screen"


@Component({
  components: {SpacerBox, RoundIconButton, Container, TextField, EditText, Row, Column}
})
export default class ScreenEditorHeader extends Vue {
  @Prop({type: Object, required: true}) screen!: ScreenModel
  @Prop({type: Number, required: true}) index!: number
  @Prop({type: Boolean, required: true}) showContent!: boolean


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  deleteScreen() {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.delete_low,
      title: `Delete Screen ${this.index + 1}`,
      text: 'You can restore this screen by resetting the unit. Changes will be permanent if you save the unit.',
      callback: (response: boolean) => {
        if (response) {
          this.$emit('delete')
        }
      }
    })
  }
}
