






import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import GifSelector from "@/components/basic/images/GifSelector.vue"
import model_image, {ImageModel} from "@/models/content/model_image"

@Component({
  components: {GifSelector, Row, Column}
})
export default class TestHtml_gifSelector extends Vue {
  gif: ImageModel = model_image.template_gif()
}
