








































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"

@Component({
  components: {Column}
})
export default class MenuButton extends Vue {
  @Prop({type: String, required: true}) icon!: string
  @Prop({type: String, default: '', required: false}) title!: string
  @Prop({type: String, default: '', required: false}) image!: string
  @Prop({type: Boolean, default: false, required: false}) selected!: boolean

  get iconSize() {
    return this.title ? 20 : 24
  }
}
