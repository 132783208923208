












import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"

@Component({
  components: {TextField, Row, Column}
})
export default class CounterDialog extends Vue {

  counter = 3
  isVisible = false

  run() {
    this.isVisible = true

    let frequency = 400
    let duration = 500
    let audioCtx = new window.AudioContext()
    let oscillator = audioCtx.createOscillator()

    oscillator.type = 'square'
    oscillator.frequency.value = frequency
    oscillator.connect(audioCtx.destination)
    oscillator.start()

    setTimeout(() => {
      oscillator.stop()
      if (this.counter > 1) {
        setTimeout(() => {
          this.counter--
          this.run()
        }, duration)
      } else {
        this.isVisible = false
        this.counter = 3
        this.$emit('completed')
      }
    }, duration)
  }
}
