import {PointModel} from "@/models/math/model_point"
import model_xy_chart, {XyChartModel} from "@/models/content/charts/model_xy_chart"
import {ActionModel, ActionType} from "@/models/action/model_action"
import model_response from "@/models/action/model_response"

export interface DrawPointActionModel extends ActionModel {
  xyChart: XyChartModel
  targetPoints: Array<PointModel>
  snapGrid: boolean
  tolerance: number
}


export default {
  template_action(): DrawPointActionModel {
    return {
      type: ActionType.drawPoint,
      responses: model_response.template_responses(),
      xyChart: model_xy_chart.template_lineChart(),
      targetPoints: [],
      snapGrid: true,
      tolerance: 0.1,
    }
  },
}