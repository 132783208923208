
























import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import dummy_screen_simple from "@/test_area/dummy_data/screens/dummy_screen_simple"
import dummy_recording0 from "@/test_area/dummy_data/recordings/dummy_recording0"
import {eventbus} from "@/main"
import {EventType, SidePanelViewModel} from "@/components/app/eventModel"
import NewRecordingButton from "@/components/recorder/recorderWindow/NewRecordingButton.vue"
import appState from "@/app/state/app_state"
import TextField from "@/components/fundamental/text/TextField.vue"

@Component({
  components: {TextField, NewRecordingButton, Row, Column}
})
export default class TestRecording_recorderButton extends Vue {

  screen = dummy_screen_simple
  recordingWithVideo = dummy_recording0

  created() {
    appState.unsavedUnitChanges = false
  }

  mounted() {
    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(true, this.screen))
  }

}
