



















import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import ContentWidget from "@/simulator/content/ContentWidget.vue"
import TextEditor from "@/components/screen_editor/content/text/TextEditor.vue"
import {TextModel} from "@/models/content/model_text"

@Component({
  components: {TextEditor, ContentWidget, Row, Column}
})
export default class ContentTextEditor extends Vue {
  @Prop({type: Object, required: true}) content!: TextModel
  @Prop({type: Boolean, default: false, required: false}) showPreview!: boolean
  @Prop({type: Boolean, default: false, required: false}) inlineText!: boolean
  @Prop({type: Boolean, default: false, required: false}) isStatic!: boolean
  @Prop({type: Number, default: -1, required: false}) recommendedFontSize!: number
}
