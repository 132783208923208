export interface SizeModel {
  width: number|null
  maxWidth: number|null
  height: number|null
  maxHeight: number|null
}

export default {
  template(w: number|null, h: number|null, maxW: number|null, maxH: number|null): SizeModel {
    return {
      width: w,
      height: h,
      maxWidth: maxW,
      maxHeight: maxH,
    }
  },
  template_widthHeight(w: number|null, h: number|null): SizeModel {
    return {
      width: w,
      height: h,
      maxWidth: null,
      maxHeight: null,
    }
  },
  template_widthMaxHeight(w: number|null, maxH: number|null): SizeModel {
    return {
      width: w,
      height: null,
      maxWidth: null,
      maxHeight: maxH,
    }
  },
}
