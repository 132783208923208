export interface LineModel {
  k: number
  d: number
}

export default {
  template(): LineModel {
    return {
      k: 1,
      d: 0,
    }
  },
}
