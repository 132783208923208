import mMath from "@/util/mMath"

export interface VisibleElement {
  index: number
  elementId: string,
  visibility: number
}

export default class ScrollHandler_y {

  /////////////////////////////////
  // CONST
  /////////////////////////////////
  static UPDATE_PERIOD = 0.02 // in s
  static SPEED_CORRECTION_FACTOR = 100
  static FADE_OUT_DECELERATION = 5000
  static TARGET_SPEED_MAX = 10000
  static TARGET_ACCEL_MIN = 20000
  static MIN_SNAP_SPEED = 100


  /////////////////////////////////
  // Members
  /////////////////////////////////
  _scrollView: any
  _visibleScreenCallback: (s: number) => void
  _visibilityCallback?: (a: Array<VisibleElement>) => void

  // scrolling
  _active = false
  _initialTouchPosition = 0
  _initialScrollPosition = 0
  _lastPosition = 0
  _scrollingSpeed = 0
  _scrollTimer = 0

  /////////////////////////////////
  // CONSTRUCTOR
  /////////////////////////////////
  constructor(
    scrollView: any,
    visibleScreenCallback: (s: number) => void,
    visibilityCallback: ((a: Array<VisibleElement>) => void) | undefined = undefined
  ) {
    this._scrollView = scrollView
    this._visibleScreenCallback = visibleScreenCallback
    this._visibilityCallback = visibilityCallback

    visibleScreenCallback(0)
  }


  /////////////////////////////////
  // PUBLIC
  /////////////////////////////////
  start(position: number) {
    this._active = true
    this._initialTouchPosition = position
    this._initialScrollPosition = this._scrollView.scrollTop

    this._lastPosition = position
    this._scrollingSpeed = 0

    this._clearScrollTimer()
  }

  scroll(position: number) {
    if (!this._active) return

    this._scroll(position)
  }

  stop() {
    this._active = false
    this._scrollOut()
  }

  hardStop() {
    this._clearScrollTimer()
  }

  scrollToElement(index: number) {
    this._calcScrollToTarget(this._scrollView.scrollTop, this._scrollView.childNodes[index].offsetTop, 0)
  }


  /////////////////////////////////
  // SCROLL
  /////////////////////////////////
  _scroll(position: number) {
    let target = this._initialScrollPosition + this._initialTouchPosition - position
    let newSpeed = (position - this._lastPosition) * ScrollHandler_y.SPEED_CORRECTION_FACTOR
    let minScroll = 0
    let maxScroll = this._scrollView.scrollHeight - this._scrollView.clientHeight

    // Shift initial touch position, if user hits the border but swipes further. This way, when the swiping
    // direction is reversed, the scrolling immediately reacts.
    if (target < (minScroll - 5) && this._lastPosition < position) {
      this._initialTouchPosition += Math.abs(minScroll - target) * 0.9
      newSpeed = 0

    } else if (target > (maxScroll + 5) && this._lastPosition > position) {
      this._initialTouchPosition -= Math.abs(target - maxScroll) * 0.9
      newSpeed = 0
    }

    this._scrollingSpeed = newSpeed
    this._lastPosition = position
    this._scrollView.scrollTop = target

    if (this._visibilityCallback) {
      this._visibilityCallback(this._getVisibleElements())
    }
  }

  _scrollOut() {
    let currentPosition = this._scrollView.scrollTop
    let currentSpeed = -this._scrollingSpeed

    // this._calcScrollFadeOut(currentPosition, 0, this._scrollView.scrollHeight, currentSpeed)

    let visibleElements = this._getVisibleElements()

    // If only 1 screen is visible, fade out scrolling is used. If 2 screens are visible, snap scrolling is applied.
    if (visibleElements.length === 1) {
      let screen = this._scrollView.childNodes[visibleElements[0].index]
      let minPosition = screen.offsetTop
      let maxPosition = minPosition + screen.scrollHeight - this._scrollView.clientHeight
      this._calcScrollFadeOut(currentPosition, minPosition, maxPosition, currentSpeed)

    } else if (visibleElements.length > 1) {
      let topScreen = this._scrollView.childNodes[visibleElements[0].index]
      let targetTopScreen = topScreen.offsetTop
      if (topScreen.scrollHeight > this._scrollView.clientHeight) {
        targetTopScreen += topScreen.scrollHeight - this._scrollView.clientHeight
      }

      let targetBottomScreen = this._scrollView.childNodes[visibleElements[1].index].offsetTop

      // If speed is larger than a certain value, we scroll in that direction. Otherwise we scroll to the screen that is more visible.
      let toTopScreen = true
      if (Math.abs(currentSpeed) > ScrollHandler_y.MIN_SNAP_SPEED) {
        toTopScreen = (currentSpeed < 0)
      } else {
        toTopScreen = (visibleElements[0].visibility >= visibleElements[1].visibility)
      }

      let target = toTopScreen ? targetTopScreen : targetBottomScreen
      this._calcScrollToTarget(currentPosition, target, currentSpeed)

      this._visibleScreenCallback(visibleElements[toTopScreen ? 0 : 1].index)
    }
  }

  // let scrolling fade out depending on the current speed and the borders
  _calcScrollFadeOut(currentPosition: number, minPosition: number, maxPosition: number, currentSpeed: number) {

    let targetList = []
    let s = currentPosition
    let v = currentSpeed
    let a = ScrollHandler_y.FADE_OUT_DECELERATION * (-Math.sign(v))
    let dt = ScrollHandler_y.UPDATE_PERIOD

    // calculate targetList using current speed and some deceleration a
    while (s > minPosition && s < maxPosition && v !== 0) {
      let nextV = v + a * dt
      v = (Math.sign(nextV) === Math.sign(v)) ? nextV : 0
      s += v * dt
      targetList.push(mMath.constrain(s, minPosition, maxPosition))
    }

    if (targetList.length > 0) {
      this._autoScroll(targetList)
    }
  }

  // scroll to target position
  _calcScrollToTarget(currentPosition: number, targetPosition: number, currentSpeed: number) {
    if (currentPosition === targetPosition) return

    // calc times and acceleration required to scroll to target
    let dx = targetPosition - currentPosition
    let v0 = currentSpeed
    let v_max = ScrollHandler_y.TARGET_SPEED_MAX
    let a_min = ScrollHandler_y.TARGET_ACCEL_MIN
    let a0 = a_min * Math.sign(dx)
    let a2 = -a0
    let t1 = 0
    let t2 = 0
    let t3 = 0

    // there can be 3 phase to scroll to the target (acceleration: t1, constant speed: t2, deceleration: t3)
    //      1. we just decelerate
    //      2. we keep the speed for some time and then decelerate
    //      3. we accelerate first to a certain speed, might keep that speed for some time and then decelerate
    if (v0 * dx > 0 && Math.abs(dx) <= v0 * v0 / (2 * a_min)) {
      a2 = -v0 * v0 / (2 * dx)
      t3 = -v0 / a2
    } else if (v0 * dx > 0 && Math.abs(dx) > v0 * v0 / (2 * a_min) && v0 * Math.sign(dx) >= v_max) {
      t2 = (dx + v0 * v0 / (2 * a2)) / v0
      t3 = -v0 / a2
    } else {
      t1 = (-v0 + Math.sign(dx) * Math.sqrt(v0 * v0 / 2 + a0 * dx)) / a0
      t3 = (v0 + a0 * t1) / a0

      if ((v0 + a0 * t1) > v_max) {
        t1 = (v_max - v0 * Math.sign(dx)) / a_min
        let x1 = v0 * Math.sign(dx) * t1 + a_min * t1 * t1 / 2
        let x3 = v_max * v_max / (2 * a_min)
        t2 = (Math.abs(dx) - x1 - x3) / v_max
      }
    }

    // we use t1, t2, t3 and a0, a2 to calculate the targetList for the target positions
    let targetList = []
    let s = currentPosition
    let v = v0
    let a = 0
    let dt = ScrollHandler_y.UPDATE_PERIOD
    let t = dt
    while (true) {
      if (t > (t1 + t2 + t3)) {
        break
      } else if (t > (t1 + t2)) {
        a = a2
      } else if (t > t1) {
        a = 0
      } else {
        a = a0
      }

      let nextV = v + a * dt
      s += (v + nextV) / 2 * dt
      v = nextV
      t += dt
      targetList.push(s)
    }

    // there might be some errors due to finite time steps, those errors are compensated here
    let l = targetList.length
    let fractionalBias = (targetPosition - targetList[l - 1]) / l
    for (let i = 0; i < l; i++) {
      targetList[i] += fractionalBias * (i + 1)
    }

    // if changes are completed within the first time step
    if (targetList.length === 0) targetList.push(targetPosition)

    // start auto scrolling
    this._autoScroll(targetList)
  }

  _autoScroll(targetList: Array<number>, counter = 0) {
    this._scrollView.scrollTop = targetList[counter++]
    if (this._visibilityCallback) {
      this._visibilityCallback(this._getVisibleElements())
    }

    if (counter < targetList.length) {
      this._scrollTimer = setTimeout(
        () => {
          this._autoScroll(targetList, counter)
        }, ScrollHandler_y.UPDATE_PERIOD * 1000)
    }
  }

  _clearScrollTimer() {
    clearTimeout(this._scrollTimer)
  }

  /////////////////////////////////
  // HELPER
  /////////////////////////////////
  _getVisibleElements(): Array<VisibleElement> {
    // check if viewports currently shows just 1 screen or 2
    let visibleAreaTop = Math.floor(this._scrollView.scrollTop)
    let visibleAreaBottom = Math.floor(visibleAreaTop + this._scrollView.clientHeight)

    let visibleElements: Array<VisibleElement> = []

    let children = this._scrollView.childNodes
    for (let i = 0; i < children.length; i++) {
      let childTop = children[i].offsetTop
      let childBottom = (i < children.length - 1) ? children[i + 1].offsetTop : this._scrollView.scrollHeight

      if (childBottom > visibleAreaTop) {
        visibleElements.push({
          index: i,
          elementId: children[i].dataset.id,
          visibility: (Math.min(childBottom, visibleAreaBottom) - Math.max(childTop, visibleAreaTop)) / (visibleAreaBottom - visibleAreaTop)
        })

        if (childBottom >= visibleAreaBottom) break
      }
    }

    return visibleElements
  }
}