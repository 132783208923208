import model_content, {ContentModel} from '../content/model_content'
import {ActionModel, ActionType} from "@/models/action/model_action"
import model_response from "@/models/action/model_response"

export interface DragDropActionModel extends ActionModel {
  dragItems: Array<DragItemModel>
  areaHeight: number
  fieldWidth: number
  fieldHeight: number
  backgroundContent: ContentModel
  showBorder?: boolean
}

export interface DragItemModel {
  position: Array<number>
  hideStart: boolean
  hideEnd: boolean
  content: ContentModel
}

export default {
  template_action(): DragDropActionModel {
    return {
      type: ActionType.dragDrop,
      responses: model_response.template_responses(),
      dragItems: [],
      areaHeight: 100,
      fieldWidth: 48,
      fieldHeight: 48,
      backgroundContent: model_content.template_none(),
    }
  },
  template_dragItem(): DragItemModel {
    return {
      position: [0.5, 0.5],
      hideStart: false,
      hideEnd: false,
      content: model_content.template_none(),
    }
  },
}