export enum EventOverlay_elementType {
  clickable,
  drawable,
  draggable,
  none,
}

export enum EventOverlay_eventType {
  draw,
  drag,
  swipe_x,
  swipe_y,
  other,
  none,
}

export enum EventOverlay_actionType {
  down,
  up,
  move,
}

export interface EventOverlay_actionEvent {
  type: EventOverlay_actionType,
  screen: string,
  id: number,
  x?: number,
  y?: number,
}