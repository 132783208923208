




















































import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import InlineColorSelector from "@/components/screen_editor/content/color/InlineColorSelector.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import AdvancedEditNumber from "@/components/fundamental/advanced_inputs/AdvancedEditNumber.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import AdvancedScaling from "@/components/fundamental/advanced_inputs/AdvancedScaling.vue"
import util from "@/util/util"
import TextField from "@/components/fundamental/text/TextField.vue"
import {ChartBoxModel} from "@/models/content/charts/model_chart_box"

@Component({
  components: {
    TextField, AdvancedScaling, TextButton, AdvancedEditNumber, Column, InlineColorSelector, RoundIconButton, InputField, Row, Container
  }
})
export default class BoxEditor extends Vue {
  @Prop({type: Number, required: true}) index!: number
  @Prop({type: Object, required: true}) box!: ChartBoxModel

  oldPercentiles = util.copyArray(this.box.percentiles)

  @Watch('box.percentiles', {deep: true})
  percentilesChanged() {
    if (this.box.percentiles[0] != this.oldPercentiles[0] && this.box.percentiles[0] > this.box.percentiles[1]) {
      this.$set(this.box.percentiles, 0, this.oldPercentiles[0])

    } else if (this.box.percentiles[1] != this.oldPercentiles[1] && (this.box.percentiles[1] > this.box.percentiles[2] || this.box.percentiles[1] < this.box.percentiles[0])) {
      this.$set(this.box.percentiles, 1, this.oldPercentiles[1])

    } else if (this.box.percentiles[2] != this.oldPercentiles[2] && (this.box.percentiles[2] > this.box.percentiles[3] || this.box.percentiles[2] < this.box.percentiles[1])) {
      this.$set(this.box.percentiles, 2, this.oldPercentiles[2])

    } else if (this.box.percentiles[3] != this.oldPercentiles[3] && (this.box.percentiles[3] > this.box.percentiles[4] || this.box.percentiles[3] < this.box.percentiles[2])) {
      this.$set(this.box.percentiles, 3, this.oldPercentiles[3])

    } else if (this.box.percentiles[4] != this.oldPercentiles[4] && this.box.percentiles[4] < this.box.percentiles[3]) {
      this.$set(this.box.percentiles, 4, this.oldPercentiles[4])
    }

    this.oldPercentiles = util.copyArray(this.box.percentiles)
  }
}
