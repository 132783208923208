






import {Component, Vue} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import {eventbus} from "@/main"
import {EventType, SidePanelViewModel} from "@/components/app/eventModel"
import Container from "@/components/fundamental/layout/Container.vue"
import Simulator from "@/components/simulator/Simulator.vue"
import dummy_unit_1_screen_1_recording from "@/test_area/dummy_data/units/dummy_unit_1_screen_1_recording"

@Component({
  components: {Simulator, Container, Row}
})
export default class TestSimulator_uiElements extends Vue {

  combinedUnit = dummy_unit_1_screen_1_recording

  mounted() {
    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(false))
  }
}
