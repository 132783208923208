

























import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import {CurriculumModel, OfficialTopicModel} from "@/models/curriculum/model_curriculum"
import Row from "@/components/fundamental/layout/Row.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import OfficialChapter from "@/pages/curriculum_viewer/official/OfficialChapter.vue"
import appState from "@/app/state/app_state"

@Component({
  components: {OfficialChapter, TextButton, SpacerBox, Container, RoundIconButton, Row, TextField, Column}
})
export default class OfficialTopic extends Vue {
  @Prop({type: Object, required: true}) curriculum!: CurriculumModel
  @Prop({type: Object, required: true}) topic!: OfficialTopicModel
  @Prop({type: Number, required: true}) topicIndex!: number

  curriculumState = appState.curriculum
  contentVisible: boolean = !!this.curriculumState.officialTopicsVisible.get(this.topic.id)

  toggleContentVisible() {
    this.contentVisible = !this.contentVisible
    this.curriculumState.officialTopicsVisible.set(this.topic.id, this.contentVisible)
  }
}
