






























import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {RecordingModel} from "@/models/recording/model_recording"
import Container from "@/components/fundamental/layout/Container.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import VideoPlayerWidget from "@/components/video/player/VideoPlayerWidget.vue"
import {VideoController} from "@/components/video/controller/VideoController"

@Component({
  components: {VideoPlayerWidget, TextField, Container, Row, Column}
})
export default class ScreenVideoPlayer extends Vue {
  @Prop({required: true}) recording!: RecordingModel | null
  @Prop({type: Number, required: true}) index!: number
  @Prop({type: Boolean, required: true}) visible!: boolean

  width = 150
  videoController: VideoController | null = null


  /////////////////////////////////
  // Watch
  /////////////////////////////////
  @Watch('visible')
  visibleChanged() {
    if (this.videoController) {
      if (!this.visible) {
        this.videoController.pause()
      }
    }
  }


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  setVideoController(v: VideoController) {
    this.videoController = v
    this.$emit('videoController', v)
  }
}
