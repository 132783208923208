







































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import {ConverterLog, ConverterLogStatus} from "@/components/converter/VideoConverterWebSocketHandler"

@Component({
  components: {TextField, Row, Column}
})
export default class ConverterList extends Vue {
  @Prop({type: String, required: true}) title!: string
  @Prop({type: Array, required: true}) list!: Array<ConverterLog>

  converterLogStatus = ConverterLogStatus
}
