import model_xy_chart, {XyChartModel} from "@/models/content/charts/model_xy_chart"
import {ActionModel, ActionType} from "@/models/action/model_action"
import model_response from "@/models/action/model_response"

export interface DragRectActionModel extends ActionModel {
  xyChart: XyChartModel
  targetRects: Array<RectModel>
  snapGrid: boolean
  tolerance: number
  direction: DragDirection
}

export interface RectModel {
  ltrb: Array<number>
}

export enum DragDirection {
  top = 'top',
  right = 'right',
  topRight = 'topRight',
}

export default {
  template_action(): DragRectActionModel {
    return {
      type: ActionType.dragRect,
      responses: model_response.template_responses(),
      xyChart: model_xy_chart.template_lineChart(),
      targetRects: [],
      snapGrid: true,
      tolerance: 0.1,
      direction: DragDirection.top,
    }
  },
  template_rect() : RectModel {
    return {
      ltrb: [0, 0, 1, 1]
    }
  }
}