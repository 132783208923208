import {ColorModel} from "@/models/model_colors"
import {ChartTextModel} from "@/models/content/charts/model_chart_text"


export interface ChartRectModel {
  ltrb: Array<number>
  label?: ChartTextModel
  color?: ColorModel
  borderColor?: ColorModel
  borderWidth?: number
}

export default {
  template(color?: ColorModel): ChartRectModel {
    return {
      ltrb: [0, 0, 1, 1],
      color: color ? color : ColorModel.cyan,
      borderColor: color ? color : ColorModel.cyan,
      borderWidth: 2,
    }
  },
}