























import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import ImageField from "@/components/fundamental/ImageField.vue"

@Component({
  components: {ImageField, Container, TransitionEffect, Row, Column}
})
export default class ImageDialog extends Vue {
  @Prop({type: Number, required: true}) displayWidth!: number

  isVisible = false
  url = ''

  show(url: string) {
    this.isVisible = true
    this.url = url
  }
}
