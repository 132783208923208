













































import {Component, Prop, Vue} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import {ScreenModel} from "@/models/screen/model_screen"
import {ContentType} from "@/models/content/model_content"

@Component({
  components: {Column, SpacerBox, TextField, Row}
})
export default class StaticEditorHeader extends Vue {
  @Prop({type: Object, required: true}) screen!: ScreenModel

  iconSize = 18

  /////////////////////////////////
  // Computed
  /////////////////////////////////
  get textItems() {
    return this.countItems(ContentType.text)
  }

  get imgItems() {
    return this.countItems(ContentType.image)
  }

  get gifItems() {
    return this.countItems(ContentType.gif)
  }

  get xyChartItems() {
    return this.countItems(ContentType.xyChart)
  }

  get tableItems() {
    return this.countItems(ContentType.table)
  }

  get spacerItems() {
    return this.countItems(ContentType.spacer)
  }

  /////////////////////////////////
  // Methods
  /////////////////////////////////
  countItems(type: ContentType) {
    let c = 0
    this.screen.statics.forEach(i => {
      if (i.type === type) c++
    })
    return c
  }

}
