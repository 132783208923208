import {ContentType} from "@/models/content/model_content"
import {ActionType} from "@/models/action/model_action"
import {ScreenModel} from "@/models/screen/model_screen"
import {TextModel} from "@/models/content/model_text"

export default <ScreenModel>{
  id: "scre",
  title: "Test Action Drag & Drop",
  statics: [
    <TextModel>{
      type: ContentType.text,
      text: "Von wem stammt die berühmte Gleichung E=mc²?",
      size: 18,
    }
  ],
  action: {
    type: ActionType.dragConnect,
    responses: [],
    startFieldWidth: 144,
    startFieldHeight: 80,
    endFieldWidth: 144,
    endFieldHeight: 80,
    startFields: [
      {
        content: {
          type: ContentType.text,
          text: {
            text: "A",
            size: 18,
          }
        },
        target: 0
      },
      {
        content: {
          type: ContentType.text,
          text: {
            text: "B",
            size: 18,
          }
        },
        target: 1
      },
      {
        content: {
          type: ContentType.text,
          text: {
            text: "C",
            size: 18,
          }
        },
        target: 2
      },
    ],
    endFields: [
      {
        content: {
          type: ContentType.text,
          text: {
            text: "a",
            size: 18,
          }
        },
      },
      {
        content: {
          type: ContentType.text,
          text: {
            text: "b",
            size: 18,
          }
        },
      },
      {
        content: {
          type: ContentType.text,
          text: {
            text: "c",
            size: 18,
          }
        },
      },
      {
        content: {
          type: ContentType.text,
          text: {
            text: "d",
            size: 18,
          }
        },
      },
    ],
  },
}