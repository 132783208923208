export default {
  "title": "Modellierung",
  "id": "sc_p3Y",
  "statics": [
    {
      "size": 18,
      "text": "Eine lineare Funktion $$f$$ wird allgemein durch eine Funktionsgleichung $$f(x) = k \cdot x + d$$ mit den Parametern $$k \in \mathbb{R} $$ und $$d \in \mathbb{R} $$ dargestellt. Welche dieser Aufgabenstellungen können mithilfe einer linearen Funktion modelliert werden?",
      "type": "text",
    }
  ],
}