














































































































import {Component, Prop, Vue} from "vue-property-decorator"
import TextField from "@/components/fundamental/text/TextField.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import {LogEventBundle} from "@/models/log/model_log_events"
import JsonView from "@/components/fundamental/text/JsonView.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import firebase from 'firebase/app'

@Component({
  components: {SpacerBox, JsonView, TextButton, Column, RoundIconButton, Container, Row, TextField}
})
export default class LogEventBundleDetails extends Vue {
  @Prop({type: Number, required: true}) index!: number
  @Prop({type: Object, required: true}) bundle!: LogEventBundle

  showDetails = this.index == 0
  showEventDetails = new Array(this.bundle.events.length).fill(false)

  getDateStr(time: firebase.firestore.Timestamp) {
    let date = time.toDate()
    let dateStr = `${date.getFullYear()}-${this.to2Digits(date.getMonth()+1)}-${this.to2Digits(date.getDate())}`;
    let timeStr = `${this.to2Digits(date.getHours())}:${this.to2Digits(date.getMinutes())}:${this.to2Digits(date.getSeconds())}.${this.to3Digits(date.getMilliseconds())}`;
    return dateStr + ' -- ' + timeStr
  }

  getTimeStr(time: firebase.firestore.Timestamp) {
    let date = time.toDate()
    return `${this.to2Digits(date.getHours())} : ${this.to2Digits(date.getMinutes())} : ${this.to2Digits(date.getSeconds())}.${this.to3Digits(date.getMilliseconds())}`;
  }

  to2Digits(d: number): string {
    return d < 10 ? `0${d}` : `${d}`
  }

  to3Digits(d: number): string {
    return d < 10 ? `00${d}` : (d < 100 ? `0${d}` : `${d}`)
  }

  roundToInt(d: number): number {
    return Math.round(d)
  }

  copy(text: string) {
    navigator.clipboard.writeText(text).catch(error => {
      console.log('Clipboard error', error)
    })
  }

  toggleLine(index: number) {
    this.$set(this.showEventDetails, index, !this.showEventDetails[index])
  }
}
