import {ActionType} from "@/models/action/model_action"
import {UnitStatus, UnitType} from "@/models/unit/model_unit"
import {
  ActionInputType,
  ResultDocModel,
  SearchCategory,
  SearchCollection,
  SearchOrder
} from "@/pages/search/model_search"


export class SearchState {
  searchStr = ''
  searchCategory = SearchCategory.id
  searchCollection = SearchCollection.units
  numEntries = 100
  startTime: null|string = null
  endTime: null|string = null
  searchOrder = SearchOrder.createdAt_desc
  requestedDocs: Array<ResultDocModel> = []
  unitStatus = UnitStatus.empty
  unitType = UnitType.theory
  actionType = ActionType.mc
  actionInputType = ActionInputType.all
  curriculumId = ''
  errorMsg = ''
  currentPage = 0
  scrollPosition = 0
}