


















import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import ContentWidget from "@/simulator/content/ContentWidget.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import BoxPlotEditor from "@/components/screen_editor/content/chart/box_plot/BoxPlotEditor.vue"
import {XyChartModel} from "@/models/content/charts/model_xy_chart"

@Component({
  components: {
    BoxPlotEditor, Container, ContentWidget, Row, Column
  }
})
export default class ContentBoxPlotEditor extends Vue {
  @Prop({type: Object, required: true}) content!: XyChartModel
  @Prop({type: Boolean, default: false, required: false}) showPreview!: boolean
}
