








import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import {SpacerModel} from "@/models/content/model_spacer"

@Component({
  components: {
    Container, TextField, SpacerBox, Row, Column
  }
})
export default class ContentSpacerPreview extends Vue {
  @Prop({type: Object, required: true}) content!: SpacerModel
}
