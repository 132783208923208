
















import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {eventbus} from "@/main"
import {EventType, SidePanelViewModel} from "@/components/app/eventModel"
import StaticEditor from "@/components/screen_editor/static/StaticEditor.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import ScreenWidget from "@/simulator/screen/ScreenWidget.vue"
import {ContentType} from "@/models/content/model_content"
import {ScreenModel} from "@/models/screen/model_screen"
import {TextModel} from "@/models/content/model_text"

@Component({
  components: {ScreenWidget, SpacerBox, StaticEditor, Row, Column}
})
export default class TestUnitEditor_staticContent extends Vue {
  screen: ScreenModel = {
    id: "",
    title: "Test Screen",
    statics: [
      <TextModel>{
        type: ContentType.text,
        text: "Das ist nur etwas Text.",
        size: 18,
      },
    ],
  }

  mounted() {
    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(true, this.screen))
  }
}
