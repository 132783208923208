export default {
  'id': 'reco-PtMqRufnxxaRbiUUI5LhIUtP4Tk-1',
  'createdBy': 'zirro.p@gmail.com',
  'createdAt': '2020-02-08T06:03:32.976Z',
  'lastModifiedAt': '2019-11-29T09:21:40.632Z',
  'lastModifiedBy': 'michael.niedermayr@gmail.com',
  'unitId': 'unit-MKAxdfYKdmHQUoIwhtmv2fRFF0c',
  'screenId': 'scre-zY8M6w5Sh7wgIappcajYeHz9D1g',
  'duration': 14.24,
  'width': 1080,
  'height': 1520,
  'videoEncodingRequired': false,
  'language': 'de-DE',
  'url': 'https://vimeo.com/390127064',
  'paths': [{
    'type': 'pen',
    'width': 'small',
    'color': 'black',
    'time': [5.149, 5.215, 5.26, 5.308, 5.356, 5.404, 5.452],
    'x': [0.1214, 0.1239, 0.1364, 0.1739, 0.1889, 0.1864, 0.1839],
    'y': [0.2775, 0.295, 0.395, 0.6425, 0.8125, 0.88, 0.885]
  }, {
    'type': 'pen',
    'width': 'small',
    'color': 'black',
    'time': [6.876, 6.925, 6.972, 7.021, 7.069, 7.119, 7.166, 7.24],
    'x': [0.4564, 0.4564, 0.4564, 0.4539, 0.4439, 0.4439, 0.4439, 0.4414],
    'y': [0.195, 0.2225, 0.2775, 0.3925, 0.6775, 0.8025, 0.8225, 0.8275]
  }, {
    'type': 'pen',
    'width': 'small',
    'color': 'black',
    'time': [8.037, 8.142, 8.189],
    'x': [0.0964, 0.2064, 0.6714],
    'y': [0.5975, 0.595, 0.595]
  }],
  'subtitlesRaw': {
    language: 'de-DE',
    text: 'Das ist nur ein Test. Ein Test. Das ist ein Test',
    sentences: [
      {
        'confidence': 0.9190641045570374,
        'words': [
          {'text': 'Das', 'start': 0.7, 'stop': 1.1},
          {'text': 'ist', 'start': 1.1, 'stop': 1.9},
          {'text': 'nur', 'start': 1.9, 'stop': 2.2},
          {'text': 'ein', 'start': 2.2, 'stop': 2.4},
          {'text': 'Test.', 'start': 2.4, 'stop': 2.8}
        ]
      },
      {
        'confidence': 0.9098036885261536,
        'words': [
          {'text': 'ein', 'start': 4.9, 'stop': 5.3},
          {'text': 'Test', 'start': 5.3, 'stop': 7.0}
        ]
      },
      {
        'confidence': 0.7569872736930847,
        'words': [
          {'text': 'Das', 'start': 10.9, 'stop': 11.3},
          {'text': 'ist', 'start': 11.3, 'stop': 11.5},
          {'text': 'ein', 'start': 11.5, 'stop': 12.2},
          {'text': 'Test.', 'start': 12.2, 'stop': 12.8}
        ]
      }
    ]
  },
  'subtitles': [],
  'keyframes': []
}