
































import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Slider from "@/components/fundamental/inputs/Slider.vue"
import {VideoController, VideoControllerValue} from "@/components/video/controller/VideoController"
import util from '@/util/util'
import IconButton from "@/components/fundamental/buttons/IconButton.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import mMath from "@/util/mMath"

@Component({
  components: {SelectField, TextField, RoundIconButton, IconButton, Slider, Row, Column}
})
export default class VideoControl extends Vue {
  @Prop({required: true}) videoController!: VideoController | null

  videoControllerValue: VideoControllerValue | null = null
  callbackId = -1

  speedValues = [0.5, 0.8, 1.0, 1.2, 1.5, 2.0]
  speedLabels = ['0.5', '0.8', '1.0', '1.2', '1.5', '2.0']

  throttlePeriod = 100
  throttleBlocking = false
  targetProgress = 0

  /////////////////////////////////
  // Computed
  /////////////////////////////////
  get time() {
    return this.videoControllerValue ? this.videoControllerValue.time : 0
  }

  get progress() {
    return this.videoControllerValue ? this.videoControllerValue.time / this.videoControllerValue.recording.duration : 0
  }

  get speed() {
    return this.videoControllerValue ? this.videoControllerValue.speed : 0
  }

  get volume() {
    return this.videoControllerValue ? this.videoControllerValue.volume : 0
  }

  get isPlaying() {
    return this.videoControllerValue ? this.videoControllerValue.isPlaying : false
  }

  get timeStr() {
    return util.createTimeString(this.time, false, true)
  }

  get durationStr() {
    let d = this.videoControllerValue ? this.videoControllerValue.recording.duration : 0
    return util.createTimeString(d, false, true)
  }

  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  created() {
    this.videoControllerChanged()
  }

  beforeDestroy() {
    if (this.videoController && this.callbackId >= 0) {
      this.videoController.removeValueCallback(this.callbackId)
    }
  }

  @Watch('videoController')
  videoControllerChanged() {
    if (this.videoController && this.callbackId < 0) {
      this.callbackId = this.videoController.addValueCallback((v: VideoControllerValue) => {
        this.videoControllerValue = util.copy(v)
      })
    }
  }


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  togglePlaying() {
    if (this.videoController) {
      this.videoController.togglePlay()
    }
  }

  setTime(time: number) {
    if (this.videoController) {
      this.videoController.setTime(mMath.constrain(time, 0, this.videoController.value.recording.duration))
    }
  }

  setProgress(progress: number) {
    this.targetProgress = progress

    if (!this.throttleBlocking) {
      this.throttleBlocking = true

      setTimeout(() => {
        if (this.videoController) {
          let time = mMath.constrain(this.targetProgress, 0, 1) * this.videoController.value.recording.duration
          this.videoController.setTime(time)
        }
        this.throttleBlocking = false
      }, this.throttlePeriod)
    }
  }

  setSpeed(speed: number) {
    if (this.videoController) {
      this.videoController.setSpeed(speed)
    }
  }

  setVolume(volume: number) {
    if (this.videoController) {
      this.videoController.setVolume(volume)
    }
  }
}
