import model_firestore, {FirestoreModel} from "@/models/model_firestore"
import {PathModel} from "@/models/recording/model_path"
import util from "@/util/util"

export interface RecordingModel extends FirestoreModel {
  type: number // RecordingType
  screenVersion: string
  unitId: string // old unitId
  uId2: string // unitId
  screenId: string
  as?: number // ActionStatus
  duration: number
  width: number
  height: number
  videoEncodingRequired: boolean
  qe?: boolean // quickEncoded

  videoEncodedAt: string
  videoEncodedBy: string
  language: string
  url: string
  previewImages: Array<PreviewImageModel>
  videoFiles: Array<VideoFileModel>
  paths: Array<PathModel>
  // subtitlesRaw: SubtitlesRawModel
  // subtitles: Array<SubtitleModel>
  // keyframes: Array<KeyframeModel>

  playerZoomFactor?: number
  playerAlignment?: string

  noRaw?: boolean
  tOff?: number
  yOff?: number
}

export interface PreviewImageModel {
  width: number
  height: number
  url: string
}

export interface VideoFileModel {
  width: number
  height: number
  size: number
  url: string
}

export interface KeyframeModel {
  time: number
  fullscreen: boolean
}

export default {
  template(): RecordingModel {
    return {
      ...model_firestore.template('re'),
      type: 0, // RecordingType.mobil
      screenVersion: '',
      unitId: '',
      uId2: '',
      screenId: '',
      duration: 0,
      width: 0,
      height: 0,
      videoEncodingRequired: false,
      videoEncodedAt: '',
      videoEncodedBy: '',
      language: '',
      url: '',
      previewImages: [],
      videoFiles: [],
      paths: [],
    }
  },
  copyRecording(recording: RecordingModel, newUnitId: string) {
    let newRecording: RecordingModel = {
      ...model_firestore.template('re'),
      type: recording.type,
      screenVersion: recording.screenVersion,
      unitId: recording.type == 0 ? newUnitId : newUnitId + '_yt',
      uId2: newUnitId,
      screenId: recording.screenId,
      duration: recording.duration,
      width: recording.width,
      height: recording.height,
      videoEncodingRequired: recording.videoEncodingRequired,
      videoEncodedAt: recording.videoEncodedAt,
      videoEncodedBy: recording.videoEncodedBy,
      language: recording.language,
      url: recording.url,
      previewImages: util.copyArray(recording.previewImages),
      videoFiles: util.copyArray(recording.videoFiles),
      paths: util.copyArray(recording.paths),
    }

    if (recording.as) {
      newRecording['as'] = recording.as
    }

    if (recording.qe) {
      newRecording['qe'] = recording.qe
    }

    if (recording.noRaw) {
      newRecording['noRaw'] = recording.noRaw
    }

    if (recording.tOff) {
      newRecording['tOff'] = recording.tOff
    }

    if (recording.yOff) {
      newRecording['yOff'] = recording.yOff
    }

    return newRecording
  }
}
