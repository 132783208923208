import Vue from 'vue'

export default class AppStore {

  init() {
  }

  /////////////////////////////////
  // Getters
  /////////////////////////////////
  get name() {
    return process.env.VUE_APP_NAME
  }

  get version() {
    return process.env.VUE_APP_VERSION
  }

  get versionDate() {
    return process.env.VUE_APP_VERSION_DATE
  }

  get vueVersion() {
    return Vue.version
  }
}