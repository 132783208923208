









































import {Component, Vue, Watch} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import EditText from "@/components/fundamental/inputs/EditText.vue"
import Loading from "@/components/fundamental/Loading.vue"
import CameraDialog from "@/components/recorder/camera/CameraDialog.vue"
import {EosHandler} from "@/components/recorder/camera/EosHandler"
import {eventbus} from "@/main"
import {EventType} from "@/components/app/eventModel"
import mMath from "@/util/mMath"
import prefs from "@/prefs/prefs"

@Component({
  components: {CameraDialog, Loading, EditText, TextButton, TextField, Row, Column}
})
export default class TestRecording_camera extends Vue {
  startingTime = 0
  stoppingTime = 0
  ready = false
  running = false
  isLoading = false

  showCameraDialog = false
  eosHandler: EosHandler = new EosHandler(prefs.cameraUrl)


  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  prefs = prefs

  @Watch('prefs.cameraUrl')
  cameraUrlChanged() {
    this.eosHandler.setCameraUrl(this.prefs.cameraUrl)
  }


  /////////////////////////////////
  // Camera
  /////////////////////////////////
  async initializeCamera() {
    this.showLoading(true)
    await this.eosHandler.initCamera()
    this.showLoading(false)
    this.ready = this.eosHandler.cameraStatus().isCameraReady()
    this.showCameraDialog = true
  }

  async startCamera() {
    this.showLoading(true)
    let response = await this.eosHandler.startCamera()
    this.showLoading(false)

    this.running = response.success
    this.startingTime = mMath.round(response.requestTime, 3)
  }

  async stopCamera() {
    this.showLoading(true)
    let response = await this.eosHandler.stopCamera()
    this.showLoading(false)

    this.running = false
    this.stoppingTime = mMath.round(response.requestTime, 3)

    console.log(
      'camera stopped:', response.success,
      'request time:', response.requestTime,
      'filename:', response.filename,
    )
  }

  showLoading(show: boolean) {
    eventbus.$emit(EventType.loadingDialog, show)
  }
}
