















































import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import {eventbus} from "@/main"
import {AlertDialogType, EventType} from "@/components/app/eventModel"
import prefs from "@/prefs/prefs"

@Component({
  components: {
    TextField,
    Container, TextButton, SpacerBox, Row, Column
  }
})
export default class TestHtml_dialogs extends Vue {

  /////////////////////////////////
  // Methods
  /////////////////////////////////
  infoDialog() {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.info,
      title: 'Wait till process is completed',
      text: '',
      positiveButton: 'Ok',
      negativeButtonVisible: false,
      callback: (response: boolean) => {
      }
    })
  }

  warningDialog() {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.warning,
      title: 'Do you want to continue',
      text: 'Bist du dir ganz sicher?',
      positiveButton: 'Continue',
      negativeButton: 'Cancel',
      callback: (response: boolean) => {
      }
    })
  }

  errorDialog() {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.error,
      title: 'Das ist ein Fehler',
      text: 'Hallo ich bin ein Fehler',
      positiveButton: 'Delete',
      negativeButton: 'Cancel',
      callback: (response: boolean) => {
      }
    })
  }

  deleteDialog() {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.error,
      title: 'Das ist ein Fehler',
      text: 'Hallo ich bin ein Fehler',
      confirmText: 'Confirm you want to delete this curriculum by typing',
      confirmAnswer: 'Hoho',
      positiveButton: 'Delete',
      negativeButton: 'Cancel',
      callback: (response: boolean) => {
      }
    })
  }

  oopsDialog() {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.oops,
      oopsMsg: 'Das ist ein Fehler',
    })
  }

  saveDialog() {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.save,
    })
  }

  deleteLowDialog() {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.delete_low,
      title: `Delete topic`,
      text: `Do you want to delete the topic. You can restore it by resetting the curriculum. Changes will be permanent if you save the curriculum.`,
    })
  }

  deleteHighDialog() {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.delete_high,
      title: `Delete topic`,
      text: `Do you want to permanently delete the topic.`,
    })
  }

  openSnackbar() {
    eventbus.$emit(EventType.snackbar, {
      msg: 'Hallo Snackbar',
      duration: 1000,
    })
  }

  openWhatsNewDialog() {
    eventbus.$emit(EventType.whatsNewDialog)
  }

  resetWhatsNewDialog() {
    prefs.latestAppVersionDocsSeen = '0'
  }
}
