<template>
  <TransitionEffect>

    <Column
      v-if="isVisible"
      center middle
      @click.self="isVisible = !isVisible"
      class="bg-color-black-05 absolute top-left bottom-right fixed z-index-dialog"
    >

      <!-- @click.native.stop required due to VueTS bug -->
      <Column @click.native.stop="" class="bg-color-white elevation-14 p-16 radius-6 p-24 max-w800px min-w320px">

        <Row>
          <TextField headline2>What's new</TextField>
        </Row>

        <Container class="content p-16">
          <TextField v-html="html"></TextField>
        </Container>

        <Row right middle class="m-t-24">
          <Checkbox v-model="stopShowing"/>

          <TextField
            :size="14"
            @click.native="stopShowing = !stopShowing"
            class="cursor-pointer m-l-8 m-r-24"
          >
            Do not show again
          </TextField>

          <TextButton
            title="Close"
            primary
            small
            @clicked="close"
          />
        </Row>

      </Column>

    </Column>
  </TransitionEffect>
</template>

<style lang="scss" scoped>
  @import "../../scss/colors";

  .content {
    border: 1px solid $color-gray-300;
    max-height: 400px;
    overflow-y: scroll;
  }
</style>

<script>
  import showdown from 'showdown'
  import versions from '../../../versions.md'
  import Checkbox from "@/components/fundamental/inputs/Checkbox"
  import TransitionEffect from "@/components/fundamental/animations/TransitionEffect"
  import Column from "@/components/fundamental/layout/Column"
  import Container from "@/components/fundamental/layout/Container"
  import Row from "@/components/fundamental/layout/Row"
  import TextButton from "@/components/fundamental/buttons/TextButton"
  import TextField from "@/components/fundamental/text/TextField"
  import {eventbus} from "@/main"
  import {EventType} from "@/components/app/eventModel"
  import store from "@/store/store"
  import prefs from "@/prefs/prefs"

  export default {
    components: {
      Checkbox,
      TransitionEffect,
      Column,
      Container,
      Row,
      TextButton,
      TextField
    },
    data() {
      return {
        isVisible: false,
        stopShowing: false,
        html: '',
      }
    },
    created() {
      if (store.app.version !== prefs.latestAppVersionDocsSeen) {
        let converter = new showdown.Converter()
        this.html = converter.makeHtml(versions)
        this.isVisible = true
      }

      eventbus.$on(EventType.whatsNewDialog, () => {
        this.show()
      })
    },
    methods: {
      show() {
        let converter = new showdown.Converter()
        this.html = converter.makeHtml(versions)
        this.isVisible = true
      },
      close() {
        this.isVisible = false
        if (this.stopShowing) {
          prefs.latestAppVersionDocsSeen = store.app.version
        }
      }
    },
  }
</script>