


























































































import {Component, Prop, Vue} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import tenorHelper from "@/util/images/tenorHelper"
import ImagePreview from "./ImagePreview.vue"
import Loading from "../../fundamental/Loading.vue"
import InputField from "../../fundamental/inputs/InputField.vue"
import TextButton from "../../fundamental/buttons/TextButton.vue"
import Row from "../../fundamental/layout/Row.vue"
import TextField from "../../fundamental/text/TextField.vue"
import Link from "../../fundamental/text/Link.vue"
import Column from "../../fundamental/layout/Column.vue"
import {ImageModel, ImageType} from "@/models/content/model_image"
import giphyHelper from "@/util/images/giphyHelper"
import gfycatHelper from "@/util/images/gfycatHelper"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"

@Component({
  components: {RoundIconButton, Column, Link, TextField, Row, TextButton, InputField, Loading, ImagePreview, Container}
})
export default class GifSelector extends Vue {
  @Prop({type: Object, required: true}) gif!: ImageModel

  tenorId: string = ''
  giphyId: string = ''
  gfycatId: string = ''

  isFetchingUrl: boolean = false
  errorGifMsg: string = ''

  /////////////////////////////////
  // Methods
  /////////////////////////////////
  async fetchGif() {
    this.isFetchingUrl = true

    let result
    let imageType

    if (this.tenorId) {
      result = await tenorHelper.fetchGif(this.tenorId)
      imageType = ImageType.tenor

    } else if (this.giphyId) {
      result = await giphyHelper.fetchGif(this.giphyId)
      imageType = ImageType.giphy

    } else if (this.gfycatId) {
      result = await gfycatHelper.fetchGif(this.gfycatId)
      imageType = ImageType.gfycat

    } else {
      return
    }

    this.isFetchingUrl = false
    this.errorGifMsg = result.msg
    if (result.item) {
      this.gif.imageType = imageType
      this.gif.url = result.item.url
      this.gif.size = result.item.size
      this.gif.width = result.item.width
      this.gif.height = result.item.height
    }
  }

  reset() {
    this.tenorId = ''
    this.giphyId = ''
    this.gfycatId = ''

    this.gif.url = ''
    this.gif.size = 0
    this.gif.width = 0
    this.gif.height = 0
  }
}

