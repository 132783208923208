











import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {eventbus} from "@/main"
import {EventType, SidePanelViewModel} from "@/components/app/eventModel"
import ActionEditorResponse from "@/components/screen_editor/action_response/ActionEditorResponse.vue"
import {ScreenModel} from "@/models/screen/model_screen"
import dummy_screen_action_mc from "@/test_area/dummy_data/screens/dummy_screen_action_mc"
import {ResponseType} from "@/models/action/model_response"

@Component({
  components: {ActionEditorResponse, Row, Column}
})
export default class TestUnitEditor_actionResponses extends Vue {

  responseType = ResponseType

  screen: ScreenModel = dummy_screen_action_mc

  mounted() {
    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(true, this.screen))
  }
}
