











































import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Checkbox from "@/components/fundamental/inputs/Checkbox.vue"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import model_text, {TextModel} from "@/models/content/model_text"
import TextEditor from "@/components/screen_editor/content/text/TextEditor.vue"
import {ContentType} from "@/models/content/model_content"

@Component({
  components: {TextEditor, TextButton, Column, TransitionEffect, Checkbox, TextField, Row}
})
export default class InlineTextEditor extends Vue {
  @Prop({type: String, required: true}) title!: string
  @Prop({required: true}) value!: TextModel | undefined
  @Prop({type: Boolean, default: false, required: false}) isNullable!: boolean
  @Prop({type: Number, default: 0, required: false}) width!: number
  @Prop({type: Number, default: 0, required: false}) titleWidth!: number
  @Prop({type: Boolean, default: false, required: false}) titleBold!: boolean
  @Prop({type: Boolean, default: false, required: false}) inlineText!: boolean
  @Prop({type: Number, default: 18, required: false}) defaultFontSize!: number
  @Prop({type: Boolean, default: false, required: false}) defaultIsBold!: boolean
  @Prop({type: Number, required: false, default: -1}) recommendedFontSize!: number

  isDialogVisible = false
  newValue: TextModel = this.value ? this.value : model_text.template()

  get rowStyle() {
    let s = ''
    if (this.width > 0 ) {
      s += 'width:' + this.width + 'px;'
    }
    return s
  }

  get titleStyle() {
    let s = ''
    if (this.titleWidth > 0 ) {
      s += 'width:' + this.titleWidth + 'px;'
    }
    return s
  }

  @Watch('value')
  valueChanged() {
    if (this.newValue === this.value) return

    this.updateValue()
  }

  open() {
    this.updateValue()

    this.isDialogVisible = true
  }

  save() {
    this.isDialogVisible = false
    this.$emit('input', this.newValue)
  }

  cancel() {
    this.isDialogVisible = false
  }

  resetValue() {
    this.newValue.text = ''
    this.isDialogVisible = false
    this.$emit('input', undefined)
  }

  updateValue() {
    if (!this.value) {
      this.newValue.text = ''
      this.newValue.size = this.defaultFontSize
      this.$delete(this.newValue, 'color')
      this.$delete(this.newValue, 'isBold')
      this.$delete(this.newValue, 'isItalic')
      this.$delete(this.newValue, 'isFormula')

      if (this.defaultIsBold) this.$set(this.newValue, 'isBold', true)

    } else {
      this.newValue = {
        type: ContentType.text,
        text: this.value.text,
        size: this.value.size,
      }

      if (this.value.color) this.$set(this.newValue, 'color', this.value.color)
      if (this.value.isBold) this.$set(this.newValue, 'isBold', true)
      if (this.value.isItalic) this.$set(this.newValue, 'isItalic', true)
      if (this.value.isFormula) this.$set(this.newValue, 'isFormula', true)
    }
  }
}
