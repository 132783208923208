























import {Component, Prop, Vue} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import mMath from "@/util/mMath"

@Component({
  components: {Container}
})
export default class ProgressBar extends Vue {
  @Prop({type: Number, required: true}) value!: number

  get width() {
    return mMath.constrain(this.value, 0, 100)
  }
}
