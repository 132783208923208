import dummy_recording0 from "../recordings/dummy_recording0"
import dummy_screen_simple from "../screens/dummy_screen_simple"

export default {
  unit: {
    "id": "unit-MKAxdfYKdmHQUoIwhtmv2fRFF0c",
    "createdAt": "2020-01-29T14:25:23.427Z",
    "createdBy": "zirro.p@gmail.com",
    "lastModifiedAt": "2019-11-29T09:21:40.632Z",
    "lastModifiedBy": "michael.niedermayr@gmail.com",
    "curriculum": "curr-1OFLkT3lrKI6jgVs6P49OITnsdcb",
    "title": "Test Unit",
    "screens": [
      "scre-zY8M6w5Sh7wgIappcajYeHz9D1g",
    ],
  },
  screens: [
    dummy_screen_simple
  ],
  recordings: [
    dummy_recording0,
  ]
}