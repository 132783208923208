import {ActionType} from "@/models/action/model_action"
import {ContentType} from "@/models/content/model_content"
import {TextModel} from "@/models/content/model_text"

export default {
  "id": "scre-mNxrSfiVIIYt6dDKD5cJHt6h244",
  "title": "Test Action Mc",
  "statics": [
    <TextModel>{
      "type": ContentType.text,
      "text": "Von wem stammt die berühmte Gleichung E=mc²?",
      size: 18,
    }
  ],
  "initialVideoId": "reco-PtMqRufnxxaRbiUUI5LhIUtP4Tk-0",
  "action": {
    "type": ActionType.mc,
    "answers": [
      {
        "id": "acgi-j1TYsL5f0jt0cchG8tNv5xYAa2s",
        "value": "false_",
        "type": "gif",
        "imageType": "tenor",
        "url": "https://media.tenor.com/images/607529d099ecb88935e390ba3805dc8a/tenor.gif",
        "size": 75017,
        "fileType": "",
        "width": 220,
        "height": 124,
        "scaling": 1
      },
      {
        "id": "acim-GYFqSCz4kNckuFtRHUd1TSIfUD0",
        "value": "false_",
        "type": "image",
        "imageType": "image",
        "url": "https://miranda-40b79.s3.amazonaws.com/images/imag-SHR7Lrk79pLwaT3TVYDa8cs0Bc4.jpg",
        "size": 294855,
        "fileType": "image/png",
        "width": 565,
        "height": 577,
        "scaling": 1
      },
      {
        "id": "acgi-OrLwH67OjZ3ZEKND8tejcrYaXcw",
        "value": "false_",
        "type": "gif",
        "imageType": "tenor",
        "url": "https://media.tenor.com/images/66ddc894edd61d05e4f4dba2f7ea67c8/tenor.gif",
        "size": 239759,
        "fileType": "",
        "width": 220,
        "height": 123,
        "scaling": 1
      },
      {
        "id": "acgi-RgcfSCjoBEBhHSvaD8cvCYVczgw",
        "value": "false_",
        "type": "gif",
        "imageType": "tenor",
        "url": "https://media.tenor.com/images/12290bf618f67df6cba3b13b2860b826/tenor.gif",
        "size": 60666,
        "fileType": "",
        "width": 220,
        "height": 167,
        "scaling": 1
      },
      {
        "id": "acgi-Q1y1jyNp87Nlr0ECpYBHwBwcnrs",
        "value": "true_",
        "type": "gif",
        "imageType": "tenor",
        "url": "https://media.tenor.com/images/ab64e98a29275e950d84221ea3100828/tenor.gif",
        "size": 173633,
        "fileType": "",
        "width": 220,
        "height": 121,
        "scaling": 1
      }
    ]
  }
}