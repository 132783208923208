






import {Component, Vue} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import {eventbus} from "@/main"
import {EventType, SidePanelViewModel} from "@/components/app/eventModel"
import Container from "@/components/fundamental/layout/Container.vue"
import Simulator from "@/components/simulator/Simulator.vue"
import dummy_unit_6_screens_6_recordings from '../dummy_data/units/dummy_unit_6_screens_6_recordings'

@Component({
  components: {Simulator, Container, Row}
})
export default class TestSimulator_mobileUnit extends Vue {

  combinedUnit = dummy_unit_6_screens_6_recordings

  mounted() {
    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(false))
  }
}
