import {ActionType} from "@/models/action/model_action"
import {ScreenModel} from "@/models/screen/model_screen"
import {TableActionModel} from "@/models/action/model_action_table"

export default <ScreenModel>{
  id: "scre",
  title: "Test Action Table",
  statics: [],
  action: <TableActionModel>{
    type: ActionType.table,
    responses: [],
    headerRow: ["$$\\mathbb{N}$$", "$$\\mathbb{Z}$$", "$$\\mathbb{R}$$"],
    headerColumn: ["$$1$$", "$$\\minus 3$$", "$$\\pi$$"],
    columnWidths: [100, 0, 0, 0],
    targetValues: [true, true, true, false, true, true, false, false, true],
  },
}