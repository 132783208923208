






















































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"

@Component({
  components: {InputField, TransitionEffect}
})
export default class SelectField extends Vue {
  @Prop({type: Array, required: true}) values!: Array<any>
  @Prop({type: Array, required: true}) labels!: Array<string>
  @Prop({default: '', required: false}) defaultValue!: any

  @Prop({type: Boolean, default: false, required: false}) isNotEnum!: boolean
  @Prop({type: Boolean, default: false, required: false}) isInput!: boolean
  @Prop({type: Boolean, default: false, required: false}) borderBottomOnly!: boolean
  @Prop({type: Number, default: 0, required: false}) width!: number
  @Prop({type: Number, required: false, default: 16}) fontSize!: number

  @Prop({type: Boolean, default: false, required: false}) forceToTop!: boolean

  listWidth = 0
  inputWidth = 0
  showList = false
  modifiedValues: Array<any> = []
  modifiedLabels: Array<string> = []
  selectedIndex = -1
  modifiedSelectedIndex = -1
  lastSelectedIndex = -1
  inputValue = ''

  get selectedLabel() {
    return this.selectedIndex >= 0 ? this.labels[this.selectedIndex] : '--'
  }

  get fontSizeStyle() {
    return 'font-size:' + this.fontSize + 'px;'
  }

  get textHeightStyle() {
    return 'height:' + this.fontSize/16*28 + 'px;'
  }

  /////////////////////////////////
  // Life Cycle
  /////////////////////////////////
  created() {
    // calculate width of select
    this.calculateWidths()

    // define initial index
    this.defaultValueChanged()
  }

  @Watch('labels')
  labelsChanged() {
    this.calculateWidths()
  }

  @Watch('values')
  valuesChanged() {
    this.selectedIndex = this.values.findIndex(value => value === this.defaultValue)
  }

  @Watch('defaultValue')
  defaultValueChanged() {
    if (typeof this.defaultValue === 'number' && !this.isNotEnum) {
      this.selectedIndex = this.defaultValue
      this.modifiedSelectedIndex = this.defaultValue
    } else {
      this.selectedIndex = this.values.findIndex(value => value === this.defaultValue)
      this.modifiedSelectedIndex = this.selectedIndex
    }
  }

  /////////////////////////////////
  // Methods
  /////////////////////////////////
  openList() {
    if (!this.showList && this.labels.length > 0) {
      this.lastSelectedIndex = this.selectedIndex
      this.modifiedSelectedIndex = this.selectedIndex
      this.showList = true
      this.modifyList('')

      // scroll to selected item
      this.$nextTick(function () {
        let list = <HTMLDivElement>this.$refs.selectList
        let listHeight = list.offsetHeight
        let elementHeight = (<HTMLDivElement>list.firstChild).offsetHeight
        list.scrollTop = this.selectedIndex * elementHeight + elementHeight - listHeight / 2
      })
    }
  }

  closeList() {
    if (this.showList) {
      this.showList = false
      if (this.selectedIndex >= 0) {
        if (typeof this.defaultValue === 'number' && !this.isNotEnum) {
          this.$emit('selected', this.selectedIndex)
        } else {
          this.$emit('selected', this.values[this.selectedIndex])
        }
      }
    }
  }

  toggleList() {
    if (this.showList) {
      this.closeList()
    } else {
      this.openList()
    }
  }

  modifyList(input: string) {
    this.inputValue = input
    this.modifiedLabels = []
    this.modifiedValues = []

    for (let i = 0; i < this.labels.length; i++) {
      let trimLabel = this.labels[i].toLowerCase().trim()
      if (trimLabel.includes(input.toLowerCase().trim())) {
        this.modifiedLabels.push(this.labels[i])
        this.modifiedValues.push(this.values[i])
      }
    }

    this.modifiedSelectedIndex = this.modifiedLabels.findIndex(item => item === this.selectedLabel)
  }

  selectItem(value: any) {
    this.selectedIndex = this.values.findIndex(item => item === value)
    this.closeList()
  }

  calculateWidths() {
    if (this.width > 0) {
      this.listWidth = this.width
      return
    }

    let maxLetters = 3
    for (let label of this.labels) {
      maxLetters = Math.max(label.length, maxLetters)
    }

    let arrowWidth = 24
    let marginWidth = 2 * 4
    let letterWidth = 7 + 7 * 5 / Math.max(5, maxLetters)
    let inputBias = 4
    this.listWidth = maxLetters * letterWidth + arrowWidth + marginWidth
    if (this.isInput) {
      this.inputWidth = maxLetters * letterWidth + inputBias
      this.listWidth += inputBias
    }

    this.listWidth = this.listWidth/16 * this.fontSize
  }
}
