export default {
  calcDocSize(json: any): number {
    let size = 0
    for (let key in json) {
      if (!Object.hasOwnProperty.call(json, key)) continue

      size += key.length + 1
      size += this.calcValueSize(json[key])
    }
    return size + 32;
  },

  calcValueSize(value: any): number {

    if (value === null) {
      // null
      return 1

    } else if (typeof value === 'object' && value.hasOwnProperty("_lat") && typeof value.isEqual === "function") {
      // GeoPoint
      return 16

    } else if (typeof value === 'object' && (typeof value.toDate === "function" || typeof value.getDate === "function")) {
      // date
      return 8

    } else if (typeof value === 'object' && Array.isArray(value)) {
      let size = 0
      for (let item of value) {
        size += this.calcValueSize(item)
      }
      return size

    } else if (typeof value === 'object') {
      return this.calcDocSize(value)

    } else if (typeof value === 'boolean') {
      return 1

    } else if (typeof value === 'number') {
      return 8

    } else if (typeof value === 'string') {
      return value.length + 1

    } else {
      throw('Error - undefined type: ${value.runtimeType}');
    }
  }
}
