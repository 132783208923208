


































import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Link from "@/components/fundamental/text/Link.vue"
import store from "@/store/store"

@Component({
  components: {Link, TextField, Row, Column}
})
export default class Footer extends Vue {
  appStore = store.app
}
