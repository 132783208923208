
































































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import appState from "@/app/state/app_state"
import MirandaChapters from "@/pages/curriculum_viewer/courses/MirandaChapters.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import {SkillsModel, TopicModel} from "@/models/curriculum/model_course"
import InlineColorSelector from "@/components/screen_editor/content/color/InlineColorSelector.vue";
import ColorPreview from "@/components/screen_editor/content/color/ColorPreview.vue";
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue";
import AdvancedSelect from "@/components/fundamental/advanced_inputs/AdvancedSelect.vue";
import AdvancedEditNumber from "@/components/fundamental/advanced_inputs/AdvancedEditNumber.vue"

@Component({
  components: {
    AdvancedEditNumber,
    AdvancedSelect,
    AdvancedEditText,
    ColorPreview,
    InlineColorSelector,
    InputField, MirandaChapters, TextButton, SpacerBox, Container, RoundIconButton, Row, TextField, Column}
})
export default class MirandaTopic extends Vue {
  @Prop({type: Object, required: true}) topic!: TopicModel
  @Prop({type: Number, required: true}) topicIndex!: number
  @Prop({type: Array, required: false, default: []}) skills!: Array<SkillsModel>

  curriculumState = appState.curriculum
  contentVisible: boolean = !!this.curriculumState.mirandaTopicsVisible.get(this.topic.id)

  toggleContentVisible() {
    this.contentVisible = !this.contentVisible
    this.curriculumState.mirandaTopicsVisible.set(this.topic.id, this.contentVisible)
  }
}
