import {ContentModel, ContentType} from "@/models/content/model_content"

export interface SpacerModel extends ContentModel {
  height: number
}

export default {
  template(): SpacerModel {
    return {
      type: ContentType.spacer,
      height: 100,
    }
  },
}