export default {
  "id": "scre-c9sharCvTqHLc0xpaTzIQEnAhBU",
  "title": "Test Screen Gif",
  "statics": [
    {
      "id": "scgi-3S3zb47IDwGNJEPO489QUSvcpaU",
      "type": "gif",
      "imageType": "tenor",
      "url": "https://media.tenor.com/images/5b6d23ff55b8d24593ea0994dbcad63e/tenor.gif",
      "size": 65676,
      "fileType": "",
      "width": 220,
      "height": 98,
      "scaling": 1
    }
  ],
  "initialVideoId": "reco-PtMqRufnxxaRbiUUI5LhIUtP4Tk-3",
}