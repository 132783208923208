






import {Component, Vue} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import firebase from 'firebase/app'
import 'firebase/firestore'
import dbHelper from "@/util/basic/dbHelper"
import params from "@/app/params"
import {ContentModel, ContentType} from "@/models/content/model_content"
import algoliasearch from "algoliasearch"
import {UnitModel} from '@/models/unit/model_unit'
import model_unit_preview from "@/models/unit/model_unit_preview"
import {ActionType} from "@/models/action/model_action"
import {McActionModel} from "@/models/action/model_action_mc"
import {AlgoliaUnitModel} from "@/models/search/model_unit_algolia"
import {ScreenModel} from "@/models/screen/model_screen"
import {AlgoliaScreenModel} from "@/models/search/model_screen_algolia"
import {TextModel} from "@/models/content/model_text"
import {DragConnectActionModel} from "@/models/action/model_action_drag_connect"
import {DragTextActionModel} from "@/models/action/model_action_drag_text"
import {InputActionModel} from "@/models/action/model_action_input"
import {TableModel} from "@/models/content/model_table"

@Component({
  components: {TextButton, Row}
})
export default class TestFirestore_code extends Vue {

  async run() {


    // let a = {
    //   'created': firebase.firestore.FieldValue.serverTimestamp(),
    //   'users': 'user',
    // }
    //
    // console.log(JSON.stringify(a))

    // let doc = await firebase.firestore().collection(params.firestore.units).doc('un_XKYsBhr1CW').get()
    // let unit = <UnitModel>dbHelper.docToJson(doc)
    // let a = this.mapToAlgoliaUnit(unit)


    // // startAfter, startAt must be the same field as the field used in orderBy
    // let query = firebase.firestore().collection(params.firestore.units).orderBy('id')
    //
    // let batchSize = 200
    //
    // let lastId = ''
    // let totalNumDocs = 0
    //
    // while (true) {
    //   let querySnapshot
    //
    //   if (lastId) {
    //     querySnapshot = await query.startAfter(lastId).limit(batchSize).get()
    //   } else {
    //     querySnapshot = await query.limit(batchSize).get()
    //   }
    //
    //   let numDocs = querySnapshot.size
    //   totalNumDocs += numDocs
    //   console.log('batch docs', numDocs, 'total docs:', totalNumDocs, lastId)
    //
    //   if (numDocs == batchSize) {
    //     lastId = querySnapshot.docs[numDocs-1].id
    //   } else {
    //     break
    //   }
    // }
    //
    // console.log('RESULT - num docs:', totalNumDocs)
    // console.log('done')


  }

  mapToAlgoliaUnit(unit: UnitModel) {

    let screens = []
    for (let screen of unit.screens) {
      screens.push(this.mapToAlgoliaScreen(screen))
    }

    let result: AlgoliaUnitModel = {
      'id': unit['id'],
      'type': unit['type'],
      'status': unit['status'],
      'rank': unit['rank'],
      'curriculumId': unit['curriculumId'],
      'title': this.cleanText(unit['title']),
      screens: screens
    }

    if (unit.level) {
      result['level'] = unit.level
    }

    if (unit.searchTerms) {
      result['searchTerms'] = unit.searchTerms
    }

    if (unit.refId) {
      result['refId'] = unit.refId
    }

    return result
  }

  mapToAlgoliaScreen(screen: ScreenModel) {

    let staticsText = ''
    for (let stat of screen.statics) {

      if (stat.type === 1) { // text
        staticsText += this.cleanText((stat as TextModel).text, true, true)

      } else if (stat.type === 8) { // table
        let table = (stat as TableModel)
        for (let row of table.rows) {
          for (let cell of row.cells) {
            let r = this.cleanText(cell, false, true)
            if (!/^-?\d*\.?\d*$/.test(r)) {
              staticsText += r + ' '
            }
          }
        }
      }

    }

    let actionText = ''
    if (screen.action) {
      let action = screen.action

      if (action.type === 'dragConnect') {
        let a = (action as DragConnectActionModel)

        for (let field of a.startFields) {
          if (field.content.type === 1) {
            let t = field.content as TextModel
            actionText += this.cleanText(t.text, true, true)
          }
        }

        for (let field of a.endFields) {
          if (field.content.type === 1) {
            let t = field.content as TextModel
            actionText += this.cleanText(t.text, true, true)
          }
        }
      }

      if (action.type === 'dragText') {
        let a = (action as DragTextActionModel)

        for (let text of a.targetTexts) {
          actionText += this.cleanText(text, false, true) + ' '
        }

        for (let field of a.fields) {
          for (let textField of field.itemTexts) {
            actionText += this.cleanText(textField, true, true)
          }
        }
      }

      if (action.type === 'input') {
        let a = (action as InputActionModel)

        for (let input of a.inputs) {
          if (input.text) {
            actionText += this.cleanText(input.text.text, true, true)
          }

          if (input.selection) {
            for (let value of input.selection) {
              actionText += this.cleanText(value, true, true)
            }
          }
        }
      }

      if (action.type === 'mc') {
        let a = (action as McActionModel)

        for (let answer of a.answers) {
          if (answer.content.type === 1) {
            let t = answer.content as TextModel
            actionText += this.cleanText(t.text, true, true)
          }
        }
      }
    }

    let s: AlgoliaScreenModel = {
      'title': this.cleanText(screen.title),
    }

    if (screen.subtitle) {
      s['subtitle'] = this.cleanText(screen.subtitle)
    }

    if (staticsText.length > 0) {
      s['staticsText'] = this.removeDoubleSpaces(staticsText.trim())
    }

    if (actionText.length > 0) {
      s['actionText'] = this.removeDoubleSpaces(actionText.trim())
    }

    if (screen.action) {
      s['actionType'] = screen.action.type
    }

    return s
  }

  cleanText(text: string, addPeriod = false, removeLinebreaks = false) {
    text = text.replace(/##(.*?)##/g, '$1')     // bold
    text = text.replace(/§§(.*?)§§/g, '$1')     // italic
    text = text.replace(/%%(.*?)%%/g, '$1')     // emp
    text = text.replace(/\$\$(.*?)\$\$/g, '')   // $$x$$ -> Latex
    text = text.replace(/\*\*(.*?)\*\*/g, '$1') // number

    text = text.replace(/\[f](.*?)\[\/f]/g, '') // [f] -> centered formulas
    text = text.replace(/\[a href=.*?](.*?)\[\/a]/g, '$1') // [a] -> urls
    text = text.replace(/\[(\w+?)](.*?)\[\/\1]/g, '$2') // [x] -> other tags

    text = text.replace(/\?\?(.*?)\?\?/g, '') // ??0,1?? -> DragText

    text = text.replace(/\n/g, ' ') // \n
    text = this.removeDoubleSpaces(text) // remove double spaces

    text = text.trim()

    // remove linebreaks
    // \w: a-z, A-Z, 0-9
    // [a-z\u00e4\u00f6\u00fc]: a-z, ä, ö, ü
    if (removeLinebreaks) {
      text = text.replace(/(\w[a-z\u00e4\u00f6\u00fc])-([a-z\u00e4\u00f6\u00fc]{2,})/g, '$1$2')
    }

    if (addPeriod && text.length > 0) {
      if (text.endsWith('.')) {
        text = text.slice(0, -1)
      }
      text += '. '
    }

    return text
  }

  removeDoubleSpaces(text: string) {
    return text.replace(/\s\s+/g, ' ') // remove double spaces
  }

  getAllUsedVideos(unit: UnitModel): Array<string> {
    let videoIds: Array<string> = []
    for (let screen of unit.screens) {

      // initial video
      if (screen.initialVideoId) {
        videoIds.push(screen.initialVideoId)
      }

      // content
      for (let item of screen.statics) {
        if (item.videoId) {
          videoIds.push(item.videoId)
        }
      }

      if (screen.action) {
        for (let response of screen.action.responses) {
          if (response.videoId) {
            videoIds.push(response.videoId)
          }
        }
      }

      // mc
      if (screen.action && screen.action.type === ActionType.mc) {
        for (let answer of (<McActionModel>screen.action).answers) {
          if (answer.videoId) {
            videoIds.push(answer.videoId)
          }
        }
      }

    }

    return videoIds
  }

  async copyAllDocs(sourceCol: string, targetCol: string) {
    console.log('COPY FROM ' + sourceCol + ' TO ' + targetCol)
    let db = firebase.firestore()

    // startAfter, startAt must be the same field as the field used in orderBy
    const query = firebase.firestore().collection(sourceCol).orderBy('id')

    let batchSize = 10

    let lastId = ''
    let totalCount = 0
    let c = 0
    for (let i = 0; i < 1000; i++) {
      let querySnapshot = lastId ? await query.startAfter(lastId).limit(batchSize).get() : await query.limit(batchSize).get()
      let numDocs = querySnapshot.size
      totalCount += numDocs

      console.log('FROM:', sourceCol, 'TO:', targetCol, 'count:', totalCount)


      let batch = db.batch()
      let json: Array<Object> = querySnapshot.docs.map(doc => dbHelper.docToJson(doc))
      for (let i = 0; i < json.length; i++) {
        // @ts-ignore
        batch.set(db.collection(targetCol).doc(json[i].id), dbHelper.jsonToFirestoreJson(json[i]))
        console.log('doc', c++)
      }
      await batch.commit()


      if (numDocs === batchSize) {
        lastId = querySnapshot.docs[numDocs-1].id
      } else {
        break
      }
    }

    console.log('done')
  }

  async countDocsInCollection(collection: string) {
    console.log('COUNT DOCS IN ' + collection)
    // startAfter, startAt must be the same field as the field used in orderBy
    const query = firebase.firestore().collection(collection).orderBy('id')

    let batchSize = 200

    let lastId = ''
    let totalCount = 0
    while (true) {
      let querySnapshot = lastId ? await query.startAfter(lastId).limit(batchSize).get() : await query.limit(batchSize).get()
      let numDocs = querySnapshot.size
      totalCount += numDocs

      if (numDocs === batchSize) {
        lastId = querySnapshot.docs[numDocs-1].id
      } else {
        break
      }
    }

    console.log('collection: ' + collection + ' -- num docs: ' + totalCount)
    return totalCount
  }

  async buildUnitPreviews(curriculumId: string) {
    let querySnapshot = await firebase.firestore()
      .collection(params.firestore.units)
      .where('curriculumId', '==', curriculumId)
      .get()

    let units: Array<UnitModel> = querySnapshot.docs.map(doc => dbHelper.docToJson(doc))
    console.log(units.length)

    let data = {}
    for (let i = 0; i < units.length; i++) {
      let unit = units[i]
      // @ts-ignore
      data[unit.id] = model_unit_preview.template_unit(unit)
    }

    console.log(data)
    await firebase.firestore().collection(params.firestore.unitPreviews)
      .doc(curriculumId).set(data)

    console.log('done')
  }

  async syncCollectionWithAlgolia() {
    let querySnapshot = await firebase.firestore().collection(params.firestore.units).limit(3000).get()

    let records = []
    for (let doc of querySnapshot.docs) {
      let data = doc.data()
      data.objectID = doc.id
      records.push(data)
    }

    console.log('records:', records.length)

    // TODO get key from https://www.algolia.com/apps/IKO82643G9/api-keys/restricted -> cloud_function_sync
    let client = algoliasearch('IKO82643G9', 'apiKey')
    let algoliaIndex = client.initIndex('units');

    for (let i = 0; i < records.length; i+=100) {
      console.log(i)
      try {
        await algoliaIndex.saveObjects(records.slice(i, Math.min(i+100, records.length)))
      } catch (error) {
        console.error('Error when importing contact into Algolia', error);
      }
    }
  }
}
