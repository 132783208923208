





























































import {Component, Prop, Vue} from "vue-property-decorator"

@Component
export default class TextButton extends Vue {
  @Prop({type: String, required: true}) title!: string
  @Prop({type: Boolean, default: false, required: false}) primary!: boolean
  @Prop({type: Boolean, default: false, required: false}) secondary!: boolean
  @Prop({type: Boolean, default: false, required: false}) green!: boolean
  @Prop({type: Boolean, default: false, required: false}) red!: boolean
  @Prop({type: Boolean, default: false, required: false}) blue!: boolean
  @Prop({type: Boolean, default: false, required: false}) yellow!: boolean
  @Prop({type: Boolean, default: false, required: false}) gray!: boolean
  @Prop({type: Boolean, default: false, required: false}) disabled!: boolean
  @Prop({type: Boolean, default: false, required: false}) selected!: boolean
  @Prop({type: Boolean, default: false, required: false}) small!: boolean
  @Prop({type: Boolean, default: false, required: false}) smaller!: boolean
  @Prop({type: Boolean, default: false, required: false}) smallest!: boolean
}
