export default class IntermediateTimer {

  /////////////////////////////////
  // CONST
  /////////////////////////////////
  static FRAME_DURATION = 0.040


  /////////////////////////////////
  // Members
  /////////////////////////////////
  _duration: number
  _timeCallback: (n: number) => void

  _playing = false
  _time = 0
  _speed = 1
  _timer = 0
  _stopped = false

  /////////////////////////////////
  // CONSTRUCTOR
  /////////////////////////////////
  constructor(duration: number, timeCallback: (time: number) => void) {
    this._duration = duration
    this._timeCallback = timeCallback
  }


  /////////////////////////////////
  // PUBLIC
  /////////////////////////////////
  updateState(playing: boolean, time: number) {
    this._playing = playing
    this._time = time
    this._run()
  }

  setSpeed(speed: number) {
    this._speed = speed
  }

  destroy() {
    clearTimeout(this._timer)
    this._stopped = true
  }


  /////////////////////////////////
  // PRIVATE
  /////////////////////////////////
  _run() {
    clearTimeout(this._timer)
    if (this._time < this._duration && this._playing && !this._stopped) {
      this._time = Math.min(this._time + IntermediateTimer.FRAME_DURATION * this._speed, this._duration)

      this._timer = setTimeout(() => {
        this._timeCallback(this._time)
        this._run()
      }, IntermediateTimer.FRAME_DURATION * 1000)
    }
  }
}