import firebase from 'firebase/app'
import 'firebase/firestore'
import ChangeNotifier from "@/util/basic/change_notifier"
import params from "@/app/params"
import {SoundCatalogModel} from "@/models/sound/model_sound"
import QuerySnapshot = firebase.firestore.QuerySnapshot
import DocumentData = firebase.firestore.DocumentData


export default class SoundsStore extends ChangeNotifier {
  _soundCatalogs: Array<SoundCatalogModel> = []
  _soundsUnsubscribe: any = undefined

  async init() {
    await this.subscribeToSounds()
  }

  /////////////////////////////////
  // Getters
  /////////////////////////////////
  getMainCatalog(): SoundCatalogModel | undefined {
    return this._soundCatalogs.find(e => e.id === 'main')
  }

  getCatalog(id: string): SoundCatalogModel | undefined {
    return this._soundCatalogs.find(e => e.id === id)
  }


  /////////////////////////////////
  // Sounds
  /////////////////////////////////
  async subscribeToSounds(): Promise<void> {
    return new Promise((resolve, reject) => {

      if (this._soundsUnsubscribe) {
        resolve()
        return
      }

      this._soundsUnsubscribe = firebase.firestore().collection(params.firestore.sounds)
        .onSnapshot(
          (querySnapshot: QuerySnapshot<DocumentData>) => {
            this._soundCatalogs = <Array<SoundCatalogModel>>querySnapshot.docs.map(doc => doc.data())
            this.notify()
            resolve()
          },

          error => reject(error)
        )
    })
  }

  async saveCatalog(catalog: SoundCatalogModel) {
    await firebase.firestore()
      .collection(params.firestore.sounds)
      .doc(catalog.id)
      .set(catalog)
  }

  async deleteCatalog(id: string) {
    await firebase.firestore()
      .collection(params.firestore.sounds)
      .doc(id)
      .delete()
  }
}