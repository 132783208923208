



















import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Draggable from "@/components/fundamental/layout/Draggable.vue"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import {eventbus} from "@/main"
import {AlertDialogType, EventType} from "@/components/app/eventModel"
import util from "@/util/util"
import OfficialTopicElement from "./OfficialTopicElement.vue"
import model_curriculum, {CurriculumModel} from "@/models/curriculum/model_curriculum"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"

@Component({
  components: {TextField, Row, OfficialTopicElement, Container, AddButton, Draggable, Column}
})
export default class OfficialTopics extends Vue {
  @Prop({type: Object, required: true}) curriculum!: CurriculumModel

  addTopic() {
    this.curriculum.to.push(model_curriculum.template_officialTopic())
  }

  requestDeletingTopic(index: number) {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.delete_low,
      title: `Delete topic`,
      text: `Delete the topic: <b>${this.curriculum.to[index].ti}</b>?<br><br> You can restore it by resetting the curriculum. Changes will be permanent if you save the curriculum.`,
      callback: (response: boolean) => {
        if (response) this.deleteTopic(index)
      }
    })
  }

  deleteTopic(index: number) {
    util.removeFromArray(this.curriculum.to, index)
  }
}
