import {ActionModel, ActionType} from "@/models/action/model_action"
import model_response from "@/models/action/model_response"

export interface TableActionModel extends ActionModel {
  headerRow: Array<String>
  headerColumn: Array<String>
  columnWidths: Array<number>
  targetValues: Array<boolean>
}

export default {
  template_action(): TableActionModel {
    return {
      type: ActionType.table,
      responses: model_response.template_responses(),
      headerRow: [''],
      headerColumn: [''],
      columnWidths: [0],
      targetValues: [false],
    }
  },
}