var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Container',[(!_vm.unit)?_c('Container',{staticClass:"unit-chip empty"},[_c('TextField',{staticClass:"p-lr-8 p-t-4 p-b-8",attrs:{"size":12}},[_vm._v("...")])],1):_c('Container',{staticClass:"unit-chip",class:{
    'empty': _vm.unit.st === _vm.unitStatus.empty,
    'dev': _vm.unit.st === _vm.unitStatus.dev,
    'rec': _vm.unit.st === _vm.unitStatus.archive,
    'wait-rev': _vm.unit.st === _vm.unitStatus.waitForReview,
    'reviewed': _vm.unit.st === _vm.unitStatus.reviewed,
    'wait-app': _vm.unit.st === _vm.unitStatus.waitForApproval,
    'approved': _vm.unit.st === _vm.unitStatus.approved,
    'error': _vm.unit.er
  },nativeOn:{"click":function($event){return _vm.$emit('open')}}},[_c('Row',{staticClass:"unit-button",attrs:{"center":"","middle":""}},[_c('TextField',{staticClass:"m-r-4",attrs:{"size":12}},[_vm._v(_vm._s(_vm.getTitle(_vm.unit)))]),(_vm.unit.le !== undefined)?_c('TextField',{staticClass:"level",class:_vm.unitLevelValues[_vm.unit.le],attrs:{"title":'Level: '+_vm.unitLevelValues[_vm.unit.le]}},[_vm._v(" "+_vm._s(_vm.unitLevelValues[_vm.unit.le][0].toUpperCase())+" ")]):_vm._e(),_c('RoundIconButton',{attrs:{"title":"Editor","smaller":"","white":"","icon":"IconPencil"},on:{"clicked":_vm.openUnitEditor}}),(!_vm.hideRemoveButton)?_c('RoundIconButton',{attrs:{"title":"Remove","smaller":"","white":"","icon":"IconClose"},on:{"clicked":function($event){return _vm.$emit('remove')}}}):_vm._e(),(_vm.hideRemoveButton)?_c('SpacerBox',{attrs:{"width":2}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }