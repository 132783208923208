import {ContentType} from "@/models/content/model_content"
import {ActionType} from "@/models/action/model_action"
import {ImageType} from "@/models/content/model_image"
import {AnswerValue} from "@/models/action/model_action_mc"
import {ScreenModel} from "@/models/screen/model_screen"
import {TextModel} from "@/models/content/model_text"
import {ResponseType} from "@/models/action/model_response"

export default <ScreenModel>{
  id: "scre",
  title: "Test Action Mc",
  statics: [
    <TextModel>{
      type: ContentType.text,
      text: "Von wem stammt die berühmte Gleichung E=mc²?",
      size: 18,
    }
  ],
  action: {
    type: ActionType.mc,
    responses: [
      {
        type: ResponseType.tip,
      }
    ],
    fieldWidth: 260,
    maxFieldHeight: 120,
    answers: [
      {
        value: AnswerValue.false,
        content: {
          type: ContentType.gif,
          image: {
            imageType: ImageType.tenor,
            url: "https://media.tenor.com/images/607529d099ecb88935e390ba3805dc8a/tenor.gif",
            size: 75017,
            fileType: "",
            width: 220,
            height: 124,
            scaling: 1
          }
        }
      },
      {
        value: AnswerValue.true,
        content: {
          type: ContentType.image,
          image: {
            imageType: ImageType.image,
            url: "https://miranda-40b79.s3.amazonaws.com/images/imag-SHR7Lrk79pLwaT3TVYDa8cs0Bc4.jpg",
            size: 294855,
            fileType: "image/png",
            width: 565,
            height: 577,
            scaling: 1
          }
        }
      },
      {
        value: AnswerValue.false,
        content: {
          type: ContentType.gif,
          image: {
            imageType: ImageType.tenor,
            url: "https://media.tenor.com/images/66ddc894edd61d05e4f4dba2f7ea67c8/tenor.gif",
            size: 239759,
            fileType: "",
            width: 220,
            height: 123,
            scaling: 1
          }
        }
      },
      {
        value: AnswerValue.false,
        content: {
          type: ContentType.text,
          text: {
            text: "Sebastian Kurz",
            size: 18,
          }
        }
      },
      {
        value: AnswerValue.false,
        content: {
          type: ContentType.text,
          text: {
            text: "Donald Duck",
            size: 18,
          }
        }
      }
    ]
  }
}