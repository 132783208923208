
































































import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import EditText from "@/components/fundamental/inputs/EditText.vue"
import EditNumber from "@/components/fundamental/inputs/EditNumber.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import ActionSectionHeader from "@/components/basic/ActionSectionHeader.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import util from "@/util/util"
import LineChartEditor from "@/components/screen_editor/content/chart/line_chart/LineChartEditor.vue"
import AdvancedCheckbox from "@/components/fundamental/advanced_inputs/AdvancedCheckbox.vue"
import AdvancedEditNumber from "@/components/fundamental/advanced_inputs/AdvancedEditNumber.vue"
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue"
import {DragBoxActionModel} from "@/models/action/model_action_drag_box"
import BoxPlotEditor from "@/components/screen_editor/content/chart/box_plot/BoxPlotEditor.vue"


@Component({
  components: {
    BoxPlotEditor,
    AdvancedEditText,
    AdvancedEditNumber,
    AdvancedCheckbox,
    LineChartEditor,
    RoundIconButton,
    ActionSectionHeader, SpacerBox, TextField, EditNumber, EditText, AddButton, Row, Column
  }
})
export default class DragBoxActionEditor extends Vue {
  @Prop({type: Object, required: true}) action!: DragBoxActionModel

  oldPercentiles = util.copyArray(this.action.targetPercentiles)

  @Watch('action.targetPercentiles', {deep: true})
  percentilesChanged() {
    if (this.action.targetPercentiles[0] != this.oldPercentiles[0] && this.action.targetPercentiles[0] > this.action.targetPercentiles[1]) {
      this.$set(this.action.targetPercentiles, 0, this.oldPercentiles[0])

    } else if (this.action.targetPercentiles[1] != this.oldPercentiles[1] && (this.action.targetPercentiles[1] > this.action.targetPercentiles[2] || this.action.targetPercentiles[1] < this.action.targetPercentiles[0])) {
      this.$set(this.action.targetPercentiles, 1, this.oldPercentiles[1])

    } else if (this.action.targetPercentiles[2] != this.oldPercentiles[2] && (this.action.targetPercentiles[2] > this.action.targetPercentiles[3] || this.action.targetPercentiles[2] < this.action.targetPercentiles[1])) {
      this.$set(this.action.targetPercentiles, 2, this.oldPercentiles[2])

    } else if (this.action.targetPercentiles[3] != this.oldPercentiles[3] && (this.action.targetPercentiles[3] > this.action.targetPercentiles[4] || this.action.targetPercentiles[3] < this.action.targetPercentiles[2])) {
      this.$set(this.action.targetPercentiles, 3, this.oldPercentiles[3])

    } else if (this.action.targetPercentiles[4] != this.oldPercentiles[4] && this.action.targetPercentiles[4] < this.action.targetPercentiles[3]) {
      this.$set(this.action.targetPercentiles, 4, this.oldPercentiles[4])
    }

    this.oldPercentiles = util.copyArray(this.action.targetPercentiles)
  }

}
