

























import {Component, Prop, Vue} from "vue-property-decorator"
import model_curriculum, {CurriculumModel, OfficialSectionModel} from '@/models/curriculum/model_curriculum'
import Row from "@/components/fundamental/layout/Row.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import Draggable from "@/components/fundamental/layout/Draggable.vue"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import OfficialSectionElement from "@/pages/curriculum_editor/official/OfficialSectionElement.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import {eventbus} from "@/main"
import {AlertDialogType, EventType} from "@/components/app/eventModel"
import util from "@/util/util"
import OfficialSubsectionElement from "@/pages/curriculum_editor/official/OfficialSubsectionElement.vue"

@Component({
  components: {OfficialSubsectionElement, Container, OfficialSectionElement, AddButton, Draggable, Column, Row}
})
export default class OfficialSubsections extends Vue {
  @Prop({type: Object, required: true}) curriculum!: CurriculumModel
  @Prop({type: Object, required: true}) section!: OfficialSectionModel
  @Prop({type: String, required: true}) topicPrefix!: string
  @Prop({type: Number, required: true}) chapterIndex!: number
  @Prop({type: Number, required: true}) sectionIndex!: number

  addSubsection() {
    this.section.su.push(model_curriculum.template_officialSubsection())
  }

  requestDeletingSubsection(index: number) {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.delete_low,
      title: `Delete subsection`,
      text: `Delete the subsection: <b>${this.section.su[index].ti}</b>?<br><br> You can restore it by resetting the curriculum. Changes will be permanent if you save the curriculum.`,
      callback: (response: boolean) => {
        if (response) this.deleteSubsection(index)
      }
    })
  }

  deleteSubsection(index: number) {
    util.removeFromArray(this.section.su, index)
  }
}
