import {ColorModel} from "@/models/model_colors"
import {ChartTextModel} from "@/models/content/charts/model_chart_text"


export interface ChartPointModel {
  position: Array<number>
  label?: ChartTextModel
  color: ColorModel
  size: number
  showLine_x: boolean
  showLine_y: boolean
  lineWidth: number
  asCircle?: boolean
  borderWidth?: number
  startAngle?: number
  endAngle?: number
  closeArc?: boolean
}

export default {
  template(): ChartPointModel {
    return {
      position: [0, 0],
      color: ColorModel.cyan,
      size: 3,
      showLine_x: false,
      showLine_y: false,
      lineWidth: 2,
    }
  },
}