import Player from "@vimeo/player"

export default class SimpleVimeoPlayer {

  constructor(url, width, containerId) {
    let options = {url: url, width: width, controls: false, loop: false}
    this._player = new Player(containerId, options)
  }

  destroy() {
    return this._player.destroy()
  }

  on(event, callback) {
    this._player.on(event, callback)
  }

  play() {
    return this._player.play()
  }

  pause() {
    return this._player.pause()
  }

  setCurrentTime(t) {
    return this._player.setCurrentTime(t)
  }

  setPlaybackRate(s) {
    return this._player.setPlaybackRate(s)
  }

  setVolume(v) {
    return this._player.setVolume(v)
  }

}