




























import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import ListItemContainer from "@/pages/unit_editor/ListItemContainer.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import {DragConnectFieldModel} from "@/models/action/model_action_drag_connect"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import AdvancedSelect from "@/components/fundamental/advanced_inputs/AdvancedSelect.vue"
import InlineContentEditor from "@/components/screen_editor/content/InlineContentEditor.vue"
import {SizeModel} from "@/models/math/model_size"
import {ContentModel} from "@/models/content/model_content"

@Component({
  components: {
    InlineContentEditor, AdvancedSelect, SelectField, SpacerBox, TextField, ListItemContainer, Row, Column
  }
})
export default class DragConnectField extends Vue {
  @Prop({type: Number, required: true}) index!: number
  @Prop({type: Object, required: true}) field!: DragConnectFieldModel
  @Prop({type: Boolean, required: false, default: false}) isStart!: boolean
  @Prop({type: Number, required: false, default: 0}) numEndField!: number
  @Prop({type: Object, required: true}) fieldSize!: SizeModel

  targetValues: Array<number> = []
  targetLabels: Array<String> = []

  created() {
    this.buildTargetValues()
  }

  @Watch('numEndField')
  numEndFieldChanged() {
    this.buildTargetValues()
  }

  updateTargetValue(value: number) {
    this.$set(this.field, 'target', value)
  }

  updateContent(newContent: ContentModel) {
    this.field.content = newContent
  }

  buildTargetValues() {
    this.targetValues = [-1]
    this.targetLabels = ['none']

    for (let i = 0; i < this.numEndField; i++) {
      this.targetValues.push(i)
      this.targetLabels.push('E' + (i+1))
    }

    if (this.field.target && this.field.target >= this.numEndField) {
      this.updateTargetValue(this.numEndField - 1)
    }
  }
}
