




























import {Component, Prop, Vue} from "vue-property-decorator"
import ListItemContainer from "@/pages/unit_editor/ListItemContainer.vue"
import EditText from "@/components/fundamental/inputs/EditText.vue"
import ImageUploaderWithPreview from "@/components/basic/images/ImageUploaderWithPreview.vue"
import GifSelector from "@/components/basic/images/GifSelector.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import model_image, {ImageType} from "@/models/content/model_image"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import AdvancedSelect from "@/components/fundamental/advanced_inputs/AdvancedSelect.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import {ResponseModel} from "@/models/action/model_response"

@Component({
  components: {
    Container,
    Column, AdvancedSelect, RoundIconButton, SelectField, SpacerBox, Row, TextField, GifSelector, ImageUploaderWithPreview, EditText,
    ListItemContainer
  }
})
export default class ResponseImage extends Vue {
  @Prop({type: Object, required: true}) response!: ResponseModel

  imageTypeValues = ['image', 'gif']
  imageTypeLabels = ['Image', 'Gif']

  get isImage() {
    return this.response.image && this.response.image.imageType === ImageType.image
  }

  get isGif() {
    return this.response.image && this.response.image.imageType !== ImageType.image
  }

  get imageType() {
    if (this.isImage) return 'image'
    if (this.isGif) return 'gif'
    return ''
  }

  updateType(newType: string) {
    if (newType === 'image' && (!this.response.image || this.response.image?.imageType !== ImageType.image)) {
      this.$set(this.response, 'image', model_image.template_image())
    } else if (newType === 'gif' && (!this.response.image || this.response.image?.imageType === ImageType.image)) {
      this.$set(this.response, 'image', model_image.template_gif())
    }
  }

  deleteImage() {
    this.$delete(this.response, 'image')
  }
}
