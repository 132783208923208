




import {Component, Prop, Vue} from "vue-property-decorator"
import ImageEditor from "@/components/screen_editor/content/image/ImageEditor.vue"
import {ImageModel} from "@/models/content/model_image"

@Component({
  components: {ImageEditor}
})
export default class ContentImageEditor extends Vue {
  @Prop({type: Object, required: true}) content!: ImageModel
}
