











































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import util from "@/util/util"
import {UnitModel} from "@/models/unit/model_unit"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import Draggable from "@/components/fundamental/layout/Draggable.vue"
import ScreenEditor from "@/components/screen_editor/ScreenEditor.vue"
import {RecordingModel} from "@/models/recording/model_recording"
import model_screen from "@/models/screen/model_screen"

@Component({
  components: {ScreenEditor, Draggable, AddButton, Row, Column}
})
export default class Demo extends Vue {
  @Prop({type: Object, required: true}) unit!: UnitModel
  @Prop({type: Array, default: [], required: false}) recordings!: Array<RecordingModel>

  activeScreenIndex = -1

  /////////////////////////////////
  // Methods
  /////////////////////////////////
  addScreen(index: number) {
    util.addToArray(this.unit.screens, model_screen.template_screen(), index)
    this.updateActiveScreenIndex(index)
  }

  deleteScreen(index: number) {
    util.removeFromArray(this.unit.screens, index)
    this.updateActiveScreenIndex(-1)
  }

  scrollToScreen(screenId: string) {
    let index = this.unit.screens.findIndex(s => s.id === screenId)
    this.updateActiveScreenIndex(index)

    // this.$nextTick(() => {
    //   window.scrollTo({
    //     // @ts-ignore
    //     top: this.$refs[screenId][0].getDiv().offsetTop - 70,
    //     left: 0,
    //     behavior: 'smooth'
    //   })
    // })
  }

  updateActiveScreenIndex(index: number) {
    this.activeScreenIndex = index
    this.$emit('activeScreenIndex', index)
  }
}
