













































































































import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import Container from "@/components/fundamental/layout/Container.vue"

@Component({
  components: {Container, SpacerBox, TextField, TransitionEffect, Row, Column}
})
export default class ShortcutsDialog extends Vue {
  isVisible = false

  show() {
    this.isVisible = true
  }
}
