






import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Drawingboard from "@/components/recorder/drawingboard/Drawingboard.vue"
import Drawingboard2 from "@/components/recorder/drawingboard/Drawingboard2.vue"

@Component({
  components: {Drawingboard2, Drawingboard, Row, Column}
})
export default class TestRecording_drawingboard extends Vue {

}
