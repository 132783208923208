import {ColorModel} from "@/models/model_colors"
import {ContentModel, ContentType} from "@/models/content/model_content"

export interface TextModel extends ContentModel {
  text: string
  size: number
  color?: ColorModel
  isBold?: boolean
  isItalic?: boolean
  isFormula?: boolean
  version?: number
}

export default {
  template(): TextModel {
    return {
      type: ContentType.text,
      text: '',
      size: 18,
      version: 3
    }
  },
}