import firebase from 'firebase/app'
import 'firebase/firestore'
import store from "./store"
import params from "@/app/params"


export default class DevSettingsStore {

  init() {}

  /////////////////////////////////
  // Action
  /////////////////////////////////
  async sendUnitIdToApp(unitId: string) {
    if (!unitId) return

    let db = firebase.firestore()
    await firebase.firestore().collection(params.firestore.users).doc(store.auth.userId).collection(params.firestore.devSettings)
      .doc(params.firestore.unitToApp).set({unitId: unitId})
  }
}