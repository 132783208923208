



































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import util from "@/util/util"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import ChartParamsEditor from "@/components/screen_editor/content/chart/ChartParamsEditor.vue"
import AdvancedScaling from "@/components/fundamental/advanced_inputs/AdvancedScaling.vue"
import ChartTextEditor from "@/components/screen_editor/content/chart/ChartTextEditor.vue"
import BoxEditor from "@/components/screen_editor/content/chart/box_plot/BoxEditor.vue"
import {XyChartModel} from "@/models/content/charts/model_xy_chart"
import model_chart_box from "@/models/content/charts/model_chart_box"
import model_chart_text from "@/models/content/charts/model_chart_text"
import BasicTextEditorDialog from "@/components/screen_editor/content/text/BasicTextEditorDialog.vue";
import SourceEditorDialog from "@/components/screen_editor/content/SourceEditorDialog.vue"

@Component({
  components: {
    SourceEditorDialog,
    BasicTextEditorDialog,
    BoxEditor, ChartTextEditor, AdvancedScaling, ChartParamsEditor, AddButton,
    Container, TextField, SpacerBox, Row, Column
  }
})
export default class BoxPlotEditor extends Vue {
  @Prop({type: Object, required: true}) boxPlot!: XyChartModel
  @Prop({type: Boolean, required: false, default: false}) hideBoxes!: boolean

  addBox() {
    if (!this.boxPlot.boxes) {
      this.$set(this.boxPlot, 'boxes', [])
    }

    this.boxPlot.boxes!.push(model_chart_box.template())
  }

  deleteBox(index: number) {
    if (!this.boxPlot.boxes) return

    util.removeFromArray(this.boxPlot.boxes, index)
  }

  addText() {
    if (!this.boxPlot.texts) {
      this.$set(this.boxPlot, 'texts', [])
    }

    this.boxPlot.texts!.push(model_chart_text.template())
  }

  deleteText(index: number) {
    if (!this.boxPlot.texts) return

    util.removeFromArray(this.boxPlot.texts, index)
  }
}
