import dummy_screen0 from "../screens/dummy_screen0"
import dummy_screen1 from "../screens/dummy_screen1"
import dummy_screen2 from "../screens/dummy_screen2"
import dummy_screen3 from "../screens/dummy_screen3"
import dummy_screen4 from "../screens/dummy_screen4"
import dummy_screen5 from "../screens/dummy_screen5"
import dummy_recording0 from "../recordings/dummy_recording0"
import dummy_recording1 from "../recordings/dummy_recording1"
import dummy_recording2 from "../recordings/dummy_recording2"
import dummy_recording3 from "../recordings/dummy_recording3"
import dummy_recording4 from "../recordings/dummy_recording4"
import dummy_recording5 from "../recordings/dummy_recording5"


export default {
  unit: {
    "id": "unit-MKAxdfYKdmHQUoIwhtmv2fRFF0c",
    "createdAt": "2020-01-29T14:25:23.427Z",
    "createdBy": "zirro.p@gmail.com",
    "lastModifiedAt": "2019-11-29T09:21:40.632Z",
    "lastModifiedBy": "michael.niedermayr@gmail.com",
    "curriculum": "curr-1OFLkT3lrKI6jgVs6P49OITnsdcb",
    "title": "Test Unit",
    "screens": [
      "scre-mNxrSfiVIIYt6dDKD5cJHt6h244",
      "scre-1lepjnTwUUttzXXbXRsQVUVqicU",
      "scre-lQQtcqvqVYS42lflcYNRYbZjvQk",
      "scre-c9sharCvTqHLc0xpaTzIQEnAhBU",
      "scre-boanXQH3p4wkuVgMs4lziRg1QLw",
      "scre-zY8M6w5Sh7wgIappcajYeHz9D1g",
    ],
  },
  screens: [
    dummy_screen0,
    dummy_screen1,
    dummy_screen2,
    dummy_screen3,
    dummy_screen4,
    dummy_screen5,
  ],
  recordings: [
    dummy_recording0,
    dummy_recording1,
    dummy_recording2,
    dummy_recording3,
    dummy_recording4,
    dummy_recording5,
  ]

}