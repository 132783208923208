






















import {Component, Prop, Vue} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Link from "@/components/fundamental/text/Link.vue"

@Component({
  components: {Link, TextField, Row}
})
export default class NavigationElement extends Vue {
  @Prop({type: String, required: true}) title!: string
  @Prop({type: String, required: true}) routeName!: string
  @Prop({type: String, required: true}) icon!: string
}
