import {ContentType} from "@/models/content/model_content"
import {ImageModel, ImageType} from "@/models/content/model_image"
import {ScreenModel} from "@/models/screen/model_screen"

export default <ScreenModel>{
  id: "scre-zY8M6w5Sh7wgIappcajYeHz9D1g",
  title: "Test Screen",
  statics: [
    {
      type: ContentType.text,
      text: {
        text: "Das ist nur etwas Text.",
        size: 18,
      }
    },
    <ImageModel>{
      type: ContentType.gif,
      height: 220,
      scaling: 0.5,
      size: 29247,
      imageType: ImageType.tenor,
      fileType: "",
      url: "https://media.tenor.com/images/7a7e9f2fa880aabab52c39058c362dd4/tenor.gif",
      width: 220
    },
    {
      type: ContentType.text,
      text: "Das ist nur etwas Text.",
      size: 18,
    }
  ],
  initialVideoId: "reco-PtMqRufnxxaRbiUUI5LhIUtP4Tk-0",
}