import model_firestore, {FirestoreModel} from "@/models/model_firestore"
import util from "@/util/util"
import {Country, CurriculumModel} from './model_curriculum'

export interface CourseModel extends FirestoreModel {
  title: string
  curriculumId: string
  language: string
  country: Country
  school: string
  subject: string
  topics: Array<TopicModel>
  bonus: TopicModel
  exams: TopicModel
  formulas: ChapterModel
  skills: Array<SkillsModel>
  autoComplete?: Array<string>
}

export interface SkillsModel {
  id: string
  ti: string
}

export interface TopicModel {
  id: string
  ti: string    // title
  su?: string   // subtitle
  st?: string   // short title
  ch: Array<ChapterModel>
  ic?: TopicIcon // topic type
  et?: string   // exam title
}

export interface TopicIcon {
  co: number     // color
  as?: string    // asset
  url?: string   // url
}

export interface ChapterModel {
  id: string
  ti: string    // title
  su?: string   // subtitle
  st?: string   // short title
  si?: string   // skills id
  de?: boolean  // definition
  ex?: boolean  // show as exam card
  se?: boolean  // sticky exam
  et?: string   // exam title
  le: Array<LessonModel>
}

export interface LessonModel {
  id: string
  ti: string    // title
  in?: string   // info text
  ty: LessonType
  se?: boolean  // sticky exam
  et?: string   // exam title
  ed?: number   // exam duration
  un: Array<string>
}

export enum LessonType {
  theory,
  exercise,
  faq,
  definitions,
  exam,
}

export default {
  template_course(curriculum: CurriculumModel): CourseModel {
    return {
      ...model_firestore.template('co'),
      title: 'new course',
      curriculumId: curriculum.id,
      language: '',
      country: curriculum.co,
      school: curriculum.sc,
      subject: '',
      topics: [],
      exams: this.template_exams(),
      bonus: this.template_bonus(),
      formulas: this.template_chapter(),
      skills: [],
    }
  },
  template_exams(): TopicModel {
    return {
      id: util.getId('ex'),
      ti: 'Exams',
      ch: []
    }
  },
  template_bonus(): TopicModel {
    return {
      id: util.getId('bo'),
      ti: 'Bonus',
      ch: []
    }
  },
  template_topic(): TopicModel {
    return {
      id: util.getId('to'),
      ti: '',
      ic: {
        co: 0xFF673AB7,
        as: 'assets/icons/topics_algebra.png',
      },
      ch: []
    }
  },
  template_chapter(): ChapterModel {
    return {
      id: util.getId('ch'),
      ti: '',
      le: [],
    }
  },
  template_lesson(): LessonModel {
    return {
      id: util.getId('le'),
      ti: '',
      ty: LessonType.theory,
      un: []
    }
  },
}