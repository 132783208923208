




















import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Container from "../../fundamental/layout/Container.vue"
import {
  EventOverlay_elementType,
  EventOverlay_eventType,
  EventOverlay_actionType,
  EventOverlay_actionEvent
} from "@/components/simulator/events/eventOverlayModels"

@Component({
  components: {Container, Row, Column}
})
export default class EventOverlay extends Vue {
  @Prop({type: Number, required: true}) width!: number
  @Prop({type: Number, required: true}) height!: number
  @Prop({type: Boolean, default: true, required: false}) active!: boolean

  pointerDown = false
  startPosition = [0, 0]
  currentPosition = [0, 0]

  throttleBlocking = false
  throttlePeriod = 5 // in ms

  swipeThreshold = 5 // in px

  underlyingElement?: any = undefined
  elementType = EventOverlay_elementType.none
  eventType = EventOverlay_eventType.none


  /////////////////////////////////
  // Mouse events
  /////////////////////////////////
  eventMouseDown(event: MouseEvent) {
    switch (event.which) {
      case 1:
        this.eventDown(event.clientX, event.clientY)
        break
      case 2:
        break
      case 3:
        break
    }
  }

  eventMouseMove(event: MouseEvent) {
    this.eventMoveThrottled(event.clientX, event.clientY)
  }

  eventMouseUp() {
    this.eventUp()
  }


  /////////////////////////////////
  // Touch events
  /////////////////////////////////
  eventTouchStart(event: TouchEvent) {
    this.eventDown(event.targetTouches[0].clientX, event.targetTouches[0].clientY)
  }

  eventTouchMove(event: TouchEvent) {
    this.eventMoveThrottled(event.targetTouches[0].clientX, event.targetTouches[0].clientY)
  }

  eventTouchUp() {
    this.eventUp()
  }

  /////////////////////////////////
  // General events
  /////////////////////////////////
  eventDown(x: number, y: number) {
    if (!this.active) return

    this.$emit('event_down')

    this.pointerDown = true
    this.startPosition = [x, y]
    this.getUnderlyingElement(x, y)

    if (this.elementType === EventOverlay_elementType.drawable) {
      this.eventType = EventOverlay_eventType.draw
      this.drawStart(x, y)

    } else if (this.elementType === EventOverlay_elementType.draggable) {
      this.eventType = EventOverlay_eventType.drag
      this.dragStart(x, y)

    }
  }

  eventMoveThrottled(x: number, y: number) {
    if (!this.active || !this.pointerDown) return

    this.currentPosition = [x, y]

    if (!this.throttleBlocking) {
      this.throttleBlocking = true
      setTimeout(() => {
        this.eventMove(this.currentPosition[0], this.currentPosition[1])
        this.throttleBlocking = false
      }, this.throttlePeriod)
    }
  }

  eventMove(x: number, y: number) {
    if (!this.active || !this.pointerDown) return

    switch (this.eventType) {
      case EventOverlay_eventType.draw:
        this.drawMove(x, y)
        break
      case EventOverlay_eventType.drag:
        this.dragMove(x, y)
        break

      case EventOverlay_eventType.swipe_x:
        break

      case EventOverlay_eventType.swipe_y:
        this.$emit('swipe_y_move', y)
        break

      case EventOverlay_eventType.other:
        break

      default:
        let dx = Math.abs(x - this.startPosition[0])
        let dy = Math.abs(y - this.startPosition[1])

        if (dy > this.swipeThreshold && dx < dy * 0.8) {
          this.$emit('swipe_y_start', this.startPosition[1])
          this.$emit('swipe_y_move', y)
          this.eventType = EventOverlay_eventType.swipe_y

        } else if (dx > this.swipeThreshold && dy < dx * 0.8) {
          this.eventType = EventOverlay_eventType.swipe_x

        } else if (dx > this.swipeThreshold && dy > this.swipeThreshold) {
          this.eventType = EventOverlay_eventType.other
        }
    }
  }

  eventUp() {
    if (!this.active || !this.pointerDown) return

    this.pointerDown = false

    switch (this.eventType) {
      case EventOverlay_eventType.none:
        if (this.elementType === EventOverlay_elementType.clickable) {
          this.underlyingElement.click()
        }
        break

      case EventOverlay_eventType.draw:
        this.drawStop()
        break
      case EventOverlay_eventType.drag:
        this.dragStop()
        break

      case EventOverlay_eventType.swipe_x:
        break

      case EventOverlay_eventType.swipe_y:
        this.$emit('swipe_y_stop')
        break

      case EventOverlay_eventType.other:
        break

      default:

    }

    this.eventType = EventOverlay_eventType.none
  }

  /////////////////////////////////
  // Draw
  /////////////////////////////////
  drawStart(x: number, y: number) {
    this.$emit('drawStart', {
      screen: this.underlyingElement.dataset.screen,
      id: this.underlyingElement.dataset.id,
      x: x,
      y: y
    })
  }

  drawMove(x: number, y: number) {
    this.$emit('drawMove', {
      screen: this.underlyingElement.dataset.screen,
      id: this.underlyingElement.dataset.id,
      x: x,
      y: y
    })
  }

  drawStop() {
    this.$emit('drawStop', {
      screen: this.underlyingElement.dataset.screen,
      id: this.underlyingElement.dataset.id
    })
  }


  /////////////////////////////////
  // Drag
  /////////////////////////////////
  dragStart(x: number, y: number) {
    let e: EventOverlay_actionEvent = {
      type: EventOverlay_actionType.down,
      screen: this.underlyingElement.dataset.screen,
      id: parseInt(this.underlyingElement.dataset.id),
      x: x,
      y: y
    }

    this.$emit('updateAction', e)
  }

  dragMove(x: number, y: number) {
    let e: EventOverlay_actionEvent = {
      type: EventOverlay_actionType.move,
      screen: this.underlyingElement.dataset.screen,
      id: parseInt(this.underlyingElement.dataset.id),
      x: x,
      y: y
    }

    this.$emit('updateAction', e)
  }

  dragStop() {
    let e: EventOverlay_actionEvent = {
      type: EventOverlay_actionType.up,
      screen: this.underlyingElement.dataset.screen,
      id: parseInt(this.underlyingElement.dataset.id),
    }

    this.$emit('updateAction', e)
  }

  /////////////////////////////////
  // Helper
  /////////////////////////////////
  getUnderlyingElement(x: number, y: number) {
    this.underlyingElement = undefined
    this.elementType = EventOverlay_elementType.none

    let elements = document.elementsFromPoint(x, y)
    for (let i = 1; i < Math.min(elements.length, 8); i++) {

      // @ts-ignore
      if (elements[i].dataset.clickable) {
        this.underlyingElement = elements[i]
        this.elementType = EventOverlay_elementType.clickable
        break

        // @ts-ignore
      } else if (elements[i].dataset.drawable) {
        this.underlyingElement = elements[i]
        this.elementType = EventOverlay_elementType.drawable
        break

        // @ts-ignore
      } else if (elements[i].dataset.draggable) {
        this.underlyingElement = elements[i]
        this.elementType = EventOverlay_elementType.draggable
        break
      }
    }
  }
}
