import axios from 'axios'
import params from "@/app/params"

export default {
  async startUpload(name: string, directory: string, file: File, progressCallback: (p: number) => void): Promise<string> {
    try {
      let response = await this.getPresignedData(name, directory, file)
      let fileUrl = await this.postData(response.fields, response.url, file, progressCallback)
      return fileUrl
    } catch (e) {
      throw e
    }
  },
  async getPresignedData(name: string, directory: string, file: File) {
    try {
      let response = await axios.post(params.awsPresignUrl, {
        objectName: `${directory}/${name}`,
        contentType: file.type,
      })
      return response.data.result

    } catch (e) {
      throw(e)
    }
  },
  async postData(fields: any, s3Url: string, file: File, progressCallback: (p: number) => void): Promise<string> {

    let formData = new FormData()

    for (const [key, value] of Object.entries(fields)) {
      // @ts-ignore
      formData.append(key, value)
    } // append fields created by presigning lambda

    formData.append('file', file)

    const config = {
      onUploadProgress: (progressEvent: any) => {
        progressCallback(progressEvent.loaded / progressEvent.total)
      }
    }

    try {
      let response = await axios.post(s3Url, formData, config)

      if (response.status === 204) {
        return s3Url + fields.key

      } else {
        throw(Error('Status: ' + response.status + ', ' + response.statusText))
      }
    } catch (e) {
      throw e
    }
  }
}