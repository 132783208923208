import {ContentType} from "@/models/content/model_content"
import { ActionType} from "@/models/action/model_action"
import {InputType} from "@/models/action/model_action_input"
import {ScreenModel} from "@/models/screen/model_screen"
import {TextModel} from "@/models/content/model_text"

export default <ScreenModel>{
  id: "scre",
  title: "Test Action Input",
  statics: [
    <TextModel>{
      type: ContentType.text,
      text: "Das ist etwas Text. Es ist nur ein Test. Das ist ein Test mit einer Formel: $$\\pi+2$$. ",
      size: 18,
    }
  ],
  action: {
    type: ActionType.input,
    responses: [],
    fontSize: 24,
    inputs: [
      {
        fieldWidth: 100,
        type: InputType.term,
        label: '1+1 =',
        targetInputs: ['2'],
        suffix: 'km',
      }
    ]
  }
}