




























































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import ScreenWidget from "@/simulator/screen/ScreenWidget.vue"
import {UnitModel} from "@/models/unit/model_unit";

@Component({
  components: {ScreenWidget, Container, TextField, Row, Column}
})
export default class ScreensMenu extends Vue {
  @Prop({type: Object, required: true}) unit!: UnitModel
}
