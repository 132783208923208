import {UnitModel} from "@/models/unit/model_unit"
import {ScreenModel} from "@/models/screen/model_screen"
import {ActionType} from "@/models/action/model_action"
import {AnswerValue, McActionModel} from "@/models/action/model_action_mc"
import {InputActionModel, InputType} from '@/models/action/model_action_input'

export interface UnitError {
  errorMsg: string
  screenErrors: Array<ScreenError>
}

export interface ScreenError {
  actionErrorMsg: string
  errorMsg: string
}

export default {
  validate(unit: UnitModel): UnitError {

    let errorMsg = ''
    let screenErrors = []

    for (let i = 0; i < unit.screens.length; i++) {
      let s = this.checkScreen(unit.screens[i])
      screenErrors.push(s)
      errorMsg += s.errorMsg ? `<b>Screen ${i + 1}</b> - ${s.errorMsg}` : ''
    }

    return {
      errorMsg: errorMsg,
      screenErrors: screenErrors,
    }
  },


  checkScreen(screen: ScreenModel): ScreenError {

    let errorMsg = ''
    let actionErrorMsg = ''

    if (screen.action) {
      switch (screen.action.type) {
        case ActionType.dragPie:
          // actionErrorMsg = this.checkAction_pie(<DragPieActionModel>screen.action.dragPie)
          break

        case ActionType.drawPoint:
          // actionErrorMsg = this.checkAction_drawPoint(<DrawPointActionModel>screen.action.drawPoint)
          break

        case ActionType.input: actionErrorMsg = this.checkAction_input(<InputActionModel>screen.action); break
        case ActionType.mc: actionErrorMsg = this.checkAction_mc(<McActionModel>screen.action); break
      }
    }

    errorMsg += actionErrorMsg ? `<b>Action:</b> ${actionErrorMsg}` : ''

    return {
      actionErrorMsg: actionErrorMsg,
      errorMsg: errorMsg,
    }
  },


  checkAction_input(action: InputActionModel): string {
    if (action.inputs.length == 0) {
      return 'At least 1 input is required'
    }

    // unsortedList
    if (action.unsortedList) {
      for (let i = 0; i < action.inputs.length; i++) {
        let isEquation = (action.inputs[0].type === InputType.equation)
        if (!(isEquation && action.inputs[i].type === InputType.equation) && !(!isEquation && action.inputs[i].type === InputType.term)) {
          return 'All inputs must be either of type "term" or of type "equation" if "Unsorted list" is selected (mixing of "term" and "equation" is not possible)'
        } else if (action.inputs[i].text) {
          return '"Text" must be empty if "Unsorted list" is selected'
        }
      }
    }

    // continuousText
    if (action.continuousText) {
      for (let i = 0; i < action.inputs.length; i++) {
        let type = action.inputs[i].type
        if (type == InputType.vector || type == InputType.ci || type == InputType.paramEq ||
          type == InputType.coordinates || type == InputType.slideUnlock
        ) {
          return 'No input must be of type "vector", "ci", "coordinates", "parametricEq", "slideUnlock" if "Continuous text" is selected'
        }
      }
    }


    for (let i = 0; i < action.inputs.length; i++) {
      let type = action.inputs[i].type
      for (let target of action.inputs[i].targetInputs) {
        if (type != InputType.equation && (target.includes('=') ||
          target.includes('<') || target.includes('>') || target.includes('\\le') || target.includes('\\ge'))) {
          return 'Input ' + (i+1) +' contains "=", "<" or ">" but is not of type "equation"'
        }

        if (type == InputType.equation && !target.includes('=') &&
          !target.includes('<') && !target.includes('>') && !target.includes('\\le') && !target.includes('\\ge')) {
          return 'Input ' + (i+1) +' contains no "=", "<" or ">" but is of type "equation"'
        }

        if (type != InputType.time && target.includes(':')) {
          return 'Input ' + (i+1) +' contains ":" but is not of type "time"'
        }

        if (type == InputType.time &&
          (!target || /[^0-9.:]/.test(target))) {
          return 'Input ' + (i+1) +' is of type "time" but contains invalid characters'
        }
      }
    }

    return ''
  },


  checkAction_mc(action: McActionModel): string {

    let numTrueAnswers = 0
    for (let answer of action.answers) {
      if (answer.value === AnswerValue.true) {
        numTrueAnswers++
      }
    }

    if (numTrueAnswers === 0) {
      return 'At least 1 correct answer is required.'

    } else {
      return ''
    }
  },
}