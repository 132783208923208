












import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import {
  PostSimulatorMessage, PostSimulatorMessage_contentData,
  ReceivedSimulatorMessage,
  ReceivedSimulatorMessage_initCompleted, ReceiveType, SendType
} from "@/simulator/content/sim_content_communication"
import {ContentModel} from "@/models/content/model_content"


@Component({
  components: {Container}
})
export default class ContentWidget extends Vue {
  @Prop({type: Number, required: true}) width!: number
  @Prop({type: Number, required: true}) height!: number
  @Prop({type: Object, required: true}) content!: ContentModel

  parentId = 'parent-content-' + window.performance.now()
  iframe?: any = undefined


  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  mounted() {
    window.addEventListener('message', this.receiveMessage)

    // @ts-ignore
    this.iframe = this.$refs.iframe
  }

  // noinspection JSUnusedGlobalSymbols
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
  }

  @Watch('content', {deep: true})
  contentChanged() {
    this.sendContentData()
  }


  /////////////////////////////////
  // Send Message
  /////////////////////////////////
  sendContentData() {
    if (!this.content) return

    let message: PostSimulatorMessage_contentData = {
      type: SendType.contentData,
      contentData: this.content
    }
    this.sendMessage(message)
  }

  sendMessage(message: PostSimulatorMessage) {
    this.iframe?.contentWindow?.postMessage(message, '*')
  }


  /////////////////////////////////
  // Receive Message
  /////////////////////////////////
  receiveMessage(event: MessageEvent) {
    let data = event.data

    if (!data || data.parentId !== this.parentId || !data.type) {
      return
    }

    let message = data as ReceivedSimulatorMessage

    switch (message.type) {
      case ReceiveType.initCompleted:
        this.$emit('version', (message as ReceivedSimulatorMessage_initCompleted).version)
        this.sendContentData()
        break
    }
  }
}
