





























import {Component, Prop, Vue} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import Row from "../layout/Row.vue"
import TextField from "../text/TextField.vue"
import InputField from "./InputField.vue"

@Component({
  components: {InputField, TextField, Row, Container}
})
export default class EditText extends Vue {
  @Prop({type: String, required: true}) text!: string
  @Prop({type: String, default: '', required: false}) placeholder!: string
  @Prop({type: Number, default: 16, required: false}) size!: number
  @Prop({type: Boolean, default: false, required: false}) light!: number
  @Prop({type: Boolean, default: false, required: false}) dark!: number
  @Prop({type: Boolean, default: false, required: false}) smallIcons!: boolean
  @Prop({type: Boolean, default: false, required: false}) bold!: boolean
  @Prop({type: Boolean, default: false, required: false}) center!: boolean

  editText = false

  get pencilSize() {
    return Math.min(16, this.size)
  }

  editTextCompleted(text: string) {
    this.editText = false
    this.$emit('completed', text)
  }


}
