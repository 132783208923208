























































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import ListItemContainer from "@/pages/unit_editor/ListItemContainer.vue"
import {AnswerValue, McAnswerModel} from "@/models/action/model_action_mc"
import util from "@/util/util"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import EditText from "@/components/fundamental/inputs/EditText.vue"
import AdvancedSelect from "@/components/fundamental/advanced_inputs/AdvancedSelect.vue"
import InlineContentEditor from "@/components/screen_editor/content/InlineContentEditor.vue"
import RecordingInfo from "@/components/recorder/RecordingInfo.vue"
import NewRecordingButton from "@/components/recorder/recorderWindow/NewRecordingButton.vue"
import PreviewPlayerWidget from "@/components/video/player/PreviewPlayerWidget.vue"
import {ScreenModel} from "@/models/screen/model_screen"
import {RecordingModel} from "@/models/recording/model_recording"
import appState from "@/app/state/app_state"
import {PredefinedActionState} from "@/simulator/screen/sim_screen_communication"
import {SizeModel} from "@/models/math/model_size"
import {UnitModel} from "@/models/unit/model_unit";
import {ContentModel} from "@/models/content/model_content"

@Component({
  components: {
    PreviewPlayerWidget,
    NewRecordingButton,
    RecordingInfo,
    InlineContentEditor, AdvancedSelect, EditText, SpacerBox, ListItemContainer, Row, Column
  }
})
export default class McAnswer extends Vue {
  @Prop({type: Object, required: true}) unit!: UnitModel
  @Prop({type: Number, required: true}) index!: number
  @Prop({type: Object, required: true}) answer!: McAnswerModel
  @Prop({type: Object, required: true}) screen!: ScreenModel
  @Prop({type: Array, required: true}) recordings!: Array<RecordingModel>
  @Prop({type: Object, required: true}) fieldSize!: SizeModel


  /////////////////////////////////
  // Answer
  /////////////////////////////////
  answerValues = AnswerValue

  get answerValuesKeys() {
    return util.enumToArray(AnswerValue) as Array<string>
  }

  get answerValueLabels() {
    return this.answerValuesKeys.map((s: string) => s[s.length - 1] === '_' ? s.slice(0, -1) : s)
  }

  updateContent(newContent: ContentModel) {
    this.answer.content = newContent
  }


  /////////////////////////////////
  // Video
  /////////////////////////////////
  predefinedActionStates = PredefinedActionState

  get recording() {
    return (this.answer.videoId) ? this.recordings.find(r => r.id === this.answer.videoId) : undefined
  }

  handleRecordingCompleted(videoId: string) {
    this.$set(this.answer, 'videoId', videoId)
    appState.unitSavingRequest = true
  }
}
