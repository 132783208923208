


























































import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Container from "@/components/fundamental/layout/Container.vue"

@Component({
  components: {Container, TextField, Row}
})
export default class Slider extends Vue {
  @Prop({type: Number, required: true}) value!: number
  @Prop({type: Number, required: true}) min!: number
  @Prop({type: Number, required: true}) max!: number
  @Prop({type: Boolean, default: false, required: false}) hideLimits!: boolean

  value_ = this.value
  active = false

  @Watch('value')
  valueChanged() {
    if (!this.active) {
      this.value_ = this.value
    }
  }
}
