import firebase from 'firebase/app'
import 'firebase/firestore'
import util from "@/util/util"
import store from "@/store/store"

export interface FirestoreModel {
  id: string

  createdAt: string | null
  createdBy: string

  lastModifiedAt: string | null
  lastModifiedBy: string
}

export default {
  template(prefix = ''): FirestoreModel {
    return {
      id: util.getId(prefix),

      createdAt: firebase.firestore.Timestamp.now().toDate().toISOString(),
      createdBy: store.auth.userEmail,

      lastModifiedAt: firebase.firestore.Timestamp.now().toDate().toISOString(),
      lastModifiedBy: store.auth.userEmail,
    }
  },
}