export default {
  "initialVideoId": "reco-PtMqRufnxxaRbiUUI5LhIUtP4Tk-5",
  "statics": [
    {
      "id": "scim-M5BBcvkXSrUbTJrlR1LV1u8ooQg",
      "type": "image",
      "imageType": "image",
      "url": "https://miranda-40b79.s3.amazonaws.com/images/imag-GYIQBS9aiNBPWOWaPoGJ3yq8haw.jpg",
      "size": 420938,
      "fileType": "image/png",
      "width": 493,
      "height": 539,
      "scaling": 1
    },
    {
      "id": "scim-vuYc5UVcQrfbODraPIPcXiNdgho",
      "type": "image",
      "imageType": "image",
      "url": "https://miranda-40b79.s3.amazonaws.com/images/imag-Du6dEhskRpl4yBiG1TDDhW04ERA.jpg",
      "size": 655880,
      "fileType": "image/png",
      "width": 530,
      "height": 671,
      "scaling": 1
    },
    {
      "id": "scim-D29VvQmgcY6P49GkKmXhwQnFenI",
      "type": "image",
      "imageType": "image",
      "url": "https://miranda-40b79.s3.amazonaws.com/images/imag-i3whoZ0nsuWLU4RxW4grSSq2wN0.jpg",
      "size": 354480,
      "fileType": "image/png",
      "width": 375,
      "height": 527,
      "scaling": 1
    },
    {
      "id": "scim-OclfachLGCgYAzVp9HS2nXa2fkA",
      "type": "image",
      "imageType": "image",
      "url": "https://miranda-40b79.s3.amazonaws.com/images/imag-iV6OWLfXlCNBCjNavICCIpg8rCg.jpg",
      "size": 158642,
      "fileType": "image/png",
      "width": 467,
      "height": 543,
      "scaling": 1
    }
  ],
  "title": "test1",
  "id": "scre-zY8M6w5Sh7wgIappcajYeHz9D1g"
}