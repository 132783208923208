


















































import {Component, Vue} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import {CatalogTopicType} from "@/models/sound/model_sound"
import store from "@/store/store"
import Checkbox from "@/components/fundamental/inputs/Checkbox.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"

@Component({
  components: {
    TextButton,
    Checkbox,
    RoundIconButton, Container, TextField, SpacerBox, TransitionEffect, Row, Column
  }
})
export default class SelectSoundDialog extends Vue {

  soundCatalog = store.sounds.getMainCatalog()

  selectedCallback?: (soundUrl: string) => void
  catalogTopicType = CatalogTopicType.tip
  selectedSoundUrl: string = ''
  isVisible = false

  /////////////////////////////////
  // Methods
  /////////////////////////////////
  open(currentSoundUrl: string, catalogTopicType: CatalogTopicType, selectedCallback?: (soundUrl: string) => void) {
    this.selectedSoundUrl = currentSoundUrl
    this.selectedCallback = selectedCallback
    this.catalogTopicType = catalogTopicType
    this.isVisible = true
  }

  close() {
    this.isVisible = false
  }

  save() {
    let sound = this.soundCatalog![this.catalogTopicType].find(e => e.url === this.selectedSoundUrl)
    if (sound && this.selectedCallback) {
      this.selectedCallback(sound.url)
    }
    this.close()
  }
}
