import firebase from 'firebase/app'
import 'firebase/firestore'
import dbHelper from '../util/basic/dbHelper'
import {CurriculumModel} from "@/models/curriculum/model_curriculum"
import ChangeNotifier from "@/util/basic/change_notifier"
import params from "@/app/params"
import util from "@/util/util"
import {UnitModel} from "@/models/unit/model_unit";
import model_unit_preview, {UnitPreviewModel} from "@/models/unit/model_unit_preview";


export default class CurriculumStore extends ChangeNotifier {
  _curricula: Array<CurriculumModel> = []
  _remoteCurricula: Array<CurriculumModel> = []
  _curriculaUnsubscribe: any = undefined

  _unitPreviewJson?: any
  _unitPreviewDocId = ''
  _unitPreviewUnsubscribe: any = undefined

  init() {}

  /////////////////////////////////
  // Getters
  /////////////////////////////////
  get remoteCurricula(): Array<CurriculumModel> {
    return this._curricula
  }

  getRemoteCurriculum(id: string): CurriculumModel | null {
    let curriculum = this._curricula.find((c: CurriculumModel) => c.id === id)
    return curriculum ?? null
  }

  getUnitPreviewJson(curriculumId: string): any {
    return (this._unitPreviewJson && this._unitPreviewDocId === curriculumId) ? this._unitPreviewJson : null
  }

  getUnitPreview(unitId: string): UnitPreviewModel | null {
    if (!this._unitPreviewJson) return null
    return this._unitPreviewJson[unitId]
  }


  /////////////////////////////////
  // Curriculum
  /////////////////////////////////
  async subscribeToCurricula(): Promise<void> {
    return new Promise((resolve, reject) => {

      if (this._curriculaUnsubscribe) {
        resolve()
        return
      }

      this._curriculaUnsubscribe = firebase.firestore().collection(params.firestore.curricula)
        .onSnapshot(
          (querySnapshot) => {
            this._curricula = querySnapshot.docs.map(doc => dbHelper.docToJson(doc))
            this._remoteCurricula = querySnapshot.docs.map(doc => dbHelper.docToJson(doc))
            this.notify()
            resolve()
          },

          error => reject(error)
        )
    })
  }

  async updateCurriculum(curriculum: CurriculumModel | null) {
    if (!curriculum) return

    // return if curriculum has not changed
    let remoteCurriculum = this._remoteCurricula.find(c => c.id === curriculum.id)
    if (util.compare(curriculum, remoteCurriculum)) return

    // new curriculum is created
    if (!remoteCurriculum) {
      await firebase.firestore().collection(params.firestore.unitPreviews).doc(curriculum.id)
        .set({})
      this.subscribeToUnitPreviewList(curriculum.id)
    }

    await firebase.firestore().collection(params.firestore.curricula).doc(curriculum.id)
      .set(dbHelper.jsonToFirestoreJson(curriculum))
  }

  async deleteCurriculum(id: string) {
    await firebase.firestore().collection(params.firestore.curricula).doc(id).delete()
  }


  /////////////////////////////////
  // UnitPreviews
  /////////////////////////////////
  async subscribeToUnitPreviewList(curriculumId: string) {
    if (this._unitPreviewJson && this._unitPreviewDocId === curriculumId) return

    if (this._unitPreviewUnsubscribe) {
      this._unitPreviewUnsubscribe()
    }

    this._unitPreviewDocId = curriculumId

    this._unitPreviewUnsubscribe = await firebase.firestore().collection(params.firestore.unitPreviews).doc(curriculumId)
      .onSnapshot((doc) => {
        let data = doc.data()

        let previewJson: any = {}
        for (let key in data) {
          if (data.hasOwnProperty(key)) {

            let jsonStr = data[key].replaceAll('\\,', '%%%%%'); // masking \, before splitting string at ,
            let items = jsonStr.split(',')

            let preview: UnitPreviewModel = {
              ti: util.replaceAll(items[0], '%%%%%', ','), // unmask ','
              ty: parseInt(items[2]),
              st: parseInt(items[3]),
              sc: parseInt(items[5]),
              ac: parseInt(items[6]),
            }

            if (items[1] > 0) {
              preview.re = util.replaceAll(items[1], '%%%%%', ',') // unmask ','
            }

            if (items[4].length > 0) {
              preview.le = parseInt(items[4])
            }

            if (items[7] > 0) preview.iv = parseInt(items[7])
            if (items[8] > 0) preview.tv = parseInt(items[8])
            if (items[9] > 0) preview.cv = parseInt(items[9])
            if (items[10] > 0) preview.wv = parseInt(items[10])
            if (items[11] > 0) preview.mv = parseInt(items[11])
            if (items[12] > 0) preview.ov = parseInt(items[12])

            if (items[13] > 0) {
              preview.er = parseInt(items[9]) == 1
            }

            previewJson[key] = preview
          }
        }

        this._unitPreviewJson = previewJson
        this.notify()
      })
  }

  updateUnitLocally(unit: UnitModel) {
    if (!this._unitPreviewJson) return

    let oldPreview = this._unitPreviewJson[unit.id]
    let newPreview = model_unit_preview.buildFromUnit(unit)

    let hasDataChanged = !oldPreview

    if (!hasDataChanged) {
      hasDataChanged = !model_unit_preview.areUnitPreviewsEqual(newPreview, oldPreview)
    }

    if (hasDataChanged) {
      this._unitPreviewJson[unit.id] = newPreview
      this.notify()
    }
  }

  deleteUnitLocally(unit: UnitModel) {
    if (!this._unitPreviewJson) return

    delete this._unitPreviewJson[unit.id]
  }
}