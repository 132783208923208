







































import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import {eventbus} from "@/main"
import {EventType} from "@/components/app/eventModel"
import icons from "@/components/fundamental/icons"

@Component({
  components: {
    Container, TextField, Row, Column
  }
})
export default class TestHtml_icons extends Vue {
  iconsList = icons.componentList()

  copy(text: string) {
    navigator.clipboard.writeText(text).catch(error => {
      console.log('Clipboard error', error)
    })
    eventbus.$emit(EventType.snackbar, {msg: text + ' copied to clipboard'})
  }
}
