




























































import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import SectionLayout from "@/components/fundamental/layout/SectionLayout.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import SoundUploader from "@/pages/sounds/SoundUploader.vue"
import model_sound, {CatalogTopicType, SoundCatalogModel, SoundModel} from "@/models/sound/model_sound"
import {eventbus} from "@/main"
import {AlertDialogType, EventType, NavbarViewModel, SidePanelViewModel} from "@/components/app/eventModel"
import SoundUploaderDialog from "@/pages/sounds/SoundUploaderDialog.vue"
import SoundItem from "@/pages/sounds/SoundItem.vue"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import store from "@/store/store"
import CatalogTopic from "@/pages/sounds/CatalogTopic.vue"

@Component({
  components: {
    CatalogTopic,
    AddButton,
    SoundItem,
    SoundUploaderDialog,
    SoundUploader, Container, SelectField, InputField, TextField, SectionLayout, TextButton, Row, Column}
})
export default class SoundsPage extends Vue {

  catalog: SoundCatalogModel = model_sound.template_soundCatalog('empty')
  catalogTopicType = CatalogTopicType

  sidePanelViewModel = new SidePanelViewModel(true, this.catalog)


  ////////////////////////////////
  // Life Cycle
  ////////////////////////////////
  created() {
    eventbus.$emit(EventType.navbar, new NavbarViewModel(true))
    eventbus.$emit(EventType.sidePanel, this.sidePanelViewModel)

    store.sounds.addListener(this.handleSoundsStoreChanges, this.constructor.name)
    this.handleSoundsStoreChanges() // init call
  }

  destroy() {
    store.sounds.removeListener(this.constructor.name)
  }


  ////////////////////////////////
  // Load & save
  ////////////////////////////////
  handleSoundsStoreChanges() {
    let c = store.sounds.getCatalog('main')
    this.catalog = c ?? model_sound.template_soundCatalog('main')
    this.sidePanelViewModel.json = c
  }

  save() {
    store.sounds.saveCatalog(this.catalog)
  }


  ////////////////////////////////
  // Methods
  ////////////////////////////////
  addSound(type: CatalogTopicType) {
    let newSound = model_sound.template_sound()
    // @ts-ignore
    this.$refs.soundDialog.open(newSound, () => {
      this.catalog[type].push(newSound)
      this.save()
    })
  }

  deleteSound(type: CatalogTopicType, sound: SoundModel) {
    if (type == CatalogTopicType.achievementShort || type == CatalogTopicType.achievementLong) {
      let index = this.catalog[type].indexOf(sound)
      this.$delete(this.catalog[type], index)
      this.save()
      return
    }


    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.delete_high,
      title: `Delete sound`,
      text: 'Only delete sounds that are not used as Response Sound in any unit yet.',
      confirmAnswer: 'Delete',
      callback: (response: boolean) => {
        if (response) {
          let index = this.catalog[type].indexOf(sound)
          this.$delete(this.catalog[type], index)
          this.save()
        }
      }
    })
  }
}
