






































































import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import {CurriculumModel, OfficialSectionModel} from '@/models/curriculum/model_curriculum'
import Row from "@/components/fundamental/layout/Row.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Column from "@/components/fundamental/layout/Column.vue";
import OfficialSubsections from "@/pages/curriculum_editor/official/OfficialSubsections.vue";

@Component({
  components: {
    OfficialSubsections,
    Column, TextField, SelectField, SpacerBox, RoundIconButton, InputField, Row, Container}
})
export default class OfficialSectionElement extends Vue {
  @Prop({type: Object, required: true}) curriculum!: CurriculumModel
  @Prop({type: Object, required: true}) section!: OfficialSectionModel
  @Prop({type: String, required: true}) topicPrefix!: string
  @Prop({type: Number, required: true}) chapterIndex!: number
  @Prop({type: Number, required: true}) sectionIndex!: number

  show = true

  created() {
    this.createSectionTitle()
  }

  @Watch('curriculum', {deep: true})
  curriculumChanged() {
    this.createSectionTitle()
  }

  createSectionTitle() {
    if (this.section.ti.length > 0) return

    this.section.ti = this.topicPrefix + ' ' + (this.chapterIndex + 1) + '.' + (this.sectionIndex + 1)
  }
}
