























import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Draggable from "@/components/fundamental/layout/Draggable.vue"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import {eventbus} from "@/main"
import {AlertDialogType, EventType} from "@/components/app/eventModel"
import util from "@/util/util"
import model_curriculum, {CurriculumModel, OfficialTopicModel} from '@/models/curriculum/model_curriculum'
import OfficialChapterElement from "@/pages/curriculum_editor/official/OfficialChapterElement.vue"
import Row from "@/components/fundamental/layout/Row.vue"

@Component({
  components: {Row, OfficialChapterElement, Container, AddButton, Draggable, Column}
})
export default class OfficialChapters extends Vue {
  @Prop({type: Object, required: true}) curriculum!: CurriculumModel
  @Prop({type: Object, required: true}) topic!: OfficialTopicModel

  addChapter() {
    this.topic.ch.push(model_curriculum.template_officialChapter())
  }

  requestDeletingChapter(index: number) {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.delete_low,
      title: `Delete chapter`,
      text: `Delete chapter: <b>${this.topic.ch[index].ti}</b>?<br><br> You can restore it by resetting the curriculum. Changes will be permanent if you save the curriculum.`,
      callback: (response: boolean) => {
        if (response) this.deleteChapter(index)
      }
    })
  }

  deleteChapter(index: number) {
    util.removeFromArray(this.topic.ch, index)
  }
}
