






























import {Component, Prop, Vue} from "vue-property-decorator"
import TextField from "@/components/fundamental/text/TextField.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import {ResponseModel, ResponseType} from "@/models/action/model_response"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import SelectSoundDialog from "@/pages/sounds/SelectSoundDialog.vue"
import {CatalogTopicType} from "@/models/sound/model_sound"
import store from "@/store/store"

@Component({
  components: {
    SelectSoundDialog, TextButton, Container,
    Column, RoundIconButton, SelectField, SpacerBox, Row, TextField,
  }
})
export default class ResponseSound extends Vue {
  @Prop({type: Object, required: true}) response!: ResponseModel

  soundCatalog = store.sounds.getMainCatalog()

  get soundId(): string | null {
    if (!this.response.soundUrl) return null
    let sound = this.soundCatalog![this.getCatalogTopicType()].find(e => e.url === this.response.soundUrl)
    return sound?.id ?? null
  }

  get soundTitle(): string | null {
    if (!this.response.soundUrl) return null
    let sound = this.soundCatalog![this.getCatalogTopicType()].find(e => e.url === this.response.soundUrl)
    return sound?.title ?? null
  }

  addSound() {
    // @ts-ignore
    this.$refs.soundDialog.open(this.response.soundUrl ?? '', this.getCatalogTopicType(), (soundUrl: string) => {
      this.$set(this.response, 'soundUrl', soundUrl)
    })
  }

  deleteSound() {
    this.$delete(this.response, 'soundUrl')
  }

  getCatalogTopicType() {
    switch (this.response.type) {
      case ResponseType.tip: return CatalogTopicType.tip
      case ResponseType.correct: return CatalogTopicType.correct
      case ResponseType.wrong: return CatalogTopicType.wrong
    }
  }
}
