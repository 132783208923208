




































































































import {Component, Vue} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import JsonView from "../fundamental/text/JsonView.vue"
import RoundIconButton from "../fundamental/buttons/RoundIconButton.vue"
import Row from "../fundamental/layout/Row.vue"
import TextField from "../fundamental/text/TextField.vue"
import Column from "../fundamental/layout/Column.vue"
import TransitionEffect from "../fundamental/animations/TransitionEffect.vue"
import {eventbus} from "@/main"
import {EventType, SidePanelViewModel} from "@/components/app/eventModel"

@Component({
  components: {TransitionEffect, Column, TextField, Row, RoundIconButton, JsonView, Container}
})
export default class SidePanel extends Vue {

  sidePanelViewModel: SidePanelViewModel = new SidePanelViewModel(false)
  showJson = false

  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  created() {
    eventbus.$on(EventType.sidePanel,
      (viewModel: SidePanelViewModel) => {
        this.sidePanelViewModel = viewModel
      })
  }

  /////////////////////////////////
  // Methods
  /////////////////////////////////
  copy(text?: string) {
    if (text) {
      navigator.clipboard.writeText(text).catch(error => {
        console.log('Clipboard error', error)
      })
    }
  }

}
