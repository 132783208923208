import {ActionModel} from "@/models/action/model_action"
import util from "@/util/util"
import {ContentModel} from "@/models/content/model_content"


export interface ScreenModel {
  id: string
  title: string
  subtitle?: string
  titleSize?: number
  statics: Array<ContentModel>
  initialVideoId?: string
  ytVideoId?: string
  action?: ActionModel
  relatedUnits?: Array<string>
}

export default {
  template_screen(): ScreenModel {
    return {
      id: util.getId('sc'),
      title: '',
      statics: [],
    }
  },
}