import {TextModel} from "@/models/content/model_text"
import model_limits, {LimitsModel} from "@/models/content/charts/model_limits"
import {ContentType} from "@/models/content/model_content"


export interface ChartParamsModel {
  limits: LimitsModel
  labelSize: number
  valueSize: number
  showAxesZero: boolean

  bottomLabel?: TextModel
  leftLabel?: TextModel

  showAxis_x: boolean
  axisLabel_x?: string
  showMarkers_x: boolean
  showValues_x: boolean
  valueFracDigits_x: number
  valueSuffix_x?: string
  showGrid_x: boolean
  showSubGrid_x: boolean
  gridDivider_x: number
  subGridDivider_x: number
  boldSubGrid_x?: boolean

  showAxis_y: boolean
  axisLabel_y?: string
  showMarkers_y: boolean
  showValues_y: boolean
  valueFracDigits_y: number
  valueSuffix_y?: string
  showGrid_y: boolean
  showSubGrid_y: boolean
  gridDivider_y: number
  subGridDivider_y: number
  boldSubGrid_y?: boolean

  version?: number
}

export default {
  compareParams(params1: ChartParamsModel, params2: ChartParamsModel): boolean {
    return model_limits.compareLimits(params1.limits, params2.limits) &&
      params1.labelSize === params2.labelSize &&
      params1.valueSize === params2.valueSize &&
      params1.showAxesZero === params2.showAxesZero &&

      params1.showAxis_x === params2.showAxis_x &&
      params1.axisLabel_x === params2.axisLabel_x &&
      params1.showMarkers_x === params2.showMarkers_x &&
      params1.showValues_x === params2.showValues_x &&
      params1.valueFracDigits_x === params2.valueFracDigits_x &&
      params1.valueSuffix_x === params2.valueSuffix_x &&
      params1.showGrid_x === params2.showGrid_x &&
      params1.showSubGrid_x === params2.showSubGrid_x &&
      params1.gridDivider_x === params2.gridDivider_x &&
      params1.subGridDivider_x === params2.subGridDivider_x &&

      params1.showAxis_y === params2.showAxis_y &&
      params1.axisLabel_y === params2.axisLabel_y &&
      params1.showMarkers_y === params2.showMarkers_y &&
      params1.showValues_y === params2.showValues_y &&
      params1.valueFracDigits_y === params2.valueFracDigits_y &&
      params1.valueSuffix_y === params2.valueSuffix_y &&
      params1.showGrid_y === params2.showGrid_y &&
      params1.showSubGrid_y === params2.showSubGrid_y &&
      params1.gridDivider_y === params2.gridDivider_y &&
      params1.subGridDivider_y === params2.subGridDivider_y &&

      params1.version === params2.version
  },
  copyParams(params: ChartParamsModel): ChartParamsModel {
    return {
      limits: model_limits.copyLimits(params.limits),
      labelSize: params.labelSize,
      valueSize: params.valueSize,
      showAxesZero: params.showAxesZero,

      showAxis_x: params.showAxis_x,
      axisLabel_x: params.axisLabel_x,
      showMarkers_x: params.showMarkers_x,
      showValues_x: params.showValues_x,
      valueFracDigits_x: params.valueFracDigits_x,
      valueSuffix_x: params.valueSuffix_x,
      showGrid_x: params.showGrid_x,
      showSubGrid_x: params.showSubGrid_x,
      gridDivider_x: params.gridDivider_x,
      subGridDivider_x: params.subGridDivider_x,

      showAxis_y: params.showAxis_y,
      axisLabel_y: params.axisLabel_y,
      showMarkers_y: params.showMarkers_y,
      showValues_y: params.showValues_y,
      valueFracDigits_y: params.valueFracDigits_y,
      valueSuffix_y: params.valueSuffix_y,
      showGrid_y: params.showGrid_y,
      showSubGrid_y: params.showSubGrid_y,
      gridDivider_y: params.gridDivider_y,
      subGridDivider_y: params.subGridDivider_y,

      version: params.version,
    }
  },
  copyParamsElementwise(sourceParams: ChartParamsModel, targetParams: ChartParamsModel) {
    targetParams.limits = model_limits.copyLimits(sourceParams.limits)
    targetParams.labelSize = sourceParams.labelSize
    targetParams.valueSize = sourceParams.valueSize
    targetParams.showAxesZero = sourceParams.showAxesZero

    targetParams.showAxis_x = sourceParams.showAxis_x
    targetParams.axisLabel_x = sourceParams.axisLabel_x
    targetParams.showMarkers_x = sourceParams.showMarkers_x
    targetParams.showValues_x = sourceParams.showValues_x
    targetParams.valueFracDigits_x = sourceParams.valueFracDigits_x
    targetParams.valueSuffix_x = sourceParams.valueSuffix_x
    targetParams.showGrid_x = sourceParams.showGrid_x
    targetParams.showSubGrid_x = sourceParams.showSubGrid_x
    targetParams.gridDivider_x = sourceParams.gridDivider_x
    targetParams.subGridDivider_x = sourceParams.subGridDivider_x

    targetParams.showAxis_y = sourceParams.showAxis_y
    targetParams.axisLabel_y = sourceParams.axisLabel_y
    targetParams.showMarkers_y = sourceParams.showMarkers_y
    targetParams.showValues_y = sourceParams.showValues_y
    targetParams.valueFracDigits_y = sourceParams.valueFracDigits_y
    targetParams.valueSuffix_y = sourceParams.valueSuffix_y
    targetParams.showGrid_y = sourceParams.showGrid_y
    targetParams.showSubGrid_y = sourceParams.showSubGrid_y
    targetParams.gridDivider_y = sourceParams.gridDivider_y
    targetParams.subGridDivider_y = sourceParams.subGridDivider_y

    targetParams.version = sourceParams.version
  },


  template_lineChart(): ChartParamsModel {
    return {
      limits: model_limits.template_lineChart(),
      labelSize: 16,
      valueSize: 12,
      showAxesZero: true,

      showAxis_x: true,
      axisLabel_x: 'x',
      showMarkers_x: true,
      showValues_x: true,
      valueFracDigits_x: 0,
      showGrid_x: true,
      showSubGrid_x: false,
      gridDivider_x: 10,
      subGridDivider_x: 5,

      showAxis_y: true,
      axisLabel_y: 'f(x)',
      showMarkers_y: true,
      showValues_y: true,
      valueFracDigits_y: 0,
      showGrid_y: true,
      showSubGrid_y: false,
      gridDivider_y: 10,
      subGridDivider_y: 5,

      version: 2,
    }
  },
  template_lineChart_small(): ChartParamsModel {
    return {
      limits: model_limits.template_lineChart(),
      labelSize: 12,
      valueSize: 10,
      showAxesZero: true,

      showAxis_x: true,
      axisLabel_x: 'x',
      showMarkers_x: true,
      showValues_x: true,
      valueFracDigits_x: 0,
      showGrid_x: true,
      showSubGrid_x: false,
      gridDivider_x: 10,
      subGridDivider_x: 5,

      showAxis_y: true,
      axisLabel_y: 'f(x)',
      showMarkers_y: true,
      showValues_y: true,
      valueFracDigits_y: 0,
      showGrid_y: true,
      showSubGrid_y: false,
      gridDivider_y: 10,
      subGridDivider_y: 5,

      version: 2,
    }
  },


  template_boxPlot(): ChartParamsModel {
    return {
      limits: model_limits.template_boxPlot(),
      labelSize: 18,
      valueSize: 14,
      showAxesZero: false,

      bottomLabel: {
        type: ContentType.text,
        text: 'Label',
        size: 18,
      },

      showAxis_x: true,
      showMarkers_x: true,
      showValues_x: true,
      valueFracDigits_x: 0,
      showGrid_x: true,
      showSubGrid_x: true,
      gridDivider_x: 10,
      subGridDivider_x: 5,

      showAxis_y: false,
      showMarkers_y: false,
      showValues_y: false,
      valueFracDigits_y: 0,
      showGrid_y: false,
      showSubGrid_y: false,
      gridDivider_y: 10,
      subGridDivider_y: 5,

      version: 2,
    }
  },
}