import firebase from 'firebase/app'
import 'firebase/firestore'
import util from "@/util/util"
import store from "@/store/store"
import Timestamp = firebase.firestore.Timestamp

export default {
  jsonToFirestoreJson(obj: any) {
    let o = util.copy(obj)
    // delete o.id

    // copy stringifies object, so we have to add the timestamp afterwards
    if (o.createdAt) {
      o.createdAt = firebase.firestore.Timestamp.fromMillis(new Date(o.createdAt).getTime())
    }
    if (o.videoEncodedAt) {
      o.videoEncodedAt = firebase.firestore.Timestamp.fromMillis(new Date(o.videoEncodedAt).getTime())
    }

    o.lastModifiedAt = firebase.firestore.Timestamp.now()
    o.lastModifiedBy = store.auth.userEmail

    return o
  },
  docToJson(doc: firebase.firestore.DocumentSnapshot): any {
    let json = doc.data()

    if (json) {

      if (json.createdAt) {
        json.createdAt = json.createdAt.toDate().toISOString()
      }
      if (json.lastModifiedAt) {
        json.lastModifiedAt = json.lastModifiedAt.toDate().toISOString()
      }
      if (json.videoEncodedAt) {
        json.videoEncodedAt = json.videoEncodedAt.toDate().toISOString()
      }

      json.id = doc.id
      return json
    } else {
      return undefined
    }
  },
  firestoreJsonToJson(json: any, id: string) {
    json.id = id

    if (json.createdAt) {
      json.createdAt = json.createdAt.toDate().toISOString()
    }
    if (json.lastModifiedAt) {
      json.lastModifiedAt = json.lastModifiedAt.toDate().toISOString()
    }
    if (json.videoEncodedAt) {
      json.videoEncodedAt = json.videoEncodedAt.toDate().toISOString()
    }

    return json
  },
  algoliaJsonToJson(json: any) {
    if (json.createdAt) {
      json.createdAt = this.mapTimestampJsonToTimestamp(json.createdAt).toDate().toISOString()
    }
    if (json.lastModifiedAt) {
      json.lastModifiedAt = this.mapTimestampJsonToTimestamp(json.lastModifiedAt).toDate().toISOString()
    }
    if (json.videoEncodedAt) {
      json.videoEncodedAt = this.mapTimestampJsonToTimestamp(json.videoEncodedAt).toDate().toISOString()
    }

    return json
  },

  mapTimestampJsonToTimestamp(json: any) {
    if (json._seconds) {
      return (new Timestamp(json._seconds, json._nanoseconds))
    } else {
      return (new Timestamp(json.seconds, json.nanoseconds))
    }
  },


  getActualError(error: Error): string {
    // The vuex store library adds on top of the actual error thrown by the action-function a generic and useless error.
    // This function removes that error and returns the actual error we are interested in.

    let lines = error.message.split('\n')

    let trueErrorMessage = ''
    let foundGenericError = false
    let foundActualError = false

    for (let line of lines) {

      if (line.startsWith('Error: Could not perform action')) {
        foundGenericError = true
        continue
      }

      if (foundGenericError && !line.startsWith(' ')) {
        foundActualError = true
      }

      if (foundActualError) {
        trueErrorMessage += line + '\n'
      }
    }

    if (!trueErrorMessage) {
      trueErrorMessage = error.message
    }

    return trueErrorMessage
  }
}
