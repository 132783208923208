



















import {Component, Vue, Watch} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {eventbus} from "@/main"
import {EventType, NavbarViewModel, SidePanelViewModel} from "@/components/app/eventModel"
import SectionLayout from "@/components/fundamental/layout/SectionLayout.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Link from "@/components/fundamental/text/Link.vue"


@Component({
  components: {TextField, SectionLayout, Row, Column, Link}
})
export default class OldVersionsPage extends Vue {

  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  async created() {
    window.scrollTo(0, 0)

    // update navbar
    let navbarViewModel = new NavbarViewModel(true)
    navbarViewModel.back = true
    navbarViewModel.title = 'Previous MC2 Versions'
    eventbus.$emit(EventType.navbar, navbarViewModel)

    // update sidePanel
    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(false))
  }
}
