





















import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import util from '@/util/util'
import dummy_recording0 from "@/test_area/dummy_data/recordings/dummy_recording0"
import {eventbus} from "@/main"
import {EventType, SidePanelViewModel} from "@/components/app/eventModel"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import VideoPlayerWidget from "@/components/video/player/VideoPlayerWidget.vue"
import {VideoController, VideoControllerValue} from "@/components/video/controller/VideoController"

@Component({
  components: {VideoPlayerWidget, TextField, TextButton, Row, Column}
})
export default class TestRecording_videoPlayer extends Vue {

  recording = dummy_recording0
  width = 300
  time = 0

  videoController?: VideoController = undefined
  callbackId = -1

  get timeStr() {
    return util.createTimeString(this.time, false, true)
  }


  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  mounted() {
    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(false))
  }

  beforeDestroy() {
    if (this.videoController && this.callbackId >= 0) {
      this.videoController.removeValueCallback(this.callbackId)
    }
  }

  /////////////////////////////////
  // VideoController
  /////////////////////////////////
  setVideoController(videoController: VideoController) {
    this.callbackId = videoController.addValueCallback((v: VideoControllerValue) => {
      this.time = v.time
    })
    this.videoController = videoController
  }

  /////////////////////////////////
  // Player
  /////////////////////////////////
  togglePlay() {
    if (this.videoController) {
      this.videoController.togglePlay()
    }
  }

  start() {
    if (this.videoController) {
      this.videoController.start()
    }
  }

  pause() {
    if (this.videoController) {
      this.videoController.pause()
    }
  }

  reset() {
    if (this.videoController) {
      this.videoController.setTime(0)
    }
  }
}
