import {ScreenModel} from "@/models/screen/model_screen"
import {ImageModel, ImageType} from "@/models/content/model_image"
import {ContentType} from "@/models/content/model_content"

export default <ScreenModel>{
  "id": "scre-1lepjnTwUUttzXXbXRsQVUVqicU",
  "title": "Test Screen Gif",
  "statics": [
    <ImageModel>{
      type:  ContentType.gif,
      imageType: ImageType.tenor,
      url: "https://media.tenor.com/images/2cd46203bcd1baca01bbdbda46382933/tenor.gif",
      size: 49942,
      fileType: "",
      width: 220,
      height: 131,
      scaling: 1
    }
  ],
}