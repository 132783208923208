




























































import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"

@Component({
  components: {Column}
})
export default class Loading extends Vue {
}
