


































import {Component, Prop, Vue} from "vue-property-decorator"
import ImageUploaderWithPreview from "@/components/basic/images/ImageUploaderWithPreview.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import GifSelector from "@/components/basic/images/GifSelector.vue"
import {ImageModel, ImageType} from "@/models/content/model_image"
import AdvancedScaling from "@/components/fundamental/advanced_inputs/AdvancedScaling.vue"
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue"
import BasicTextEditorDialog from "@/components/screen_editor/content/text/BasicTextEditorDialog.vue"
import AdvancedCheckbox from "@/components/fundamental/advanced_inputs/AdvancedCheckbox.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import SourceEditorDialog from "@/components/screen_editor/content/SourceEditorDialog.vue"

@Component({
  components: {
    SourceEditorDialog,
    Row,
    AdvancedCheckbox,
    BasicTextEditorDialog, AdvancedEditText, AdvancedScaling, GifSelector, Column, Container, ImageUploaderWithPreview,}
})
export default class ImageEditor extends Vue {
  @Prop({type: Object, required: true}) value!: ImageModel

  imageTypes = ImageType
}
