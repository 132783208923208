




































































import {Component, Prop, Vue} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import IconButton from "@/components/fundamental/buttons/IconButton.vue"
import {TextModel} from "@/models/content/model_text"
import AdvancedEditNumber from "@/components/fundamental/advanced_inputs/AdvancedEditNumber.vue"
import AdvancedCheckbox from "@/components/fundamental/advanced_inputs/AdvancedCheckbox.vue"
import InlineColorSelector from "@/components/screen_editor/content/color/InlineColorSelector.vue"
import BasicTextEditorDialog from "@/components/screen_editor/content/text/BasicTextEditorDialog.vue"
import BasicTextEditor from "@/components/screen_editor/content/text/BasicTextEditor.vue";
import SourceEditorDialog from "@/components/screen_editor/content/SourceEditorDialog.vue"

@Component({
  components: {
    SourceEditorDialog,
    BasicTextEditor,
    BasicTextEditorDialog,
    InlineColorSelector,
    AdvancedCheckbox, AdvancedEditNumber,
    IconButton, RoundIconButton,
    SpacerBox, Row, TextField, Column, Container}
})
export default class TextEditor extends Vue {
  @Prop({type: Object, required: true}) value!: TextModel
  @Prop({type: Boolean, default: false, required: false}) inlineText!: boolean
  @Prop({type: Number, required: false, default: -1}) recommendedFontSize!: number
  @Prop({type: Number, required: false, default: 6}) minFontSize!: number
  @Prop({type: Number, required: false, default: 96}) maxFontSize!: number
  @Prop({type: Boolean, default: false, required: false}) setFocusWhenMounted!: boolean
}
