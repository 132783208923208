






















































































import {Component, Prop, Vue} from "vue-property-decorator"

@Component
export default class Row extends Vue {
  @Prop({type: Boolean, default: false, required: false}) left!: boolean
  @Prop({type: Boolean, default: false, required: false}) center!: boolean
  @Prop({type: Boolean, default: false, required: false}) right!: boolean
  @Prop({type: Boolean, default: false, required: false}) top!: boolean
  @Prop({type: Boolean, default: false, required: false}) middle!: boolean
  @Prop({type: Boolean, default: false, required: false}) bottom!: boolean
  @Prop({type: Boolean, default: false, required: false}) spaced!: boolean
  @Prop({type: Boolean, default: false, required: false}) spacedAround!: boolean
  @Prop({type: Boolean, default: false, required: false}) spacedEvenly!: boolean
  @Prop({type: Boolean, default: false, required: false}) wrap!: boolean
  @Prop({type: Boolean, default: false, required: false}) grid3!: boolean

  @Prop({type: Number, default: undefined, required: false}) width!: number
  @Prop({type: Number, default: undefined, required: false}) height!: number

  get styleStr() {
    let s = ''
    s += this.width ? 'width: ' + this.width + 'px; ' : ''
    s += this.height ? 'height: ' + this.height + 'px; ' : ''
    return s
  }

  getDiv() {
    return this.$refs.row
  }
}
