










import {Component, Prop, Vue} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import AdvancedScaling from "@/components/fundamental/advanced_inputs/AdvancedScaling.vue"
import {SpacerModel} from "@/models/content/model_spacer"

@Component({
  components: {AdvancedScaling, Row}
})
export default class ContentSpacerEditor extends Vue {
  @Prop({type: Object, required: true}) content!: SpacerModel
}
