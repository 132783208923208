export default {
  "id": "scre-boanXQH3p4wkuVgMs4lziRg1QLw",
  "title": "Test Screen Gif",
  "statics": [
    {
      "type": "gif",
      "imageType": "tenor",
      "url": "https://media.tenor.com/images/bf63b522ad724ec304a470c9f12ba95a/tenor.gif",
      "size": 25127,
      "fileType": "",
      "width": 220,
      "height": 159,
      "scaling": 1
    }
  ],
  "initialVideoId": "reco-PtMqRufnxxaRbiUUI5LhIUtP4Tk-4",
}