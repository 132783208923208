







































































































































































import {Component, Vue} from "vue-property-decorator"
import {eventbus} from "@/main"
import util from "@/util/util"
import {AlertDialogType, EventType} from "@/components/app/eventModel"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import IconButton from "@/components/fundamental/buttons/IconButton.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import EditText from "@/components/fundamental/inputs/EditText.vue"
import FirestoreInfo from "@/components/basic/FirestoreInfo.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import UnitInfo from "@/pages/unit_editor/UnitInfo.vue"
import store from "@/store/store"
import {UnitLevel, UnitModel, UnitStatus, UnitStatusLabels, UnitType} from "@/models/unit/model_unit"
import AdvancedSelect from "@/components/fundamental/advanced_inputs/AdvancedSelect.vue"
import AdvancedCheckbox from "@/components/fundamental/advanced_inputs/AdvancedCheckbox.vue"
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue";
import BasicTextEditorDialog from "@/components/screen_editor/content/text/BasicTextEditorDialog.vue";

@Component({
  components: {
    BasicTextEditorDialog,
    AdvancedEditText,
    AdvancedCheckbox,
    AdvancedSelect,
    UnitInfo, InputField,
    SelectField, TextButton, SpacerBox, FirestoreInfo, EditText, TextField, IconButton, Row, Column, TransitionEffect
  }
})
export default class UnitDialog extends Vue {
  isVisible = false
  remoteUnit: UnitModel | null = null
  localUnit: UnitModel | null = null

  unitStatus = UnitStatus
  unitStatusValues = util.enumToArray(UnitStatus)
  unitStatusLabels = util.enumToArray(UnitStatusLabels)
  unitTypeValues = util.enumToArray(UnitType)
  unitLevel = UnitLevel
  unitLevelValues = util.enumToArray(UnitLevel)


  /////////////////////////////////
  // Computed
  /////////////////////////////////
  get unsavedChanges() {
    return !util.compare(this.localUnit, this.remoteUnit)
  }

  get error() {
    return !(this.localUnit && this.localUnit.title !== '')
  }


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  async open(unitId: string) {
    this.showLoading(true)

    await store.units.fetchUnit(unitId)
    let unit = store.units.unit

    if (unit) {
      this.remoteUnit = unit
      this.localUnit = util.copy(this.remoteUnit)
      this.isVisible = true
    } else {
      eventbus.$emit(EventType.alertDialog, {alertType: AlertDialogType.oops, oopsMsg: `Could not load unit ${unitId}`})
    }

    this.showLoading(false)
  }

  close() {
    this.isVisible = false
  }

  openUnitEditor() {
    if (!this.localUnit) return

    this.$router.push({path: '/unit-editor/' + this.localUnit.id, query: {back: this.$route.fullPath}})
  }

  async saveUnit() {
    if (!this.localUnit) return

    this.showLoading(true)
    this.close()
    await store.units.updateUnit(this.localUnit)
    this.showLoading(false)
  }

  requestDeleting() {
    if (!this.localUnit) return

    if (this.localUnit.screens.length === 0) {
      this.deleteUnit()
      return
    }

    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.delete_high,
      title: 'Delete unit',
      text: `This will permanently delete the unit: <b>${this.localUnit.title}</b>.<br><br>All <b>screens</b> and <b>recordings</b> will be deleted as well.`,
      confirmAnswer: 'Delete',
      callback: (response: boolean) => {
        if (response) {
          this.deleteUnit()
        }
      }
    })
  }

  deleteUnit() {
    if (!this.localUnit) {
      return
    }

    this.showLoading(true)
    this.close()
    store.units.deleteUnit(this.localUnit)
    this.showLoading(false)
  }

  async copyUnit() {
    if (!this.localUnit) return

    this.showLoading(true)
    this.close()
    await store.units.copyUnit(this.localUnit)
    this.showLoading(false)
  }

  async sendToApp() {
    await store.devSettings.sendUnitIdToApp(this.localUnit!.id)
    eventbus.$emit(EventType.snackbar, {msg: 'Unit ID sent to app', duration: 2000})
  }

  copyLink() {
    util.unitLinkToClipboard(this.localUnit!.id)
  }

  updateLevel(newLevel: UnitLevel) {
    this.$set(this.localUnit!, 'level', newLevel)
  }

  removeLevel() {
    this.$delete(this.localUnit!, 'level')
  }

  updateNotes(str: string) {
    if (!this.localUnit) return

    if (str === '' && this.localUnit.notes !== undefined) {
      this.$delete(this.localUnit, 'notes')
    } else {
      this.$set(this.localUnit, 'notes', str)
    }
  }

  showLoading(show: boolean) {
    eventbus.$emit(EventType.loadingDialog, show)
  }
}
