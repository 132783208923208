import {ContentModel} from "@/models/content/model_content"
import {ActionModel, ActionType} from "@/models/action/model_action"
import model_response from "@/models/action/model_response"
import model_text from "@/models/content/model_text"

export interface McActionModel extends ActionModel {
  fieldWidth: number
  maxFieldHeight?: number
  twoColumns?: boolean
  answers: Array<McAnswerModel>
}

export interface McAnswerModel {
  value: AnswerValue
  content: ContentModel
  videoId?: string
}

export enum AnswerValue {
  false = 'false_',
  true = 'true_',
  neutral = 'neutral',
}

export default {
  template_action(): McActionModel {
    return {
      type: ActionType.mc,
      responses: model_response.template_responses(),
      fieldWidth: 260,
      maxFieldHeight: 120,
      answers: [],
    }
  },
  template_answer(): McAnswerModel {
    let content = model_text.template()
    content.size = 16
    return {
      value: AnswerValue.false,
      content: content,
    }
  },
}