




import {Component, Vue} from "vue-property-decorator"
import Loading from "@/components/fundamental/Loading.vue"
import {eventbus} from "@/main"
import {EventType} from "@/components/app/eventModel"

@Component({
  components: {Loading}
})
export default class LoadingDialog extends Vue {

  isVisible = false

  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  created() {
    eventbus.$on(EventType.loadingDialog,
      (visible: boolean) => {
        this.isVisible = visible
      })
  }

}
