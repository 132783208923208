






































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {PathColor, PathWidth} from "@/models/recording/model_path"
import Container from "@/components/fundamental/layout/Container.vue"
import {ContextMenuEvent} from "@/components/recorder/drawingboard/drawingboardEvents"
import {PointModel} from "@/models/math/model_point"

@Component({
  components: {Container, Row, Column}
})
export default class Eventboard extends Vue {
  @Prop({type: Number, required: true}) width!: number
  @Prop({type: Number, required: false, default: 0}) extraWidth!: number
  @Prop({type: Number, default: 10, required: false}) throttlePeriod!: number
  @Prop({type: Boolean, required: true}) contextMenuVisible!: boolean
  @Prop({type: Boolean, required: true}) active!: boolean

  pointerDown = false
  currentPosition = [0, 0]
  throttleBlocking = false
  // spacerButton?: HTMLDivElement = undefined

  pathColors = PathColor
  pathWidths = PathWidth
  

  /////////////////////////////////
  // Pointer events
  /////////////////////////////////
  eventPointerDown(event: PointerEvent) {
    this.currentPosition = [event.clientX, event.clientY]

    if (this.contextMenuVisible) {
      this.closeContextMenu()
      return
    }

    this.eventDown()
  }

  eventPointerMove(event: PointerEvent) {
    this.currentPosition = [event.clientX, event.clientY]
    this.eventMoveThrottled()
  }

  eventPointerUp() {
    this.eventUp()
  }


  /////////////////////////////////
  // Mouse events
  /////////////////////////////////
  eventMouseDown(event: MouseEvent) {
    this.currentPosition = [event.clientX, event.clientY]

    if (this.contextMenuVisible) {
      this.closeContextMenu()
      return
    }


    switch (event.which) {
      case 1:
        this.eventDown()
        break
      case 2:
        this.deleteLastPath()
        break
      // case 3: this.openContextMenu(); break
      case 3:
        this.togglePathType()
        break
    }
  }

  eventMouseMove(event: MouseEvent) {
    this.currentPosition = [event.clientX, event.clientY]
    this.eventMoveThrottled()
  }

  eventMouseUp() {
    this.eventUp()
  }


  /////////////////////////////////
  // Touch events
  /////////////////////////////////
  eventTouchStart(event: TouchEvent) {
    this.currentPosition = [event.targetTouches[0].clientX, event.targetTouches[0].clientY]

    if (this.contextMenuVisible) {
      this.closeContextMenu()
      return
    }

    this.eventDown()
  }

  eventTouchMove(event: TouchEvent) {
    this.currentPosition = [event.targetTouches[0].clientX, event.targetTouches[0].clientY]
    this.eventMoveThrottled()
  }

  eventTouchUp() {
    this.eventUp()
  }


  /////////////////////////////////
  // General events
  /////////////////////////////////
  eventDown() {
    if (!this.active || this.pointerDown) return

    // if (this.lookForSpacerButtons(this.currentPosition)) return

    this.pointerDown = true
    this.$emit('startPath', this.getPathCoordinates())
  }

  eventMoveThrottled() {
    if (!this.active || !this.pointerDown || this.throttleBlocking) return


    this.throttleBlocking = true
    setTimeout(() => {
      this.eventMove()
      this.throttleBlocking = false
    }, this.throttlePeriod)
  }

  eventMove() {
    if (!this.active || !this.pointerDown) return
    this.$emit('updatePath', this.getPathCoordinates())
  }

  eventUp() {
    // if (this.spacerButton) {
    //     this.clickSpacerButton()
    //     return
    // }

    if (!this.pointerDown) return
    this.pointerDown = false
    this.$emit('stopPath')
  }


  /////////////////////////////////
  // Flashlight or Pen
  /////////////////////////////////
  togglePathType() {
    this.$emit('togglePathType')

    if (this.pointerDown) {
      this.$emit('stopPath')
      this.$emit('startPath', this.getPathCoordinates())
    }
  }

  /////////////////////////////////
  // Context menu
  /////////////////////////////////
  openContextMenu() {
    let contextMenuEvent: ContextMenuEvent = {visible: true, x: this.currentPosition[0] + 3, y: this.currentPosition[1] + 3}
    this.$emit('contextMenu', contextMenuEvent)
  }

  closeContextMenu() {
    let contextMenuEvent: ContextMenuEvent = {visible: false}
    this.$emit('contextMenu', contextMenuEvent)
  }


  /////////////////////////////////
  // Drawing props
  /////////////////////////////////
  setPathColor(color: PathColor) {
    this.$emit('pathColor', color)
  }

  setPathWidth(width: PathWidth) {
    this.$emit('pathWidth', width)
  }


  /////////////////////////////////
  // Delete path
  /////////////////////////////////
  deleteAllPaths() {
    if (!this.active) return
    this.$emit('deleteAll')
  }

  deleteLastPath() {
    if (!this.active) return
    this.$emit('deleteLast')
  }


  /////////////////////////////////
  // Helpers
  /////////////////////////////////
  getPathCoordinates(): PointModel {
    // @ts-ignore
    let rect = this.$refs.eventboard.getDiv().getBoundingClientRect()
    let x = (this.currentPosition[0] - rect.left) / this.width
    let y = (this.currentPosition[1] - rect.top) / this.width
    return {x, y}
  }


  /////////////////////////////////
  // Spacer Buttons
  /////////////////////////////////
  // lookForSpacerButtons(position: Array<number>) {
  //     this.spacerButton = undefined
  //
  //     let elements = document.elementsFromPoint(position[0], position[1])
  //     for (let i = 1; i < Math.min(elements.length, 8); i++) {
  //
  //         // @ts-ignore
  //         if (elements[i].dataset.spacerbutton) {
  //             this.spacerButton = <HTMLDivElement>elements[i]
  //             return true
  //         }
  //     }
  //
  //     return false
  // }

  // clickSpacerButton() {
  //     if (!this.spacerButton) return
  //
  //     // @ts-ignore
  //     let boardRect = this.$refs.eventboard.getDiv().getBoundingClientRect()
  //     let buttonRect = this.spacerButton.getBoundingClientRect()
  //     let y = (buttonRect.top + buttonRect.height/2 - boardRect.top) / this.width
  //
  //     let path = model_path.template_spacer()
  //     // @ts-ignore
  //     path.location = this.spacerButton.dataset.spacerlocation
  //     // @ts-ignore
  //     path.index = parseInt(this.spacerButton.dataset.spacerindex)
  //     // path.y = y
  //     path.height = this.spacerButton.dataset.spacerplus ? 50 : -50
  //
  //     this.$emit('addSpacer', path)
  //     this.spacerButton = undefined
  // }
}
