import Vue from 'vue'
import App from './App.vue'
import router from './router/router'

Vue.use(require('vue-shortkey'))

/////////////////////////////////
// Icons
/////////////////////////////////
import './components/fundamental/icons'


////////////////////////////////////
// eventbus
////////////////////////////////////
export const eventbus = new Vue()


////////////////////////////////////
// Firebase
////////////////////////////////////
import firebase from 'firebase/app'
import 'firebase/analytics'
import firebaseConfig from '../firebase.config'

firebase.initializeApp(firebaseConfig)
firebase.analytics()


/////////////////////////////////
// Store & Prefs
/////////////////////////////////
import appState from "./app/state/app_state"
import store from "./store/store"
import prefs from "@/prefs/prefs"

appState.init()
store.init()
prefs.init()


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
