



































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {RecordingModel} from "@/models/recording/model_recording"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import VideoEditor from "@/components/video/editor/VideoEditor.vue"
import RecordingInfo from "@/components/recorder/RecordingInfo.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import {ScreenModel} from "@/models/screen/model_screen"

@Component({
  components: {Container, RecordingInfo, VideoEditor, RoundIconButton, TextField, SpacerBox, TransitionEffect, Row, Column}
})
export default class VideoEditorDialog extends Vue {
  @Prop({type: Object, required: true}) screen!: ScreenModel
  @Prop({type: Object, required: true}) recording!: RecordingModel
  @Prop({type: Boolean, default: false, required: false}) linkedExercise!: boolean

  isVisible = false

  show() {
    this.isVisible = true
  }

  close() {
    this.isVisible = false
  }
}
