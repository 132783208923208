







import {Component, Prop, Vue} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Checkbox from "@/components/fundamental/inputs/Checkbox.vue"

@Component({
  components: {Checkbox, TextField, Row}
})
export default class AdvancedCheckbox extends Vue {
  @Prop({type: String, required: false, default: ''}) title!: string
  @Prop({required: true}) value!: boolean | undefined
  @Prop({type: Number, default: 0, required: false}) width!: number
  @Prop({type: Number, default: 0, required: false}) titleWidth!: number
  @Prop({type: Boolean, default: false, required: false}) titleBold!: boolean

  get rowStyle() {
    let s = ''
    if (this.width > 0 ) {
      s += 'width:' + this.width + 'px;'
    }
    return s
  }

  get titleStyle() {
    let s = ''
    if (this.titleWidth > 0 ) {
      s += 'width:' + this.titleWidth + 'px;'
    }
    return s
  }

  updateState() {
    this.$emit('input', !this.value)
  }
}
