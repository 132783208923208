


































import {Component, Prop, Vue} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"

@Component({
  components: {Container}
})
export default class JsonView extends Vue {
  @Prop({required: true}) json!: any
  @Prop({type: Boolean, default: true, required: false}) showScrollbars!: boolean
  @Prop({type: Number, default: 12, required: false}) fontSize!: number

  prettyHtml = require('json-pretty-html').default

  get formattedJson() {
    return this.json ? this.prettyHtml(this.json) : ''
  }
}
