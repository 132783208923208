import model_xy_chart, {XyChartModel} from "@/models/content/charts/model_xy_chart"
import {ActionModel, ActionType} from "@/models/action/model_action"
import model_response from "@/models/action/model_response"

export interface DrawPolyActionModel extends ActionModel {
  xyChart: XyChartModel
  funcExpr: string
  maxOrder: number
  snapGrid: boolean
  tolerance: number
}


export default {
  template_action(): DrawPolyActionModel {
    return {
      type: ActionType.drawPoly,
      responses: model_response.template_responses(),
      xyChart: model_xy_chart.template_lineChart(),
      funcExpr: 'x^2',
      maxOrder: 2,
      snapGrid: true,
      tolerance: 0.1,
    }
  },
}