











































































































































































































































































import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import IconButton from "@/components/fundamental/buttons/IconButton.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import MenuButton from "@/components/fundamental/buttons/MenuButton.vue"
import MobileButton from "@/components/fundamental/buttons/MobileButton.vue"
import Container from "@/components/fundamental/layout/Container.vue"

@Component({
  components: {
    Container,
    MobileButton,
    MenuButton, RoundIconButton, IconButton, TextButton, SpacerBox, Row, Column
  }
})
export default class TestHtml_buttons extends Vue {
}
