import { render, staticRenderFns } from "./TestHtml_icons.vue?vue&type=template&id=f3bc9b62&scoped=true&"
import script from "./TestHtml_icons.vue?vue&type=script&lang=ts&"
export * from "./TestHtml_icons.vue?vue&type=script&lang=ts&"
import style0 from "./TestHtml_icons.vue?vue&type=style&index=0&id=f3bc9b62&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3bc9b62",
  null
  
)

export default component.exports