




















































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import {ContentType} from "@/models/content/model_content"

@Component({
  components: {SpacerBox, TextField, RoundIconButton, Container, Row, Column}
})
export default class ListItemContainer extends Vue {
  @Prop({default: '', required: false}) type!: any
  @Prop({type: String, default: '', required: false}) label!: string
  @Prop({type: Boolean, default: false, required: false}) green!: string
  @Prop({type: Boolean, default: false, required: false}) yellow!: string
  @Prop({type: Boolean, default: false, required: false}) red!: string
  @Prop({type: Boolean, default: true, required: false}) showDragIcon!: string
  @Prop({type: Boolean, default: true, required: false}) showDeleteButton!: string

  contentTypes = ContentType
}
