




































































































































































import {Component, Prop, Vue} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Checkbox from "@/components/fundamental/inputs/Checkbox.vue"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import BasicTextEditor from "@/components/screen_editor/content/text/BasicTextEditor.vue"
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import IconButton from "@/components/fundamental/buttons/IconButton.vue"

@Component({
  components: {
    IconButton,
    Container,
    AdvancedEditText, BasicTextEditor, TextButton, Column, TransitionEffect, Checkbox, TextField, Row}
})
export default class SourceEditorDialog extends Vue {
  @Prop({required: true}) value!: string | undefined
  @Prop({type: Boolean, default: false, required: false}) isNullable!: boolean
  @Prop({type: Number, default: 0, required: false}) width!: number
  @Prop({type: Number, default: 0, required: false}) titleWidth!: number
  @Prop({type: Boolean, default: false, required: false}) titleBold!: boolean

  title = ''
  author = ''
  link = ''
  license = ''
  licenseUrl = ''
  date = ''

  isDialogVisible = false

  get rowStyle() {
    let s = ''
    if (this.width > 0 ) {
      s += 'width:' + this.width + 'px;'
    }
    return s
  }

  get titleStyle() {
    let s = ''
    if (this.titleWidth > 0 ) {
      s += 'width:' + this.titleWidth + 'px;'
    }
    return s
  }

  get newValue() {
    return this.title + '&&' + this.author + '&&' + this.link + '&&' + this.license + '&&' + this.licenseUrl + '&&' + this.date
  }

  created() {
    this.updateParameters()
  }

  open() {
    this.updateParameters()
    this.isDialogVisible = true
  }

  save() {
    this.isDialogVisible = false
    this.$emit('input', this.newValue)
  }

  cancel() {
    this.updateParameters()
    this.isDialogVisible = false
  }

  resetValue() {
    this.title = ''
    this.author = ''
    this.link = ''
    this.license = ''
    this.licenseUrl = ''
    this.date = ''
    this.isDialogVisible = false
    this.$emit('input', undefined)
  }

  updateParameters() {
    let v = this.value ?? ''
    let a = v.split('&&')

    if (a.length >= 6) {
      this.title = a[0]
      this.author = a[1]
      this.link = a[2]
      this.license = a[3]
      this.licenseUrl = a[4]
      this.date = a[5]

    } else {
      this.title = v
      this.author = ''
      this.link = ''
      this.license = ''
      this.licenseUrl = ''
      this.date = ''
    }
  }

  removeTitle() {
    this.title = ''
  }

  removeAuthor() {
    this.author = ''
  }

  removeLink() {
    this.link = ''
  }

  removeLicense() {
    this.license = ''
  }

  removeLicenseUrl() {
    this.licenseUrl = ''
  }

  removeDate() {
    this.date = ''
  }

  updateDateToNow() {
    this.date = (new Date()).toISOString()
  }

  addCCBY20License() {
    this.license = 'CC BY 2.0'
    this.licenseUrl = 'https://creativecommons.org/licenses/by/2.0/'
  }

  addCCBY30License() {
    this.license = 'CC BY 3.0'
    this.licenseUrl = 'https://creativecommons.org/licenses/by/3.0/'
  }

}
