









import {Component, Vue} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import firebase from 'firebase/app'
import 'firebase/firestore'
import {UserModel} from "@/models/user/model_user"
import Column from "@/components/fundamental/layout/Column.vue"
import {LogEventBundle} from "@/models/log/model_log_events"
import QuerySnapshot = firebase.firestore.QuerySnapshot
import DocumentData = firebase.firestore.DocumentData
import Timestamp = firebase.firestore.Timestamp

@Component({
  components: {Column, TextButton, Row}
})
export default class TestFirestore_userLogs extends Vue {

  users: Array<UserModel> = []
  allLogs: Array<LogEventBundle> = []
  allPurchases: Array<any> = []


  ////////////////////////////////
  // Runners
  ////////////////////////////////
  async runLoadUsers() {
    console.log('loading users...')

    let users = await this.loadUsers()

    let numVerifiedUsers = 0
    let numAnonymousUsers = 0
    let numGoogleUsers = 0
    let numAbandoned = 0

    for (let user of users) {
      if (user.abandoned) {
        numAbandoned++
      } else if (!user.anonymous) {
        numVerifiedUsers++
        if (user.signInProviders.includes('google.com')) numGoogleUsers++
      } else {
        numAnonymousUsers++
      }
    }

    // result
    this.users = users
    console.log('users', this.users.length - numAbandoned)
    console.log('  - verf:', numVerifiedUsers)
    console.log('    - google:', numGoogleUsers)
    console.log('  - anon:', numAnonymousUsers)
    console.log('abandoned users:', numAbandoned)
  }

  async runLoadLogs() {
    console.log('loading logs...')

    let allLogs = await this.loadLogs(new Date(1995, 11, 17))

    // result
    this.allLogs = allLogs
    console.log('logs:', allLogs.length)
  }

  async runLoadPurchases() {
    console.log('loading purchases...')

    let allPurchases = await this.loadPurchases(new Date(1995, 11, 17))

    // result
    this.allPurchases = allPurchases
    console.log('purchases:', allPurchases.length)
  }

  async runBuildUserPreviews() {
    console.log('build preview...')
    await this.buildUserPreviews(this.users, this.allLogs, this.allPurchases)
  }


  ////////////////////////////////
  // Load
  ////////////////////////////////
  async loadUsers(): Promise<Array<UserModel>> {
    let users: Array<UserModel> = []

    let querySnapshot: QuerySnapshot<DocumentData> = await firebase.firestore()
      .collection('users')
      .orderBy('created')
      .get()

    for (let doc of querySnapshot.docs) {
      users.push(doc.data() as UserModel)
    }

    // abandoned users
    querySnapshot = await firebase.firestore()
      .collection('abandonedUsers')
      .orderBy('created')
      .get()

    for (let doc of querySnapshot.docs) {
      let user = doc.data() as UserModel
      if (user.relatedUserId) continue
      users.push(user)
    }

    users.sort((a, b) => {
      return a.created.toMillis() - b.created.toMillis()
    })

    return users
  }

  async loadLogs(date: Date): Promise<Array<LogEventBundle>> {
    let logBundles = []

    let querySnapshot: QuerySnapshot<DocumentData> = await firebase.firestore()
      .collectionGroup('logs')
      .where('time', '>', Timestamp.fromDate(date))
      .get()

    for (let doc of querySnapshot.docs) {
      let logBundle = doc.data() as LogEventBundle
      logBundle.id = doc.id
      logBundle.abandoned = doc.ref.path.startsWith('abandonedUsers')
      logBundles.push(logBundle)
    }

    return logBundles
  }

  async loadPurchases(date: Date): Promise<Array<any>> {
    let purchases = []

    let querySnapshot: QuerySnapshot<DocumentData> = await firebase.firestore()
      .collectionGroup('subscriptions')
      .where('purchaseTime', '>', Timestamp.fromDate(date))
      .get()

    for (let doc of querySnapshot.docs) {
      let purchase = doc.data()
      purchase['id'] = doc.id
      purchases.push(purchase)
    }

    return purchases
  }

  ////////////////////////////////
  // Merge
  ////////////////////////////////
  buildDateStr(date: Timestamp): string {
    let bd = date.toDate()
    let year = bd.getUTCFullYear()
    let month = bd.getUTCMonth()+1
    let day = bd.getUTCDate()+1
    return `${year}-${month > 9 ? month : `0${month}`}-${day > 9 ? day : `0${day}`}`
  }

  buildUserPreviews(users: Array<UserModel>, allLogs: Array<LogEventBundle>, allPurchases: Array<any>) {
    let userPreviewList = []
    let allNumLogBundles = 0
    let allNumLogEvents = 0

    for (let user of users) {
      let id = user.id
      let email = user.email ? user.email : ''
      let role = user.role[0]
      let delRequest = user.delRequest ? 1 : ''
      let setupCompleted = user.setupCompleted ? 1 : ''
      let freeAccess = user.freeAccess ? 1 : ''
      let googleSignIn = user.signInProviders.includes('google.com') ? 1 : ''
      let abandoned = user.abandoned ? 1 : ''

      let numLogBundles = 0
      let numLogEvents = 0
      for (let log of allLogs) {
        if (log.userId == user.id) {
          numLogBundles += 1
          numLogEvents += log.events.length
        }
      }

      let userStr = `${id},${email},${role},${delRequest},${setupCompleted},${freeAccess},${googleSignIn},${abandoned},${numLogBundles},${numLogEvents}`

      let deviceIds: Array<string> = []
      let deviceStrs = []
      for (let log of allLogs) {
        if (log.userId == user.id && !deviceIds.includes(log.deviceId)) {
          let deviceId = log.deviceId
          let w = Math.round(log.device['display']['w'])
          let h = Math.round(log.device['display']['h'])
          let phone = log.device['os']['phone']
          let model = log.device['os']['m']
          let phoneVersion = log.device['os']['sdk']
          deviceStrs.push(`${deviceId},${w},${h},${phone},${model},${phoneVersion}`)
          deviceIds.push(deviceId)
        }
      }

      let infoStr = ''
      let purchaseStrs = []

      if (user.email) {
        let info = user.info
        let settings = user.settings

        let birthday = info.birthday ? this.buildDateStr(info.birthday) : ''
        let photoProvided = info.photoUrl ? 1 : ''
        let gender = info.gender ? info.gender[0] : ''
        let country = info.country ? info.country.substring(0,2) : ''
        let state = info.state ? info.state.substring(0,2) : ''
        let schoolType = info.schoolType ? info.schoolType.substring(0,2) : ''
        let grade = info.grade ? info.grade : ''

        let theme = settings.theme[0]
        let showGrid = settings.showGrid ? 1 : ''

        infoStr = `${birthday},${photoProvided},${gender},${country},${state},${schoolType},${grade},${theme},${showGrid}`

        for (let p of allPurchases) {
          if (p.userId == user.id) {
            let product = p['productId'].replace('miranda_subscription_', '')

            let purchaseDate = this.buildDateStr(p['purchaseTime'])
            let expiryDate = this.buildDateStr(p['expiryTime'])
            let autoRenewing = p['autoRenewing'] ? 1 : ''
            purchaseStrs.push(`${product},${purchaseDate},${expiryDate},${autoRenewing}`)
          }
        }
      }

      let entry = {
        'c': user.created,
        'u': `${userStr}#${infoStr}#${deviceStrs.join('§')}#${purchaseStrs.join('§')}`
      }

      userPreviewList.push(entry)
      allNumLogBundles += numLogBundles
      allNumLogEvents += numLogEvents
    }

    firebase.firestore().collection('userPreviews').doc().set({
      'created': firebase.firestore.FieldValue.serverTimestamp(),
      'users': userPreviewList,
    })

    console.log(userPreviewList.length)
    console.log('  bundles:', allNumLogBundles)
    console.log('  events:', allNumLogEvents)
    // console.log(userPreviewList)
  }
}

