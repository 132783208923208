







































































import {Component, Prop, Vue} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Slider from "@/components/fundamental/inputs/Slider.vue"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"

@Component({
  components: {SelectField, Slider, TextField, Row}
})
export default class AdvancedSelect extends Vue {
  @Prop({type: String, required: false, default: ''}) title!: string

  @Prop({type: Array, required: true}) values!: Array<any>
  @Prop({type: Array, required: true}) labels!: Array<string>
  @Prop({required: true}) value!: any

  @Prop({type: Boolean, default: false, required: false}) isNotEnum!: boolean

  @Prop({type: Number, default: 0, required: false}) width!: number
  @Prop({type: Number, default: 0, required: false}) titleWidth!: number
  @Prop({type: Boolean, default: false, required: false}) titleBold!: boolean

  @Prop({type: Boolean, default: false, required: false}) white!: boolean
  @Prop({type: Boolean, default: false, required: false}) gray!: boolean
  @Prop({type: Boolean, default: false, required: false}) blue!: boolean
  @Prop({type: Boolean, default: false, required: false}) lightBlue!: boolean
  @Prop({type: Boolean, default: false, required: false}) green!: boolean
  @Prop({type: Boolean, default: false, required: false}) lightGreen!: boolean
  @Prop({type: Boolean, default: false, required: false}) purple!: boolean
  @Prop({type: Boolean, default: false, required: false}) lightPurple!: boolean
  @Prop({type: Boolean, default: false, required: false}) orange!: boolean
  @Prop({type: Boolean, default: false, required: false}) lightOrange!: boolean
  @Prop({type: Boolean, default: false, required: false}) red!: boolean
  @Prop({type: Boolean, default: false, required: false}) lightRed!: boolean

  get rowStyle() {
    let s = ''
    if (this.width > 0 ) {
      s += 'width:' + this.width + 'px;'
    }
    return s
  }

  get titleStyle() {
    let s = ''
    if (this.titleWidth > 0 ) {
      s += 'width:' + this.titleWidth + 'px;'
    }
    return s
  }
}
