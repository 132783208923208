





















































































import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import IconButton from "@/components/fundamental/buttons/IconButton.vue"
import CounterDialog from "@/components/recorder/recorderUtil/CounterDialog.vue"
import {eventbus} from "@/main"
import {EventType} from "@/components/app/eventModel"
import {CameraDetailsJson, EosHandler, EosHandlerResponse} from "@/components/recorder/camera/EosHandler"
import CameraDialog from "@/components/recorder/camera/CameraDialog.vue"
import mMath from "@/util/mMath"
import {
  PathModel,
  FlashlightPathModel,
  PenPathModel,
  SpacerPathModel,
  PathType,
  SliderPathModel,
} from "@/models/recording/model_path"
import EditText from "@/components/fundamental/inputs/EditText.vue"
import Timer from "@/components/recorder/recorderUtil/Timer.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import {StartPathEvent} from "@/components/recorder/drawingboard/drawingboardEvents"
import {RecorderState} from "@/components/recorder/recorderEvents"
import model_recording_raw from "@/models/recording/model_recording_raw"
import prefs from "@/prefs/prefs"
import {PointModel} from "@/models/math/model_point"
import appState from "@/app/state/app_state"
import WhiteBalanceDialog from "@/components/recorder/camera/WhiteBalanceDialog.vue"
import {ReceivedSimulatorMessage_sliderChanged} from "@/simulator/screen/sim_screen_communication";

@Component({
  components: {WhiteBalanceDialog, Container, TextField, Timer, EditText, CameraDialog, CounterDialog, IconButton, Row, Column}
})
export default class RecorderControl extends Vue {
  @Prop({type: String, default: '', required: false}) recordingId!: string

  recorderStates = RecorderState
  recorderState = RecorderState.stopped

  showCameraDialog = false
  showWhiteBalanceDialog = false
  eosHandler: EosHandler = new EosHandler(prefs.cameraUrl)
  useCamera = true
  isCameraRecording = false
  recording = model_recording_raw.template_recordingRaw()


  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  prefs = prefs

  @Watch('prefs.cameraUrl')
  cameraUrlChanged() {
    this.eosHandler.setCameraUrl(this.prefs.cameraUrl)
  }


  /////////////////////////////////
  // Control
  /////////////////////////////////
  closeWhiteBalanceDialogAndStart() {
    this.showWhiteBalanceDialog = false
    this.start()
  }

  start() {
    if (this.recorderState == RecorderState.stopped && (Date.now() - appState.lastTimeShowedWhiteBalanceDialog) > (30 * 60 * 1000)) {
      this.showWhiteBalanceDialog = true
      return
    }

    switch (this.recorderState) {
      case RecorderState.stopped:
        this.recorderState = RecorderState.initializeCamera
        this.initializeCamera()
        break

      case RecorderState.cameraInitialized:
        this.recorderState = RecorderState.startCounter
        // @ts-ignore
        this.$refs.counter.run()
        break

      case RecorderState.counterCompleted:
        this.recorderState = RecorderState.startRecorder
        this.startRecorder()
        break

      case RecorderState.paused:
        this.recorderState = RecorderState.startCounterAfterPause
        // @ts-ignore
        this.$refs.counter.run()
        break

      case RecorderState.counterCompletedAfterPause:
        this.recorderState = RecorderState.startRecorder
        this.restartRecorder()
        break

      case RecorderState.recorderStarted:
        this.recorderState = RecorderState.startCamera
        this.startCamera()
        break

      case RecorderState.cameraStarted:
        this.recorderState = RecorderState.running
        this.$emit('started')
        break
    }
  }

  pause() {
    switch (this.recorderState) {
      case RecorderState.running:
        this.recorderState = RecorderState.pauseRecorder
        this.pauseRecorder()
        break

      case RecorderState.recorderPaused:
        this.recorderState = RecorderState.pauseCamera
        this.stopCamera()
        break

      case RecorderState.cameraPaused:
        this.recorderState = RecorderState.paused
        break
    }
  }

  stop() {
    switch (this.recorderState) {
      case RecorderState.running:
      case RecorderState.paused:
        this.recorderState = RecorderState.stopRecorder
        this.stopRecorder()
        break

      case RecorderState.recorderStopped:
        this.recorderState = RecorderState.stopCamera
        this.stopCamera()
        break

      case RecorderState.cameraStopped:
        this.recorderState = RecorderState.stopped
        this.$emit('stopped', this.recording)
        break
    }
  }


  /////////////////////////////////
  // Recorder
  /////////////////////////////////
  startRecorder() {
    this.createNewRecording()
    // @ts-ignore
    this.$refs.timer.start(this.getRelativeTime())
    this.$emit('clearBoard')
    this.$emit('drawingEnabled', true)

    this.recorderState = RecorderState.recorderStarted
    this.start()
  }

  restartRecorder() {
    this.startSequence()
    // @ts-ignore
    this.$refs.timer.start(this.getRelativeTime())
    this.$emit('drawingEnabled', true)

    this.recorderState = RecorderState.recorderStarted
    this.start()
  }

  pauseRecorder() {
    this.stopSequence()
    // @ts-ignore
    this.$refs.timer.stop()
    this.$emit('drawingEnabled', false)

    this.recorderState = RecorderState.recorderPaused
    this.pause()
  }

  stopRecorder() {
    this.stopSequence()
    // @ts-ignore
    this.$refs.timer.reset()
    this.$emit('drawingEnabled', true)

    this.recorderState = RecorderState.recorderStopped
    this.stop()
  }

  /////////////////////////////////
  // Recording
  /////////////////////////////////
  createNewRecording() {
    this.recording = model_recording_raw.template_recordingRaw()
    if (this.recordingId) {
      this.recording.id = this.recordingId
    }
    this.recording.videoEncodingRequired = this.useCamera
  }

  startSequence() {
    let s = model_recording_raw.template_sequence()
    s.startTime = this.getAbsoluteTime()
    this.recording.sequences.push(s)
  }

  stopSequence() {
    let lastSequence = this.recording.sequences[this.recording.sequences.length - 1]
    if (!lastSequence.stopTime) {
      lastSequence.stopTime = this.getAbsoluteTime()
      lastSequence.duration = mMath.round(lastSequence.stopTime - lastSequence.startTime, 3)
      this.recording.duration = this.getRelativeTime()
    }
  }

  addCameraStartingTime(time: number) {
    let lastSequence = this.recording.sequences[this.recording.sequences.length - 1]
    if (!lastSequence.cameraStartingTime) {
      lastSequence.cameraStartingTime = mMath.round(time, 3)
    }
  }

  addCameraStoppingTime(time: number) {
    let lastSequence = this.recording.sequences[this.recording.sequences.length - 1]
    if (!lastSequence.cameraStoppingTime) {
      lastSequence.cameraStoppingTime = mMath.round(time, 3)
    }
  }

  addVideoData(filename: string, cameraDetails?: CameraDetailsJson) {
    let lastSequence = this.recording.sequences[this.recording.sequences.length - 1]
    if (!lastSequence.filename) {
      lastSequence.filename = filename
      lastSequence.cameraDetails = cameraDetails
    }
  }

  getSequencePaths(): Array<PathModel> {
    return this.recording.sequences[this.recording.sequences.length - 1].paths
  }

  getAbsoluteTime() {
    return mMath.round((Date.now() / 1000 - this.recording.startTime), 3)
  }

  getRelativeTime() {
    let runningTime = 0
    for (let s of this.recording.sequences) {
      runningTime += (s.stopTime ? s.stopTime : this.getAbsoluteTime()) - s.startTime
    }
    return mMath.round(runningTime, 3)
  }


  /////////////////////////////////
  // Update path
  /////////////////////////////////
  startPath(startPathEvent: StartPathEvent) {
    if (this.recorderState !== RecorderState.running) return

    let path: PenPathModel | FlashlightPathModel = {
      type: startPathEvent.type,
      width: startPathEvent.width,
      color: startPathEvent.color,
      time: [this.getAbsoluteTime()],
      x: [mMath.round(startPathEvent.x, 4)],
      y: [mMath.round(startPathEvent.y, 4)],
    }

    let sequencePaths = this.getSequencePaths()
    sequencePaths.push(path)
  }

  updatePath(r: PointModel) {
    if (this.recorderState !== RecorderState.running) return

    let sequencePaths = this.getSequencePaths()
    let lastPath = sequencePaths[sequencePaths.length - 1]

    if (lastPath.type === PathType.pen || lastPath.type === PathType.flashlight) {
      let p = lastPath as PenPathModel
      p.time.push(this.getAbsoluteTime())
      p.x.push(mMath.round(r.x, 4))
      p.y.push(mMath.round(r.y, 4))
    }
  }

  deleteLastPath() {
    if (this.recorderState !== RecorderState.running) return

    let sequencePaths = this.getSequencePaths()
    for (let i = sequencePaths.length - 1; i >= 0; i--) {
      if (sequencePaths[i].type === PathType.pen && !(sequencePaths[i] as PenPathModel).deletionTime) {
        (sequencePaths[i] as PenPathModel).deletionTime = this.getAbsoluteTime()
        break
      } else if (sequencePaths[i].type === PathType.flashlight && !(sequencePaths[i] as FlashlightPathModel).deletionTime) {
        (sequencePaths[i] as FlashlightPathModel).deletionTime = this.getAbsoluteTime()
        break
      }
    }
  }

  deleteAllPaths() {
    if (this.recorderState !== RecorderState.running) return

    let sequencePaths = this.getSequencePaths()
    for (let i = sequencePaths.length - 1; i >= 0; i--) {
      if (sequencePaths[i].type === PathType.pen && !(sequencePaths[i] as PenPathModel).deletionTime) {
        (sequencePaths[i] as PenPathModel).deletionTime = this.getAbsoluteTime()
      } else if (sequencePaths[i].type === PathType.flashlight && !(sequencePaths[i] as FlashlightPathModel).deletionTime) {
        (sequencePaths[i] as FlashlightPathModel).deletionTime = this.getAbsoluteTime()
      }
    }
  }

  addSpacer(path: SpacerPathModel) {
    if (this.recorderState !== RecorderState.running) return

    path.t = this.getAbsoluteTime()
    let sequencePaths = this.getSequencePaths()
    sequencePaths.push(path)
  }

  sliderChanged(event: ReceivedSimulatorMessage_sliderChanged) {
    if (this.recorderState !== RecorderState.running) return

    let sequencePaths = this.getSequencePaths()

    let addedDataToCurrentPath = false
    let l = sequencePaths.length
    if (l > 0 && sequencePaths[l-1].type == PathType.slider) {
      let lastPath = sequencePaths[l - 1] as SliderPathModel
      if (lastPath.ad.ii === event.itemIndex && lastPath.ad.si === event.sliderIndex) {
        lastPath.t.push(this.getAbsoluteTime())
        lastPath.v.push(event.value)
        addedDataToCurrentPath = true
      }
    }

    if (!addedDataToCurrentPath) {
      let p: SliderPathModel = {
        type: PathType.slider,
        ad: {
          lo: event.location,
          ii: event.itemIndex,
          si: event.sliderIndex,
        },
        t: [this.getAbsoluteTime()],
        v: [event.value],
      }
      sequencePaths.push(p)
    }
  }


  /////////////////////////////////
  // Camera
  /////////////////////////////////
  async initializeCamera() {
    let cameraReady = true

    if (this.useCamera) {
      this.showLoading(true)
      await this.eosHandler.initCamera()
      cameraReady = this.eosHandler.cameraStatus().isCameraReady()
      this.showLoading(false)
    }

    if (cameraReady) {
      this.recorderState = RecorderState.cameraInitialized
      this.start()
    } else {
      this.recorderState = RecorderState.stopped
      this.showCameraDialog = true
    }
  }

  async startCamera() {
    let success = true
    let requestTime = 0

    if (this.useCamera) {
      this.showLoading(true)
      let response = await this.eosHandler.startCamera()
      this.showLoading(false)
      success = response.success
      requestTime = response.requestTime
    }

    if (success) {
      this.addCameraStartingTime(requestTime)
      this.isCameraRecording = true
      this.recorderState = RecorderState.cameraStarted
      this.start()
    } else {
      this.recorderState = RecorderState.stopped
    }
  }

  async stopCamera() {
    let response: EosHandlerResponse = {
      success: true,
      requestTime: 0,
      filename: '',
      cameraDetails: undefined
    }


    if (this.useCamera) {
      this.showLoading(true)
      response = await this.eosHandler.stopCamera()
      this.showLoading(false)
    }

    if (response.success) {
      this.addCameraStoppingTime(response.requestTime)
      this.addVideoData(response.filename ? response.filename : '', response.cameraDetails)
      this.isCameraRecording = false

      if (this.recorderState === RecorderState.pauseCamera) {
        this.recorderState = RecorderState.cameraPaused
        this.pause()
      } else {
        this.recorderState = RecorderState.cameraStopped
        this.stop()
      }

    } else {
      this.recorderState = RecorderState.stopped
    }
  }

  /////////////////////////////////
  // Counter
  /////////////////////////////////
  counterCompleted() {
    if (this.recorderState === RecorderState.startCounter) {
      this.recorderState = RecorderState.counterCompleted

    } else if (this.recorderState === RecorderState.startCounterAfterPause) {
      this.recorderState = RecorderState.counterCompletedAfterPause
    }

    this.start()
  }

  /////////////////////////////////
  // Helper
  /////////////////////////////////
  showLoading(show: boolean) {
    eventbus.$emit(EventType.loadingDialog, show)
  }
}
