





























































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import model_action_mc, {McActionModel} from "@/models/action/model_action_mc"
import Draggable from "@/components/fundamental/layout/Draggable.vue"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import ActionSectionHeader from "@/components/basic/ActionSectionHeader.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import EditNumber from "@/components/fundamental/inputs/EditNumber.vue"
import McAnswer from "@/components/screen_editor/action/mc/McAnswer.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import AdvancedEditNumber from "@/components/fundamental/advanced_inputs/AdvancedEditNumber.vue"
import AdvancedSelect from "@/components/fundamental/advanced_inputs/AdvancedSelect.vue"
import {ScreenModel} from "@/models/screen/model_screen"
import {RecordingModel} from "@/models/recording/model_recording"
import AdvancedCheckbox from "@/components/fundamental/advanced_inputs/AdvancedCheckbox.vue"
import {SizeModel} from "@/models/math/model_size"
import {UnitModel} from "@/models/unit/model_unit";

@Component({
  components: {
    AdvancedCheckbox,
    AdvancedSelect, AdvancedEditNumber,
    SpacerBox, McAnswer, EditNumber, SelectField, TextField, ActionSectionHeader, AddButton, Draggable, Row, Column}
})
export default class McActionEditor extends Vue {
  @Prop({type: Object, required: true}) unit!: UnitModel
  @Prop({type: Object, required: true}) action!: McActionModel
  @Prop({type: Object, required: true}) screen!: ScreenModel
  @Prop({type: Array, required: true}) recordings!: Array<RecordingModel>


  get fieldSize(): SizeModel {
    return {height: null, maxWidth: null, width: this.action.fieldWidth, maxHeight: this.action.maxFieldHeight ? this.action.maxFieldHeight: null}
  }

  get maxFieldWidth() {
    return this.action.twoColumns ? 84 : 276
  }

  addAnswer() {
    this.action.answers.push(model_action_mc.template_answer())
  }

  deleteAnswer(index: number) {
    this.$delete(this.action.answers, index)
  }

  updateTwoColumns(state: boolean) {
    this.$set(this.action, 'twoColumns', state)

    if (state && this.action.fieldWidth > 84) {
      this.action.fieldWidth = 84
    }
  }
}
