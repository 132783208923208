export interface Device {
  key: string
  name: string
  width: number
  height: number
}

export class Devices {

  static devices = [
    {
      key: 'default',
      name: 'Default (400x700)',
      width: 400,
      height: 700,
    },
    {
      key: 'fairphone',
      name: 'Fairphone (360x612)',
      width: 360,
      height: 612,
    },
    {
      key: 'oneplus6',
      name: 'Oneplus6 (412x791)',
      width: 412,
      height: 791,
    }
  ]

  static getDevice(key: string): Device {
    let d = this.devices.find(e => e.key === key)
    return d ? d : this.devices[0]
  }

  static getDefault(): Device {
    return this.getDevice('default')
  }

  static getValues(): Array<string> {
    return this.devices.map(e => e.key)
  }

  static getLabels(): Array<string> {
    return this.devices.map(e => e.name)
  }
}