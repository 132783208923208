











































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import EditText from "@/components/fundamental/inputs/EditText.vue"
import EditNumber from "@/components/fundamental/inputs/EditNumber.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import ActionSectionHeader from "@/components/basic/ActionSectionHeader.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import util from "@/util/util"
import LineChartEditor from "@/components/screen_editor/content/chart/line_chart/LineChartEditor.vue"
import AdvancedCheckbox from "@/components/fundamental/advanced_inputs/AdvancedCheckbox.vue"
import AdvancedEditNumber from "@/components/fundamental/advanced_inputs/AdvancedEditNumber.vue"
import {DragPieActionModel} from "@/models/action/model_action_drag_pie"
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue"


@Component({
  components: {
    AdvancedEditText,
    AdvancedEditNumber,
    AdvancedCheckbox,
    LineChartEditor,
    RoundIconButton,
    ActionSectionHeader, SpacerBox, TextField, EditNumber, EditText, AddButton, Row, Column
  }
})
export default class DragPieActionEditor extends Vue {
  @Prop({type: Object, required: true}) action!: DragPieActionModel

  addShare() {
    this.action.targetShares.push(0.2)
    this.action.labels.push('new')
  }

  deleteShare(index: number) {
    util.removeFromArray(this.action.targetShares, index)
    util.removeFromArray(this.action.labels, index)
  }
}
