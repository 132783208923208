




















import {Component, Vue} from 'vue-property-decorator'
import AlertDialog from "@/components/app/AlertDialog.vue"
import SidePanel from "@/components/app/SidePanel.vue"
import LoadingDialog from "@/components/app/LoadingDialog.vue"
import Footer from "@/components/app/Footer.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import Navbar from "@/components/app/Navbar.vue"
import Snackbar from "@/components/app/Snackbar.vue"


@Component({
  components: {Snackbar, Navbar, Column, Footer, LoadingDialog, SidePanel, AlertDialog}
})
export default class APP extends Vue {

}
