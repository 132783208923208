import IntermediateTimer from "@/components/video/controller/IntermediateTimer"
import SimpleVimeoPlayer from "@/components/video/controller/SimpleVimeoPlayer"
import {RecordingModel} from "@/models/recording/model_recording"

export enum PlayerStatus {
  ok = 'ok',
  error = 'error',
  timeout = 'timeout'
}

export class VideoPlayerValue {
  recording: RecordingModel
  width = 0
  containerId = ''

  isInitialized = false
  isBuffering = false
  isPlaying = false
  isFinished = false
  time = 0
  speed = 1
  volume = 1

  status = PlayerStatus.ok

  constructor(recording: RecordingModel, width = 0, containerId = '') {
    this.recording = recording
    this.width = width
    this.containerId = containerId
  }
}


export abstract class VideoPlayer {

  /////////////////////////////////
  // Members
  /////////////////////////////////
  value: VideoPlayerValue
  _valueCallback: ((v: VideoPlayerValue) => void) | null

  /////////////////////////////////
  // Constructor
  /////////////////////////////////
  protected constructor(recording: RecordingModel, valueCallback: (v: VideoPlayerValue) => void, width: number, containerId: string) {
    this.value = new VideoPlayerValue(recording, width, containerId)
    this._valueCallback = valueCallback
  }

  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  destroy() {
    this._valueCallback = null
  }

  /////////////////////////////////
  // Player Methods
  /////////////////////////////////
  abstract togglePlay(): void

  abstract start(): void

  abstract pause(): void

  abstract setTime(time: number): void

  abstract setSpeed(speed: number): void

  abstract setVolume(volume: number): void
}


export class VimeoVideoPlayer extends VideoPlayer {

  /////////////////////////////////
  // Constants
  /////////////////////////////////
  static TIME_OUT = 10 // in seconds


  /////////////////////////////////
  // Members
  /////////////////////////////////
  _requestPlay = false
  _timeoutTimer = 0

  _intermediateTimer: IntermediateTimer
  _vimeoPlayer: SimpleVimeoPlayer


  /////////////////////////////////
  // CONSTRUCTOR
  /////////////////////////////////
  constructor(recording: RecordingModel, valueCallback: (v: VideoPlayerValue) => void, width: number, containerId: string) {
    super(recording, valueCallback, width, containerId)

    this._intermediateTimer = new IntermediateTimer(this.value.recording.duration, (t: number) => {
        this._intermediateTimerCallback(t)
      }
    )

    // init vimeo player
    this._vimeoPlayer = new SimpleVimeoPlayer(this.value.recording.url, this.value.width, this.value.containerId)

    this._vimeoPlayer.on('timeupdate', (data: any) => {
      this._intermediateTimer.updateState(this.value.isPlaying, data.seconds)
      this.value.time = data.seconds
      if (this._valueCallback) {
        this._valueCallback(this.value)
      }
    })

    this._vimeoPlayer.on('bufferstart', (data: any) => {
      this._intermediateTimer.updateState(false, data.seconds)
      this.value.isBuffering = true
      if (this._valueCallback) {
        this._valueCallback(this.value)
      }
    })

    this._vimeoPlayer.on('bufferend', (data: any) => {
      this._intermediateTimer.updateState(this.value.isPlaying, data.seconds)
      this.value.isBuffering = false
      if (this._valueCallback) {
        this._valueCallback(this.value)
      }
    })

    this._vimeoPlayer.on('play', (data: any) => {
      // prevent player from starting autonomously when time is changed in pause-mode
      if (!this._requestPlay) {
        this._vimeoPlayer.pause()
      }

      this.value.isPlaying = true
      this.value.isFinished = false
      this._requestPlay = false

      this._intermediateTimer.updateState(this.value.isPlaying, data.seconds)

      if (this._valueCallback) {
        this._valueCallback(this.value)
      }
    })

    this._vimeoPlayer.on('pause', (data: any) => {
      this.value.isPlaying = false
      this._requestPlay = false

      this._intermediateTimer.updateState(this.value.isPlaying, data.seconds)

      if (this._valueCallback) {
        this._valueCallback(this.value)
      }
    })

    this._vimeoPlayer.on('ended', () => {
      this.value.isPlaying = false
      this.value.isFinished = true
      this._requestPlay = false

      if (this._valueCallback) {
        this._valueCallback(this.value)
      }
      this._vimeoPlayer.setCurrentTime(0)
    })

    this._vimeoPlayer.on('playbackratechange', (data: any) => {
      this.value.speed = data.playbackRate

      if (this._valueCallback) {
        this._valueCallback(this.value)
      }
    })

    this._vimeoPlayer.on('volumechange', (data: any) => {
      this.value.volume = data.volume

      if (this._valueCallback) {
        this._valueCallback(this.value)
      }
    })

    this._vimeoPlayer.setCurrentTime(0)
    // this.setSpeed(speed) // not supported by current vimeo account
    // this.setVolume(this.value.volume)

    this.value.isInitialized = true
    if (this._valueCallback) {
      this._valueCallback(this.value)
    }
  }


  /////////////////////////////////
  // PUBLIC
  /////////////////////////////////
  togglePlay() {
    if (!this.value.isPlaying) {
      this._requestPlay = true
      this._vimeoPlayer.play()
        .then(() => {
          this._status(true)
        })
        .catch((error: any) => {
          this._requestPlay = false
          this._status(false, error)
        })

    } else {
      this._vimeoPlayer.pause()
        .then(() => {
          this._status(true)
        })
        .catch((error: any) => {
          this._status(false, error)
        })
    }
  }

  start() {
    if (!this.value.isPlaying && !this._requestPlay) {
      this.togglePlay()
    }
  }

  pause() {
    if (this.value.isPlaying) {
      this.togglePlay()
    }
  }

  setTime(time: number) {
    this._vimeoPlayer.setCurrentTime(time)
      .then(() => {
        this._status(true)
      })
      .catch((error: any) => {
        this._status(false, error)
      })
  }

  setSpeed(speed: number) {
    this._intermediateTimer.setSpeed(speed)
    this._vimeoPlayer.setPlaybackRate(speed)
      .then(() => {
        this._status(true)
      })
      .catch((error: any) => {
        this._status(false, error)
      })
  }

  setVolume(volume: number) {
    this._vimeoPlayer.setVolume(volume)
      .then(() => {
        this._status(true)
      })
      .catch((error: any) => {
        this._status(false, error)
      })
  }

  destroy() {
    super.destroy()
    this._intermediateTimer.destroy()
    this._vimeoPlayer.destroy()
      .then(() => {
        this._status(true)
      })
      .catch((error: any) => {
        this._status(false, error)
      })
  }


  /////////////////////////////////
  // PRIVATE
  /////////////////////////////////
  _status(status: boolean, errorMsg = '') {
    if (errorMsg) {
      console.log(errorMsg)
    }

    clearTimeout(this._timeoutTimer)
    if (!status) {
      this._timeoutTimer = setTimeout(() => {
        this.value.status = PlayerStatus.timeout

        if (this._valueCallback) {
          this._valueCallback(this.value)
        }

      }, VimeoVideoPlayer.TIME_OUT * 1000)
    }

    this.value.status = status ? PlayerStatus.ok : PlayerStatus.error

    if (this._valueCallback) {
      this._valueCallback(this.value)
    }
  }

  _intermediateTimerCallback(time: number) {
    this.value.time = time

    if (this._valueCallback) {
      this._valueCallback(this.value)
    }
  }
}


export class MockVideoPlayer extends VideoPlayer {

  /////////////////////////////////
  // CONST
  /////////////////////////////////
  static FRAME_DURATION = 0.040


  /////////////////////////////////
  // Members
  /////////////////////////////////
  _timer = 0


  /////////////////////////////////
  // CONSTRUCTOR
  /////////////////////////////////
  constructor(recording: RecordingModel, valueCallback: (v: VideoPlayerValue) => void, width: number) {
    super(recording, valueCallback, width, '')

    this.value.isInitialized = true

    if (this._valueCallback) {
      this._valueCallback(this.value)
    }
  }


  /////////////////////////////////
  // PUBLIC
  /////////////////////////////////
  togglePlay() {
    clearTimeout(this._timer)
    this.value.isPlaying = !this.value.isPlaying
    this._run()
  }

  start() {
    if (!this.value.isPlaying) {
      this.togglePlay()
    }
  }

  pause() {
    if (this.value.isPlaying) {
      this.togglePlay()
    }
  }

  setTime(time: number) {
    clearTimeout(this._timer)
    this.value.time = time
    this._run()
  }

  setSpeed(speed: number) {
    this.value.speed = speed

    if (this._valueCallback) {
      this._valueCallback(this.value)
    }
  }

  setVolume(volume: number) {
    this.value.volume = volume

    if (this._valueCallback) {
      this._valueCallback(this.value)
    }
  }

  destroy() {
    super.destroy()
  }


  /////////////////////////////////
  // PRIVATE
  /////////////////////////////////
  _run() {
    if (this.value.isPlaying) {
      this.value.time = Math.min(
        this.value.time + MockVideoPlayer.FRAME_DURATION * this.value.speed,
        this.value.recording.duration
      )
      this._timer = setTimeout(() => {
        this._run()
      }, MockVideoPlayer.FRAME_DURATION * 1000)
    }

    this.value.isFinished = this.value.time >= this.value.recording.duration
    if (this.value.isFinished) {
      clearTimeout(this._timer)
      this.value.time = 0
      this.value.isPlaying = false
    }

    if (this._valueCallback) {
      this._valueCallback(this.value)
    }
  }
}