import firebase from "firebase/app"
import Timestamp = firebase.firestore.Timestamp
import store from "@/store/store"
import util from "@/util/util"

export enum CatalogTopicType {
  tip = 'tip',
  correct = 'correct',
  wrong = 'wrong',
  achievementShort = 'achievementShort',
  achievementLong = 'achievementLong',
}

export interface SoundCatalogModel {
  id: string
  tip: Array<SoundModel>
  correct: Array<SoundModel>
  wrong: Array<SoundModel>
  achievementShort: Array<SoundModel>
  achievementLong: Array<SoundModel>
}

export interface SoundModel {
  id: string
  createdAt: Timestamp
  createdBy: string
  lastModifiedAt: Timestamp
  lastModifiedBy: string

  title: string
  url: string
  size: number
  duration: number
  fileType: string
}

export default {
  template_soundCatalog(id: string): SoundCatalogModel {
    return {
      id: id,
      tip: [],
      correct: [],
      wrong: [],
      achievementShort: [],
      achievementLong: [],
    }
  },
  template_sound(): SoundModel {
    return {
      id: util.getId('so'),
      createdAt: Timestamp.now(),
      createdBy: store.auth.userEmail,
      lastModifiedAt: Timestamp.now(),
      lastModifiedBy: store.auth.userEmail,
      title: '',
      url: '',
      size: 0,
      duration: 0,
      fileType: '',
    }
  },
}