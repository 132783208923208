export interface PointModel {
  x: number
  y: number
}

export default {
  template(): PointModel {
    return {
      x: 0,
      y: 0,
    }
  },
}
