












































































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"

@Component({
  components: {SelectField, RoundIconButton, Container, Row, Column}
})
export default class SideToolbox extends Vue {
  @Prop({type: Boolean, default: false, required: false}) borders!: boolean
  @Prop({type: Boolean, default: false, required: false}) flashlight!: boolean
  @Prop({type: Boolean, default: false, required: false}) drawingModeActive!: boolean
}
