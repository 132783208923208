



















































































































































































































































































































































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {UnitLevel, UnitModel, UnitStatus, UnitStatusLabels, UnitType} from "@/models/unit/model_unit"
import EditText from "@/components/fundamental/inputs/EditText.vue"
import IconButton from "@/components/fundamental/buttons/IconButton.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import UnitInfo from "@/pages/unit_editor/UnitInfo.vue"
import FirestoreInfo from "@/components/basic/FirestoreInfo.vue"
import CurriculumInfo from "@/pages/curriculum_viewer/CurriculumInfo.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import {RecordingModel} from "@/models/recording/model_recording"
import UnitExtendedInfo from "@/pages/unit_editor/UnitExtendedInfo.vue"
import AdvancedSelect from "@/components/fundamental/advanced_inputs/AdvancedSelect.vue"
import util from "@/util/util"
import store from "@/store/store"
import {eventbus} from "@/main"
import {EventType} from "@/components/app/eventModel"
import AdvancedCheckbox from "@/components/fundamental/advanced_inputs/AdvancedCheckbox.vue"
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue"
import BasicTextEditorDialog from "@/components/screen_editor/content/text/BasicTextEditorDialog.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import AdvancedEditNumber from "@/components/fundamental/advanced_inputs/AdvancedEditNumber.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import InlineColorSelector from "@/components/screen_editor/content/color/InlineColorSelector.vue"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import {ColorModel} from "@/models/model_colors"
import ImageUploaderWithPreview from "@/components/basic/images/ImageUploaderWithPreview.vue"
import model_image, {ImageModel} from "@/models/content/model_image"
import ImageUploaderDialog from "@/components/basic/images/ImageUploaderDialog.vue"
import mMath from "@/util/mMath"
import model_thumbnail, {
  ChartThumbnailModel,
  FormulaThumbnailModel, ImageThumbnailModel,
  ThumbnailType
} from "@/models/unit/model_thumbnail"

@Component({
  components: {
    ImageUploaderDialog,
    ImageUploaderWithPreview,
    AddButton,
    InlineColorSelector,
    RoundIconButton,
    AdvancedEditNumber,
    TextButton,
    BasicTextEditorDialog,
    AdvancedEditText,
    AdvancedCheckbox,
    AdvancedSelect,
    UnitExtendedInfo,
    TextField, SelectField, InputField, CurriculumInfo, FirestoreInfo, UnitInfo, SpacerBox, IconButton, EditText, Row, Column
  }
})
export default class UnitEditor extends Vue {
  @Prop({type: Object, required: true}) unit!: UnitModel
  @Prop({type: Array, default: undefined, required: false}) recordings!: Array<RecordingModel>

  unitStatus = UnitStatus
  unitStatusValues = util.enumToArray(UnitStatus)
  unitStatusLabels = util.enumToArray(UnitStatusLabels)
  unitTypeValues = util.enumToArray(UnitType)
  unitLevel = UnitLevel
  unitLevelValues = util.enumToArray(UnitLevel)

  thumbnailTypeValues = util.enumToArray(ThumbnailType)
  thumbnailType = ThumbnailType.chart
  thumbnailImage: ImageModel = model_image.template_image()

  // getters
  get chartThumbnail(): ChartThumbnailModel|null {
    return this.unit.thumbnail && this.unit.thumbnail.ty === ThumbnailType.chart ? this.unit.thumbnail as ChartThumbnailModel : null
  }

  get formulaThumbnail(): FormulaThumbnailModel|null {
    return this.unit.thumbnail && this.unit.thumbnail.ty === ThumbnailType.formula ? this.unit.thumbnail as FormulaThumbnailModel : null
  }

  get imageThumbnail(): ImageThumbnailModel|null {
    return this.unit.thumbnail && this.unit.thumbnail.ty === ThumbnailType.image ? this.unit.thumbnail as ImageThumbnailModel : null
  }


  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  created() {

  }


  ////////////////////////////////
  // Methods
  ////////////////////////////////
  async sendToApp() {
    await store.devSettings.sendUnitIdToApp(this.unit.id)
    eventbus.$emit(EventType.snackbar, {msg: 'Unit ID sent to app', duration: 2000})
  }

  copyLink() {
    util.unitLinkToClipboard(this.unit.id)
  }

  updateNotes(str: string) {
    if (str === '' && this.unit.notes !== undefined) {
      this.$delete(this.unit, 'notes')
    } else {
      this.$set(this.unit, 'notes', str)
    }
  }

  updateLevel(newLevel: UnitLevel) {
    this.$set(this.unit, 'level', newLevel)
  }

  removeLevel() {
    this.$delete(this.unit, 'level')
  }

  addThumbnail() {
    switch (this.thumbnailType) {
      case ThumbnailType.chart:
        this.$set(this.unit, 'thumbnail', model_thumbnail.template_chartThumbnail())
        break

      case ThumbnailType.formula:
        this.$set(this.unit, 'thumbnail', model_thumbnail.template_formulaThumbnail())
        break

      case ThumbnailType.image:
        this.$set(this.unit, 'thumbnail', model_thumbnail.template_imageThumbnail())
        break
    }

  }

  deleteThumbnail() {
    this.$delete(this.unit, 'thumbnail')
  }

  addChartThumbnailFunction2() {
    this.$set(this.unit.thumbnail!, 'f2', 'x^2')
    this.$set(this.unit.thumbnail!, 'c2', ColorModel.cyan)
  }

  deleteChartThumbnailFunction2() {
    this.$delete(this.unit.thumbnail!, 'f2')
    this.$delete(this.unit.thumbnail!, 'c2')
  }

  openThumbnailImageDialog() {
    if (!this.imageThumbnail) return null
    this.thumbnailImage.url = this.imageThumbnail.ur
    this.thumbnailImage.width = 1
    this.thumbnailImage.height = this.imageThumbnail.hw

    // @ts-ignore
    this.$refs.imageDialog.open()
  }

  onClosedThumbnailImageDialog() {
    if (!this.imageThumbnail) return null
    this.imageThumbnail.ur = this.thumbnailImage.url
    this.imageThumbnail.hw = mMath.round(this.thumbnailImage.width / this.thumbnailImage.height, 2)
  }
}
