import {
  PathModel,
  FlashlightPathModel,
  PenPathModel,
  SpacerPathModel,
  PathType,
  SliderPathModel
} from "@/models/recording/model_path"
import {RecordingModel} from "@/models/recording/model_recording"
import util from "@/util/util"

export default class PathHandler {

  /////////////////////////////////
  // Members
  /////////////////////////////////
  _recording: RecordingModel

  /////////////////////////////////
  // CONSTRUCTOR
  /////////////////////////////////
  constructor(recording: RecordingModel) {
    this._recording = recording
  }


  /////////////////////////////////
  // Public
  /////////////////////////////////
  updateTime(time: number): Array<PathModel> {
    return this._run(time)
  }

  updateProgress(progress: number): Array<PathModel> {
    return this._run(progress * this._recording.duration)
  }


  /////////////////////////////////
  // Private
  /////////////////////////////////
  _run(time: number): Array<PathModel> {
    if (time === 0) return []

    let currentPaths = []

    for (let path of this._recording.paths) {

      // spacer
      if (path.type === PathType.spacer) {
        let p = <SpacerPathModel>path

        if (p.t > time) break

        currentPaths.push(util.copy(p))

      // slider
      } else if (path.type === PathType.slider) {
        let p = <SliderPathModel>path

        if (p.t[0] > time) break

        let newPath = <SliderPathModel>{
          type: path.type,
          ad: p.ad,
          v: [] as Array<number>,
          t: [] as Array<number>,
        }

        for (let i = 0; i < p.t.length; i++) {
          if (p.t[i] <= time) {
            newPath.t.push(p.t[i])
            newPath.v.push(p.v[i])
          } else {
            break
          }
        }

        currentPaths.push(newPath)

      // flashlight
      } else if (path.type === PathType.flashlight) {
        let p = <FlashlightPathModel>path

        if (p.time[0] > time) break

        currentPaths.push(this._copyPartialPath(time, p))

      // pen
      } else if (path.type === PathType.pen) {
        let p = <PenPathModel>path

        if (p.time[0] > time) break

        // skip path if deletionTime is reached
        if (p.deletionTime && p.deletionTime < time) continue

        currentPaths.push(this._copyPartialPath(time, p))
      }
    }

    return currentPaths
  }

  _copyPartialPath(time: number, path: PenPathModel | FlashlightPathModel) {
    let newPath = {
      type: path.type,
      color: path.color,
      width: path.width,
      x: [] as Array<number>,
      y: [] as Array<number>,
      time: [] as Array<number>,
    }

    for (let i = 0; i < path.time.length; i++) {
      if (path.time[i] <= time) {
        newPath.x.push(path.x[i])
        newPath.y.push(path.y[i])
        newPath.time.push(path.time[i])
      } else {
        break
      }
    }

    return newPath
  }

}