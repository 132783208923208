import {ContentModel, ContentType} from "@/models/content/model_content"

export interface TableModel extends ContentModel {
  rows: Array<RowModel>
  columnWidths: Array<number>
  showHeaderRow: boolean
  showHeaderColumn: boolean
  hideBorders?: boolean
  fontSize: number
  scaling: number
  alignment?: Alignment
  horizontalCellPadding?: number
  verticalCellPadding?: number
}

export enum Alignment {
  topLeft = 'topLeft',
  topCenter = 'topCenter',
  topRight = 'topRight',
  centerLeft = 'centerLeft',
  center = 'center',
  centerRight = 'centerRight',
  bottomLeft = 'bottomLeft',
  bottomCenter = 'bottomCenter',
  bottomRight = 'bottomRight',
}

export interface RowModel {
  cells: Array<string>
}

export default {
  template(): TableModel {
    return {
      type: ContentType.table,
      showHeaderRow: true,
      showHeaderColumn: false,
      rows: [
        {cells: ['', '', '']},
        {cells: ['', '', '']},
        {cells: ['', '', '']},
      ],
      columnWidths: [0, 0, 0],
      fontSize: 16,
      scaling: 1,
    }
  },
}