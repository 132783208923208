




























import {Component, Vue, Watch} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import {EventType, NavbarViewModel, SidePanelViewModel} from "@/components/app/eventModel"
import {eventbus} from "@/main"
import {Route} from "vue-router"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import store from "@/store/store"

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate', 'beforeRouteLeave'])

@Component({
  components: {SpacerBox, TextButton, TextField, Column},
})
export default class LoginPage extends Vue {

  auth = store.auth

  /////////////////////////////////
  // Life Cycle
  /////////////////////////////////
  beforeRouteEnter(to: Route, from: Route, next: () => void) {
    eventbus.$emit(EventType.navbar, new NavbarViewModel(false))
    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(false))
    next()
  }

  mounted() {
    eventbus.$emit(EventType.loadingDialog, !this.auth.signedInStateKnown)
  }

  beforeRouteLeave(to: Route, from: Route, next: () => void) {
    eventbus.$emit(EventType.loadingDialog, false)
    next()
  }

  @Watch('auth.signedInStateKnown')
  authChanged() {
    eventbus.$emit(EventType.loadingDialog, !store.auth.signedInStateKnown)
  }
}
