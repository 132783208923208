







import {Component, Prop, Vue} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"

@Component({
  components: {SpacerBox, TextField, Row}
})
export default class ActionSectionHeader extends Vue {
  @Prop({type: String, required: true}) title!: string
  @Prop({type: Boolean, default: false, required: false}) isTop!: boolean
}
