import Vector from "@/util/Vector"

export default {

  /////////////////////////////////
  // Numbers
  /////////////////////////////////
  constrain(value: number, min: number, max: number) {
    return Math.min(max, Math.max(min, value))
  },
  round(number: number, digits: number) {
    let n = Math.pow(10, digits)
    return Math.round(number * n) / n
  },


  /////////////////////////////////
  // Vectors
  /////////////////////////////////
  // norm(v1: Vector, v2: Vector) {
  //     let s = 0
  //     for (let i = 0; i < v1.length; i++) {
  //         s += Math.pow(v1[i] - v2[i], 2)
  //     }
  //     return Math.sqrt(s)
  // },
  // norm_sq(v1: Vector, v2: Vector) {
  //     let s = 0
  //     for (let i = 0; i < v1.length; i++) {
  //         s += Math.pow(v1[i] - v2[i], 2)
  //     }
  //     return s
  // },
  angleBetweenVectors(v0: Vector, v1: Vector) { // 0 - 2pi
    let n0 = v0.norm()
    let n1 = v1.norm()
    if (n0 === 0 || n1 === 0) return 0

    let alpha = Math.atan2(v0.x * v1.y - v0.y * v1.x, v0.x * v1.x + v0.y * v1.y)
    if (alpha < 0) {
      alpha = 2 * Math.PI + alpha
    }
    return alpha
  },


  /////////////////////////////////
  // Angles
  /////////////////////////////////
  constrainAngle(alpha: number) {
    if (alpha < 0) {
      alpha = 2 * Math.PI - ((-alpha) % (2 * Math.PI))
    } else if (alpha > 2 * Math.PI) {
      alpha %= (2 * Math.PI)
    }
    return alpha
  },
  addDegToAngle(alpha: number, deg: number) {
    alpha += deg / 180 * Math.PI
    return this.constrainAngle(alpha)
  },
  addRadToAngle(alpha: number, rad: number) {
    alpha += rad
    return this.constrainAngle(alpha)
  },
  getAbsDeltaAngle(alpha: number, beta: number) {
    let d = Math.abs(this.constrainAngle(beta) - this.constrainAngle(alpha))
    if (d > Math.PI) {
      d = 2 * Math.PI - d
    }
    return d
  },
  getCcwDeltaAngle(alpha: number, beta: number) {
    let a = this.constrainAngle(alpha)
    let b = this.constrainAngle(beta)

    if (b >= a) {
      return b - a
    } else {
      return 2 * Math.PI - a + b
    }
  },


  /////////////////////////////////
  // Functions
  /////////////////////////////////
  evalFunction(functionExpression: string): boolean {
    let e = 2.71828182846
    let pi = 3.14159265359
    // TODO sin, cos, tan, sinh, cosh, tanh, ln, log, exp, sign


    try {
      let x = 0
      let y = eval(functionExpression)
      return true
    } catch (e) {
      return false
    }
  },
}

