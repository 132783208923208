import {ContentModel} from "@/models/content/model_content"
import {ActionModel, ActionType} from "@/models/action/model_action"
import model_response from "@/models/action/model_response"
import model_text from "@/models/content/model_text"

export interface DragConnectActionModel extends ActionModel {
  startFieldWidth: number
  startFieldHeight: number
  endFieldWidth: number
  endFieldHeight: number
  startFields: Array<DragConnectFieldModel>
  endFields: Array<DragConnectFieldModel>
}

export interface DragConnectFieldModel {
  target?: number
  content: ContentModel
}

export default {
  template_action(): DragConnectActionModel {
    return {
      type: ActionType.dragConnect,
      responses: model_response.template_responses(),
      startFieldWidth: 144,
      startFieldHeight: 80,
      endFieldWidth: 144,
      endFieldHeight: 80,
      startFields: [],
      endFields: [],
    }
  },
  template_startField(): DragConnectFieldModel {
    return {
      target: 0,
      content: model_text.template(),
    }
  },
  template_endField(): DragConnectFieldModel {
    return {
      content: model_text.template(),
    }
  },
}