






















import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"

@Component({
  components: {RoundIconButton, Row, Column}
})
export default class BottomNavigation extends Vue {

  buttons = [
    'IconCamera',
    'IconCloudUploadOutline',
    'IconTeach',
    'IconCalculator',
    'IconSubtitles'
  ]

}
