











import {Component, Prop, Vue} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import util from "@/util/util"
import model_content, {ContentModel, ContentType, SelectableContentType} from "@/models/content/model_content"
import model_text, {TextModel} from "@/models/content/model_text"
import model_image from "@/models/content/model_image"
import model_table from "@/models/content/model_table"
import model_spacer from "@/models/content/model_spacer"
import AdvancedSelect from "@/components/fundamental/advanced_inputs/AdvancedSelect.vue"
import model_pie_chart from "@/models/content/charts/model_pie_chart";
import model_xy_chart from "@/models/content/charts/model_xy_chart"

@Component({
  components: {AdvancedSelect, Row}
})
export default class ContentTypeSelector extends Vue {
  @Prop({type: Object, required: true}) content!: ContentModel
  @Prop({type: Array, default: [], required: false}) invalidTypes!: SelectableContentType
  @Prop({type: Boolean, default: false, required: false}) isHeadline!: boolean
  @Prop({type: Number, default: 18, required: false}) recommendedFontSize!: number


  /////////////////////////////////
  // Getters
  /////////////////////////////////
  get selectableContentTypes() {
    let types = util.enumToArray(SelectableContentType)

    for (let t of this.invalidTypes) {
      types.splice(types.indexOf(t), 1)
    }
    return types
  }

  get currentType() {
    switch (this.content.type) {
      case ContentType.none: return SelectableContentType.none
      case ContentType.text: return SelectableContentType.text
      case ContentType.textDivider: return SelectableContentType.textDivider
      case ContentType.image: return SelectableContentType.image
      case ContentType.gif: return SelectableContentType.gif
      case ContentType.xyChart: return SelectableContentType.lineChart
      case ContentType.boxPlot: return SelectableContentType.boxPlot
      case ContentType.pieChart: return SelectableContentType.pieChart
      case ContentType.table: return SelectableContentType.table
      case ContentType.spacer: return SelectableContentType.spacer
    }
  }


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  updateType(type: SelectableContentType) {
    if (type === this.currentType) return

    let newContent
    switch (type) {
      case SelectableContentType.none:
        newContent = model_content.template_none()
        break

      case SelectableContentType.textDivider:
        newContent = model_content.template_textDivider()
        break

      case SelectableContentType.text:
        newContent = model_text.template()
        if (this.recommendedFontSize) {
          (<TextModel>newContent).size = this.recommendedFontSize
        }
        break

      case SelectableContentType.image:
        newContent = model_image.template_image()
        break

      case SelectableContentType.gif:
        newContent = model_image.template_gif()
        break

      case SelectableContentType.lineChart:
        newContent = model_xy_chart.template_lineChart()
        break

      case SelectableContentType.lineChart_small:
        newContent = model_xy_chart.template_lineChart_small()
        break

      case SelectableContentType.pieChart:
        newContent = model_pie_chart.template()
        break

      case SelectableContentType.boxPlot:
        newContent = model_xy_chart.template_boxPlot()
        break

      case SelectableContentType.table:
        newContent = model_table.template()
        break

      case SelectableContentType.spacer:
        newContent = model_spacer.template()
        break
    }

    this.$emit('onContentChanged', newContent)
  }
}
