








































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {RecordingModel} from "@/models/recording/model_recording"
import {eventbus} from '@/main'
import {AlertDialogType, EventType} from "@/components/app/eventModel"
import {RouteName} from "@/router/router"
import util from "@/util/util"
import TextField from "@/components/fundamental/text/TextField.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import FirestoreInfo from "@/components/basic/FirestoreInfo.vue"
import Link from "@/components/fundamental/text/Link.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import store from "@/store/store"


@Component({
  components: {Container, SpacerBox, Link, FirestoreInfo, RoundIconButton, TextField, Row, Column}
})
export default class VideoInfo extends Vue {
  @Prop({type: Object, required: true}) recording!: RecordingModel
  @Prop({type: Boolean, default: false, required: false}) center!: boolean


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  createTimeString(time: number) {
    return util.createTimeString(time, false, false)
  }

  requestResettingVideoEncoding() {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.error,
      title: 'Reset Video Encoding',
      text: 'Reset the video encoding to reupload a video if the first upload failed. Run the <b>Video Converter</b> for reuploading the video.<br><br><b>ATTENTION:</b> Current changes will be discarded if you continue.',
      positiveButton: 'Continue',
      negativeButton: 'Cancel',
      callback: (response: boolean) => {
        if (response) this.resetVideoEncoding()
      }
    })
  }

  async resetVideoEncoding() {
    try {
      eventbus.$emit(EventType.loadingDialog, true)
      await store.recordings.resetVideoEncoding(this.recording.id)
      this.$router.push({name: RouteName.converter}).then()

    } catch (e) {
      eventbus.$emit(EventType.loadingDialog, false)
      eventbus.$emit(EventType.alertDialog, {alertType: AlertDialogType.oops, oopsMsg: e})
    }
  }
}
