export default {
  "id": "scre-lQQtcqvqVYS42lflcYNRYbZjvQk",
  "title": "Test Screen Gif",
  "statics": [
    {
      "type": "gif",
      "imageType": "tenor",
      "url": "https://media.tenor.com/images/0a977c1771c8b724e3470456d61660de/tenor.gif",
      "size": 24006,
      "fileType": "",
      "width": 220,
      "height": 114,
      "scaling": 1
    }
  ],
  "initialVideoId": "reco-PtMqRufnxxaRbiUUI5LhIUtP4Tk-2",
}