export interface LimitsModel {
  x_min: number
  x_max: number
  y_min: number
  y_max: number
}

export default {
  copyLimits(limits: LimitsModel): LimitsModel {
    return {x_min: limits.x_min, x_max: limits.x_max, y_min: limits.y_min, y_max: limits.y_max}
  },
  compareLimits(limits1: LimitsModel, limits2: LimitsModel): boolean {
    return limits1.x_min === limits2.x_min &&
      limits1.x_max === limits2.x_max &&
      limits1.y_min === limits2.y_min &&
      limits1.y_max === limits2.y_max
  },
  template_lineChart(): LimitsModel {
    return {
      x_min: -5,
      x_max: 5,
      y_min: -5,
      y_max: 5,
    }
  },
  template_boxPlot(): LimitsModel {
    return {
      x_min: -5,
      x_max: 5,
      y_min: 0,
      y_max: 1,
    }
  },
}