import model_xy_chart, {XyChartModel} from "@/models/content/charts/model_xy_chart"
import {VectorModel} from "@/models/math/model_vector"
import {ActionModel, ActionType} from "@/models/action/model_action"
import model_response from "@/models/action/model_response"

export interface DrawVectorActionModel extends ActionModel {
  xyChart: XyChartModel
  targetVectors: Array<VectorModel>
  snapGrid: boolean
  absolutePosition?: boolean
  tolerance: number
  hideArrowHead?: boolean
  fixStartPosition?: boolean
  fixLength?: boolean
}


export default {
  template_action(): DrawVectorActionModel {
    return {
      type: ActionType.drawVector,
      responses: model_response.template_responses(),
      xyChart: model_xy_chart.template_lineChart(),
      targetVectors: [],
      snapGrid: true,
      tolerance: 0,
    }
  },
}