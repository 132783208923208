






































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {RecordingModel} from '@/models/recording/model_recording'
import ContentEditorTitle from "@/pages/unit_editor/ContentEditorTitle.vue"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import VideoEditor from "@/components/video/editor/VideoEditor.vue"
import RecordingInfo from "@/components/recorder/RecordingInfo.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import NewRecordingButton from "@/components/recorder/recorderWindow/NewRecordingButton.vue"
import PreviewPlayerWidget from "@/components/video/player/PreviewPlayerWidget.vue"
import {ScreenModel} from "@/models/screen/model_screen"
import appState from "@/app/state/app_state"
import {UnitModel} from "@/models/unit/model_unit"
import {PredefinedActionState} from '@/simulator/screen/sim_screen_communication'

@Component({
  components: {
    PreviewPlayerWidget,
    NewRecordingButton, SpacerBox, RecordingInfo, VideoEditor, TransitionEffect, ContentEditorTitle, Row, Column
  }
})
export default class YtVideoEditor extends Vue {
  @Prop({type: Object, required: true}) unit!: UnitModel
  @Prop({type: Object, required: true}) screen!: ScreenModel
  @Prop({type: Array, required: true}) recordings!: Array<RecordingModel>

  get predefinedActionState() {
    return PredefinedActionState.ytMode
  }

  get recording() {
    return this.screen.ytVideoId ? this.recordings.find(r => r.id === this.screen.ytVideoId) : undefined
  }

  handleRecordingCompleted(videoId: string) {
    this.$set(this.screen, 'ytVideoId', videoId)
    appState.unitSavingRequest = true
  }
}
