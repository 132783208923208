


















import {Component, Vue} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import Phone from "@/components/simulator/phone/Phone.vue"
import Slider from "@/components/fundamental/inputs/Slider.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import ScreenWidget from "@/simulator/screen/ScreenWidget.vue"

@Component({
  components: {ScreenWidget, SpacerBox, Container, Slider, Phone, Row}
})
export default class TestSimulator_displaySizes extends Vue {
  displayWidth = 400
  displayHeight = 700
  scaling = 1
}
