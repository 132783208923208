














































































































































import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import {eventbus} from '@/main'
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import {AlertDialogEvent, AlertDialogType, EventType} from '@/components/app/eventModel'

@Component({
  components: {TransitionEffect, InputField, TextButton, TextField, Row, Column}
})
export default class AlertDialog extends Vue {

  isVisible = false
  alertTypes = AlertDialogType
  alertType: AlertDialogType = AlertDialogType.info
  title = ''
  text = '>'
  callback?: (response: boolean) => void = undefined
  confirmAnswer = ''
  userAnswer = ''
  positiveButton = 'Ok'
  positiveButton_default = 'Ok'
  negativeButton = 'Cancel'
  negativeButton_default = 'Cancel'
  negativeButtonVisible = true

  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  created() {
    eventbus.$on(EventType.alertDialog,
      (e: AlertDialogEvent) => {

        if (e.alertType === AlertDialogType.oops) {
          e.title = 'Something went wrong'
          e.text = '<b>Error:</b> ' + e.oopsMsg
          e.negativeButtonVisible = false

        } else if (e.alertType === AlertDialogType.save) {
          e.title = 'Save changes?'
          e.text = 'Last chance to save your current changes. Otherwise they will be discarded.'
          e.positiveButton = 'Save'
          e.negativeButton = 'Discard'

        } else if (e.alertType === AlertDialogType.delete_low) {
          e.positiveButton = 'Delete'
          e.negativeButton = 'Cancel'

        } else if (e.alertType === AlertDialogType.delete_high) {
          e.positiveButton = 'Delete'
          e.negativeButton = 'Cancel'
        }

        this.alertType = e.alertType ? e.alertType : AlertDialogType.info

        this.title = e.title ? e.title : ''
        this.text = e.text ? e.text : ''
        this.callback = e.callback ? e.callback : () => {
        }

        this.confirmAnswer = e.confirmAnswer ? e.confirmAnswer : ''
        this.userAnswer = ''

        this.positiveButton = e.positiveButton ? e.positiveButton : this.positiveButton_default
        this.negativeButton = e.negativeButton ? e.negativeButton : this.negativeButton_default
        this.negativeButtonVisible = (e.negativeButtonVisible !== undefined) ? e.negativeButtonVisible : true

        this.isVisible = true
      })
  }

  /////////////////////////////////
  // Methods
  /////////////////////////////////
  close(response: boolean) {
    this.isVisible = false
    if (this.callback) {
      this.callback(response)
      this.callback = undefined
    }
  }

  cancel() {
    this.isVisible = false
    this.callback = undefined
  }

  doNothing() {}
}
