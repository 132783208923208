import model_xy_chart, {XyChartModel} from "@/models/content/charts/model_xy_chart"
import {ActionModel, ActionType} from "@/models/action/model_action"
import model_response from "@/models/action/model_response"

export interface DrawAxisActionModel extends ActionModel {
  xyChart: XyChartModel
  xMovable: boolean
  yMovable: boolean
  snapGrid: boolean
  tolerance: number
}


export default {
  template_action(): DrawAxisActionModel {
    return {
      type: ActionType.drawAxis,
      responses: model_response.template_responses(),
      xyChart: model_xy_chart.template_lineChart(),
      xMovable: true,
      yMovable: false,
      snapGrid: true,
      tolerance: 0,
    }
  },
}