










































































import {Component, Prop, Vue} from "vue-property-decorator"
import TextField from "@/components/fundamental/text/TextField.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import {ScreenBugReportModel} from "@/models/log/model_log_events"
import JsonView from "@/components/fundamental/text/JsonView.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import firebase from 'firebase/app'

@Component({
  components: {SpacerBox, JsonView, TextButton, Column, RoundIconButton, Container, Row, TextField}
})
export default class BugReportDetails extends Vue {
  @Prop({type: Number, required: true}) index!: number
  @Prop({type: Object, required: true}) bugReport!: ScreenBugReportModel

  showDetails = false

  getDateStr(time: firebase.firestore.Timestamp) {
    let date = time.toDate()
    let dateStr = `${date.getFullYear()}-${this.to2Digits(date.getMonth()+1)}-${this.to2Digits(date.getDate())}`;
    let timeStr = `${this.to2Digits(date.getHours())}:${this.to2Digits(date.getMinutes())}:${this.to2Digits(date.getSeconds())}.${this.to3Digits(date.getMilliseconds())}`;
    return dateStr + ' -- ' + timeStr
  }

  to2Digits(d: number): string {
    return d < 10 ? `0${d}` : `${d}`
  }

  to3Digits(d: number): string {
    return d < 10 ? `00${d}` : (d < 100 ? `0${d}` : `${d}`)
  }

  copy(text: string) {
    navigator.clipboard.writeText(text).catch(error => {
      console.log('Clipboard error', error)
    })
  }
}
