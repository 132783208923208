class Prefs {

  init() {
    this.initCameraUrl()
    this.initLatestAppVersionDocsSeen()
  }


  /////////////////////////////////
  // Camera Url
  /////////////////////////////////
  _cameraUrl = 'http://192.168.1.103:8081'

  initCameraUrl() {
    if (localStorage.cameraUrl)
      this._cameraUrl = localStorage.cameraUrl
  }

  get cameraUrl(): string {
    return this._cameraUrl
  }

  set cameraUrl(cameraUrl: string) {
    localStorage.setItem('cameraUrl', cameraUrl)
    this._cameraUrl = cameraUrl
  }


  /////////////////////////////////
  // Action
  /////////////////////////////////
  _latestAppVersionDocsSeen = '0'

  initLatestAppVersionDocsSeen() {
    if (localStorage.latestAppVersionDocsSeen)
      this._latestAppVersionDocsSeen = localStorage.latestAppVersionDocsSeen
  }

  get latestAppVersionDocsSeen(): string {
    return this._latestAppVersionDocsSeen
  }

  set latestAppVersionDocsSeen(latestAppVersionDocsSeen: string) {
    localStorage.setItem('latestAppVersionDocsSeen', latestAppVersionDocsSeen)
    this._latestAppVersionDocsSeen = latestAppVersionDocsSeen
  }
}

const prefs = new Prefs()
export default prefs