















import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import ColorPreview from "@/components/screen_editor/content/color/ColorPreview.vue"
import {XyChartModel} from "@/models/content/charts/model_xy_chart"

@Component({
  components: {
    ColorPreview, Container, TextField, SpacerBox, Row, Column
  }
})
export default class ContentBoxPlotPreview extends Vue {
  @Prop({type: Object, required: true}) content!: XyChartModel
}
