


































import {Component, Prop, Vue} from "vue-property-decorator"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import Loading from "@/components/fundamental/Loading.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import appState from "@/app/state/app_state"
import ImageField from "@/components/fundamental/ImageField.vue"

@Component({
  components: {ImageField, TextButton, Row, TextField, Loading, Column, TransitionEffect}
})
export default class WhiteBalanceDialog extends Vue {
  @Prop({type: Boolean, required: true}) visible!: boolean

  greyCardUrl = 'https://miranda-40b79.s3.amazonaws.com/images/imag_sl15ioO.jpg'
  micUrl = 'https://miranda-40b79.s3.amazonaws.com/images/imag_sl13Ufh.jpg'

  continueRecording() {
    appState.lastTimeShowedWhiteBalanceDialog = Date.now()
    this.$emit('start')
  }

  cancelRecording() {
    this.$emit('cancel')
  }
}
