

















import {Component, Prop, Vue} from "vue-property-decorator"
import ImagePreview from "./ImagePreview.vue"
import ImageUploader from "./ImageUploader.vue"
import Row from "../../fundamental/layout/Row.vue"
import {ImageModel} from "@/models/content/model_image"

@Component({
  components: {Row, ImageUploader, ImagePreview}
})
export default class ImageUploaderWithPreview extends Vue {
  @Prop({type: Object, required: true}) image!: ImageModel

  /////////////////////////////////
  // Methods
  /////////////////////////////////
  uploadCompleted(image: ImageModel) {
    this.image.url = image.url
    this.image.size = image.size
    this.image.fileType = image.fileType
    this.image.width = image.width
    this.image.height = image.height
  }

  reset() {
    this.image.url = ''
    this.image.size = 0
    this.image.fileType = ''
    this.image.width = 0
    this.image.height = 0
  }
}
