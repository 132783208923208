


































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"

@Component({
  components: {TextButton, TextField, TransitionEffect, Row, Column}
})
export default class WaitForRecorderDialog extends Vue {
  @Prop({type: Boolean, required: true}) visible!: boolean
}
