







































































import {Component, Prop, Vue} from "vue-property-decorator"
import {FirestoreModel} from "@/models/model_firestore"
import TextField from "@/components/fundamental/text/TextField.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"

@Component({
  components: {RoundIconButton, Container, Row, TextField}
})
export default class FirestoreInfo extends Vue {
  @Prop({type: Object, required: true}) doc!: FirestoreModel
  @Prop({type: Boolean, default: false, required: false}) center!: boolean
  @Prop({type: Boolean, default: false, required: false}) light!: boolean

  columns = 3

  mounted() {
    // @ts-ignore
    let resizeObserver = new ResizeObserver(() => {
      this.calcColumns()
    })

    // @ts-ignore
    resizeObserver.observe(this.$refs.firestoreInfo.getDiv())
  }

  calcColumns() {
    // @ts-ignore
    let width = this.$refs.firestoreInfo ? (this.$refs.firestoreInfo.getDiv() as HTMLDivElement).clientWidth : 0

    if (width > 840) {
      this.columns = 3

    } else if (width > 640) {
      this.columns = 2

    } else {
      this.columns = 1
    }
  }

  copy(text: string) {
    navigator.clipboard.writeText(text).catch(error => {
      console.log('Clipboard error', error)
    })
  }

  getDate(date: any): string {
    if (typeof date === 'string') {
      return date}

    return date.toDate().toISOString()
  }
}
