export enum ColorModel {
  black = 'black',
  darkGrey = 'darkGrey',
  lightGrey = 'lightGrey',
  red = 'red',
  pink = 'pink',
  purple = 'purple',
  deepPurple = 'deepPurple',
  indigo = 'indigo',
  blue = 'blue',
  lightBlue = 'lightBlue',
  cyan = 'cyan',
  teal = 'teal',
  green = 'green',
  lightGreen = 'lightGreen',
  lime = 'lime',
  yellow = 'yellow',
  amber = 'amber',
  orange = 'orange',
  deepOrange = 'deepOrange',
}

export enum ColorModelValue {
  black = '000000',
  darkGrey = '757575',
  lightGrey = 'E0E0E0',
  red = 'F44336',
  pink = 'E91E63',
  purple = '9C27B0',
  deepPurple = '673AB7',
  indigo = '3F51B5',
  blue = '2196F3',
  lightBlue = '03A9F4',
  cyan = '00BCD4',
  teal = '009688',
  green = '4CAF50',
  lightGreen = '8BC34A',
  lime = 'CDDC39',
  yellow = 'FFEB3B',
  amber = 'FFC107',
  orange = 'FF9800',
  deepOrange = 'FF5722',
}
