























































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import EditText from "@/components/fundamental/inputs/EditText.vue"
import EditNumber from "@/components/fundamental/inputs/EditNumber.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import ActionSectionHeader from "@/components/basic/ActionSectionHeader.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import util from "@/util/util"
import LineChartEditor from "@/components/screen_editor/content/chart/line_chart/LineChartEditor.vue"
import AdvancedCheckbox from "@/components/fundamental/advanced_inputs/AdvancedCheckbox.vue"
import AdvancedEditNumber from "@/components/fundamental/advanced_inputs/AdvancedEditNumber.vue"
import {DrawLineActionModel} from "@/models/action/model_action_draw_line"
import model_line from "@/models/math/model_line"


@Component({
  components: {
    AdvancedEditNumber,
    AdvancedCheckbox,
    LineChartEditor,
    RoundIconButton,
    ActionSectionHeader, SpacerBox, TextField, EditNumber, EditText, AddButton, Row, Column
  }
})
export default class DrawLineActionEditor extends Vue {
  @Prop({type: Object, required: true}) action!: DrawLineActionModel

  addLine() {
    this.action.targetLines.push(model_line.template())
  }

  deleteLine(index: number) {
    util.removeFromArray(this.action.targetLines, index)
  }
}
