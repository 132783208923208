import {ColorModel} from "@/models/model_colors"


export interface ChartBoxModel {
  percentiles: Array<number>
  color: ColorModel
}

export default {
  template(): ChartBoxModel {
    return {
      percentiles: [-4, -2, 0, 2, 4],
      color: ColorModel.cyan,
    }
  },
}