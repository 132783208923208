

















































































































































import {Component, Prop, Vue} from "vue-property-decorator"

@Component
export default class RoundIconButton extends Vue {
  @Prop({type: String, required: true}) icon!: string
  @Prop({type: String, default: '', required: false}) title!: string
  @Prop({type: Boolean, default: false, required: false}) white!: boolean
  @Prop({type: Boolean, default: false, required: false}) gray!: boolean
  @Prop({type: Boolean, default: false, required: false}) primary!: boolean
  @Prop({type: Boolean, default: false, required: false}) secondary!: boolean
  @Prop({type: Boolean, default: false, required: false}) green!: boolean
  @Prop({type: Boolean, default: false, required: false}) red!: boolean
  @Prop({type: Boolean, default: false, required: false}) blue!: boolean
  @Prop({type: Boolean, default: false, required: false}) yellow!: boolean
  @Prop({type: Boolean, default: false, required: false}) disabled!: boolean
  @Prop({type: Boolean, default: false, required: false}) selected!: boolean
  @Prop({type: Boolean, default: false, required: false}) large!: boolean
  @Prop({type: Boolean, default: false, required: false}) small!: boolean
  @Prop({type: Boolean, default: false, required: false}) smaller!: boolean
  @Prop({type: Boolean, default: false, required: false}) smallest!: boolean

  get iconSize() {
    if (this.large) {
      return 32
    } else if (this.small) {
      return 20
    } else if (this.smaller) {
      return 16
    } else if (this.smallest) {
      return 12
    } else {
      return 24
    }
  }
}
