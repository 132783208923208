import axios from 'axios'
import util from "@/util/util"

export interface EosHandlerResponse {
  success: boolean
  requestTime: number,
  filename?: string
  cameraDetails?: CameraDetailsJson
}

export interface CameraDetailsJson {
  productName: string
  serialNumber: string
  macAddress: string
  firmwareVersion: string
  battery: string
  lens: string
  availableStorage: number
  remainingTime: number
  afOperation: string
  afMethod: string
  shootingModeDial: string
  movieQuality: string
  soundRecording: string
}

export class CameraDetails {
  productName = ''
  serialNumber = ''
  macAddress = ''
  firmwareVersion = ''
  battery = ''
  lens = ''
  availableStorage = 0
  remainingTime = 0

  afOperation = 'unknown'
  afOperation_required = '-' // not supported by EOS250D
  get isAfOperationOk() {
    return true
  }

  afMethod = ''
  afMethod_required1 = 'face+tracking'
  afMethod_required2 = 'zone'

  get isAfMethodOk() {
    return this.afMethod === this.afMethod_required1 || this.afMethod === this.afMethod_required2
  }

  shootingModeDial = ''
  shootingModeDial_required = 'av'

  get isShootingModeDialOk() {
    return this.shootingModeDial === this.shootingModeDial_required
  }

  movieQuality = ''
  movieQuality_required = 'fhd_2500_ipb_standard'

  get isMovieQualityOk() {
    return this.movieQuality === this.movieQuality_required
  }

  soundRecording = ''
  soundRecording_required = '-' // TODO tbd
  get isSoundRecordingOk() {
    return true // this.soundRecording === this.soundRecording_required TODO
  }

  isShootingSettingsOk() {
    return this.isAfOperationOk && this.isAfMethodOk && this.isShootingModeDialOk && this.isMovieQualityOk && this.isSoundRecordingOk
  }

  getJson(): CameraDetailsJson {
    return {
      productName: this.productName,
      serialNumber: this.serialNumber,
      macAddress: this.macAddress,
      firmwareVersion: this.firmwareVersion,
      battery: this.battery,
      lens: this.lens,
      availableStorage: this.availableStorage,
      remainingTime: this.remainingTime,
      afOperation: this.afOperation,
      afMethod: this.afMethod,
      shootingModeDial: this.shootingModeDial,
      movieQuality: this.movieQuality,
      soundRecording: this.soundRecording,
    }
  }
}


export class CameraStatus {
  serverAvailable = false
  cameraAvailable = false
  batteryOk = false
  lensAvailable = false
  storageAvailable = false
  movieMode = false
  remainingTimeOk = false

  cameraDetails: CameraDetails = new CameraDetails()

  get shootingSettingsOk() {
    return this.cameraDetails.isShootingSettingsOk()
  }

  isCameraReady() {
    return this.serverAvailable &&
      this.cameraAvailable &&
      this.batteryOk &&
      this.lensAvailable &&
      this.storageAvailable &&
      this.movieMode &&
      this.remainingTimeOk &&
      this.shootingSettingsOk
  }

  reset() {
    this.serverAvailable = false
    this.cameraAvailable = false
    this.batteryOk = false
    this.lensAvailable = false
    this.storageAvailable = false
    this.movieMode = false
    this.remainingTimeOk = false

    this.cameraDetails = new CameraDetails()
  }
}


export class EosHandler {

  /////////////////////////////////
  // CONST
  /////////////////////////////////
  static SERVER_URL = 'http://localhost:9092/canon/server'
  static CANON_URL = 'http://localhost:9092/canon/ccapi/ver100/'

  /////////////////////////////////
  // Members
  /////////////////////////////////
  _cameraUrl: string
  _isCameraRecording: boolean = false
  _cameraStatus: CameraStatus = new CameraStatus()
  _files: Array<string> = []


  /////////////////////////////////
  // CONSTRUCTOR
  /////////////////////////////////
  constructor(cameraUrl: string) {
    this._cameraUrl = cameraUrl
  }


  /////////////////////////////////
  // PUBLIC
  /////////////////////////////////
  cameraRecording() {
    return this._isCameraRecording
  }

  cameraStatus() {
    return this._cameraStatus
  }

  async initCamera(): Promise<void> {
    this._cameraStatus.reset()

    try {
      await this._getServerStatus()
      await this._getCameraStatus()
      await this._getBatteryStatus()
      await this._getLensStatus()
      await this._getStorageStatus()
      await this._getMovieMode()
      await this._getRemainingTime()
      await this._getShootingSettings()
      await this._updateFileList()
    } catch (e) {
      console.log(e)
    }
  }

  async startCamera(): Promise<EosHandlerResponse> {
    try {
      let startTime = Date.now() / 1000
      await axios.post(this._fullUrl('shooting/control/recbutton'), {action: 'start'})
      this._isCameraRecording = true
      return {success: true, requestTime: Date.now() / 1000 - startTime}
    } catch (e) {
      return {success: false, requestTime: 0}
    }
  }

  async stopCamera(): Promise<EosHandlerResponse> {
    try {
      let startTime = Date.now() / 1000
      await axios.post(this._fullUrl('shooting/control/recbutton'), {action: 'stop'})
      this._isCameraRecording = false
      let requestTime = Date.now() / 1000 - startTime

      let filename = await this._updateFileList(true)
      return {
        success: true,
        requestTime: requestTime,
        filename: filename,
        cameraDetails: this._cameraStatus.cameraDetails.getJson()
      }

    } catch (e) {
      return {success: false, requestTime: 0, filename: ''}
    }
  }

  setCameraUrl(cameraUrl: string) {
    this._cameraUrl = cameraUrl
  }


  /////////////////////////////////
  // SERVER CALLS
  /////////////////////////////////
  async _getServerStatus() {
    try {
      await axios.post(EosHandler.SERVER_URL)
      this._cameraStatus.serverAvailable = true
    } catch (e) {
      throw e
    }
  }

  async _getCameraStatus() {
    try {
      let response = await axios.get(this._fullUrl('deviceinformation'))
      this._cameraStatus.cameraAvailable = true
      this._cameraStatus.cameraDetails.productName = response.data.productname
      this._cameraStatus.cameraDetails.serialNumber = response.data.serialnumber
      this._cameraStatus.cameraDetails.macAddress = response.data.macaddress
      this._cameraStatus.cameraDetails.firmwareVersion = response.data.firmwareversion
    } catch (e) {
      throw e
    }
  }

  async _getBatteryStatus() {
    try {
      let response = await axios.get(this._fullUrl('devicestatus/battery'))
      this._cameraStatus.batteryOk = true
      this._cameraStatus.cameraDetails.battery = response.data.level
    } catch (e) {
      throw e
    }
  }

  async _getLensStatus() {
    try {
      let response = await axios.get(this._fullUrl('devicestatus/lens'))
      this._cameraStatus.lensAvailable = response.data.mount
      this._cameraStatus.cameraDetails.lens = response.data.mount ? response.data.name : '-'
    } catch (e) {
      throw e
    }
  }

  async _getStorageStatus() {
    try {
      let response = await axios.get(this._fullUrl('devicestatus/storage'))
      this._cameraStatus.storageAvailable = response.data.storagelist.length > 0
      this._cameraStatus.cameraDetails.availableStorage =
        this._cameraStatus.storageAvailable ? Math.floor(response.data.storagelist[0].spacesize / 1e6) : 0
    } catch (e) {
      throw e
    }
  }

  async _getMovieMode() {
    try {
      let response = await axios.get(this._fullUrl('shooting/control/moviemode'))
      this._cameraStatus.movieMode = response.data.status === 'on'
    } catch (e) {
      throw e
    }
  }

  async _getRemainingTime() {
    try {
      let response = await axios.get(this._fullUrl('shooting/information/recordable'))
      this._cameraStatus.cameraDetails.remainingTime = response.data.remainingtime ? response.data.remainingtime : 0
      this._cameraStatus.remainingTimeOk = this._cameraStatus.cameraDetails.remainingTime > 300
    } catch (e) {
      throw e
    }
  }

  async _getShootingSettings() {
    try {
      let response = await axios.get(this._fullUrl('shooting/settings/'))
      this._cameraStatus.cameraDetails.afMethod = response.data.afmethod.value
      this._cameraStatus.cameraDetails.shootingModeDial = response.data.shootingmodedial.value
      this._cameraStatus.cameraDetails.movieQuality = response.data.moviequality.value
      this._cameraStatus.cameraDetails.soundRecording = response.data.soundrecording.value
    } catch (e) {
      throw e
    }
  }

  async _updateFileList(searchForNewFile = false) {
    if (!this._cameraStatus.storageAvailable) {
      throw Error('No storage available')
    }

    try {

      let oldFiles = util.copyArray(this._files)
      this._files = []

      let response = await axios.get(this._fullUrl('contents/sd'))
      let directoryUrls = response.data.url

      for (let directoryUrl of directoryUrls) {
        let s = directoryUrl.split('contents')

        let response2 = await axios.get(this._fullUrl('contents' + s[1] + '?type=mp4&kind=chunked&order=desc'))
        let fileUrls = response2.data.url

        for (let fileUrl of fileUrls) {
          let u = fileUrl.split('/')
          let filename = u[u.length - 1]
          this._files.push(filename)

          if (searchForNewFile && oldFiles.findIndex(f => f === filename) < 0) {
            return filename
          }
        }
      }

    } catch (e) {
      throw e
    }
  }


  /////////////////////////////////
  // HELPER
  /////////////////////////////////
  _fullUrl(api = '') {
    api += api.includes('?') ? '&url=' + this._cameraUrl : '?url=' + this._cameraUrl
    return EosHandler.CANON_URL + api
  }

}