var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"row",staticClass:"row",class:{
          left: _vm.left,
          center: _vm.center,
          right: _vm.right,
          top: _vm.top,
          middle: _vm.middle,
          bottom: _vm.bottom,
          spaced: _vm.spaced,
          'spaced-around': _vm.spacedAround,
          'spaced-evenly': _vm.spacedEvenly,
          wrap: _vm.wrap,
          grid3: _vm.grid3,
       },style:(_vm.styleStr)},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }