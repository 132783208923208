














import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import dummy_screen_simple from "@/test_area/dummy_data/screens/dummy_screen_simple"
import dummy_recording0 from "@/test_area/dummy_data/recordings/dummy_recording0"
import {eventbus} from "@/main"
import {EventType, SidePanelViewModel} from "@/components/app/eventModel"
import VideoEditorDialog from "@/components/video/editor/VideoEditorDialog.vue"

@Component({
  components: {VideoEditorDialog, TextButton, Row, Column}
})
export default class TestRecording_videoEditorDialog extends Vue {

  screen = dummy_screen_simple
  recording = dummy_recording0

  mounted() {
    eventbus.$emit(EventType.sidePanel, new SidePanelViewModel(false))
  }

  openDialog() {
    // @ts-ignore
    this.$refs.dialog.show()
  }
}
