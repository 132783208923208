































































































import {Component, Prop, Vue} from "vue-property-decorator"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import Row from "@/components/fundamental/layout/Row.vue"

@Component({
  components: {Row, RoundIconButton}
})
export default class InputField extends Vue {
  @Prop({type: String, required: false}) id!: string
  @Prop({type: String, default: 'text', required: false}) type!: string
  @Prop({default: '', required: false}) value!: string | number
  @Prop({type: String, required: false}) placeholder!: string

  @Prop({type: Boolean, default: false, required: false}) isTextarea!: boolean
  @Prop({type: Boolean, default: false, required: false}) showControl!: boolean
  @Prop({type: Boolean, default: false, required: false}) showSmallControl!: boolean
  @Prop({type: Boolean, default: false, required: false}) setFocusWhenMounted!: boolean
  @Prop({type: Boolean, default: false, required: false}) light!: boolean
  @Prop({type: Boolean, default: false, required: false}) borderBox!: boolean

  @Prop({type: Number, default: 10000, required: false}) maxLength!: number
  @Prop({type: Number, default: 4, required: false}) rows!: number
  @Prop({type: Number, default: 16, required: false}) fontSize!: number

  initialValue = this.value
  currentValue = this.value

  /////////////////////////////////
  // Life Cycle
  /////////////////////////////////
  mounted() {
    if (this.setFocusWhenMounted) {
      this.setFocus()
    }
  }

  /////////////////////////////////
  // Methods
  /////////////////////////////////
  updateCurrentValue(v: string) {
    this.currentValue = v
    this.$emit('input', v)
  }

  setFocus() {
    if (this.isTextarea) {
      (<HTMLTextAreaElement>this.$refs.textarea).focus()
    } else {
      (<HTMLInputElement>this.$refs.input).focus()
    }
  }

  getValue() {
    if (this.isTextarea) {
      return (<HTMLTextAreaElement>this.$refs.textarea).value
    } else {
      return (<HTMLInputElement>this.$refs.input).value
    }
  }

  setValue(v: string) {
    if (this.isTextarea) {
      (<HTMLTextAreaElement>this.$refs.textarea).value = v
    } else {
      (<HTMLInputElement>this.$refs.input).value = v
    }
  }

  getCursorPosition() {
    if (this.isTextarea) {
      let start = (<HTMLTextAreaElement>this.$refs.textarea).selectionStart
      let end = (<HTMLTextAreaElement>this.$refs.textarea).selectionEnd
      return {start, end}
    } else {
      let start = (<HTMLInputElement>this.$refs.input).selectionStart
      let end = (<HTMLInputElement>this.$refs.input).selectionEnd
      return {start, end}
    }
  }

  setFocusAndCursor(start: number, end: number) {
    if (!end) {
      end = start
    }
    if (this.isTextarea) {
      this.$nextTick(() => {
        let ta = (<HTMLTextAreaElement>this.$refs.textarea)
        ta.focus()
        ta.setSelectionRange(start, end)
      })
    } else {

    }
  }
}
