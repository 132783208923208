




















































































































































































































































































































































































































































import {Component, Prop, Vue} from "vue-property-decorator"
import TextField from "@/components/fundamental/text/TextField.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import {ResultDocModel, SearchCollection} from './model_search'
import {RecordingModel} from "@/models/recording/model_recording"
import util from "@/util/util"
import Link from "@/components/fundamental/text/Link.vue"
import {eventbus} from "@/main"
import {AlertDialogType, EventType} from "@/components/app/eventModel"
import store from "@/store/store"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import {UnitModel, UnitStatus, UnitType} from "@/models/unit/model_unit"
import {RecordingRawModel} from "@/models/recording/model_recording_raw"
import JsonView from "@/components/fundamental/text/JsonView.vue"
import {AlgoliaUnitModel} from "@/models/search/model_unit_algolia"

@Component({
  components: {JsonView, TextButton, Link, Column, RoundIconButton, Container, Row, TextField}
})
export default class DocOverview extends Vue {
  @Prop({type: Number, required: true}) index!: number
  @Prop({type: Object, required: true}) result!: ResultDocModel

  showJson = false

  unitStatus = UnitStatus
  unitType = UnitType

  util = util

  /////////////////////////////////
  // Getters
  /////////////////////////////////
  get unit(): UnitModel|null {
    return this.result.collectionType === SearchCollection.units ? this.result.doc as UnitModel : null
  }

  get algoliaUnit(): AlgoliaUnitModel|null {
    return this.result.collectionType === SearchCollection.algoliaUnits ? this.result.algoliaDoc as AlgoliaUnitModel : null
  }

  get recording(): RecordingModel|null {
    return this.result.collectionType === SearchCollection.recordings ? this.result.doc as RecordingModel : null
  }

  get recordingRaw(): RecordingRawModel|null {
    return this.result.collectionType === SearchCollection.recordingsRaw ? this.result.doc as RecordingRawModel : null
  }

  get isDeletable(): boolean {
    return this.result.collectionType === SearchCollection.recordings ||
      this.result.collectionType === SearchCollection.recordingsRaw ||
      this.result.collectionType === SearchCollection.units
  }


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  getDuration(time: number) {
    return util.createTimeString(time)
  }

  copy(text: string) {
    navigator.clipboard.writeText(text).catch(error => {
      console.log('Clipboard error', error)
    })
  }

  toggleJson() {
    this.showJson = !this.showJson
  }

  requestDeleting(docId: string) {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.delete_high,
      title: 'Delete doc',
      text: `This will permanently delete the doc <b>${docId}</b>.`,
      confirmAnswer: 'Delete',
      callback: (response: boolean) => {
        if (response) {
          this.deleteDoc(docId)
        }
      }
    })
  }

  async deleteDoc(docId: string) {
    eventbus.$emit(EventType.loadingDialog, true)

    if (this.result.collectionType === SearchCollection.recordings || this.result.collectionType === SearchCollection.recordingsRaw) {
      await store.recordings.deleteRemoteRecording(docId)
    } else if (this.result.collectionType === SearchCollection.units && this.unit) {
      await store.units.deleteUnit(this.unit)
    }

    eventbus.$emit(EventType.loadingDialog, false)
    this.$emit('reloadData')
  }


  /////////////////////////////////
  // Unit
  /////////////////////////////////
  toUnitEditor(unitId: string) {
    this.$router.push({path: '/unit-editor/' + unitId, query: {back: this.$route.fullPath}})
  }

  copyLink(unitId: string) {
    util.unitLinkToClipboard(unitId)
  }

  async sendToApp(unitId: string) {
    if (!unitId) return

    await store.devSettings.sendUnitIdToApp(unitId)
    eventbus.$emit(EventType.snackbar, {msg: 'Unit ID sent to app', duration: 2000})
  }


  /////////////////////////////////
  // Recording
  /////////////////////////////////
  requestResettingVideoEncoding(recordingId: string) {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.error,
      title: 'Reset Video Encoding',
      text: 'Reset the video encoding to reupload a video if the first upload failed. Run the <b>Video Converter</b> for reuploading the video.',
      positiveButton: 'Continue',
      negativeButton: 'Cancel',
      callback: (response: boolean) => {
        if (response) this.resetVideoEncoding(recordingId)
      }
    })
  }

  async resetVideoEncoding(recordingId: string) {
    eventbus.$emit(EventType.loadingDialog, true)
    await store.recordings.resetVideoEncoding(recordingId)
    eventbus.$emit(EventType.loadingDialog, false)
    this.$emit('reloadData')
  }

  /////////////////////////////////
  // Helper
  /////////////////////////////////
  getUnitTitle(unit: UnitModel|AlgoliaUnitModel): string {
    let title = unit.title
    if (unit.refId) {
      title += ' - ' + unit.refId
    }
    return  title
  }

  getDateStr(str: string) {
    let date = new Date(str)
    let dateStr = `${date.getFullYear()}-${this.to2Digits(date.getMonth()+1)}-${this.to2Digits(date.getDate())}`;
    let timeStr = `${this.to2Digits(date.getHours())}:${this.to2Digits(date.getMinutes())}:${this.to2Digits(date.getSeconds())}.${this.to3Digits(date.getMilliseconds())}`;
    return dateStr + ' -- ' + timeStr
  }

  to2Digits(d: number): string {
    return d < 10 ? `0${d}` : `${d}`
  }

  to3Digits(d: number): string {
    return d < 10 ? `00${d}` : (d < 100 ? `0${d}` : `${d}`)
  }
}
