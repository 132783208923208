









import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Slider from "@/components/fundamental/inputs/Slider.vue"

@Component({
  components: {Slider, TextField, Row}
})
export default class AdvancedScaling extends Vue {
  @Prop({type: String, required: false, default: ''}) title!: string
  @Prop({type: Number, required: true}) value!: number
  @Prop({type: Number, required: true}) min!: number
  @Prop({type: Number, required: true}) max!: number
  @Prop({type: Number, default: 0, required: false}) width!: number
  @Prop({type: Number, default: 0, required: false}) titleWidth!: number
  @Prop({type: Boolean, default: false, required: false}) titleBold!: boolean
  @Prop({type: Number, default: 2, required: false}) fractionDigits!: number

  value_ = this.value

  get valueStr() {
    return this.value.toFixed(this.fractionDigits)
  }

  get rowStyle() {
    let s = ''
    if (this.width > 0 ) {
      s += 'width:' + this.width + 'px;'
    }
    return s
  }

  get titleStyle() {
    let s = ''
    if (this.titleWidth > 0 ) {
      s += 'width:' + this.titleWidth + 'px;'
    }
    return s
  }

  @Watch('value_')
  valueChanged() {
    this.$emit('input', this.value_)
  }
}
