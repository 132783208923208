


















import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import ContentWidget from "@/simulator/content/ContentWidget.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import BoxPlotEditor from "@/components/screen_editor/content/chart/box_plot/BoxPlotEditor.vue"
import PieChartEditor from "@/components/screen_editor/content/chart/pie_chart/PieChartEditor.vue"
import {PieChartModel} from "@/models/content/charts/model_pie_chart"

@Component({
  components: {
    PieChartEditor,
    BoxPlotEditor, Container, ContentWidget, Row, Column
  }
})
export default class ContentPieChartEditor extends Vue {
  @Prop({type: Object, required: true}) content!: PieChartModel
  @Prop({type: Boolean, default: false, required: false}) showPreview!: boolean
}
