export enum RecorderState {
  running = 'running',
  paused = 'paused',
  stopped = 'stopped',
  startCounter = 'startCounter',
  counterCompleted = 'counterCompleted',
  startCounterAfterPause = 'startCounterAfterPause',
  counterCompletedAfterPause = 'counterCompletedAfterPause',
  initializeCamera = 'initializeCamera',
  cameraInitialized = 'cameraInitialized',
  startCamera = 'startCamera',
  cameraStarted = 'cameraStarted',
  pauseCamera = 'pauseCamera',
  cameraPaused = 'cameraPaused',
  stopCamera = 'stopCamera',
  cameraStopped = 'cameraStopped',
  startRecorder = 'startRecorder',
  recorderStarted = 'recorderStarted',
  pauseRecorder = 'pauseRecorder',
  recorderPaused = 'recorderPaused',
  stopRecorder = 'stopRecorder',
  recorderStopped = 'recorderStopped',
}