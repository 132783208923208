
























































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Draggable from "@/components/fundamental/layout/Draggable.vue"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import util from "@/util/util"
import model_action_drag_connect, {DragConnectActionModel} from "@/models/action/model_action_drag_connect"
import DragConnectField
  from "@/components/screen_editor/action/drag_connect/DragConnectField.vue"
import ActionSectionHeader from "@/components/basic/ActionSectionHeader.vue"
import EditNumber from "@/components/fundamental/inputs/EditNumber.vue"
import AdvancedEditNumber from "@/components/fundamental/advanced_inputs/AdvancedEditNumber.vue"
import model_size, {SizeModel} from "@/models/math/model_size"


@Component({
  components: {
    AdvancedEditNumber,
    EditNumber,
    ActionSectionHeader, DragConnectField, InputField, Container, SpacerBox, TextField, AddButton, Draggable, Row, Column
  }
})
export default class DragConnectActionEditor extends Vue {
  @Prop({type: Object, required: true}) action!: DragConnectActionModel

  get startFieldSize(): SizeModel {
    return model_size.template_widthHeight(this.action.startFieldWidth, this.action.startFieldHeight)
  }

  get endFieldSize(): SizeModel {
    return model_size.template_widthHeight(this.action.endFieldWidth, this.action.endFieldHeight)
  }

  addStartField() {
    this.action.startFields.push(model_action_drag_connect.template_startField())
  }

  deleteStartField(index: number) {
    util.removeFromArray(this.action.startFields, index)
  }

  addEndField() {
    this.action.endFields.push(model_action_drag_connect.template_endField())
  }

  deleteEndField(index: number) {
    util.removeFromArray(this.action.endFields, index)
  }
}
