

















































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Phone from "@/components/simulator/phone/Phone.vue"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import {Device, Devices} from "@/app/devices"
import DeviceSelector from "@/components/simulator/phone/DeviceSelector.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import EventOverlay from "@/components/simulator/events/EventOverlay.vue"
import ScrollHandler_y from "@/components/simulator/events/ScrollHandler_y"
import ScreenVideoPlayer from "@/components/simulator/ScreenVideoPlayer.vue"
import {RecordingModel} from "@/models/recording/model_recording"
import {VideoController} from "@/components/video/controller/VideoController"
import Toast from "@/components/simulator/events/Toast.vue"
import ImageDialog from "@/components/simulator/events/ImageDialog.vue"
import ScreenWidget from "@/simulator/screen/ScreenWidget.vue"
import {ScreenModel} from "@/models/screen/model_screen"
import {UnitModel} from "@/models/unit/model_unit"
import {ResponseModel} from "@/models/action/model_response"

@Component({
  components: {
    ScreenWidget,
    ImageDialog,
    Toast,
    ScreenVideoPlayer,
    EventOverlay,
    Container, DeviceSelector, SpacerBox, RoundIconButton, TextField, SelectField, Phone, Row, Column
  }
})
export default class Simulator extends Vue {
  @Prop({type: Object, required: true}) unit!: UnitModel
  @Prop({type: Array, default: [], required: false}) recordings!: Array<RecordingModel>
  @Prop({type: Number, default: 1, required: false}) scaling!: number

  device: Device = Devices.getDefault()
  showBorders = false

  scrollHandler_y?: ScrollHandler_y = undefined
  currentScreen = 0

  videoControllers: Array<VideoController | null> = []


  /////////////////////////////////
  // Computed
  /////////////////////////////////
  get displayWidth() {
    return this.device.width * this.scaling
  }

  get displayHeight() {
    return this.device.height * this.scaling
  }

  get numScreens() {
    return this.unit && this.unit.screens ? this.unit.screens.length : 0
  }

  get unitScreens() {
    return this.unit && this.unit ? this.unit.screens : []
  }


  /////////////////////////////////
  // Life Cycles
  /////////////////////////////////
  mounted() {
    this.scrollHandler_y = new ScrollHandler_y(
      // @ts-ignore
      this.$refs.screens.getDiv(),
      (n: number) => {
        this.currentScreen = n
      }
    )
  }


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  getScreen(screenId: string): ScreenModel | undefined {
    return this.unit.screens.find(s => s.id === screenId)
  }

  getInitialRecording(screenId: string): RecordingModel | null {
    let screen = this.getScreen(screenId)

    let recording = undefined
    if (screen !== undefined && screen.initialVideoId) {
      recording = this.recordings.find(r => {
        // @ts-ignore
        return r.id === screen.initialVideo.id
      })
    }

    return recording ? recording : null
  }

  setVideoController(vc: VideoController, index: number) {
    this.$set(this.videoControllers, index, vc)
  }


  /////////////////////////////////
  // Action
  /////////////////////////////////
  handleResponse(response: ResponseModel) {
    // switch (response.partOne.type) {
    // case ResponsePartOneType.none:
    //   break
    //
    // case ResponsePartOneType.toast:
    //   // @ts-ignore
    //   this.$refs.toast.show((response.partOne as Response_partOne_toast).message)
    //   break
    //
    // case ResponsePartOneType.image:
    //   // @ts-ignore
    //   this.$refs.imageDialog.show((response.partOne as Response_partOne_image).url)
    //   break
    //
    // case ResponsePartOneType.gif:
    //   // @ts-ignore
    //   this.$refs.imageDialog.show((response.partOne as Response_partOne_image).url)
    //   break
    // }
  }

}
