


















import {Component, Prop, Vue} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import BottomNavigation from "@/components/simulator/phone/BottomNavigation.vue"
import UnitProgressIndicator from "@/components/simulator/phone/UnitProgressIndicator.vue"

@Component({
  components: {UnitProgressIndicator, BottomNavigation, Container}
})
export default class Phone extends Vue {
  @Prop({type: Number, required: true}) displayWidth!: number
  @Prop({type: Number, required: true}) displayHeight!: number
  @Prop({type: Boolean, default: false, required: false}) hideBottomNavigation!: boolean
  @Prop({type: Number, default: 0, required: false}) numScreens!: number
  @Prop({type: Number, default: 0, required: false}) currentScreen!: number
}
