import firebase from 'firebase/app'
import Timestamp = firebase.firestore.Timestamp

////////////////////////////////
// Enum
////////////////////////////////
export enum UserRole {
  admin = 'admin',
  tutor = 'tutor',
  user = 'user',
}

export enum Gender {
  male,
  female,
  other,
  unknown,
}

export enum DelReason {
  emailNotVerified,
  userRequest,
}


////////////////////////////////
// Interface
////////////////////////////////
export interface UserModel {
  id: string
  relatedUserId?: string
  role: UserRole
  anonymous: boolean
  abandoned?: Timestamp
  signInProviders: Array<String>
  setupCompleted: boolean
  created: Timestamp
  lastLogin: Timestamp
  delRequest?: Timestamp
  deleted?: Timestamp
  delReason?: DelReason
  freeAccess?: boolean
  email: string
  emailVerified?: Timestamp
  phoneNumber?: string
  phoneNumberVerified?: Timestamp
  info: UserInfoModel
  settings: SettingsModel
}

export interface UserInfoModel {
  firstName: string
  photoUrl?: string
  gender: string
  birthday?: Timestamp
  country?: string
  state?: string
  schoolType?: string
  grade?: number
}

export interface SettingsModel {
  activeCourseId?: string
  theme: string
  showGrid: boolean
}