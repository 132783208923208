









































import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import SelectField from "@/components/fundamental/inputs/SelectField.vue"
import EditText from "@/components/fundamental/inputs/EditText.vue"
import appState from "@/app/state/app_state"
import IconButton from "@/components/fundamental/buttons/IconButton.vue"
import util from "@/util/util"
import {eventbus} from "@/main"
import {AlertDialogType, EventType} from "@/components/app/eventModel"
import store from "@/store/store"
import model_course, {CourseModel} from "@/models/curriculum/model_course"
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue"
import {CurriculumModel} from "@/models/curriculum/model_curriculum"
import AdvancedSelect from "@/components/fundamental/advanced_inputs/AdvancedSelect.vue";
import MirandaCourse from "@/pages/curriculum_viewer/courses/MirandaCourse.vue";

@Component({
  components: {
    MirandaCourse,
    AdvancedSelect, AdvancedEditText, IconButton,
    EditText, SelectField, TextButton, SpacerBox, Container, Row, TextField, Column}
})
export default class MirandaCourses extends Vue {
  @Prop({type: Object, required: true}) curriculum!: CurriculumModel
  @Prop({type: Boolean, required: true}) isSingleView!: boolean

  curriculumState = appState.curriculum

  course: CourseModel|null = null
  remoteCourse: CourseModel|null = null

  courseValues: Array<string> = []
  courseLabels: Array<string> = []


  /////////////////////////////////
  // Computed
  /////////////////////////////////
  get unsavedChanges(): boolean {
    let isIdentical = (this.course === null && this.remoteCourse === null)
    if (!isIdentical) {
      isIdentical = util.compare(this.course, this.remoteCourse)
    }
    appState.curriculum.unsavedCourseChanges = !isIdentical
    return !isIdentical
  }


  /////////////////////////////////
  // Life Cycle
  /////////////////////////////////
  created() {
    this.selectCourse(appState.curriculum.mirandaCourseId)
    store.courses.addListener(this.handleRemoteCoursesChanged, this.constructor.name)
  }

  destroy() {
    store.courses.removeListener(this.constructor.name)
  }

  @Watch('curriculum')
  curriculumChanged() {
    if (this.curriculum.id !== this.course?.curriculumId) {
      this.course = null
      this.remoteCourse = null
    }
  }


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  handleRemoteCoursesChanged() {
    this.courseValues = []
    store.courses.remoteCourses.forEach(c => this.courseValues.push(c.id))

    this.courseLabels = []
    store.courses.remoteCourses.forEach(c => this.courseLabels.push(c.title))

    // update course
    if (this.course) {
      let course = store.courses.remoteCourses.find(c => c.id === this.course!.id)
      if (course && course.lastModifiedAt !== this.remoteCourse?.lastModifiedAt) {
        this.remoteCourse = course
        this.course = util.copy(course)
      }
    }
  }

  async addNewCourse() {
    this.course = model_course.template_course(this.curriculum)
    this.remoteCourse = null
    appState.curriculum.courseChanged(this.course)
  }

  selectCourse(id: string) {
    let course = store.courses.remoteCourses.find(c => c.id === id)
    if (course) {
      this.course = util.copy(course)
      this.remoteCourse = course
      appState.curriculum.courseChanged(this.course)
    }
  }

  resetCourse() {
    if (!this.course) return

    this.course = util.copy(this.remoteCourse)
    appState.curriculum.courseChanged(this.course)
  }

  async saveCourse() {
    if (!this.course) return

    // update list of course units
    this.curriculumState.courseChanged(this.course)

    this.showLoading(true)
    await store.courses.updateCourse(this.course)
    this.showLoading(false)
  }

  requestDeletingCourse() {

    let title: string|undefined = this.course?.title

    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.delete_high,
      title: `Delete course`,
      text: `Delete the course: <b>${title}</b>?<br><br> This will permanently delete the course.`,
      confirmAnswer: title && title.length > 0 ? title : 'Delete',
      callback: (response: boolean) => {
        if (response) this.deleteCourse()
      }
    })
  }

  async deleteCourse() {
    if (!this.course) return

    this.showLoading(true)
    await store.courses.deleteCourse(this.course.id)
    this.showLoading(false)

    this.course = null
    this.remoteCourse = null
  }

  showLoading(show: boolean) {
    eventbus.$emit(EventType.loadingDialog, show)
  }
}
