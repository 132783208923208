import axios from 'axios'

export interface GfycatHelperResponse {
  item?: GfycatHelperResponseItem
  msg: string
}

interface GfycatHelperResponseItem {
  url: string,
  size: number,
  width: number,
  height: number,
}

export default {
  async fetchGif(gfycatId: string): Promise<GfycatHelperResponse> {

    if (gfycatId.startsWith('https://gfycat.com/')) {
      let a = gfycatId.split('/')
      gfycatId = a[a.length - 1]
    }

    if (!gfycatId) {
      return ({item: undefined, msg: 'Please enter a valid Gfycat ID.'})
    }

    try {
      let response = await axios.get('https://api.gfycat.com/v1/gfycats/' + gfycatId)

      if (!response.data || !response.data.gfyItem || !response.data.gfyItem.content_urls) {
        return ({item: undefined, msg: 'Could not find gif. Please check the Gfycat ID.'})
      } else if (!response.data.gfyItem.content_urls.max2mbGif) {
        return ({item: undefined, msg: 'Found Gfycat ID, but no max2mbGif available.'})
      }

      let tinyGif = response.data.gfyItem.content_urls.max2mbGif
      let item = {
        url: tinyGif.url,
        size: 0,
        width: tinyGif.width,
        height: tinyGif.height,
      }
      return ({item: item, msg: ''})


    } catch (e) {
      throw e
    }
  }
}