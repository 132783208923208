import {ImageModel} from '../content/model_image'

export enum ResponseType {
  tip,
  correct,
  wrong,
}

export interface ResponseModel {
  type: ResponseType,
  image?: ImageModel
  soundUrl?: string
  videoId?: string
}

export default {
  template_responses(): Array<ResponseModel> {
    return [
      this.template_response(ResponseType.tip),
      this.template_response(ResponseType.correct),
      this.template_response(ResponseType.wrong),
    ]
  },
  template_response(type: ResponseType): ResponseModel {
    return {
      type: type
    }
  },
}