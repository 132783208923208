





















































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import util from '@/util/util'
import Draggable from "@/components/fundamental/layout/Draggable.vue"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import ListItemContainer from "@/pages/unit_editor/ListItemContainer.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import {TableActionModel} from "@/models/action/model_action_table"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import ActionSectionHeader from "@/components/basic/ActionSectionHeader.vue"
import AdvancedEditNumber from "@/components/fundamental/advanced_inputs/AdvancedEditNumber.vue"
import AdvancedSelect from "@/components/fundamental/advanced_inputs/AdvancedSelect.vue"

@Component({
  components: {
    AdvancedSelect, AdvancedEditNumber, ActionSectionHeader, InputField, Container,
    RoundIconButton, SpacerBox, TextField, ListItemContainer, AddButton, Draggable, Row, Column
  }
})
export default class TableActionEditor extends Vue {
  @Prop({type: Object, required: true}) action!: TableActionModel

  created() {
    if (!this.action.columnWidths) {
      this.$set(this.action, 'columnWidths', util.arrayOf(this.action.headerRow.length+1, 0))
    }
  }

  get cellValues() {
    let values: Array<Array<boolean>> = []
    let c = 0
    for (let i = 0; i < this.action.headerColumn.length; i++) {
      values.push([])
      for (let j = 0; j < this.action.headerRow.length; j++) {
        values[i].push(this.action.targetValues[c])
        c++
      }
    }

    return values
  }

  updateHeaderRow(delta: number) {
    if (delta === 1) {
      this.action.headerRow.push('')
      this.action.columnWidths.push(0)

      for (let i = 0; i < this.action.headerColumn.length; i++) {
        util.addToArray(this.action.targetValues, false, this.action.headerRow.length * (i + 1) - 1)
      }

    } else if (delta === -1 && this.action.headerRow.length > 1) {
      for (let i = this.action.headerColumn.length - 1; i >= 0; i--) {
        util.removeFromArray(this.action.targetValues, this.action.headerRow.length * (i + 1) - 1)
      }

      util.removeFromArray(this.action.headerRow, this.action.headerRow.length - 1)
      util.removeFromArray(this.action.columnWidths, this.action.columnWidths.length-1)
    }
  }

  updateHeaderColumn(delta: number) {
    if (delta === 1) {
      this.action.headerColumn.push('')

      for (let i = 0; i < this.action.headerRow.length; i++) {
        util.addToArray(this.action.targetValues, false, this.action.targetValues.length)
      }
    } else if (delta === -1 && this.action.headerColumn.length > 1) {
      for (let i = this.action.headerRow.length - 1; i >= 0; i--) {
        util.removeFromArray(this.action.targetValues, this.action.targetValues.length - 1)
      }

      util.removeFromArray(this.action.headerColumn, this.action.headerColumn.length - 1)
    }
  }

  updateHeaderRowCell(value: string, index: number) {
    this.$set(this.action.headerRow, index, value)
  }

  updateHeaderColumnCell(value: string, index: number) {
    this.$set(this.action.headerColumn, index, value)
  }

  updateTargetValue(value: boolean, index: number) {
    this.$set(this.action.targetValues, index, value)
  }
}
