import {TextModel} from "@/models/content/model_text"
import {ActionModel, ActionType} from "@/models/action/model_action"
import model_response from "@/models/action/model_response"

export interface InputActionModel extends ActionModel {
  fontSize: number
  unsortedList?: boolean
  continuousText?: boolean
  inputs: Array<InputItemModel>
}

export interface InputItemModel {
  type: InputType
  text?: TextModel
  fieldWidth?: number
  label?: string
  suffix?: string
  showDegRad?: boolean
  forceRad?: boolean

  targetInputs: Array<string>

  globalTolerance?: number
  pointTolerance?: PointToleranceModel
  variables?: Array<VariableModel>
  functions?: Array<string>

  selection?: Array<string>
  vectorType?: VectorType
  roundBracketsOnly?: boolean
  parameterVariable?: string
}

export enum InputType {
  term = 'term',
  equation = 'equation',
  time = 'time',
  interval = 'interval',
  dropDown = 'dropDown',
  vector = 'vector',
  ci = 'ci',
  coordinates = 'coordinates',
  paramEq = 'paramEq',
  slideUnlock = 'slideUnlock',
}

export enum InputTypeLabels {
  term = 'Term',
  equation = 'Equation',
  time = 'Time',
  interval = 'Interval',
  dropDown = 'Drop Down',
  vector = 'Vector',
  ci = 'Confidence Interval',
  coordinates = 'Coordinates',
  paramEq = 'Parametric Equation',
  slideUnlock = 'Slide Unlock',
}

export enum VectorType {
  identical = 'identical',
  identicalOrAnti = 'identicalOrAnti',
  parallel = 'parallel',
  parallelOrAnti = 'parallelOrAnti',
}

export interface VariableModel {
  name: string
  value: number
}

export interface PointToleranceModel {
  name: string
  values: Array<number>
  tolerances: Array<number>
}


export default {
  template_action(): InputActionModel {
    return {
      type: ActionType.input,
      responses: model_response.template_responses(),
      fontSize: 18,
      inputs: [
        this.template_term()
      ]
    }
  },
  template_term(): InputItemModel {
    return {
      type: InputType.term,
      targetInputs: ['']
    }
  },
  template_equation(): InputItemModel {
    return {
      type: InputType.equation,
      targetInputs: ['']
    }
  },
  template_time(): InputItemModel {
    return {
      type: InputType.time,
      targetInputs: ['']
    }
  },
  template_interval(): InputItemModel {
    return {
      type: InputType.interval,
      targetInputs: ['[0,1]'],
      roundBracketsOnly: false,
    }
  },
  template_dropDown(): InputItemModel {
    return {
      type: InputType.dropDown,
      targetInputs: ['1'],
      selection: ['1'],
    }
  },
  template_vector(): InputItemModel {
    return {
      type: InputType.vector,
      targetInputs: ['0', '1'],
      vectorType: VectorType.identical,
    }
  },
  template_ci(): InputItemModel {
    return {
      type: InputType.ci,
      targetInputs: ['0', '1'],
    }
  },
  template_coordinates(): InputItemModel {
    return {
      type: InputType.coordinates,
      targetInputs: ['0', '1'],
    }
  },
  template_paramEq(): InputItemModel {
    return {
      type: InputType.paramEq,
      fieldWidth: 80,
      targetInputs: ['0', '0', '1', '1'],
    }
  },
  template_slideUnlock(): InputItemModel {
    return {
      type: InputType.slideUnlock,
      targetInputs: [],
    }
  },
  template_pointTolerance(): PointToleranceModel {
    return {
      name: 'x',
      values: [0],
      tolerances: [0.1],
    }
  },
  template_variable(): VariableModel {
    return {
      name: 'x',
      value: 1,
    }
  },
}