import {UnitLevel, UnitModel, UnitStatus, UnitType} from "@/models/unit/model_unit"
import 'firebase/firestore'

export interface UnitPreviewModel {
  ti: string
  re?: string
  ty: UnitType
  st: UnitStatus
  le?: UnitLevel
  sc: number
  ac: number
  iv?: number
  tv?: number
  cv?: number
  wv?: number
  mv?: number
  ov?: number
  er?: boolean
}

export default {
  buildFromUnit(unit: UnitModel): UnitPreviewModel {

    let numActions = 0
    for (let screen of unit.screens) {
      if (screen.action) numActions++
    }

    let json: UnitPreviewModel = {
      ti: unit.title,
      ty: unit.type,
      st: unit.status,
      sc: unit.screens.length,
      ac: numActions,
    }

    if (unit.level !== undefined) {
      json.le = unit.level
    }

    if (unit.refId) {
      json.re = unit.refId
    }

    if (unit.iniViTi) json.iv = unit.iniViTi
    if (unit.tipViTi) json.tv = unit.tipViTi
    if (unit.corViTi) json.cv = unit.corViTi
    if (unit.wroViTi) json.wv = unit.wroViTi
    if (unit.mcViTi) json.mv = unit.mcViTi
    if (unit.conViTi) json.ov = unit.conViTi

    if (unit.error) {
      json.er = unit.error
    }

    return json
  },
  areUnitPreviewsEqual(u1: UnitPreviewModel, u2: UnitPreviewModel): boolean {
    return u1.ti === u2.ti &&
      u1.re === u2.re &&
      u1.ty === u2.ty &&
      u1.st === u2.st &&
      u1.le === u2.le &&
      u1.iv === u2.iv &&
      u1.tv === u2.tv &&
      u1.cv === u2.cv &&
      u1.wv === u2.wv &&
      u1.mv === u2.mv &&
      u1.ov === u2.ov &&
      u1.sc === u2.sc &&
      u1.ac === u2.ac &&
      u1.er === u2.er
  }
}