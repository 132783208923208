import { render, staticRenderFns } from "./OfficialChapter.vue?vue&type=template&id=78d24f5c&scoped=true&"
import script from "./OfficialChapter.vue?vue&type=script&lang=ts&"
export * from "./OfficialChapter.vue?vue&type=script&lang=ts&"
import style0 from "./OfficialChapter.vue?vue&type=style&index=0&id=78d24f5c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78d24f5c",
  null
  
)

export default component.exports