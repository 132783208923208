



































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {RecordingModel} from "@/models/recording/model_recording"
import VideoPlayerWidget from "@/components/video/player/VideoPlayerWidget.vue"
import {VideoController} from "@/components/video/controller/VideoController"
import VideoControl from "@/components/video/editor/VideoControl.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import IconButton from "@/components/fundamental/buttons/IconButton.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import ScreenWidget from "@/simulator/screen/ScreenWidget.vue"
import {ScreenModel} from "@/models/screen/model_screen"

@Component({
  components: {
    ScreenWidget,
    SpacerBox, TextField, IconButton, Container, VideoControl, VideoPlayerWidget, Row, Column
  }
})
export default class VideoEditor extends Vue {
  @Prop({type: Object, required: true}) screen!: ScreenModel
  @Prop({type: Object, required: true}) recording!: RecordingModel
  @Prop({type: Boolean, default: false, required: false}) linkedExercise!: boolean

  videoController: VideoController | null = null

  selectedTab = 0

  width = 240
  height = 420

  stackLayers = false
}
