












import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import ImageUploaderWithPreview from "@/components/basic/images/ImageUploaderWithPreview.vue"
import model_image, {ImageModel} from "@/models/content/model_image"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import ImageUploaderDialog from "@/components/basic/images/ImageUploaderDialog.vue"

@Component({
  components: {ImageUploaderDialog, TextButton, SpacerBox, ImageUploaderWithPreview, Row, Column}
})
export default class TestHtml_imageUpload extends Vue {
  image: ImageModel = model_image.template_image()
}
