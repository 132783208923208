export default class ChangeNotifier {
  listeners: Array<() => void> = []
  listenerIds: Array<string> = []

  addListener(listener: () => void, listenerId: string) {
    this.listeners.push(listener)
    this.listenerIds.push(listenerId)

    listener() // initial call
  }

  removeListener(listenerId: string) {
    let index = this.listenerIds.findIndex(e => e === listenerId)
    if (index !== undefined) {
      this.listeners.splice(index, 1)
      this.listenerIds.splice(index, 1)
    }
  }

  notify() {
    for (let listener of this.listeners) {
      listener()
    }
  }
}