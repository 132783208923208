import axios from 'axios'

export interface GiphyHelperResponse {
  item?: GiphyHelperResponseItem
  msg: string
}

interface GiphyHelperResponseItem {
  url: string,
  size: number,
  width: number,
  height: number,
}

export default {
  async fetchGif(giphyId: string): Promise<GiphyHelperResponse> {

    if (giphyId.startsWith('https://giphy.com/gifs/')) {
      let a = giphyId.split('/')
      giphyId = a[a.length - 1]

      if (giphyId.includes('-')) {
        let b = giphyId.split('-')
        giphyId = b[b.length - 1]
      }
    }

    if (!giphyId) {
      return ({item: undefined, msg: 'Please enter a valid Giphy ID.'})
    }

    try {
      let response = await axios.get('https://api.giphy.com/v1/gifs/' + giphyId + '?api_key=KvHqcI19MG3Jg7ZPYwLO8vtF6j4ZPUvC') // API from facebook account zirro.p

      if (!response.data || !response.data.data || !response.data.data.images || !response.data.data.images.downsized_medium) {
        return ({item: undefined, msg: 'Could not find gif. Please check the Giphy ID.'})
      }

      let tinyGif = response.data.data.images.downsized_medium
      let item = {
        url: tinyGif.url,
        size: parseFloat(tinyGif.size),
        width: parseFloat(tinyGif.width),
        height: parseFloat(tinyGif.height),
      }
      return ({item: item, msg: ''})


    } catch (e) {
      throw e
    }
  }
}