






import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import ActionSectionHeader from "@/components/basic/ActionSectionHeader.vue"
import {SlideUnlockActionModel} from "@/models/action/model_action_slide_unlock"

@Component({
  components: {
    ActionSectionHeader,
    Container, SpacerBox, Row, Column}
})
export default class SlideUnlockActionEditor extends Vue {
  @Prop({type: Object, required: true}) action!: SlideUnlockActionModel
}
