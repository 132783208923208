








































































































































































































































































import {Component, Prop, Vue} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import IconButton from "@/components/fundamental/buttons/IconButton.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import AddUrlDialog from "@/components/screen_editor/content/text/AddUrlDialog.vue";

@Component({
  components: {
    AddUrlDialog,
    InputField, IconButton, RoundIconButton,
    TextButton, SpacerBox, Row, TextField, Column, Container}
})
export default class BasicTextEditor extends Vue {
  @Prop({type: String, required: true}) value!: string
  @Prop({type: Boolean, default: false, required: false}) inlineText!: boolean
  @Prop({type: Boolean, default: false, required: false}) isFormula!: boolean
  @Prop({type: Boolean, default: false, required: false}) setFocusWhenMounted!: boolean

  addMarkup(markers: Array<string>, replaceSelection = false) {
    if (markers.length < 1 && markers.length > 2) return

    let inputField = <any>this.$refs.input

    let selection = inputField.getCursorPosition()
    let text = inputField.getValue()

    if (replaceSelection) {
      text = text.substring(0, selection.start) + markers[0] + text.substring(selection.end)

    } else if (markers.length == 1) {
      text = text.substring(0, selection.start) + markers[0] + text.substring(selection.start)

    } else {

      let selectedText = text.substring(selection.start, selection.end)
      if (selectedText.startsWith(' ')) {
        selection.start = selection.start+1
      }
      if (selectedText.endsWith(' ')) {
        selection.end = selection.end-1
      }

      text = text.substring(0, selection.start) + markers[0] + text.substring(selection.start, selection.end) + markers[1] + text.substring(selection.end)
    }

    inputField.setValue(text)
    inputField.setFocusAndCursor(selection.end + markers[0].length)

    this.$emit('input', text)
  }

  getSelectedText(): string {

    let inputField = <any>this.$refs.input

    let selection = inputField.getCursorPosition()
    let text = inputField.getValue()

    return text.substring(selection.start, selection.end)
  }
}
