

































































































































































import {Component, Prop, Vue} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import TextButton from "../../components/fundamental/buttons/TextButton.vue"
import TextField from "../../components/fundamental/text/TextField.vue"
import Row from "../../components/fundamental/layout/Row.vue"
import ProgressBar from "@/components/fundamental/ProgressBar.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import s3Uploader from "@/util/images/s3Uploader"
import model_sound, {SoundModel} from "@/models/sound/model_sound"

enum SoundUploaderState {
  noFile,
  fileProvided,
  uploading,
  uploadCompleted,
  uploadFailed,
}

@Component({
  components: {Column, ProgressBar, Row, TextField, TextButton, Container}
})
export default class SoundUploader extends Vue {
  @Prop({required: true}) currentSound!: SoundModel

  soundUploaderState = SoundUploaderState
  state: SoundUploaderState = SoundUploaderState.noFile
  dragHovering = false

  file?: File = undefined
  soundDuration = 0
  previewSrc: any = ''

  progress = 0

  errorMsg = ''

  /////////////////////////////////
  // Methods
  /////////////////////////////////
  dragEnter() {
    if (this.state === SoundUploaderState.noFile || this.state === SoundUploaderState.fileProvided) {
      this.dragHovering = true
    }
  }

  dragLeave() {
    this.dragHovering = false
  }

  dropFile(e: any) {
    this.dragHovering = false

    if (e.dataTransfer) {
      this.handleUpload(e.dataTransfer.files[0])
    }
  }

  handleUpload(file: File) {
    if (this.state !== SoundUploaderState.noFile && this.state !== SoundUploaderState.fileProvided) return

    // unsupported type
    if (file.type != 'audio/mp3' && file.type != 'audio/mpeg') {
      this.errorMsg = `Unsupported file type: ${file.type}\n(supported types: mp3)`;
      this.state = SoundUploaderState.uploadFailed;
      return;
    }

    this.state = SoundUploaderState.fileProvided
    this.file = file

    let reader = new FileReader()

    // audio duration
    reader.onload = (event: any) => {
      this.previewSrc = event.target.result
      let audio = <HTMLAudioElement>document.getElementById('audio')
      audio.onloadedmetadata = () => {
        this.soundDuration = audio.duration
      }
      audio.load()
    }
    
    reader.readAsDataURL(file)
  }

  reset() {
    this.file = undefined
    this.state = SoundUploaderState.noFile
  }

  async submitFile() {
    this.state = SoundUploaderState.uploading

    if (!this.file) {
      this.state = SoundUploaderState.uploadFailed
      return
    }

    try {
      let name = this.currentSound.id + '.mp3';
      let fileUrl = await s3Uploader.startUpload(name, 'sounds', this.file, this.progressCallback)

      let sound = model_sound.template_sound()
      sound.title = this.currentSound.title ? this.currentSound.title : this.file.name.split('.')[0]
      sound.url = fileUrl
      sound.size = this.file ? this.file.size : 0
      sound.duration = this.soundDuration
      sound.fileType = this.file ? this.file.type : ''

      this.$emit('uploaded', sound)

      this.state = SoundUploaderState.uploadCompleted
    } catch (e) {
      this.state = SoundUploaderState.uploadFailed
    }
  }

  progressCallback(progress: number) {
    this.progress = Math.round(progress * 100)
  }
}
