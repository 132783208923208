































import {Component, Vue} from "vue-property-decorator"
import Row from "@/components/fundamental/layout/Row.vue"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import SoundUploader from "@/pages/sounds/SoundUploader.vue"
import {SoundModel} from "@/models/sound/model_sound"
import store from "@/store/store"
import firebase from "firebase/app"
import Timestamp = firebase.firestore.Timestamp

@Component({
  components: {
    SoundUploader,
    RoundIconButton, Container, TextField, SpacerBox, TransitionEffect, Row, Column
  }
})
export default class SoundUploaderDialog extends Vue {

  sound?: SoundModel
  updatedCallback?: () => void
  isVisible = false

  /////////////////////////////////
  // Methods
  /////////////////////////////////
  open(sound: SoundModel, updatedCallback?: () => void) {
    this.sound = sound
    this.updatedCallback = updatedCallback
    this.isVisible = true
  }

  close() {
    this.isVisible = false
  }

  uploadCompleted(sound: SoundModel) {
    if (!this.sound) return

    this.sound.lastModifiedAt = Timestamp.now()
    this.sound.lastModifiedBy = store.auth.userEmail
    this.sound.title = sound.title
    this.sound.url = sound.url
    this.sound.size = sound.size
    this.sound.duration = sound.duration
    this.sound.fileType = sound.fileType

    if (this.updatedCallback) {
      this.updatedCallback()
    }

    this.close()
  }
}
