import {UnitError} from "@/pages/unit_editor/unit_content_validator"
import {CurriculumState} from "@/app/state/state_curriculum"
import {SearchState} from "@/app/state/state_search"
import {RegExpEditorState} from "@/app/state/state_regexp_editor"

class AppState {

  // back path
  backRoute: string = ''

  // curriculum
  curriculum = new CurriculumState()

  // unit editor
  unitSavingRequest = false
  unsavedUnitChanges = false
  unitError?: UnitError = undefined

  testingPathName = ''
  skipTestPath = false

  // converter
  converterWindowRef: Window | null = null

  // content
  copiedContent: string | null = null

  // action
  copiedAction: string | null = null

  // recorder
  lastTimeShowedWhiteBalanceDialog = 0

  // search
  search = new SearchState()

  // regexp editor
  regexpEditor = new RegExpEditorState()

  init() {}
}

const appState = new AppState()
export default appState