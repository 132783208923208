




import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import util from "@/util/util"
import TextField from "@/components/fundamental/text/TextField.vue"

@Component({
  components: {TextField, Row, Column}
})
export default class Timer extends Vue {
  @Prop({type: Number, default: 50, required: false}) frameDuration!: number

  timerString = ''
  timer = 0
  startTime = 0

  mounted() {
    this.reset()
  }

  start(startTime: number) {
    this.startTime = Date.now() / 1000 - startTime
    this.update()
  }

  setStartTime(startTime: number) {
    this.startTime = Date.now() / 1000 - startTime
  }

  stop() {
    clearTimeout(this.timer)
  }

  reset() {
    clearTimeout(this.timer)
    this.timerString = util.createTimeString(0, false)
  }

  update() {
    this.timerString = util.createTimeString(Date.now() / 1000 - this.startTime, false)
    this.timer = setTimeout(() => {
      this.update()
    }, this.frameDuration)
  }
}
