





















































































import {Component, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import SectionLayout from "@/components/fundamental/layout/SectionLayout.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import {eventbus} from "@/main"
import {EventType, NavbarViewModel} from "@/components/app/eventModel"

@Component({
  components: {
   Container, TextField, SectionLayout, TextButton, Row, Column}
})
export default class HelpPage extends Vue {


  ////////////////////////////////
  // Life Cycle
  ////////////////////////////////
  created() {
    eventbus.$emit(EventType.navbar, new NavbarViewModel(true))
  }

}
