
















import {Component, Prop, Vue} from "vue-property-decorator"
import ListItemContainer from "@/pages/unit_editor/ListItemContainer.vue"
import {ContentModel} from "@/models/content/model_content"
import ContentEditor from "@/components/screen_editor/content/ContentEditor.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {ScreenModel} from "@/models/screen/model_screen";
import {RecordingModel} from "@/models/recording/model_recording";
import {UnitModel} from "@/models/unit/model_unit";

@Component({
  components: {Row, ContentEditor, ListItemContainer}
})
export default class StaticEditorItem extends Vue {
  @Prop({type: Object, required: true}) content!: ContentModel
  @Prop({type: Boolean, default: false, required: false}) showRecordingButton?: boolean
  @Prop({type: Object, required: false}) unit?: UnitModel|undefined
  @Prop({type: Object, required: false}) screen?: ScreenModel|undefined
  @Prop({type: Array, required: false}) recordings?: Array<RecordingModel>|undefined
}
