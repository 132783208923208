export enum EventType {
  navbar = 'navbar',
  alertDialog = 'alertDialog',
  snackbar = 'snackbar',
  sidePanel = 'sidePanel',
  loadingDialog = 'loadingDialog',
  whatsNewDialog = 'whatsNewDialog',
}

export class NavbarViewModel {

  constructor(visible: boolean) {
    this.visible = visible
  }

  visible = true
  back = false
  showBackArrow = true
  backPath = ''
  backRoute?: MirandaRoute = undefined
  title = ''

  showReset = false
  resetCallback?: () => void

  showSave = false
  saveCallback?: () => void

  showSaveClose = false
  saveCloseCallback?: () => void

  showCancel = false
  cancelCallback?: () => void
}

export interface MirandaRoute {
  name: string
  query?: object
}

export enum AlertDialogType {
  info,
  warning,
  error,
  oops,
  save,
  delete_low,
  delete_high,
}

export interface AlertDialogEvent {
  alertType?: AlertDialogType
  title?: string
  text?: string
  callback?: (result: boolean) => void
  confirmAnswer?: string
  positiveButton?: string
  negativeButton?: string
  negativeButtonVisible?: boolean
  oopsMsg?: string
}

export interface SnackbarEvent {
  msg: string,
  duration?: number
}

export class SidePanelViewModel {
  visible = false
  json?: object | null = undefined

  constructor(visible: boolean, json?: object | null) {
    this.visible = visible
    this.json = json ? json : this.json
  }
}