import model_xy_chart, {XyChartModel} from "@/models/content/charts/model_xy_chart"
import model_chart_function, {ChartFunctionModel} from "@/models/content/charts/model_chart_function"
import {ActionModel, ActionType} from "@/models/action/model_action"
import model_response from "@/models/action/model_response"

export interface TuneChartActionModel extends ActionModel {
  xyChart: XyChartModel
  func: ChartFunctionModel
  tolerance: number
}

export default {
  template_action(): TuneChartActionModel {

    let xyChart = model_xy_chart.template_lineChart()
    xyChart.functions = undefined

    let func: ChartFunctionModel = model_chart_function.template()
    func.tu = [model_chart_function.template_tunableParameter_action()]

    return {
      type: ActionType.tuneChart,
      responses: model_response.template_responses(),
      xyChart: xyChart,
      func: func,
      tolerance: 0.05,
    }
  },
}