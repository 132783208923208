




import {Component, Prop, Vue} from "vue-property-decorator"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"

@Component({
  components: {InputField, TransitionEffect}
})
export default class ImageField extends Vue {
  @Prop({type: String, required: true}) url!: string
  @Prop({type: String, default: '', required: false}) title!: string

  @Prop({type: Number, default: 0, required: false}) width!: number
  @Prop({type: Number, default: 0, required: false}) height!: number
  @Prop({type: Number, default: 0, required: false}) maxWidth!: number
  @Prop({type: Number, default: 0, required: false}) maxHeight!: number
  @Prop({type: Number, default: 0, required: false}) scaling!: number

  @Prop({type: Boolean, default: false, required: false}) cover!: boolean


  get styleStr() {
    let s = ''

    if (this.width > 0) {
      s += 'width: ' + this.width + 'px; '
    } else if (this.scaling > 0) {
      s += 'width: ' + this.scaling * 100 + '%; '
    }

    if (this.height > 0) {
      s += 'height: ' + this.height + 'px; '
    }

    if (this.maxWidth > 0) {
      s += 'max-width: ' + this.maxWidth + 'px; '
    }

    if (this.maxHeight > 0) {
      s += 'max-height: ' + this.maxHeight + 'px; '
    }

    if (this.cover) {
      s += 'object-fit: cover; '
    }

    return s
  }
}
