


















import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import {CurriculumModel} from "@/models/curriculum/model_curriculum"
import Row from "@/components/fundamental/layout/Row.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import Draggable from "@/components/fundamental/layout/Draggable.vue"
import UnitChip from "@/pages/curriculum_viewer/UnitChip.vue"
import store from "@/store/store"

@Component({
  components: {
    UnitChip, Draggable, TextButton, SpacerBox, Container, Row, TextField, Column}
})
export default class CurriculumTable extends Vue {
  @Prop({type: Object, required: true}) curriculum!: CurriculumModel

  unassignedUnits: Array<string> = []

  /////////////////////////////////
  // Life Cycle
  /////////////////////////////////
  created() {
    this.updateUnassignedUnits()
  }


  /////////////////////////////////
  // Watch
  /////////////////////////////////
  @Watch('curriculum', {deep: true})
  curriculumChanged() {
    this.updateUnassignedUnits()
  }


  /////////////////////////////////
  // Methods
  /////////////////////////////////
  updateUnassignedUnits() {
    let previewJson = store.curriculum.getUnitPreviewJson(this.curriculum.id)
    if (!previewJson) return

    this.unassignedUnits = []

    for (let key in previewJson) {
      this.unassignedUnits.push(key)
    }

    for (let topic of this.curriculum.to) {
      for (let chapter of topic.ch) {
        for (let section of chapter.se) {
          for (let sub of section.su) {
            for (let unitId of sub.un) {
              let index = this.unassignedUnits.findIndex(value => value === unitId)
              if (index >= 0) {
                this.unassignedUnits.splice(index, 1)
              }
            }
          }
        }
      }
    }
  }
}
