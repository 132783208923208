


























































import {Component, Prop, Vue} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import OfficialChapters from "./OfficialChapters.vue"
import {CurriculumModel, OfficialTopicModel} from "@/models/curriculum/model_curriculum"

@Component({
  components: {OfficialChapters, TextField, RoundIconButton, InputField, Row, Container}
})
export default class OfficialTopicElement extends Vue {
  @Prop({type: Object, required: true}) curriculum!: CurriculumModel
  @Prop({type: Object, required: true}) topic!: OfficialTopicModel

  show = true
}
