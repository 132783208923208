



























import {Component, Prop, Vue} from "vue-property-decorator"
import {RecordingModel} from "@/models/recording/model_recording"
import Row from "@/components/fundamental/layout/Row.vue"
import ResponseImage from "@/components/screen_editor/action_response/ResponseImage.vue"
import ResponseVideo from "@/components/screen_editor/action_response/ResponseVideo.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import {ScreenModel} from "@/models/screen/model_screen"
import Column from "@/components/fundamental/layout/Column.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import {UnitModel} from "@/models/unit/model_unit";
import {ResponseModel} from "@/models/action/model_response"
import ResponseSound from "@/components/screen_editor/action_response/ResponseSound.vue"


@Component({
  components: {ResponseSound, Container, Column, SpacerBox, ResponseVideo, ResponseImage, Row}
})
export default class ActionEditorResponse extends Vue {
  @Prop({type: Object, required: true}) unit!: UnitModel
  @Prop({required: true}) responseType!: ResponseType
  @Prop({type: Object, required: true}) response!: ResponseModel
  @Prop({type: Object, required: true}) screen!: ScreenModel
  @Prop({type: Array, required: true}) recordings!: Array<RecordingModel>
}
