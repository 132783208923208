

















































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import {TopicModel} from "@/models/curriculum/model_course"
import MirandaChapters from "@/pages/curriculum_viewer/courses/MirandaChapters.vue"
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue"
import TextField from "@/components/fundamental/text/TextField.vue"

@Component({
  components: {
    TextField,
    AdvancedEditText,
    MirandaChapters, Container, Row, Column}
})
export default class MirandaSingleTopic extends Vue {
  @Prop({type: Object, required: true}) topic!: TopicModel
  @Prop({type: Boolean, required: false, default: false}) isExam!: boolean
  @Prop({type: Boolean, required: false, default: false}) isBonus!: boolean
}
