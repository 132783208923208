













































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import ListItemContainer from "@/pages/unit_editor/ListItemContainer.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import {DragItemModel} from '@/models/action/model_action_drag_drop'
import AdvancedScaling from "@/components/fundamental/advanced_inputs/AdvancedScaling.vue"
import AdvancedCheckbox from "@/components/fundamental/advanced_inputs/AdvancedCheckbox.vue"
import InlineContentEditor from "@/components/screen_editor/content/InlineContentEditor.vue"
import {ContentModel} from "@/models/content/model_content"

@Component({
  components: {
    InlineContentEditor, AdvancedCheckbox, AdvancedScaling, SpacerBox, TextField, ListItemContainer, Row, Column
  }
})
export default class DragDropItem extends Vue {
  @Prop({type: Number, required: true}) index!: number
  @Prop({type: Object, required: true}) dragItem!: DragItemModel

  updateContent(newContent: ContentModel) {
    this.dragItem.content = newContent
  }
}
