

































































































































import {Component, Prop, Vue} from "vue-property-decorator"

@Component
export default class TransitionEffect extends Vue {
  @Prop({type: Boolean, default: true, required: false}) fade!: boolean
  @Prop({type: Boolean, default: false, required: false}) expandFromTop!: boolean
  @Prop({type: Boolean, default: false, required: false}) expandFromBottom!: boolean
  @Prop({type: Boolean, default: false, required: false}) expandFromSide!: boolean

  get name() {
    if (this.expandFromTop) {
      return 'expand-from-top'

    } else if (this.expandFromBottom) {
      return 'expand-from-bottom'

    } else if (this.expandFromSide) {
      return 'expand-from-side'
    }

    return 'fade'
  }
}
