





































































































































































import {Component, Prop, Vue} from "vue-property-decorator"
import Container from "@/components/fundamental/layout/Container.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import InputField from "@/components/fundamental/inputs/InputField.vue"
import RoundIconButton from "@/components/fundamental/buttons/RoundIconButton.vue"
import TextField from "../../../../fundamental/text/TextField.vue"
import InlineColorSelector from "@/components/screen_editor/content/color/InlineColorSelector.vue"
import Column from "@/components/fundamental/layout/Column.vue"
import AdvancedEditText from "@/components/fundamental/advanced_inputs/AdvancedEditText.vue"
import AdvancedEditNumber from "@/components/fundamental/advanced_inputs/AdvancedEditNumber.vue"
import AdvancedCheckbox from "@/components/fundamental/advanced_inputs/AdvancedCheckbox.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import AdvancedScaling from "@/components/fundamental/advanced_inputs/AdvancedScaling.vue"
import InlineTextEditor from "@/components/screen_editor/content/text/InlineTextEditor.vue"
import TextElement from "@/components/screen_editor/content/chart/TextElement.vue"
import model_chart_text from "@/models/content/charts/model_chart_text"
import model_chart_function, {ChartFunctionModel} from "@/models/content/charts/model_chart_function"
import AddButton from "@/components/fundamental/buttons/AddButton.vue";

@Component({
  components: {
    AddButton,
    TextElement, InlineTextEditor, AdvancedScaling, TextButton,
    AdvancedCheckbox, AdvancedEditNumber, AdvancedEditText, Column,
    InlineColorSelector, TextField, RoundIconButton, InputField, Row, Container
  }
})
export default class ChartFunctionEditor extends Vue {
  @Prop({type: Number, required: true}) index!: number
  @Prop({type: Object, required: true}) func!: ChartFunctionModel
  @Prop({type: Boolean, required: false, default: false}) smallContent!: boolean
  @Prop({type: Boolean, required: false, default: false}) isTunableAction!: boolean

  addLabel() {
    this.$set(this.func, 'label', model_chart_text.template())

    if (this.smallContent) {
      this.func.label!.text.size = 14
    }
  }

  deleteLabel() {
    this.$delete(this.func, 'label')
  }

  addTunableParameter() {
    if (!this.func.tu) {
      this.$set(this.func, 'tu', [model_chart_function.template_tunableParameter()])
    } else {
      if (this.isTunableAction) {
        this.func.tu.push(model_chart_function.template_tunableParameter_action())
      } else {
        this.func.tu.push(model_chart_function.template_tunableParameter())
      }
    }
  }

  deleteTunableParameter(index: number) {
    if (!this.func.tu) return

    this.$delete(this.func.tu, index)

    if (this.func.tu.length == 0) {
      this.$delete(this.func, 'tu')
    }
  }
}
