

















































































































































































import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import {RecordingModel} from "@/models/recording/model_recording"
import ContentEditorTitle from "@/pages/unit_editor/ContentEditorTitle.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import ActionEditorHeader from "@/components/screen_editor/action/ActionEditorHeader.vue"
import TransitionEffect from "@/components/fundamental/animations/TransitionEffect.vue"
import TextField from "@/components/fundamental/text/TextField.vue"
import {ActionModel, ActionType} from "@/models/action/model_action"
import McActionEditor from "@/components/screen_editor/action/mc/McActionEditor.vue"
import ActionEditorResponse from "@/components/screen_editor/action_response/ActionEditorResponse.vue"
import {eventbus} from "@/main"
import {AlertDialogType, EventType} from "@/components/app/eventModel"
import DrawPointActionEditor from "@/components/screen_editor/action/draw_point/DrawPointActionEditor.vue"
import TableActionEditor from "@/components/screen_editor/action/table/TableActionEditor.vue"
import DragDropActionEditor from "@/components/screen_editor/action/drag_drop/DragDropActionEditor.vue"
import DragConnectActionEditor from "@/components/screen_editor/action/drag_connect/DragConnectActionEditor.vue"
import {ScreenModel} from "@/models/screen/model_screen"
import InputActionEditor from "@/components/screen_editor/action/input/InputActionEditor.vue"
import DrawLineActionEditor from "@/components/screen_editor/action/draw_line/DrawLineActionEditor.vue"
import DrawVectorActionEditor from "@/components/screen_editor/action/draw_vector/DrawVectorActionEditor.vue"
import DrawPolyActionEditor from "@/components/screen_editor/action/draw_poly/DrawPolyActionEditor.vue"
import DragBoxActionEditor from "@/components/screen_editor/action/drag_box/DragBoxActionEditor.vue"
import DragPieActionEditor from "@/components/screen_editor/action/drag_pie/DragPieActionEditor.vue"
import DragTextActionEditor from "@/components/screen_editor/action/drag_text/DragTextActionEditor.vue"
import SlideUnlockActionEditor from "@/components/screen_editor/action/slide_unlock/SlideUnlockActionEditor.vue"
import DragRectActionEditor from "@/components/screen_editor/action/drag_rect/DragRectActionEditor.vue"
import {UnitModel} from "@/models/unit/model_unit";
import TuneChartActionEditor from "@/components/screen_editor/action/tune_chart/TuneChartActionEditor.vue";
import {ResponseType} from "@/models/action/model_response"
import model_action_drag_box from "@/models/action/model_action_drag_box"
import model_action_table from "@/models/action/model_action_table"
import model_action_mc from "@/models/action/model_action_mc"
import model_action_input from "@/models/action/model_action_input"
import model_action_drag_connect from "@/models/action/model_action_drag_connect"
import model_action_draw_line from "@/models/action/model_action_draw_line"
import model_action_drag_text from "@/models/action/model_action_drag_text"
import model_action_drag_rect from "@/models/action/model_action_drag_rect"
import model_action_drag_pie from "@/models/action/model_action_drag_pie"
import model_action_drag_drop from "@/models/action/model_action_drag_drop"
import model_action_slide_unlock from "@/models/action/model_action_slide_unlock"
import model_action_draw_vector from "@/models/action/model_action_draw_vector"
import model_action_draw_poly from "@/models/action/model_action_draw_poly"
import model_action_draw_point from "@/models/action/model_action_draw_point"
import model_action_tune_chart from "@/models/action/model_action_tune_chart"
import DrawAxisActionEditor from "@/components/screen_editor/action/draw_axis/DrawAxisActionEditor.vue"
import model_action_draw_axis from "@/models/action/model_action_draw_axis"

@Component({
  components: {
    DrawAxisActionEditor,
    TuneChartActionEditor,
    DragRectActionEditor,
    SlideUnlockActionEditor,
    DragTextActionEditor,
    DragPieActionEditor,
    DragBoxActionEditor,
    DrawPolyActionEditor,
    DrawVectorActionEditor,
    DrawLineActionEditor,
    InputActionEditor,
    DragConnectActionEditor, DragDropActionEditor,
    TableActionEditor, DrawPointActionEditor,
    ActionEditorResponse,
    McActionEditor, TextField, TransitionEffect, ActionEditorHeader, SpacerBox, ContentEditorTitle, Row, Column
  }
})
export default class ActionEditor extends Vue {
  @Prop({type: Object, required: true}) unit!: UnitModel
  @Prop({type: Object, required: true}) screen!: ScreenModel
  @Prop({type: Number, required: true}) index!: number
  @Prop({type: Array, required: true}) recordings!: Array<RecordingModel>
  @Prop({type: Boolean, default: false, required: false}) linkedExercise!: boolean

  actionTypes = ActionType
  responseType = ResponseType

  get actionAvailable() {
    return !!this.screen.action
  }

  /////////////////////////////////
  // Methods
  /////////////////////////////////
  addAction(type: ActionType) {
    switch (type) {
      case ActionType.dragBox:
        this.$set(this.screen, 'action', model_action_drag_box.template_action())
        break
      case ActionType.dragConnect:
        this.$set(this.screen, 'action', model_action_drag_connect.template_action())
        break
      case ActionType.dragDrop:
        this.$set(this.screen, 'action', model_action_drag_drop.template_action())
        break
      case ActionType.dragPie:
        this.$set(this.screen, 'action', model_action_drag_pie.template_action())
        break
      case ActionType.dragRect:
        this.$set(this.screen, 'action', model_action_drag_rect.template_action())
        break
      case ActionType.dragText:
        this.$set(this.screen, 'action', model_action_drag_text.template_action())
        break
      case ActionType.drawAxis:
        this.$set(this.screen, 'action', model_action_draw_axis.template_action())
        break
      case ActionType.drawLine:
        this.$set(this.screen, 'action', model_action_draw_line.template_action())
        break
      case ActionType.drawPoint:
        this.$set(this.screen, 'action', model_action_draw_point.template_action())
        break
      case ActionType.drawPoly:
        this.$set(this.screen, 'action', model_action_draw_poly.template_action())
        break
      case ActionType.drawVector:
        this.$set(this.screen, 'action', model_action_draw_vector.template_action())
        break
      case ActionType.input:
        this.$set(this.screen, 'action', model_action_input.template_action())
        break
      case ActionType.mc:
        this.$set(this.screen, 'action', model_action_mc.template_action())
        break
      case ActionType.slideUnlock:
        this.$set(this.screen, 'action', model_action_slide_unlock.template_action())
        break
      case ActionType.table:
        this.$set(this.screen, 'action', model_action_table.template_action())
        break
      case ActionType.tuneChart:
        this.$set(this.screen, 'action', model_action_tune_chart.template_action())
        break
    }
  }

  deleteAction() {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.delete_low,
      title: `Delete action`,
      text: 'You can restore the action by resetting the unit. Changes will be permanent if you save the unit.<br><br><b>Keep in mind:</b> This will also delete all response videos of this action.',
      callback: (response: boolean) => {
        if (response) {
          this.$delete(this.screen, 'action')
          this.$delete(this.screen, 'actionResponses')
        }
      }
    })
  }

  pasteAction(action: ActionModel) {
    this.$set(this.screen, 'action', action)
  }
}
