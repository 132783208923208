


























import {Component, Prop, Vue} from "vue-property-decorator"
import Column from "@/components/fundamental/layout/Column.vue"
import Row from "@/components/fundamental/layout/Row.vue"
import Draggable from "@/components/fundamental/layout/Draggable.vue"
import Container from "@/components/fundamental/layout/Container.vue"
import SpacerBox from "@/components/fundamental/layout/SpacerBox.vue"
import TextButton from "@/components/fundamental/buttons/TextButton.vue"
import MirandaLesson from "@/pages/curriculum_viewer/courses/MirandaLesson.vue"
import {eventbus} from "@/main"
import {AlertDialogType, EventType} from "@/components/app/eventModel"
import util from "@/util/util"
import AddButton from "@/components/fundamental/buttons/AddButton.vue"
import model_course, { ChapterModel } from '@/models/curriculum/model_course'

@Component({
  components: {
    AddButton,
    MirandaLesson, TextButton, SpacerBox, Container, Draggable, Row, Column}
})
export default class MirandaLessons extends Vue {
  @Prop({type: Object, required: true}) chapter!: ChapterModel
  @Prop({type: Boolean, required: false, default: false}) isExam!: boolean

  addLesson() {
    this.chapter.le.push(model_course.template_lesson())
  }

  requestDeletingLesson(index: number) {
    eventbus.$emit(EventType.alertDialog, {
      alertType: AlertDialogType.delete_low,
      title: `Delete lesson`,
      text: `Delete the lesson: <b>${this.chapter.le[index].ti}</b>?<br><br> You can restore this lesson by resetting the course. Changes will be permanent if you save the course.`,
      callback: (response: boolean) => {
        if (response) this.deleteLesson(index)
      }
    })
  }

  deleteLesson(index: number) {
    util.removeFromArray(this.chapter.le, index)
  }
}
